<!-- メイン -->
<div class="main">
    <!-- メインコンテンツ -->
    <div class="main-content">
      <div class="p-shadow-24 login-card">
        <div class="login-content-wrapper">
          <div class="header p-grid p-nogutter">
            <button pButton pRipple type="button" icon="pi pi-arrow-left" class="p-button-rounded btn-back"
              *ngIf="screenMode!=Modes[0].code"
              (click)="changeScreenMode(screenMode-1)">
            </button>
            <div class="p-col-12 p-nogutter logo"> 
              <img
                class="header-text-title"
                src="../../../../assets/images/Delivery_logo2.png"
              />
              <!-- <div class="text">
                情報系荷物運搬便システム
              </div> -->
            </div>
            <div class="p-col-12 p-nogutter title" *ngIf="screenMode!=Modes[0].code">
              {{title}}
            </div>
          </div>
            <div class="message" *ngIf="message" [ngClass]="{
              'success':messageType==msgTypes[0].code,
              'warn':messageType==msgTypes[1].code,
              'error':messageType==msgTypes[2].code
              }"
            >
              {{message}}
            </div>
          <div class="body">
            <div *ngIf="screenMode==Modes[0].code" @onOff>
              <form [formGroup]="loginForm" autocomplete="off">
                <div class="p-inputgroup mb-10p">
                  <span class="p-inputgroup-addon"><i class="pi pi-envelope"></i></span>
                  <input type="text" name="email" pInputText placeholder="yours@example.com" formControlName="email">          
                </div>
                <div class="p-inputgroup mb-10p">
                  <span class="p-inputgroup-addon"><i class="pi pi-lock"></i></span>
                  <p-password name="password" [toggleMask]="true" placeholder="パスワード" [feedback]="false" formControlName="password"></p-password>
                </div>
              </form>
              <div class="alt-input">
                <a class="pointer" (click)="changeScreenMode(1)">
                  パスワードをお忘れですか？
                </a>
              </div>
            </div>  
            <div *ngIf="screenMode==Modes[1].code" @onOff>
              <p class="p-inputgroup mb-10p">
                メールを入力してください。  
              </p>
              <form [formGroup]="forgotPasswordForm" autocomplete="off">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon"><i class="pi pi-lock"></i></span>
                  <input type="text" name="email" pInputText placeholder="yours@example.com" formControlName="email">         
                </div>
                <app-validator-message [control]="forgotPasswordForm.get('email')">
                </app-validator-message>
              </form>
             
            </div>
            <div *ngIf="screenMode==Modes[2].code" @onOff>
              <p class="p-inputgroup mb-10p text-sm">
                {{forgotPasswordForm.controls.email.value}}に送信されたコードを入力して、 パスワードを更新してください。
              </p>
              <form [formGroup]="changePasswordForm" autocomplete="off">
                <div class="p-fluid">
                  <div class="p-field">
                    <label for="code">コード</label>
                    <div class="p-inputgroup mb-10p">
                      <span class="p-inputgroup-addon"><i class="pi pi-key"></i></span>
                      <input type="text" id="code" pInputText formControlName="code" (input)="checkCode(true)">         
                    </div>
                    <app-validator-message [control]="changePasswordForm.get('code')">
                    </app-validator-message>
                  </div>
                  <div class="p-field">
                    <label for="newPassword">新パスワード</label>
                    <div class="p-inputgroup mb-10p">
                      <span class="p-inputgroup-addon"><i class="pi pi-lock"></i></span>
                      <p-password name="password" id="newPassword" [toggleMask]="true" (input)="checkPassword(true)" [feedback]="false" formControlName="password"></p-password>
                    </div>
                    <app-validator-message [control]="changePasswordForm.get('password')">
                    </app-validator-message>
                  </div>
                  <div class="p-field">
                    <label for="re-newPassword">パスワード再入力</label>
                    <div class="p-inputgroup">
                      <span class="p-inputgroup-addon"><i class="pi pi-lock"></i></span>
                      <p-password name="password" id="re-newPassword" [toggleMask]="true" (input)="checkRePassword(true)" [feedback]="false" formControlName="re_password"></p-password>
                    </div>
                    <app-validator-message [control]="changePasswordForm.get('re_password')">
                    </app-validator-message>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="login-button">
          <button 
            pButton 
            type="button"
            [icon]="isLoading?'pi pi-spin pi-spinner':'pi pi-angle-right'"
            [ngClass]="{'font-spinner':isLoading}"
            [disabled]="!checkDisabledMainButton() || isLoading"
            (click)="screenMode==Modes[0].code?login(): screenMode==1?forgetPassword():changePassword()">
            {{ isLoading?'':loginButtonLabel}}
            </button>  
        </div>
      </div>
    </div>
</div>
