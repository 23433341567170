export const PDFConstant = {
  PAGE_TOP_X: 10,
  PAGE_TOP_Y: 10,
  PAGE_BOTTOM_X: 585.28,
  PAGE_BOTTOM_Y: 831.89,
  HEADER_X: 40,
  HEADER_Y: 30,
  TITLE_START_Y: 80,
  LINE_HEIGHT: 14,

  CONTENT_Y: 110,
  CONTENT_PADDING_X: 50,
  CONTENT_PADDING_Y: 30,
  CONTENT_LINE_HEIGHT: 18,

  FONT_SIZE_NORMAL: 12,
  FONT_SIZE_APPROVE: 10,
  FONT_SIZE_TITLE: 16,

  FONT_REGULAR_PATH: '/assets/fonts/Meiryo.ttf',
  FONT_BOLD_PATH: '/assets/fonts/Meiryo-Bold.ttf',
  FONT_BOLD: 'Meiryo-Bold',
  FONT_REGULAR: 'Meiryo-Regular',

  PAGE1: 'page1',
  PAGE2: 'page2',
  PAGE3: 'page3',

  // Header delivery
  DELIVERY_HEADER_1_1: "＜配送依頼票＞",
  DELIVERY_HEADER_1_2: "メールセンター控 1/3",

  DELIVERY_HEADER_2_1: "＜配送依頼票＞",
  DELIVERY_HEADER_2_2: "受領控 2/3",

  DELIVERY_HEADER_3_1: "＜配送依頼票＞",
  DELIVERY_HEADER_3_2: "荷物貼付用 3/3",

  PICKUP_HEADER_1_1: "＜集荷依頼票＞",
  PICKUP_HEADER_1_2: "メールセンター控 1/2",

  PICKUP_HEADER_2_1: "＜集荷依頼票＞",
  PICKUP_HEADER_2_2: "荷物貼付用 2/2",

  COMMON_HEADER_1: "集配依頼伝票（バイク・軽四輪）",
  COMMON_HEADER_2: "集配依頼伝票（バイク・軽四輪）　業者控え",
};
