import { Component, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { GenerateDisplayComponent } from 'src/app/shared/generate/generate-display/generate-display.component';
import { MessageCommonComponent } from 'src/app/shared/html-parts/message-common/message-common.component';
import { CONSTANT, DELIVERY_COLLECT_KBN, DELIVERY_KBN, LOADING_KEY, RESPONSE_DATA_WHEN_ADD_ORDER_DUMMY } from 'src/app/shared/constant/constant';
import { LoadData, LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import { DatePipe } from '@angular/common';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { C151_TEMPLATE } from 'manager/template-constant';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { DataView } from 'primeng/dataview';
import {
  API_URL_ORDER,
  API_URL_PREVIEW_ORDER, API_URL_WORK_DELIVERY_DETAIL,
} from 'manager/http-constants';
import { GenerateInputComponent } from 'src/app/shared/generate/generate-input/generate-input.component';
import { PdfService } from 'src/app/shared/service/pdf.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-c151',
  templateUrl: './c151.component.html',
  styleUrls: ['./c151.component.scss']
})
export class C151Component implements OnInit {
  /** 詳細画面 */
  @ViewChild(GenerateDisplayComponent)
  generateDisplayItemsComponent: GenerateDisplayComponent;

  /** メッセージ */
  @ViewChild(MessageCommonComponent)
  messageCommonComponent: MessageCommonComponent;

  @ViewChild('dv')
  dv: DataView;
  // 検索項目生成エンドポイント

  /** 新規登録、編集、複製画面 */
  @ViewChild(GenerateInputComponent)
  generateInputComponent: GenerateInputComponent;

  // 検索項目生成テンプレートID
  public searchTemplateId: number = C151_TEMPLATE.SEARCH_TEMPLATE_ID;

  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnOrder: any[];

  // 画面検索結果一覧格納先
  searchResultsList: any[];

  // list checked of delivery-search
  searchCheckbox: any[];

  // ページ数
  pages: number = 0;

  delivery_collect_kbn: string = DELIVERY_COLLECT_KBN.DELIVERY_0;

  delivery_kbn: number = 4;

  use_spot: number;

  SEARCH_RESULTS_TEMPLATE_ID: number;

  // カート選択リスト格納先
  cartSelected: any[] = [];

  // 検索条件保持
  generateSearchItems: Object;

  // 全選択チェックボックス
  allChecked: boolean = false;

  // checked value of items search
  checkedArr: any[];

  // dataView ソートカラム名
  sortField: string;

  // dataView ソート順 (1:昇順, -1:降順)
  sortOrder: number;

  existData: boolean = false;

  constructor(
    private dbOperationService: DbOperationService,
    private router: Router,
    private commonService: CommonService,
    public datePipe: DatePipe,
    private loadingState: LoadingState,
    private route: ActivatedRoute,
    private pdfService: PdfService,
    protected sanitizer: DomSanitizer
  ) { }

  async ngOnInit() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    const { delivery_collect_kbn } = this.route.snapshot.queryParams;
    if (delivery_collect_kbn) {
      this.delivery_collect_kbn = delivery_collect_kbn;
    } else {
      this.router.navigate(['/pages/platform/c151'], { queryParams: { delivery_collect_kbn: DELIVERY_COLLECT_KBN.DELIVERY_0 } });
    }

    await this.getWorkDeliveryDetail();

    this.SEARCH_RESULTS_TEMPLATE_ID = this.use_spot == 1 ? C151_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID_USE_SPOT_1 : C151_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID_USE_SPOT_0;

    // ヘッダー情報取得処理(画面用)
    this.dbOperationService
      .getHeaderList(this.SEARCH_RESULTS_TEMPLATE_ID)
      .subscribe((response) => {
        this.columnOrder = response.body;
      });

    this.searchResult();
  }

  /**
   * 検索処理
   * @param table テーブル状態
   * @param generateSearchItems 検索条件
   */
  public searchResult(table?: DataView, generateSearchItems?: Object) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
      })
    );

    // 販促資材マスタ一覧取得処理(画面用)
    this.dbOperationService
      .getData(
        API_URL_PREVIEW_ORDER,
        this.SEARCH_RESULTS_TEMPLATE_ID,
        {
          delivery_collect_kbn: [this.delivery_collect_kbn]
        },
        true
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 販促資材マスタ一覧情報のJSONをオブジェクトに格納する
          this.searchResultsList = response.body;
        } else {
          this.searchResultsList = [];
        }
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3);
      });

    // ページ数を初期化
    this.pages = 0;
  }

  /**
 * 検索処理
 * @param generateSearchItems 検索条件
 */
  selectRow(event: any) {
    // 届け先資材(WORK)一覧取得処理(画面用)

  }

  protected onCheckItem(dv: DataView) {
    const currentValue = this.cartSelected.pop();
    if (currentValue) {
      this.cartSelected = new Array(currentValue);
    } else {
      this.cartSelected = new Array();
    }
  }

  /**
 * dataView ソート処理
 */
  protected onSortChange(column: string) {
    let setOrder;
    if (this.sortField == column) {
      setOrder = this.sortOrder == 1 ? -1 : 1;
    } else {
      setOrder = 1
    }
    this.sortOrder = setOrder;
    this.sortField = column;
  }

  /**
 *  課題・ソリューション登録(C101)へ遷移する
 */
  public moveC141(): void {
    // 課題・ソリューション登録(C101)へ遷移
    this.router.navigate(['pages/platform/c141'], { queryParams: { delivery_collect_kbn: this.delivery_collect_kbn } });
  }


  /**
 * Get Work Delivery Detail
 * This function is used to get the work delivery detail information and update this.use_spot
 * @returns {Promise<any>} A promise that returns the response data
 */
  getWorkDeliveryDetail() {
    // 画面カスタムロードフラグをON(ロード中状態)
    return new Promise((resolve) => {
      this.dbOperationService
        .getData(
          API_URL_WORK_DELIVERY_DETAIL,
          C151_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID_USE_SPOT_0,
          {
            delivery_collect_kbn: [this.delivery_collect_kbn]
          }
        )
        .subscribe((response) => {
          // コード値の一覧情報が存在するか否か
          if (!this.commonService.checkNoneResponse(response)) {
            // 取得したデータをuse_spotに代入
            this.use_spot = response.body[0].use_spot === true ? 1 : 0;
            this.existData = true;
          }
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadSleepEnd(0.3);
          resolve(null);
        });
    });
  }
  /**
   * check overflow of element
   * @param el html element of list 
   * @returns 
  */
  isOverflow(el: HTMLElement): boolean {
    return el.clientWidth < el.scrollWidth;
  }

  onHover(e) {
    if (this.isOverflow(e.target)) {
      e.target.dataset.showtooltip = 'true'
    };
  }

  /**
   * カート追加
   */
  public addCart() {
    // カートが選択されているか否か
    if (!this.cartSelected) {
      // カートが選択されていない場合
      return;
    }

    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: CONSTANT.EMPTY_STRING,
        background_color: CONSTANT.EMPTY_STRING,
        opacity: 0.3,
      }),
      LOADING_KEY.DATA_INSERT
    );

    // カート登録
    this.dbOperationService
      .insertData(API_URL_ORDER, {
        delivery_collect_kbn: this.delivery_collect_kbn,
      })
      .subscribe(async (response) => {
        // 画面ロードフラグをOFF(ロード終了)
        // レスポンスを判定
        this.messageCommonComponent.clearToastMessage();
        if (this.messageCommonComponent.responseToastMessage2(response, true)) {
          // レスポンスが正常終了の場合
          if (response.body[0].delivery_kbn != DELIVERY_KBN.SPOT_3) {
            await this.outputPdf(response.body)
          }
          // 発注管理_届先選択へ遷移
          this.router.navigate(['pages/platform/c201']);
        }
        this.loadingState.loadEnd(LOADING_KEY.DATA_INSERT);
      });
  }

  async outputPdf(data) {

    if (data[0] == undefined) {
      data = RESPONSE_DATA_WHEN_ADD_ORDER_DUMMY
    }

    const newTab = window.open('', '_blank');

    this.pdfService.init();
    await this.pdfService.loadFont();
    const blob = await this.pdfService.export(data);

    const blobUrl = URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl));

    newTab.document.body.appendChild(iframe);
    iframe.contentWindow.print();
  }
}
