<div class="main">
    <div class="mobile">
        <img src="https://xxxxxjpg">
    </div>
    <!-- メインコンテンツ -->
    <div class="main-content">
      <!-- タイトル -->
            
			<div class="page-title">
					<label class="name">
                        「情報系荷物運搬便」とは？
					</label>
			</div>
			<div class="service-detail p-col-12 p-mb-3 card">
					<p>
                        主な配送・集荷の手法として、「エリア便」「ルート便」「スポット便」の３種類がある。<br>
                        予め登録されている得意先／TOPPAN拠点を、リクエストに応じて回る配達・集荷便。<br>
                        コース毎に回るエリアが決まっていて、エリア内のどの拠点を回るかは、その日のリクエストに応じて決まる。<br>
                        そのため、出発時刻は決まっているが、各拠点への到着時刻にはブレが生じる。			
                    </p>
			</div>
			<div class="service-image p-d-flex p-jc-center p-mb-3 p-col-12 card desktop">
					<img src="https://xxxxx.jpg">
			</div>
			<div class="trade-mark p-col-12 card p-mb-3">
					xxxxx
			</div>
    </div>
</div>
<div class="footer desktop">
    <div class="p-col-12 head p-d-flex">
        <ng-container>
            <div class="p-col-5">

            </div>
            <div class="p-col-5">
                <button
                    pButton
                    type="button"
                    label="プライバシーポリシー"
                    class="p-button-text"
                    (click)="null"
                ></button>
                |
                <button
                    pButton
                    type="button"
                    label="マニュアル(学校ユーザー向け)"
                    class="p-button-text"
                    (click)="getManualSchool()"
                ></button>
                |
                <button
                    pButton
                    type="button"
                    label="マニュアル(支援ユーザー向け)"
                    class="p-button-text"
                    (click)="getManualSupport()"
                ></button>
                |
                <button
                    pButton
                    type="button"
                    label="お問い合わせ"
                    class="p-button-text"
                    (click)="moveContact()"
                ></button>
            </div>
        </ng-container>
    </div>
        
    <div class="p-col-12 body p-grid p-m-0">
        <div class="p-col-12 p-d-flex cp-0"><div class="p-col-5"></div><div class="p-col-5">運営団体：NPO法人 会津エンジン</div></div>
        <div class="p-col-12 p-d-flex cp-0"><div class="p-col-5"></div><div class="p-col-5">〒965-0042</div></div>
        <div class="p-col-12 p-d-flex cp-0"><div class="p-col-5"></div><div class="p-col-5">福島県会津若松市大町1-1-41（株式会社わーくすたいる内）</div></div>
        <div class="p-col-12 p-d-flex cp-0"><div class="p-col-5"></div><div class="p-col-5">0242-85-6262</div></div>
        <div class="p-col-12 p-d-flex cp-0"><div class="p-col-5"></div><div class="p-col-5">受付時間：平日9時～17時</div></div>
    </div>
</div>
<div class="footer mobile">
    <div class="p-col-12 head p-d-flex p-jc-center">
        <ng-container>
            
            <div class="p-col-7">
                <button
                    pButton
                    type="button"
                    label="プライバシーポリシー"
                    class="p-button-text"
                    (click)="null"
                ></button>
                |
                <button
                    pButton
                    type="button"
                    label="マニュアル(学校ユーザー向け)"
                    class="p-button-text"
                    (click)="getManualSchool()"
                ></button>
                |
                <button
                    pButton
                    type="button"
                    label="マニュアル(支援ユーザー向け)"
                    class="p-button-text"
                    (click)="getManualSupport()"
                ></button>
                |
                <button
                    pButton
                    type="button"
                    label="お問い合わせ"
                    class="p-button-text"
                    (click)="moveContact()"
                ></button>
            </div>
        </ng-container>
    </div>
        
    <div class="p-col-12 body p-grid p-m-0 p-jc-center ">
        <div class="p-col-7">運営団体：NPO法人 会津エンジン</div>
        <div class="p-col-7">〒965-0042</div>
        <div class="p-col-7">福島県会津若松市大町1-1-41（株式会社わーくすたいる内）</div>
        <div class="p-col-7">0242-85-6262</div>
        <div class="p-col-7">受付時間：平日9時～17時</div>
    </div>
</div>