import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SESSION_KEY } from 'manager/environment';
import {
  API_URL_USER,
  API_URL_USER_ADD,
  API_URL_USER_DELETE,
  API_URL_USER_DETAIL,
  API_URL_USER_DUPLICATE,
  API_URL_INPUT_MASTER,
  API_URL_GENERATE_SEARCH,
} from 'manager/http-constants';
import { USER_M301_TEMPLATE } from 'manager/template-constant';
import { CONSTANT, ROLES } from 'src/app/shared/constant/constant';
import { GenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import { GenerateDisplayComponent } from 'src/app/shared/generate/generate-display/generate-display.component';
import { GENERATE_INPUT_TYPE } from 'src/app/shared/generate/generate-input/constant';
import { GenerateInputComponent } from 'src/app/shared/generate/generate-input/generate-input.component';
import { DeleteDialogComponent } from 'src/app/shared/html-parts/delete-dialog/delete-dialog.component';
import { ExportFileService } from 'src/app/shared/html-parts/export-file/export-file.service';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { AGENDA_SOLUTION_CONSTANT } from './constant';

@Component({
  selector: 'app-m301',
  templateUrl: '../master.component.html',
  styleUrls: ['../master.component.scss', '../../platform.component.scss'],
})
export class M301Component implements OnInit {

  /** 詳細画面 */
  @ViewChild(GenerateDisplayComponent)
  generateDisplayItemsComponent: GenerateDisplayComponent;

  /** 新規登録、編集、複製画面 */
  @ViewChild(GenerateInputComponent)
  generateInputComponent: GenerateInputComponent;

  /** 削除ダイアログ */
  @ViewChild(DeleteDialogComponent)
  deleteDialogComponent: DeleteDialogComponent;

  departmentType: any = JSON.parse(window.sessionStorage.getItem(SESSION_KEY.loginUserInformation))?.department_type;
  // 検索項目生成エンドポイント
  public searchEndPoint: string = API_URL_GENERATE_SEARCH;

  // 検索項目生成テンプレートID
  public searchTemplateId: number = USER_M301_TEMPLATE.SEARCH_TEMPLATE_ID;

  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnOrder: any[] = new Array();

  // 画面検索結果一覧格納先
  searchResultsList: any[] = new Array();

  // 検索条件保持
  generateSearchItems: Object;

  // set first page = 1 
  pages: number = 0;
  showLinkPDF: boolean = true;
  m301 = true
  innerWidth: any;
  checkMobile: boolean = false;
  isShowSearchMobile: boolean = false;
  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    public datePipe: DatePipe,
    private changeDetector: ChangeDetectorRef,
    private exportFileService: ExportFileService
  ) {
    //  super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    /* ヘッダー情報取得処理(画面用) */
    this.dbOperationService
      .getHeaderList(USER_M301_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID)
      .subscribe((response) => {
        if ((this.innerWidth > 450 && window.screen.orientation.type == 'portrait-primary') || (window.innerHeight > 450 && window.screen.orientation.type == 'landscape-primary')) {
          this.columnOrder = response.body
        }
        else {
          this.columnOrder = response.body.slice(0, 4);
          let temp = this.columnOrder.pop()
          this.columnOrder.unshift(temp)
          this.checkMobile = true
        }
      });

    // 検索処理を実施
    this.searchResult();
  }

  /**
   * 検索処理
   * @param generateSearchItems 検索条件
   */
  public searchResult(generateSearchItems?: Object) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.loadStart(API_URL_USER);

    // CSV出力用に検索条件を保持
    this.generateSearchItems = generateSearchItems;

    /* 課題・ソリューション一覧取得処理(画面用) */
    this.dbOperationService
      .getForkJoinData(
        AGENDA_SOLUTION_CONSTANT.TABLE,
        API_URL_USER,
        USER_M301_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID,
        generateSearchItems,
        true
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 課題・ソリューション一覧情報のJSONをオブジェクトに格納する
          this.searchResultsList = response.body;
        } else {
          this.searchResultsList = new Array();
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, API_URL_USER);
        //return to first page
        this.pages = 0;
      });


  }

  isOverflow(el: HTMLElement): boolean {
    return el.clientWidth < el.scrollWidth;
  }

  onHover(e){
    if (this.isOverflow(e.target)) {
      e.target.dataset.showtooltip='true'
    };
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
  /**
 * 新規登録画面表示
 */
  public new() {
    // 新規登録画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.NEW,
      API_URL_INPUT_MASTER,
      API_URL_USER_ADD,
      API_URL_USER_ADD,
      USER_M301_TEMPLATE.INPUT_DUPLICATE_OR_ADD_TEMPLATE_ID,
      null
    );
  }

  /**
 * 編集画面表示
 * @param pkeyId IDコード(クリックされたID)
 */
  public edit(pkeyId: string) {
    // 編集画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.EDIT,
      API_URL_INPUT_MASTER,
      API_URL_USER_DETAIL,
      API_URL_USER_ADD,
      USER_M301_TEMPLATE.INPUT_TEMPLATE_ID,
      pkeyId
    );
  }
  /**
   * 複製画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  public duplicate(pkeyId: string) {
    // 複製画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.DUPLICATE,
      API_URL_INPUT_MASTER,
      API_URL_USER_DUPLICATE,
      API_URL_USER_ADD,
      USER_M301_TEMPLATE.INPUT_DUPLICATE_OR_ADD_TEMPLATE_ID,
      pkeyId
    );
  }
  /**
   * 詳細情報画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  protected detail(pkeyId: string) {

    // 詳細画面表示
    let generateDisplay: GenerateDisplay = new GenerateDisplay();
    generateDisplay.endPoint = API_URL_USER_DETAIL;
    generateDisplay.templateId = USER_M301_TEMPLATE.OUTPUT_TEMPLATE_ID;
    this.generateDisplayItemsComponent.initial(generateDisplay, pkeyId);
  }

  /**
* 削除処理
* @param pkeyId IDコード(クリックされたID)
*/
  public delete(pkeyId: string) {
    const title = '削除';
    // 削除ダイアログにより削除処理
    this.deleteDialogComponent.deleteDialog(
      API_URL_USER_DELETE,
      this.columnOrder[0].header,
      pkeyId,
      title
    );
  }

  /**
   * プライマリーカラムを取得
   */
  protected getPkeyColumn(): string {
    return this.commonService.getPkeyColumn(this.columnOrder);
  }

  /**
 * 再検索処理＆データ置換
 * @param pkeyId コード値
 */
  public searchReplacement(pkeyId: string) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.loadStart(API_URL_USER);


    // 返却されたコード値が存在するか否か
    if (null == pkeyId || undefined == pkeyId) {
      // 返却されたコード値が存在しない場合

      // 検索処理を実施
      this.searchResult();

      return;
    }
    const colId = this.checkMobile ? 1 : 0;
    // 営業日マスタ一覧取得処理(画面用)
    this.dbOperationService
      .getSingleData(
        API_URL_USER,
        USER_M301_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID,
        this.columnOrder[colId].field,
        pkeyId
      )
      .subscribe((response) => {

        // コード値の一覧情報が存在するか否か
        if (this.commonService.checkNoneResponse(response)) {
          // 一覧情報が存在しない場合

          // 検索結果一覧から該当データを削除
          this.searchResultsList = this.searchResultsList.filter(
            (val) => val[this.columnOrder[colId].field] !== pkeyId
          );

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadSleepEnd(0.3, API_URL_USER);
        } else {
          // 一覧情報が存在する場合

          // 検索結果一覧格納先から対象のデータの行数を抽出
          var indexTargetData = this.searchResultsList.indexOf(
            this.searchResultsList.find((results) => {
              return results[this.columnOrder[colId].field] === pkeyId;
            })
          );

          // 検索結果一覧情報のJSONをオブジェクトに置換する
          this.searchResultsList.splice(indexTargetData, 1, response.body[0]);

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadSleepEnd(0.3, API_URL_USER);
        }
      });
  }

  /**
 * CSV出力
 */
  public exportCSV() {
    // csvファイル名の設定
    const fileName = AGENDA_SOLUTION_CONSTANT.CSV_FILENAME + CONSTANT.UNDERBAR + this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    // CSVファイル出力
    this.exportFileService.exportTemplateCsv(
      fileName,
      AGENDA_SOLUTION_CONSTANT.TABLE,
      API_URL_USER,
      USER_M301_TEMPLATE.OUTPUT_TEMPLATE_ID,
      this.generateSearchItems,
      true,
    );
  }

  public authorizeCrudButton(buttonName?: string) {
    const departmentType = JSON.parse(window.sessionStorage.getItem(SESSION_KEY.loginUserInformation))?.department_type;
    const path: string = this.route.routeConfig.path;

    if (departmentType == ROLES.ADMIN_9 || departmentType == ROLES.COMMON_3) {
      return true;
    }

    if (path.includes("pages/platform/master/m301") && (buttonName == "edit" || buttonName == "header") && departmentType == ROLES.SALES_1) {
      return true;
    }

    return false;
  }
}
