<!-- ページアンカー -->
<a id="jump-page-top"></a>
<div class="content">
  <!-- ヘッダー -->
  <header>
    <app-header [headerFlag]="headerFlag"></app-header>
  </header>
  <div *ngIf="breadcrumbTitle && controlCreadcrumbTitle()" class="breadcrumb-title">
    <div class="label">{{breadcrumbTitle}}</div> 
  </div>
  <!-- メイン画面 -->
  <main>
    <div class="main">
      <router-outlet></router-outlet>
    </div>
  </main>
  <footer>
    <app-footer *ngIf="headerFlag"></app-footer>
  </footer>
</div>

<!-- 確認ダイアログ -->
<app-confirm-dialog></app-confirm-dialog>

<!-- メッセージ -->
<app-message-common></app-message-common>

<!-- ローディング画面 -->
<app-loading></app-loading>
