import { CONSTANT } from "src/app/shared/constant/constant";
import { MESSAGE_CODE } from "src/app/shared/constant/message-constant";
import { PROD_DOMAIN, STAGING_DOMAIN, DEMO_DOMAIN, TEST_DOMAIN, TPDEV_DOMAIN } from "./environment";

/**
 * API URL取得用キー
 */

/**
 * 共通
 */
// ログインユーザ情報取得
export const USER_AUTHINFOS = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/userauthinfos/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/userauthinfos/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/userauthinfos/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/userauthinfos/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/userauthinfos/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/userauthinfos/",
);
// ヘッダー項目生成
export const HEADER_LIST = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/headerlist/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/headerlist/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/headerlist/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/headerlist/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/headerlist/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/headerlist/",
);
// バリデーションチェック
export const API_URL_VALIDATION_RESULT = getUrl(
  "https://h3an7fve5g.execute-api.ap-northeast-1.amazonaws.com/dev/validationresult/",
  "https://9x8fi91vd4.execute-api.ap-northeast-1.amazonaws.com/test/validationresult/",
  "https://uruzr2hh08.execute-api.ap-northeast-1.amazonaws.com/staging/validationresult/",
  "https://zzzzz.execute-api.ap-northeast-1.amazonaws.com/prod/validationresult/",
  "https://hbf235tf33.execute-api.ap-northeast-1.amazonaws.com/demo/validationresult/",
  "https://t1khtwjov4.execute-api.ap-northeast-1.amazonaws.com/tpdev/validationresult/",
);
// バリデーションチェック(複数)
export const API_URL_MULTIVALIDATION_RESULT = getUrl(
  "https://h3an7fve5g.execute-api.ap-northeast-1.amazonaws.com/dev/multivalidationresult/",
  "https://9x8fi91vd4.execute-api.ap-northeast-1.amazonaws.com/test/multivalidationresult/",
  "https://uruzr2hh08.execute-api.ap-northeast-1.amazonaws.com/staging/multivalidationresult/",
  "https://zzzzz.execute-api.ap-northeast-1.amazonaws.com/prod/multivalidationresult/",
  "https://hbf235tf33.execute-api.ap-northeast-1.amazonaws.com/demo/multivalidationresult/",
  "https://t1khtwjov4.execute-api.ap-northeast-1.amazonaws.com/tpdev/multivalidationresult/",
);

// ブロック実行必要回数の取得
export const API_URL_RUN_COUNT = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/master/runcount/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/master/runcount/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/master/runcount/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/master/runcount/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/master/runcount/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/master/runcount/",
);

// ブロック実行必要回数の取得
export const API_URL_RUN_COUNT_PUB = getUrl(
  "https://h3an7fve5g.execute-api.ap-northeast-1.amazonaws.com/dev/runcountpub/",
  "https://9x8fi91vd4.execute-api.ap-northeast-1.amazonaws.com/test/runcountpub/",
  "https://uruzr2hh08.execute-api.ap-northeast-1.amazonaws.com/staging/runcountpub/",
  "https://zzzzz.execute-api.ap-northeast-1.amazonaws.com/prod/runcountpub/",
  "https://hbf235tf33.execute-api.ap-northeast-1.amazonaws.com/demo/runcountpub/",
  "https://t1khtwjov4.execute-api.ap-northeast-1.amazonaws.com/demo/runcountpub/",
);

// 全辞書値取得
export const API_URL_DIC_LISTS = getUrl(
  "https://h3an7fve5g.execute-api.ap-northeast-1.amazonaws.com/dev/spec-code-masters/",
  "https://9x8fi91vd4.execute-api.ap-northeast-1.amazonaws.com/test/spec-code-masters/",
  "https://uruzr2hh08.execute-api.ap-northeast-1.amazonaws.com/staging/spec-code-masters/",
  "https://zzzzz.execute-api.ap-northeast-1.amazonaws.com/prod/spec-code-masters/",
  "https://hbf235tf33.execute-api.ap-northeast-1.amazonaws.com/demo/spec-code-masters/",
  "https://t1khtwjov4.execute-api.ap-northeast-1.amazonaws.com/tpdev/spec-code-masters/",
);

// ファイルアップロード（JOB登録）
export const API_URL_APPLICATIONS_FILES = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/applications/files/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/applications/files/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/applications/files/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/applications/files/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/applications/files/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/applications/files/",
);

// 郵便番号マスタ一覧
export const POSTALS_LIST = getUrl(
  "https://lwyjs61ai5.execute-api.ap-northeast-1.amazonaws.com/dev/postals/list/",
  "https://9x8fi91vd4.execute-api.ap-northeast-1.amazonaws.com/test/postals/list/",
  "https://uruzr2hh08.execute-api.ap-northeast-1.amazonaws.com/staging/postals/list/",
  "https://zzzzz.execute-api.ap-northeast-1.amazonaws.com/prod/postals/list/",
  "https://hbf235tf33.execute-api.ap-northeast-1.amazonaws.com/demo/postals/list/",
  "https://t1khtwjov4.execute-api.ap-northeast-1.amazonaws.com/tpdev/postals/list/",
);

// 会員申請登録
export const APPLICATIONS = getUrl(
  "https://h3an7fve5g.execute-api.ap-northeast-1.amazonaws.com/dev/applications/",
  "https://9x8fi91vd4.execute-api.ap-northeast-1.amazonaws.com/test/applications/",
  "https://uruzr2hh08.execute-api.ap-northeast-1.amazonaws.com/staging/applications/",
  "https://zzzzz.execute-api.ap-northeast-1.amazonaws.com/prod/applications/",
  "https://hbf235tf33.execute-api.ap-northeast-1.amazonaws.com/demo/applications/",
  "https://t1khtwjov4.execute-api.ap-northeast-1.amazonaws.com/tpdev/applications/",
);

// 確認コードチェック（会員プロフィール、ユーザへの分離作成）
export const AUTH_CODES = getUrl(
  "https://h3an7fve5g.execute-api.ap-northeast-1.amazonaws.com/dev/authcodes/",
  "https://9x8fi91vd4.execute-api.ap-northeast-1.amazonaws.com/test/authcodes/",
  "https://uruzr2hh08.execute-api.ap-northeast-1.amazonaws.com/staging/authcodes/",
  "https://zzzzz.execute-api.ap-northeast-1.amazonaws.com/prod/authcodes/",
  "https://hbf235tf33.execute-api.ap-northeast-1.amazonaws.com/demo/authcodes/",
  "https://t1khtwjov4.execute-api.ap-northeast-1.amazonaws.com/tpdev/authcodes/",
);

// 認証コード再送信
export const AUTH_CODES_SEND = getUrl(
  "https://h3an7fve5g.execute-api.ap-northeast-1.amazonaws.com/dev/authcodes/send",
  "https://9x8fi91vd4.execute-api.ap-northeast-1.amazonaws.com/test/authcodes/send/",
  "https://uruzr2hh08.execute-api.ap-northeast-1.amazonaws.com/staging/authcodes/send/",
  "https://zzzzz.execute-api.ap-northeast-1.amazonaws.com/prod/authcodes/send/",
  "https://hbf235tf33.execute-api.ap-northeast-1.amazonaws.com/demo/authcodes/send/",
  "https://t1khtwjov4.execute-api.ap-northeast-1.amazonaws.com/tpdev/authcodes/send/",
);

// メールアドレス入力チェック
export const EMAIL_CHECKS = getUrl(
  "https://h3an7fve5g.execute-api.ap-northeast-1.amazonaws.com/dev/emailchecks/",
  "https://9x8fi91vd4.execute-api.ap-northeast-1.amazonaws.com/test/emailchecks/",
  "https://uruzr2hh08.execute-api.ap-northeast-1.amazonaws.com/staging/emailchecks/",
  "https://zzzzz.execute-api.ap-northeast-1.amazonaws.com/prod/emailchecks/",
  "https://hbf235tf33.execute-api.ap-northeast-1.amazonaws.com/demo/emailchecks/",
  "https://t1khtwjov4.execute-api.ap-northeast-1.amazonaws.com/tpdev/emailchecks/",
);

// パスワード設定
export const PASSWORDS = getUrl(
  "https://h3an7fve5g.execute-api.ap-northeast-1.amazonaws.com/dev/passwords/",
  "https://9x8fi91vd4.execute-api.ap-northeast-1.amazonaws.com/test/passwords/",
  "https://uruzr2hh08.execute-api.ap-northeast-1.amazonaws.com/staging/passwords/",
  "https://zzzzz.execute-api.ap-northeast-1.amazonaws.com/prod/passwords/",
  "https://hbf235tf33.execute-api.ap-northeast-1.amazonaws.com/demo/passwords/",
  "https://t1khtwjov4.execute-api.ap-northeast-1.amazonaws.com/tpdev/passwords/",
);

// 入会申請詳細
export const APPLICATIONS_DETAIL = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/applications/detail/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/applications/detail/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/applications/detail/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/applications/detail/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/applications/detail/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/applications/detail/",
);


// 未承認一覧(入会申請承認)
export const APPLICATIONS_APPROVE = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/applications/approve/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/applications/approve/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/applications/approve/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/applications/approve/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/applications/approve/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/applications/approve/",
);

// カテゴリ一覧取得
export const AGENDA_CATEGORY_LIST = getUrl(
  "https://h3an7fve5g.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_category/list/",
  "https://9x8fi91vd4.execute-api.ap-northeast-1.amazonaws.com/test/agenda_category/list/",
  "https://uruzr2hh08.execute-api.ap-northeast-1.amazonaws.com/staging/agenda_category/list/",
  "https://zzzzz.execute-api.ap-northeast-1.amazonaws.com/prod/agenda_category/list/",
  "https://hbf235tf33.execute-api.ap-northeast-1.amazonaws.com/demo/agenda_category/list/",
  "https://t1khtwjov4.execute-api.ap-northeast-1.amazonaws.com/tpdev/agenda_category/list/",
);

// 新着情報一覧
export const WHAT_SNEWS = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/whatsnews/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/whatsnews/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/whatsnews/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/whatsnews/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/whatsnews/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/whatsnews/",
);

// 課題・ソリューション一覧
export const AGENDA_SOLUTIONS_LIST = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/mypage/agenda_solutions/list",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/mypage/agenda_solutions/list",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/mypage/agenda_solutions/list",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/mypage/agenda_solutions/list",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/mypage/agenda_solutions/list",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/mypage/agenda_solutions/list",
);

export const PUB_AGENDA_SOLUTIONS_LIST = getUrl(
  "https://h3an7fve5g.execute-api.ap-northeast-1.amazonaws.com/dev/mypage/agenda_solutions_pub/list",
  "https://9x8fi91vd4.execute-api.ap-northeast-1.amazonaws.com/test/mypage/agenda_solutions_pub/list",
  "https://uruzr2hh08.execute-api.ap-northeast-1.amazonaws.com/staging/mypage/agenda_solutions_pub/list",
  "https://zzzzz.execute-api.ap-northeast-1.amazonaws.com/prod/mypage/agenda_solutions_pub/list",
  "https://hbf235tf33.execute-api.ap-northeast-1.amazonaws.com/demo/mypage/agenda_solutions_pub/list",
  "https://t1khtwjov4.execute-api.ap-northeast-1.amazonaws.com/demo/mypage/agenda_solutions_pub/list",
);

// addtition image to url of test
export const AGENDA_NEEDS_LIST = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/mypage/agenda_needs/list/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/mypage/agenda_needs/list/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/mypage/agenda_needs/list/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/mypage/agenda_needs/list/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/mypage/agenda_needs/list/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/mypage/agenda_needs/list/",
);

// 課題・ソリューション詳細
export const LOGIN_NOT_AGENDA_SOLUTIONS = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_solutions/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/agenda_solutions/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/agenda_solutions/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/agenda_solutions/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/agenda_solutions/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/agenda_solutions/",
);

// 課題・ソリューション詳細
export const LOGIN_NOT_AGENDA_SOLUTIONS_PUB = getUrl(
  "https://h3an7fve5g.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_solutions_pub/",
  "https://9x8fi91vd4.execute-api.ap-northeast-1.amazonaws.com/test/agenda_solutions_pub/",
  "https://uruzr2hh08.execute-api.ap-northeast-1.amazonaws.com/staging/agenda_solutions_pub/",
  "https://zzzzz.execute-api.ap-northeast-1.amazonaws.com/prod/agenda_solutions_pub/",
  "https://hbf235tf33.execute-api.ap-northeast-1.amazonaws.com/demo/agenda_solutions_pub/",
  "https://t1khtwjov4.execute-api.ap-northeast-1.amazonaws.com/demo/agenda_solutions_pub/",
);


// 課題・ソリューション登録・更新
export const LOGIN_DONE_AGENDA_SOLUTIONS = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_solutions/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/agenda_solutions/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/agenda_solutions/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/agenda_solutions/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/agenda_solutions/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/agenda_solutions/",
);

// 課題・ソリューション承認
export const AGENDA_SOLUTIONS_APPROVE = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_solutions/approve/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/agenda_solutions/approve/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/agenda_solutions/approve/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/agenda_solutions/approve/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/agenda_solutions/approve/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/agenda_solutions/approve/",
);

// 課題・ソリューション件数取得
export const AGENDA_CATEGORY_COUNT = getUrl(
  "https://lwyjs61ai5.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_category/count/",
  "https://9x8fi91vd4.execute-api.ap-northeast-1.amazonaws.com/test/agenda_category/count/",
  "https://uruzr2hh08.execute-api.ap-northeast-1.amazonaws.com/staging/agenda_category/count/",
  "https://zzzzz.execute-api.ap-northeast-1.amazonaws.com/prod/agenda_category/count/",
  "https://hbf235tf33.execute-api.ap-northeast-1.amazonaws.com/demo/agenda_category/count/",
  "https://t1khtwjov4.execute-api.ap-northeast-1.amazonaws.com/tpdev/agenda_category/count/",
);


// 掲示板コメント(GET:取得 POST:登録 DELETE:削除)
export const BOARDS = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/boards/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/boards/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/boards/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/boards/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/boards/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/boards/",
);

// 掲示板コメント削除(DELETE:削除)
export const DELETE_BOARDS = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/boards/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/boards/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/boards/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/boards/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/boards/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/boards/",
);

// 掲示板コメント件数取得
export const BOARDS_COUNT = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/boards/count/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/boards/count/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/boards/count/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/boards/count/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/boards/count/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/boards/count/",
);

// マッチングリスト取得
export const AGENDA_SOLUTIONS_SUGGEST = getUrl(
  "https://lwyjs61ai5.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_solutions/suggest/",
  "https://9x8fi91vd4.execute-api.ap-northeast-1.amazonaws.com/test/agenda_solutions/suggest/",
  "https://uruzr2hh08.execute-api.ap-northeast-1.amazonaws.com/staging/agenda_solutions/suggest/",
  "https://zzzzz.execute-api.ap-northeast-1.amazonaws.com/prod/agenda_solutions/suggest/",
  "https://hbf235tf33.execute-api.ap-northeast-1.amazonaws.com/demo/agenda_solutions/suggest/",
  "https://t1khtwjov4.execute-api.ap-northeast-1.amazonaws.com/tpdev/agenda_solutions/suggest/",
);

// チャットリスト取得
export const CHAT_LIST = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/chat/list/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/chat/list/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/chat/list/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/chat/list/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/chat/list/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/chat/list/",
);

// 会員一覧取得
export const MEMBERS_LIST = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/members/list/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/members/list/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/members/list/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/members/list/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/members/list/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/members/list/",
);

// 会員の件数取得
export const MEMBER_COUNT = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/members/count",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/members/count",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/members/count",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/members/count",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/members/count",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/members/count",
);

// 会員プロファイル更新
export const MEMBER_INFO_UPDATE = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/member/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/member/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/member/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/member/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/member/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/member/",
);

// 会員プロフィール詳細
export const MEMBERS_DETAIL = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/members/detail/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/members/detail/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/members/detail/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/members/detail/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/members/detail/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/members/detail/",
);

export const MEMBERS_DETAIL_MASTER = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/member/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/member/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/member/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/member/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/member/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/member/",
);

// 会員プロフィール詳細課題一覧
export const MEMBERS_AGENDA = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/members/agenda/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/members/agenda/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/members/agenda/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/members/agenda/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/members/agenda/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/members/agenda/",
);

// 会員プロフィール詳細 前回情報
export const MEMBERS_DETAIL_LOG = getUrl(
  "https://aaaaa.execute-api.ap-northeast-1.amazonaws.com/dev/member_logs/detail/",
  "https://aaaaa.execute-api.ap-northeast-1.amazonaws.com/dev/member_logs/detail/",
  "https://aaaaa.execute-api.ap-northeast-1.amazonaws.com/dev/member_logs/detail/",
  "https://aaaaa.execute-api.ap-northeast-1.amazonaws.com/dev/member_logs/detail/",
  "https://aaaaa.execute-api.ap-northeast-1.amazonaws.com/dev/member_logs/detail/",
  "https://aaaaa.execute-api.ap-northeast-1.amazonaws.com/dev/member_logs/detail/",
);

// ユーザ一覧取得
export const USER_LIST = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/users/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/users/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/users/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/users/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/users/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/users/",
);

// ユーザ一覧詳細
export const USER_GENERATE = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/users/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/users/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/users/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/users/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/users/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/users/",
);

// ユーザ登録
export const USER_ADD = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/users/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/users/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/users/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/users/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/users/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/users/",
);

// ユーザ編集
export const USER_EDIT = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/users/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/users/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/users/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/users/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/users/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/users/",
);

// ユーザ編集
export const USER_DELETE = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/users/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/users/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/users/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/users/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/users/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/users/",
);

// ユーザ編集 master
export const USER_DELETE_MASTER = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/users_master/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/users_master/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/users_master/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/users_master/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/users_master/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/users_master/",
);

export const DELIVERY_DELETE_MASTER = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/master/delivery/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/master/delivery/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/master/delivery/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/master/delivery/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/master/delivery/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/master/delivery/",
);


// 未承認入会申請一覧
export const UNAPPROVE_APPLICATION_LIST = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/unapproves/application",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/unapproves/application",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/unapproves/application",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/unapproves/application",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/unapproves/application",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/unapproves/application",
);

// 未承認件数取得
export const UNAPPROVES_COUNT = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/unapproves/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/unapproves/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/unapproves/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/unapproves/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/unapproves/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/unapproves/",
);

// 未承認一覧（提案）
export const UNAPPROVES_SUGGEST_LIST = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/unapproves/suggest",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/unapproves/suggest",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/unapproves/suggest",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/unapproves/suggest",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/unapproves/suggest",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/unapproves/suggest",
);

// 未承認一覧(課題・ソリューション)
export const UNAPPROVES_AGENDA_SOLUTION = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/unapproves/agenda_solution",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/unapproves/agenda_solution",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/unapproves/agenda_solution",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/unapproves/agenda_solution",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/unapproves/agenda_solution",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/unapproves/agenda_solution",
);

// 課題・ソリューションマスタ管理／REST API(GET:一覧/GET:詳細)
export const API_URL_USER = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/master/users/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/master/users/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/master/users/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/master/users/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/master/users/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/master/users/",
);

export const API_URL_DELIVERY = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/master/delivery/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/master/delivery/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/master/delivery/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/master/delivery/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/master/delivery/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/master/delivery/",
);

export const API_URL_DELIVERY_DUPLICATE = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/master/delivery/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/master/delivery/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/master/delivery/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/master/delivery/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/master/delivery/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/master/delivery/",
);

// 検索項目取得／検索画面(GET)
export const API_URL_GENERATE_SEARCH = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/master/search-item/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/master/search-item/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/master/search-item/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/master/search-item/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/master/search-item/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/master/search-item/",
);

// お知らせ一覧取得
export const INFORMATION_LIST = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/informations/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/informations/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/informations/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/informations/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/informations/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/informations/",
);
// E002 
// お知らせ一覧取得
export const INFORMATION = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/informations/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/informations/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/informations/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/informations/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/informations/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/informations/",
);


// お知らせ詳細
export const INFORMATION_DETAIL = getUrl(
  "https://lwyjs61ai5.execute-api.ap-northeast-1.amazonaws.com/dev/informations/detail/",
  "https://9x8fi91vd4.execute-api.ap-northeast-1.amazonaws.com/test/informations/detail/",
  "https://uruzr2hh08.execute-api.ap-northeast-1.amazonaws.com/staging/informations/detail/",
  "https://zzzzz.execute-api.ap-northeast-1.amazonaws.com/prod/informations/detail/",
  "https://hbf235tf33.execute-api.ap-northeast-1.amazonaws.com/demo/informations/detail/",
  "https://t1khtwjov4.execute-api.ap-northeast-1.amazonaws.com/tpdev/informations/detail/",
);

// お知らせ登録
export const INFORMATION_INSERT = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/informations/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/informations/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/informations/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/informations/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/informations/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/informations/",
);

// 会員プロフィール申請
export const MEMBERS_PROFILE_APPROVE = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/members/approve/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/members/approve/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/members/approve/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/members/approve/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/members/approve/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/members/approve/",
);

// 会員プロフィール未登録項目チェック(GET)
export const NEEDINPUT_STATUS = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/inputchecks/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/inputchecks/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/inputchecks/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/inputchecks/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/inputchecks/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/inputchecks/",
);

export const API_URL_INFORMATION = getUrl(
  "https://aaaaa.execute-api.ap-northeast-1.amazonaws.com/dev/informations/",
  "https://aaaaa.execute-api.ap-northeast-1.amazonaws.com/dev/informations/",
  "https://aaaaa.execute-api.ap-northeast-1.amazonaws.com/dev/informations/",
  "https://aaaaa.execute-api.ap-northeast-1.amazonaws.com/dev/informations/",
  "https://aaaaa.execute-api.ap-northeast-1.amazonaws.com/dev/informations/",
  "https://aaaaa.execute-api.ap-northeast-1.amazonaws.com/dev/informations/",
);

export const API_URL_AGENDA = getUrl(
  "https://aaaaa.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_solutions/list/",
  "https://aaaaa.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_solutions/list/",
  "https://aaaaa.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_solutions/list/",
  "https://aaaaa.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_solutions/list/",
  "https://aaaaa.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_solutions/list/",
  "https://aaaaa.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_solutions/list/",
);

export const API_URL_INPUT_MASTER = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/master/input-item",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/master/input-item",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/master/input-item",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/master/input-item",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/master/input-item",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/master/input-item",
);

export const API_URL_USER_DETAIL = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/master/users/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/master/users/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/master/users/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/master/users/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/master/users/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/master/users/",
);

// Nga add
export const API_URL_USER_ADD = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/master/users/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/master/users/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/master/users/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/master/users/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/master/users/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/master/users/",
);
// Nga add
export const API_URL_USER_DELETE = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/master/users/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/master/users/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/master/users/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/master/users/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/master/users/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/master/users/",
);
export const API_URL_USER_DUPLICATE = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/master/users/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/master/users/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/master/users/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/master/users/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/master/users/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/master/users/",
);

export const API_URL_DEPARTMENT_LIST = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/master/departments/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/master/departments/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/master/departments/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/master/departments/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/master/departments/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/master/departments/",
);

export const API_URL_DEPARTMENT_DETAIL = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/master/departments/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/master/departments/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/master/departments/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/master/departments/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/master/departments/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/master/departments/",
);

export const API_URL_DEPARTMENT_DELETE = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/master/departments/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/master/departments/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/master/departments/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/master/departments/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/master/departments/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/master/departments/",
);

export const API_URL_DEPARTMENT_ADD_EDIT = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/master/departments/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/master/departments/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/master/departments/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/master/departments/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/master/departments/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/master/departments/",
);

export const API_URL_DEPARTMENT_DUPLICATE = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/master/departments/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/master/departments/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/master/departments/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/master/departments/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/master/departments/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/master/departments/",
);

export const API_URL_GET_AGENDA_SOLUTIONS = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/mypage/agenda_solutions/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/mypage/agenda_solutions/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/mypage/agenda_solutions/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/mypage/agenda_solutions/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/mypage/agenda_solutions/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/mypage/agenda_solutions/",
);

export const API_URL_GET_AGENDA_NEEDS = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/mypage/agenda_needs/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/mypage/agenda_needs/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/mypage/agenda_needs/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/mypage/agenda_needs/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/mypage/agenda_needs/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/mypage/agenda_needs/",
);

export const API_URL_GET_MATCHING_AGENDA_SOLUTIONS = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/matching/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/matching/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/matching/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/matching/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/matching/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/matching/",
);

export const API_URL_ADD_MATCHING_AGENDA_SOLUTIONS_TEACHING = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/matching/agenda_solution/add/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/matching/agenda_solution/add/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/matching/agenda_solution/add/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/matching/agenda_solution/add/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/matching/agenda_solution/add/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/matching/agenda_solution/add/",
);

export const API_URL_ADD_MATCHING_AGENDA_SOLUTIONS_NEEDS_SUGGESTION = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/matching/agenda_need/add/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/matching/agenda_need/add/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/matching/agenda_need/add/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/matching/agenda_need/add/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/matching/agenda_need/add/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/matching/agenda_need/add/",
);

// api generate upload url in S3
export const API_URL_GET_UPLOAD_URL = getUrl(
  "https://h3an7fve5g.execute-api.ap-northeast-1.amazonaws.com/dev/generate-upload-url/",
  "https://9x8fi91vd4.execute-api.ap-northeast-1.amazonaws.com/test/generate-upload-url/",
  "https://uruzr2hh08.execute-api.ap-northeast-1.amazonaws.com/staging/generate-upload-url/",
  "https://zzzzz.execute-api.ap-northeast-1.amazonaws.com/prod/generate-upload-url/",
  "https://hbf235tf33.execute-api.ap-northeast-1.amazonaws.com/demo/generate-upload-url/",
  "https://t1khtwjov4.execute-api.ap-northeast-1.amazonaws.com/tpdev/generate-upload-url/",
);

export const API_URL_GET_DETAIL_AGENDA_NEEDS = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_needs/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/agenda_needs/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/agenda_needs/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/agenda_needs/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/agenda_needs/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/agenda_needs/",
);

export const GENERATE_DISPLAY_NEED_SEED_MATCHING = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/matching/ns/{0}",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/matching/ns/{0}",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/matching/ns/{0}",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/matching/ns/{0}",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/matching/ns/{0}",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/matching/ns/{0}",
);

export const API_URL_ADD_MATCHING = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/massmatch/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/massmatch/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/massmatch/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/massmatch/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/massmatch/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/massmatch/",
);

export const API_URL_GET_LIST_CATEGORY_NEEDS = getUrl(
  "https://h3an7fve5g.execute-api.ap-northeast-1.amazonaws.com/dev/searchagendaneeds",
  "https://9x8fi91vd4.execute-api.ap-northeast-1.amazonaws.com/test/searchagendaneeds",
  "https://uruzr2hh08.execute-api.ap-northeast-1.amazonaws.com/staging/searchagendaneeds",
  "https://zzzzz.execute-api.ap-northeast-1.amazonaws.com/prod/searchagendaneeds",
  "https://hbf235tf33.execute-api.ap-northeast-1.amazonaws.com/demo/searchagendaneeds",
  "https://t1khtwjov4.execute-api.ap-northeast-1.amazonaws.com/tpdev/searchagendaneeds",
);

export const API_URL_GET_DETAIL_MATCHING = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/matching/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/matching/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/matching/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/matching/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/matching/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/matching/",
);

export const API_URL_ADD_EVALUATION = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/evaluations/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/evaluations/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/evaluations/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/evaluations/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/evaluations/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/evaluations/",
);

export const API_URL_GET_LIST_POSTALS_INITIAL = getUrl(
  "https://h3an7fve5g.execute-api.ap-northeast-1.amazonaws.com/dev/postals/list/",
  "https://9x8fi91vd4.execute-api.ap-northeast-1.amazonaws.com/test/postals/list/",
  "https://uruzr2hh08.execute-api.ap-northeast-1.amazonaws.com/staging/postals/list/",
  "https://zzzzz.execute-api.ap-northeast-1.amazonaws.com/prod/postals/list/",
  "https://hbf235tf33.execute-api.ap-northeast-1.amazonaws.com/demo/postals/list/",
  "https://t1khtwjov4.execute-api.ap-northeast-1.amazonaws.com/tpdev/postals/list/",
);

export const API_URL_GET_LIST_POSTALS = getUrl(
  "https://h3an7fve5g.execute-api.ap-northeast-1.amazonaws.com/dev/postals/",
  "https://9x8fi91vd4.execute-api.ap-northeast-1.amazonaws.com/test/postals/",
  "https://uruzr2hh08.execute-api.ap-northeast-1.amazonaws.com/staging/postals/",
  "https://zzzzz.execute-api.ap-northeast-1.amazonaws.com/prod/postals/",
  "https://hbf235tf33.execute-api.ap-northeast-1.amazonaws.com/demo/postals/",
  "https://t1khtwjov4.execute-api.ap-northeast-1.amazonaws.com/tpdev/postals/",
);

export const API_URL_ITEM = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/master/items/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/master/items/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/master/items/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/master/items/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/master/items/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/master/items/",
);

export const API_URL_DETAIL_ITEM = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/master/items/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/master/items/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/master/items/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/master/items/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/master/items/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/master/items/",
);

export const API_URL_GET_DETAIL_EVALUATION = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/evaluation/{0}",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/evaluation/{0}",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/evaluation/{0}",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/evaluation/{0}",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/evaluation/{0}",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/evaluation/{0}",
);

export const API_URL_GET_LIST_TITLE_APPLICATION = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/match/application/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/match/application/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/match/application/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/match/application/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/match/application/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/match/application/",
);

// chat list api

export const API_URL_GENERATE_CHAT_LIST = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/chats/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/chats/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/chats/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/chats/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/chats/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/chats/",
);

export const API_URL_CHAT_STATUS = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/chats/status/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/chats/status/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/chats/status/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/chats/status/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/chats/status/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/chats/status/",
);

export const API_URL_MATCH_CHAT_DETAIL = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/match/chat/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/match/chat/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/match/chat/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/match/chat/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/match/chat/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/match/chat/",
);

// get api searcg my page chat
export const API_URL_SEARCH_MY_PAGE_CHAT = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/mypage/chats",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/mypage/chats",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/mypage/chats",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/mypage/chats",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/mypage/chats",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/mypage/chats",
);

// api update implemetation date
export const API_URL_UPDATE_IMPLEMENTATION_DATE = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/match/implementation_date/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/match/implementation_date/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/match/implementation_date/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/match/implementation_date/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/match/implementation_date/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/match/implementation_date/",
);
// api update match status 
export const API_URL_UPDATE_MATCH_STATUS = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/match/status/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/match/status/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/match/status/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/match/status/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/match/status/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/match/status/",
);

// api update match status my page
export const API_URL_UPDATE_MATCH_STATUS_MY_PAGE = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/match/status/mypage/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/match/status/mypage/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/match/status/mypage/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/match/status/mypage/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/match/status/mypage/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/match/status/mypage/",
);

// api update memo
export const API_URL_UPDATE_MEMO = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/match/memo/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/match/memo/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/match/memo/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/match/memo/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/match/memo/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/match/memo/",
);
// 会員主催イベント検索(POST)
export const MEMBER_EVENTS_LIST = getUrl(
  "https://vvvv.execute-api.ap-northeast-1.amazonaws.com/dev/memberevent/list2",
  "https://vvvv.execute-api.ap-northeast-1.amazonaws.com/dev/memberevent/list2",
  "https://vvvv.execute-api.ap-northeast-1.amazonaws.com/dev/memberevent/list2",
  "https://vvvv.execute-api.ap-northeast-1.amazonaws.com/dev/memberevent/list2",
  "https://vvvv.execute-api.ap-northeast-1.amazonaws.com/dev/memberevent/list2",
  "https://vvvv.execute-api.ap-northeast-1.amazonaws.com/dev/memberevent/list2",
);

// Information search(POST)
export const INFOMATION_LIST_MASTER = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/master/informations/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/master/informations/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/master/informations/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/master/informations/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/master/informations/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/master/informations/",
);

// Add Edit information master(POST, PUT)
export const INFOMATION__MASTER_ADD_EDIT = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/master/informations/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/master/informations/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/master/informations/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/master/informations/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/master/informations/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/master/informations/",
);
//Generate Display Information master
export const INFOMATION_GENERATE_DISPLAY_MASTER = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/master/informations/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/master/informations/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/master/informations/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/master/informations/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/master/informations/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/master/informations/",
);
//Delete Information master(DELETE)
export const DELETE_INFOMATION_MASTER = getUrl(
  "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/master/informations/",
  "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/master/informations/",
  "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/master/informations/",
  "https://yyyyy.execute-api.ap-northeast-1.amazonaws.com/prod/master/informations/",
  "https://jmcaq39lzd.execute-api.ap-northeast-1.amazonaws.com/demo/master/informations/",
  "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/master/informations/",
);

//Get list 5 recent notification (GET)
export const LIST_NEW_NOTIFICATION = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/information-bell",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/information-bell",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/information-bell",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/information-bell",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/information-bell",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/information-bell",
);

//count unread
export const COUNT_UNREAD = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/informations/unread",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/informations/unread",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/informations/unread",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/informations/unread",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/informations/unread",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/informations/unread",
);

export const GET_INDUSTRIALIZATION_REPORT = getUrl(
  "https://h3an7fve5g.execute-api.ap-northeast-1.amazonaws.com/dev/industrialization_report?agenda_id={0}",
  "https://9x8fi91vd4.execute-api.ap-northeast-1.amazonaws.com/test/industrialization_report?agenda_id={0}",
  "https://uruzr2hh08.execute-api.ap-northeast-1.amazonaws.com/staging/industrialization_report?agenda_id={0}",
  "https://zzzzz.execute-api.ap-northeast-1.amazonaws.com/prod/industrialization_report?agenda_id={0}",
  "https://hbf235tf33.execute-api.ap-northeast-1.amazonaws.com/demo/industrialization_report?agenda_id={0}",
  "https://t1khtwjov4.execute-api.ap-northeast-1.amazonaws.com/tpdev/industrialization_report?agenda_id={0}",
);

export const LOGIN = getUrl(
  "https://h3an7fve5g.execute-api.ap-northeast-1.amazonaws.com/dev/login",
  "https://9x8fi91vd4.execute-api.ap-northeast-1.amazonaws.com/test/login",
  "https://uruzr2hh08.execute-api.ap-northeast-1.amazonaws.com/staging/login",
  "https://zzzzz.execute-api.ap-northeast-1.amazonaws.com/prod/login",
  "https://hbf235tf33.execute-api.ap-northeast-1.amazonaws.com/demo/login",
  "https://t1khtwjov4.execute-api.ap-northeast-1.amazonaws.com/tpdev/login",
);

export const FORGOT_PASSWORD = getUrl(
  "https://h3an7fve5g.execute-api.ap-northeast-1.amazonaws.com/dev/forgot_password",
  "https://9x8fi91vd4.execute-api.ap-northeast-1.amazonaws.com/test/forgot_password",
  "https://uruzr2hh08.execute-api.ap-northeast-1.amazonaws.com/staging/forgot_password",
  "https://zzzzz.execute-api.ap-northeast-1.amazonaws.com/prod/forgot_password",
  "https://hbf235tf33.execute-api.ap-northeast-1.amazonaws.com/demo/forgot_password",
  "https://t1khtwjov4.execute-api.ap-northeast-1.amazonaws.com/tpdev/forgot_password",
);

export const CONFIRM_FORGOT_PASSWORD = getUrl(
  "https://h3an7fve5g.execute-api.ap-northeast-1.amazonaws.com/dev/confirm_forgot_password",
  "https://9x8fi91vd4.execute-api.ap-northeast-1.amazonaws.com/test/confirm_forgot_password",
  "https://uruzr2hh08.execute-api.ap-northeast-1.amazonaws.com/staging/confirm_forgot_password",
  "https://zzzzz.execute-api.ap-northeast-1.amazonaws.com/prod/confirm_forgot_password",
  "https://hbf235tf33.execute-api.ap-northeast-1.amazonaws.com/demo/confirm_forgot_password",
  "https://t1khtwjov4.execute-api.ap-northeast-1.amazonaws.com/tpdev/confirm_forgot_password",
);

export const API_URL_WORK_EMPLOYEE_BUSINESS = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/work-employee-business/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/work-employee-business/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/work-employee-business/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/work-employee-business/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/work-employee-business/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/work-employee-business/",
);

export const API_URL_WORK_EMPLOYEE_SALES = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/work-employee-sales/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/work-employee-sales/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/work-employee-sales/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/work-employee-sales/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/work-employee-sales/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/work-employee-sales/",
);

export const API_URL_WORK_ITEM = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/work-item/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/work-item/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/work-item/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/work-item/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/work-item/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/work-item/",
);

export const API_URL_WORK_DELIVERY_FROM = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/work-delivery-from/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/work-delivery-from/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/work-delivery-from/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/work-delivery-from/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/work-delivery-from/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/work-delivery-from/",
);

export const API_URL_WORK_DELIVERY_TO = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/work-delivery-to/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/work-delivery-to/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/work-delivery-to/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/work-delivery-to/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/work-delivery-to/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/work-delivery-to/",
);

export const API_URL_PREVIEW_WORK_DELIVERY_DETAIL = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/preview-work-delivery-detail/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/preview-work-delivery-detail/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/preview-work-delivery-detail/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/preview-work-delivery-detail/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/preview-work-delivery-detail/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/preview-work-delivery-detail/",
);

export const API_URL_WORK_DELIVERY_DETAIL = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/work-delivery-detail/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/work-delivery-detail/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/work-delivery-detail/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/work-delivery-detail/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/work-delivery-detail/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/work-delivery-detail/",
);

export const API_URL_DELETE_WORK = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/works/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/works/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/works/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/works/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/works/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/works/",
);

export const API_URL_PREVIEW_WORK_ORDER = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/preview-work-order/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/preview-work-order/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/preview-work-order/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/preview-work-order/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/preview-work-order/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/preview-work-order/",
);

export const API_URL_WORK_ORDER = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/work-order/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/work-order/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/work-order/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/work-order/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/work-order/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/work-order/",
);

export const API_URL_PREVIEW_ORDER = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/preview-order/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/preview-order/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/preview-order/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/preview-order/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/preview-order/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/preview-order/",
);

export const API_URL_ORDER = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/orders/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/orders/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/orders/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/orders/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/orders/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/orders/",
);

export const API_URL_APPROVE_ORDER = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/orders/{0}/approve",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/orders/{0}/approve",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/orders/{0}/approve",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/orders/{0}/approve",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/orders/{0}/approve",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/orders/{0}/approve",
);

export const API_URL_BATCH_APPROVE_ORDER = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/orders/batch-approve",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/orders/batch-approve",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/orders/batch-approve",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/orders/batch-approve",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/orders/batch-approve",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/orders/batch-approve",
);

export const API_URL_GET_DELIVERY_TYPE = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/delivery-type/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/delivery-type/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/delivery-type/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/delivery-type/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/delivery-type/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/delivery-type/",
);

export const API_URL_GET_WORK_PROGRESS = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/work-progress/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/work-progress/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/work-progress/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/work-progress/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/work-progress/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/work-progress/",
);

export const API_URL_ADD_SPOT_DELIVERY = getUrl(
  "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/spot-delivery/",
  "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/spot-delivery/",
  "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/spot-delivery/",
  "https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/prod/spot-delivery/",
  "https://o4u9i8tt1j.execute-api.ap-northeast-1.amazonaws.com/demo/spot-delivery/",
  "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/spot-delivery/",
);

// url取得(検証、本番取得)
function getUrl(devUrl: string, testUrl: string, StagingUrl: string, prodUrl: string, DemoUrl: string, tpDevDomain: string): string {
  // エンドポイント判定
  let endPoint =
    PROD_DOMAIN == window.location.host ? prodUrl
      : TEST_DOMAIN == window.location.host ? testUrl
        : STAGING_DOMAIN == window.location.host ? StagingUrl
          : DEMO_DOMAIN == window.location.host ? DemoUrl
            : TPDEV_DOMAIN == window.location.host ? tpDevDomain
              : devUrl;

  // エンドポイントが設定されたか否か
  if (!endPoint) {
    // エンドポイントが設定されなかった場合

    console.error(
      this.msg(
        MESSAGE_CODE.E80000,
        PROD_DOMAIN == window.location.host ? "本番用" : STAGING_DOMAIN ? "検証用" : "開発用"
      )
    );

    return CONSTANT.EMPTY_STRING;
  }

  return endPoint;
}
