import { AfterContentInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  AGENDA_CATEGORY_LIST,
  API_URL_DIC_LISTS,
  AGENDA_NEEDS_LIST,
  API_URL_GET_LIST_CATEGORY_NEEDS
} from 'manager/http-constants';
import { AuthorityDecision } from 'manager/user-authority-constant';
import { TreeNode } from 'primeng/api';
import { DICVALUES_API_CONSTANT } from 'src/app/shared/constant/api-constant';
import { CONSTANT, CATEGORIES } from 'src/app/shared/constant/constant';
import { LOCAL_CATEGORY } from 'src/app/shared/constant/local';
import { SESSION_KEY } from 'src/app/shared/constant/session-constants';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import { ChatService } from 'src/app/shared/service/chat.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';

@Component({
  selector: 'app-platform',
  template: '',
})

/**
 * Platform系統Component基幹
 */
export class PlatformComponent implements OnInit,AfterContentInit {
  /* ログインユーザー情報 */
  // セッションからログインユーザ情報取得
  loginUser = JSON.parse(
    window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
  );
ngAfterContentInit(): void {
  //Called after ngOnInit when the component's or directive's content has been initialized.
  //Add 'implements AfterContentInit' to the class.
  
}
  // 入力タイプを格納
  inputType: string = this.activatedRoute.snapshot.params.inputType;

  // カテゴリ取得格納先
  categoryList: TreeNode[];

  // 地域取得格納先
  prefecturesList: any[] = LOCAL_CATEGORY;

  // 辞書情報格納先
  specCodeList: any;

  constructor(
    public activatedRoute: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
  ) {}

  ngOnInit(): void {}

  /**
   * 文字サイズ取得
   */
  public getTextSize(): boolean {
    // セッションから文字サイズを取得
    let textSize: string = window.sessionStorage.getItem(SESSION_KEY.textSize);

    // 文字サイズが存在するか否か
    if (textSize) {
      // セッション.文字サイズをboolean型にして返却
      return textSize.toLowerCase() === 'true';
    }

    return false;
  }

  /**
   * ドメインを取得
   */
  protected getDomain(): string {
    return window.location.origin;
  }

  /**
   * 辞書値リストを取得
   */
  protected getSpecCodeList(...dicId: any[]) {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(API_URL_DIC_LISTS);

    // 全辞書情報取得処理
    this.dbOperationService.getDicList(dicId).subscribe((response) => {
      // 全辞書情報が存在するか否か
      if (!this.commonService.checkNoneResponse(response)) {
        // 辞書情報に存在する場合

        // 辞書情報を格納
        this.specCodeList = response.body;
      } else {
        // 辞書情報に存在しない場合

        this.specCodeList = null;
      }

      // 画面ロードフラグをOFF(ロード終了)
      this.loadingState.loadSleepEnd(0.3, API_URL_DIC_LISTS);
    });
  }

  /**
   * 辞書値リストを返却
   * @param spDicId 辞書番号
   */
  public getDicList(spDicId: string | number): Object[] {
    // 辞書値リストを返却
    return this.commonService.dictionaryDicIdList(spDicId, this.specCodeList);
  }

  /**
   * 辞書名を返却
   * @param spDicId 辞書番号
   * @param spCode 値
   */
  public getDicName(
    spDicId: string | number,
    spCode: string | number
  ): Object[] {
    // 辞書値リストを返却
    return this.commonService.dictionaryCodeCnvName(
      spDicId,
      spCode,
      this.specCodeList
    );
  }

  /**
   * 辞書名リストを返却
   * @param spDicId 辞書番号
   * @param spCode コード値の配列
   */
  public getDicNameList(
    spDicId: string | number,
    spCode: string[] | number[]
  ): Object[] {
    // 辞書値リストを返却
    const dicList = this.commonService.dictionaryDicIdList(
      spDicId,
      this.specCodeList
    );

    // コード値を返却
    return this.commonService.createArrayObjectSearchValueString(
      dicList,
      DICVALUES_API_CONSTANT.SP_CODE,
      spCode
    );
  }

  /**
   * 視点判定
   * @param memberId 会員ID
   * @returns true リクエストの会員IDが一致する場合
   * @returns false リクエストの会員IDが一致しない場合
   */
  // 会員IDの引数判定
  public decisionPerspective(memberId: string): boolean {
    if (!memberId) {
      // 会員IDが存在しない場合
      return false;
    }

    // ログイン状態か否か
    // if (!this.decisionLoginState()) {
    //   // 未ログインの場合
    //   return false;
    // }

    // セッションからログインユーザ情報
    const loginUser = JSON.parse(
      window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
    );

    // 会員IDとセッションの会員IDが一致するか否か
    if (memberId != loginUser.department_id) {
      // 一致しない場合

      return false;
    }

    return true;
  }

  /**
   * 事務局視点判定
   * @returns true 事務局の場合
   * @returns false 事務局以外の場合
   */
  public decisionSecretariat(): boolean {
    return this.commonService.decisionUserAuthority(
      false,
      new AuthorityDecision({
        user_4: true,
      })
    );
  }

  /**
   * ログイン状態判定
   * @returns true ログイン中
   * @returns false 未ログイン
   */
  public decisionLoginState(): boolean {
    // セッションからログイン状態取得
    const loginState = JSON.parse(
      window.sessionStorage.getItem(SESSION_KEY.loginState)
    );

    // ログイン状態か否か
    if (!loginState) {
      // 未ログインを返却
      return false;
    }

    // セッションからログインユーザ情報
    const loginUser = JSON.parse(
      window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
    );

    // ログインユーザ情報が存在するか否か
    if (!loginUser) {
      // ログインユーザ情報が存在しない場合

      // 未ログインを返却
      return false;
    }

    return true;
  }

  /**
   * ログインユーザのユーザIDを取得
   * @returns userId ログインユーザ情報
   */
  public getLoginUserId(): string | number {
    // ログインユーザ情報
    const loginUser = JSON.parse(
      window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
    );

    // ログインユーザ情報が存在するか否か
    if (!loginUser) {
      // 存在しない場合、空文字を返却
      return CONSTANT.EMPTY_STRING;
    }

    // ユーザIDを返却
    return loginUser.user_id;
  }

  /**
   * ログインユーザの会員IDを取得
   * @returns memberId ログインユーザ情報
   */
  public getLoginMemberId(): string | number {
    // ログインユーザ情報
    const loginUser = JSON.parse(
      window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
    );

    // ログインユーザ情報が存在するか否か
    if (!loginUser) {
      // 存在しない場合、空文字を返却
      return CONSTANT.EMPTY_STRING;
    }

    // 会員IDを返却
    return loginUser.department_id;
  }

  /**
   * 課題カテゴリを取得
   */
  // protected getAgendaCategoryList(): void {
  //   this.loadingState.loadStart(AGENDA_CATEGORY_LIST);

  //   this.dbOperationService
  //     .getNoTemplateData(AGENDA_CATEGORY_LIST, null, true)
  //     .subscribe((response) => {
  //       if (!this.commonService.checkNoneResponse(response)) {
  //         this.categoryList = response.body;

  //         const childCategory = response.body.map((value: any) => {
  //           return {
  //             label: value.class_name1,
  //             data: value.value,
  //             children: value.cd2.map((child1:any) => {
  //               return {
  //                 label: child1.class_name2,
  //                 data: child1.value,
  //                 children: child1.cd3.map((child2 : any) => {
  //                   return {
  //                     label: child2.class_name3,
  //                     data: child2.value
  //                   }
  //                 })
  //               }
  //             })
  //           }
  //         })
  //         this.categoryList = [{
  //           label: 'カテゴリーを選ぶ',
  //           data: '',
  //           children: childCategory
  //         }]
          
  //       } else {
  //         this.categoryList = new Array();
  //       }

  //       // 画面ロードフラグをOFF(ロード終了)
  //       this.loadingState.loadSleepEnd(0.3, AGENDA_CATEGORY_LIST);
  //     });
  // }

  
   /**
   * 課題カテゴリを取得
   */
   protected getAgendaCategoryList(): void {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(AGENDA_CATEGORY_LIST);

    // 課題カテゴリを取得
    this.dbOperationService
      .getNoTemplateData(AGENDA_CATEGORY_LIST, null, true)
      .subscribe((response) => {
        if (!this.commonService.checkNoneResponse(response)) {
          // 課題カテゴリを格納
          this.categoryList = response.body;
        } else {
          this.categoryList = new Array();
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, AGENDA_CATEGORY_LIST);
      });
  }

  /**
   * 課題カテゴリ名を返却
   * @param spCode コード値の配列
   */
  public getClassNameList(spCode: string[] | number[]): Object[] {
    // 中項目リストを生成
    let recList = new Array();
    this.categoryList.forEach((data: any) => {
      recList = recList.concat(data?.cd2);
    });

    // コード値を返却
    return this.commonService.createArrayObjectSearchValueString(
      recList,
      'value',
      spCode
    );
  }

  /**
   * ページアンカーにスクロール
   */
  public jumpTop(): void {
    let jumpTop = document.getElementById('jump-top');
    window.scrollTo({
      top: jumpTop.offsetTop,
      behavior: 'smooth',
    });
  }

  public setClasses(category: any) {
    if (category === CATEGORIES.WORK_EXPERIENCE) {
      return 'green-color-category';
    } else if (category === CATEGORIES.CLASS_SUPPORT) {
      return 'yellow-color-category';
    } else if (category === CATEGORIES.CLUB_ACTIVITY_SUPPORT) {
      return 'orange-color';
    } else if (category === CATEGORIES.OTHERS) {
      return 'pink-color';
    } else {
      return 'no-category';
    }
  }
  checkInputMaxBoundary(form:FormGroup|FormControl, control:string=''){
    if(form instanceof FormControl){
      let temp = form.errors;
      if (!temp) {
        return false;
      }
      return temp.invalidLength==true;
    }else if (form instanceof FormGroup){
      let temp = form.get(control);
      if (!temp) {
        return false;
      }
      let temp1 = temp.errors;
      if (!temp1) {
        return false;
      }
      return temp1.invalidLength==true;
    }
    return false;
    
  }
}
