/**
 * 設定テンプレートID
 */

// Assignment / organization master screen
export const ITEM_M101_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1001,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 1002,
  // 入力項目生成(新規,複製)テンプレートID
  INPUT_TEMPLATE_ID: 1005,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1003,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1004,
};

export const DELIVERY_M201_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 2001,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 2002,
  // 入力項目生成(新規,複製)テンプレートID
  INPUT_TEMPLATE_ID: 2005,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 2003,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 2004,
};

// 課題・ソリューションマスタ画面
export const USER_M301_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 3101,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 3102,
  // 入力項目生成(新規,複製)テンプレートID
  INPUT_TEMPLATE_ID: 3105,

  INPUT_DUPLICATE_OR_ADD_TEMPLATE_ID: 3106,

  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 3103,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 3104,
};

// 課題・ソリューションマスタ画面
export const DEPARTMENT_M401_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 4001,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 4002,
  // 入力項目生成(新規,複製)テンプレートID
  INPUT_TEMPLATE_ID: 4005,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 4003,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 4004,

  SEARCH_C241_RESULTS_TEMPLATE_ID: 3108,
};

// Information master screen
export const INFORMATION_M501_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1501,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 1502,
  // 入力項目生成(新規,複製)テンプレートID
  INPUT_TEMPLATE_ID: 1505,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1503,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1504,
};

// 課題・ソリューションマスタ画面
export const C101_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 3201,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 3202,
  // 入力項目生成(新規,複製)テンプレートID
  INPUT_TEMPLATE_ID: 3205,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 3203,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 3204,
  TABLE_NAME: 'view_user',
};

export const C111_TEMPLATE = {
  SEARCH_TEMPLATE_ID: 1101,
  SEARCH_RESULTS_TEMPLATE_ID: 1102,
  INPUT_TEMPLATE_ID: 1105,
  OUTPUT_TEMPLATE_ID: 1103,
  CSV_TEMPLATE_ID: 1104,
  TABLE_NAME: 'item',
};

export const C121_TEMPLATE = {
  SEARCH_TEMPLATE_ID: 2101,
  SEARCH_RESULTS_TEMPLATE_ID: 2102,
  INPUT_TEMPLATE_ID: 2105,
  INPUT_DELIVERY_SPOT_TEMPLATE_ID: 2115,
  OUTPUT_TEMPLATE_ID: 2103,
  CSV_TEMPLATE_ID: 2104,
  TABLE_NAME: 'delivery',
};

export const C131_TEMPLATE = {
  SEARCH_TEMPLATE_ID: 2101,
  SEARCH_RESULTS_TEMPLATE_ID: 2102,
  INPUT_TEMPLATE_ID: 2105,
  INPUT_DELIVERY_SPOT_TEMPLATE_ID: 2115,
  OUTPUT_TEMPLATE_ID: 2103,
  CSV_TEMPLATE_ID: 2104,
  TABLE_NAME: 'delivery',
};

export const C141_TEMPLATE = {
  SEARCH_TEMPLATE_ID: 2101,
  SEARCH_RESULTS_TEMPLATE_ID_USE_SPOT_0: 50022,
  SEARCH_RESULTS_TEMPLATE_ID_USE_SPOT_1: 50021,
  INPUT_TEMPLATE_ID: 2105,
  OUTPUT_TEMPLATE_ID: 2103,
  CSV_TEMPLATE_ID: 2104,
  TABLE_NAME: 'work_order',
  DIC_HOPE_SHIP_TIME: '104',
  DIC_VEHICLE_TYPE: '106',
  DIC_DELIVERY_COMPANY: '107',
};

export const CXXX_TEMPLATE = {
  SEARCH_TEMPLATE_ID: 6001,
  SEARCH_RESULTS_TEMPLATE_ID: 6002,
  INPUT_TEMPLATE_ID: 6005,
  OUTPUT_TEMPLATE_ID: 6003,
  CSV_TEMPLATE_ID: 6004,
  TABLE_NAME: 'work_delivery_detail',
};

export const C151_TEMPLATE = {
  SEARCH_TEMPLATE_ID: 2101,
  SEARCH_RESULTS_TEMPLATE_ID_USE_SPOT_0: 70022,
  SEARCH_RESULTS_TEMPLATE_ID_USE_SPOT_1: 70021,
  INPUT_TEMPLATE_ID: 2105,
  OUTPUT_TEMPLATE_ID: 2103,
  CSV_TEMPLATE_ID: 2104,
  TABLE_NAME: 'work_order',
  DIC_VEHICLE_TYPE: '106',
  DIC_DELIVERY_COMPANY: '107',
};

export const C201_TEMPLATE = {
  SEARCH_TEMPLATE_ID: 13001,

  TEMPLATE_HEADER_LIST: 13002,

  TEMPLATE_SEARCH_RESULT: 13004,

  TEMPLATE_DETAIL_FOR_APPROVE: 130021,

  TEMPLATE_EDIT_USE_SPOT_0: 130053,
  TEMPLATE_EDIT_USE_SPOT_1: 130054,

  TEMPLATE_APPROVE: 13005,

  TEMPLATE_DUPLICATE_USE_SPOT_0: 130056,
  TEMPLATE_DUPLICATE_USE_SPOT_1: 130057,

  OUTPUT_TEMPLATE_ID_USE_SPOT_0: 130030,
  OUTPUT_TEMPLATE_ID_USE_SPOT_1: 130031,
  TEMPLATE_EXPORT_CSV: 13003,
  TABLE_NAME: 'order',
};
