import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CommonService } from "src/app/shared/service/common.service";
import { GenerateService } from "../../../generate.service";
import {
  DISPLAY_INFORMATIONS_API_CONSTANT,
  INPUT_INFORMATIONS_API_CONSTANT,
} from "src/app/shared/constant/api-constant";
import { GENERATE_INPUT_TYPE } from "../../constant";
import {
  CONSTANT,
  DELIVERY_KBN,
  LOADING_KEY,
} from "src/app/shared/constant/constant";
import { TranslateService } from "@ngx-translate/core";
import { MessageService, PrimeNGConfig } from "primeng/api";
import { Validator } from "src/app/shared/validator/validator";
import { GenerateInputErrorMessage } from "../generate-input-error-message/generate-input-error-message";
import { GenerateInputFormConfirmation } from "./generate-input-form-confirmation";
import {
  LoadData,
  LoadingState,
} from "src/app/shared/html-parts/loading/loading-state";
import { forkJoin, Observable } from "rxjs";
import {
  MessageData,
  ToastMessageData,
} from "src/app/shared/html-parts/message-common/message-data";
import { TOAST } from "src/app/shared/constant/primeng-constants";
import { MESSAGE_CODE } from "src/app/shared/constant/message-constant";
import { FileUpload } from "primeng/fileupload";
import { HttpClient } from "@angular/common/http";
import {
  API_URL_GET_DELIVERY_TYPE,
  MEMBERS_DETAIL_MASTER,
} from "manager/http-constants";
import { DbOperationService } from "src/app/shared/service/db-operation.service";
import { C141_TEMPLATE } from "manager/template-constant";

@Component({
  selector: "app-generate-input-form",
  templateUrl: "./generate-input-form.component.html",
  styleUrls: ["./generate-input-form.component.scss"],
})

/**
 * 入力フォーム
 */
export class GenerateInputFormComponent implements OnInit {
  // 画面.入力フォーム
  generateInputForm: FormGroup = this.formBuilder.group(Object());

  // アウトプット.入力フォーム情報
  @Output() generateInputInformation = new EventEmitter<FormGroup>();

  // アウトプット.入力フォームエラー情報
  @Output() generateInputErrorInformation = new EventEmitter<
    GenerateInputErrorMessage[]
  >();

  @Output() reloadID = new EventEmitter<string | boolean>();
  // 画面.入力項目生成
  generateInputList: any[] = new Array();

  // 入力フォーム確認画面出力オブジェクトリスト
  generateInputFormConfirmationList: GenerateInputFormConfirmation[] =
    new Array();

  // 入力エラーメッセージオブジェクト
  generateInputErrorMessageList: GenerateInputErrorMessage[] = new Array();

  // 確認画面表示フラグ
  confirmationFlag: boolean;

  // 登録ボタン押下フラグ(データ登録中にボタン押下させないフラグ)
  insertFlag: boolean;

  // file upload
  fileUploaded: any;

  //reset file upload
  fileUploadFlag: boolean = true;

  hasSelectedFile: boolean = true;
  pkeyId: string;
  endPointWorkInputCheck: string;
  listCategory: any[] = [];
  listSubCategory: any[] = [];
  fileUploadedImage: File;
  fileUploadDocument: File;
  generateDisplayList: any;
  inputType: string;
  selectedCategory: string;
  templateId: number;
  //List postal of member
  postalNoList: any[] = [];
  checkMobile: boolean = false;
  deliveryTypeList: any[] = [];
  generateDisplayInformation: any[] = [];
  listVehicleType: any[] = [];
  listDeliveryCompany: any[] = [];

  constructor(
    private generateService: GenerateService,
    private loadingState: LoadingState,
    private commonService: CommonService,
    private validator: Validator,
    private translateService: TranslateService,
    private config: PrimeNGConfig,
    private messageData: MessageData,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private http: HttpClient,
    private dbOperationService: DbOperationService
  ) {
    // PrimeNGを日本語化
    this.translateService
      .get("primeng")
      .subscribe((res) => this.config.setTranslation(res));
  }

  ngOnInit(): void {
    const innerWidth = window.innerWidth;
    if ((innerWidth > 450 && window.screen.orientation.type == 'portrait-primary') || (window.innerHeight > 450 && window.screen.orientation.type == 'landscape-primary')) {
      this.checkMobile = false;

    }
    else {
      this.checkMobile = true;
    }
  }

  public getPulldownItem() {
    return new Promise<any>((resolve) => {
      this.dbOperationService.getDicLists(C141_TEMPLATE.DIC_VEHICLE_TYPE + "," + C141_TEMPLATE.DIC_DELIVERY_COMPANY).subscribe((response) => {
        if (!this.commonService.checkNoneResponse(response)) {
          this.listVehicleType = response.body.filter(x => x.sp_dic_id == C141_TEMPLATE.DIC_VEHICLE_TYPE);
          this.listDeliveryCompany = response.body.filter(x => x.sp_dic_id === C141_TEMPLATE.DIC_DELIVERY_COMPANY);
        } else {
          this.listVehicleType = null;
          this.listDeliveryCompany = null;
        }
        resolve(null);
      });
    });
  }

  /**
   * 入力項目生成
   * @param inputType 登録種別（新規:new、編集:edit、複製:duplicate）
   * @param endPointInput 入力項目生成用エンドポイント
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @param pkeyId 対象ID
   * @param inputQueryParameters 入力項目生成時の絞り込み条件
   * @param endPointWorkInputCheck 業務入力チェックエンドポイント
   * ※引数:endPointはinputType=新規:new かつ 入力フォームから実施する場合、任意項目とする
   * ※引数:inputQueryParametersは以下の記述式とする
   * 引数
   * {
   *   content_id:'1111',
   *   circulation_id:'2222'
   * }
   * → 入力フォーム情報取得時のQueryParametersにcontent_id=1111&circulation_id=2222が追加
   */
  public async initial(
    inputType: string,
    endPointInput: string,
    endPointGenerateDisplay: string,
    templateId: number,
    pkeyId?: string,
    inputQueryParameters?: Object,
    endPointWorkInputCheck?: string
  ) {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(LOADING_KEY.GENERATE_INPUT);
    this.inputType = inputType;
    // 対象IDを一時保存
    this.pkeyId = pkeyId;
    this.templateId = templateId;
    // 業務入力チェックエンドポイントを一時保存
    this.endPointWorkInputCheck = endPointWorkInputCheck;

    // 画面.入力フォームを初期化
    {
      this.generateInputList = new Array();
      this.generateInputForm = this.formBuilder.group(Object());
    }

    // 入力フォーム状態初期化
    this.resetFlag();

    let currentScreen = window.location.pathname;
    if (currentScreen == '/pages/platform/c201') {
      console.log("currentScreen: ", currentScreen);
      await this.getPulldownItem();
    }

    /* 入力項目情報取得処理  */
    this.generateService
      .getGenerateInput(endPointInput, templateId, inputQueryParameters)
      .subscribe((response) => {

        if (this.commonService.checkInvalidValue(response)) {
          // 入力項目情報が取得されなかった場合
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00003),
              detail: this.commonService.msg(MESSAGE_CODE.E00026),
            })
          );

          this.reloadID.emit(this.pkeyId);
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd(LOADING_KEY.GENERATE_INPUT);
          return;
        }
        // 入力項目情報が取得されたか否か
        if (this.commonService.checkNoneResponse(response)) {
          // 入力項目情報が取得されなかった場合

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd(LOADING_KEY.GENERATE_INPUT);

          return;
        }

        // 入力フォーム用コントロール作成
        for (const generateInput of response.body) {
          // 入力フォームグループに入力フォームコントロールを追加
          this.generateInputForm.addControl(
            generateInput.column_id,
            this.formBuilder.control(generateInput.column_defualt, {
              asyncValidators: [
                this.validator.CheckValidator(
                  generateInput.table_id,
                  generateInput.column_id,
                  generateInput.input_type
                ),
              ],
            })
          );

          // 入力項目タイプの判定
          if (
            generateInput.input_type ==
            INPUT_INFORMATIONS_API_CONSTANT.CHECKBOX_TYPE
          ) {
            // 入力項目タイプが"checkbox"の場合

            // 入力フォームコントロールにチェックが外れた場合のバリデーションチェックを実施を追加
            this.generateInputForm.get(generateInput.column_id).updateOn;
          }

          // 登録種別が編集 かつ
          // 編集不可項目か否か
          if (
            (GENERATE_INPUT_TYPE.EDIT == inputType || GENERATE_INPUT_TYPE.APPROVE == inputType) &&
            (generateInput.column_input_update == "0" || generateInput.column_input_update == "2")
          ) {
            // 登録種別が編集の場合 かつ
            // 編集不可項目の場合

            // 入力フォームコントロールを非活性化
            this.generateInputForm.get(generateInput.column_id).disable();
          }

          if (GENERATE_INPUT_TYPE.DUPLICATE == inputType &&
            (templateId == 130056 || templateId == 130057) &&
            (generateInput.column_input_update == "0" || generateInput.column_input_update == "2")
          ) {
            this.generateInputForm.get(generateInput.column_id).disable();
          }
        }

        // 入力項目情報を画面.入力項目生成に格納
        this.generateInputList = response.body;

        // 登録種別の判定
        if (GENERATE_INPUT_TYPE.NEW == inputType) {
          // 登録種別が新規の場合
          this.listSubCategory = [];
          this.fileUploadDocument = null;
          this.fileUploadedImage = null;
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadSleepEnd(0.3, LOADING_KEY.GENERATE_INPUT);
        } else if (GENERATE_INPUT_TYPE.EDIT == inputType || GENERATE_INPUT_TYPE.APPROVE == inputType) {
          // 登録種別が編集の場合
          // 更新用の詳細情報取得
          this.editGenerateDisplay(endPointGenerateDisplay, templateId, pkeyId);
        } else if (GENERATE_INPUT_TYPE.DUPLICATE == inputType) {
          // 登録種別が複製の場合
          // 複製用の詳細情報取得
          this.duplicateGenerateDisplay(
            endPointGenerateDisplay,
            templateId,
            pkeyId
          );
        }
      });
  }

  /**
   * 更新用の詳細情報取得
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @param pkeyId 対象データのID
   */
  private editGenerateDisplay(
    endPoint: string,
    templateId: number,
    pkeyId: string
  ) {
    // 更新用の詳細情報取得
    this.generateService
      .getGenerateDisplay(endPoint, templateId, pkeyId)
      .subscribe((response) => {
        if (this.commonService.checkInvalidValue(response)) {
          // 警告メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00003),
              detail: this.commonService.msg(MESSAGE_CODE.E00026),
            })
          );

          this.reloadID.emit(pkeyId);
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd(LOADING_KEY.GENERATE_INPUT);
          return;
        }
        // 詳細情報が取得されたか否か
        if (this.commonService.checkNoneResponse(response)) {
          // 詳細情報が取得されなかった場合

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd(LOADING_KEY.GENERATE_INPUT);

          return;
        }

        this.generateDisplayList = response.body;
        if (window.location.pathname.toLowerCase() == "/pages/platform/c201") {
          this.handleDeliveryType(pkeyId);
        }
        this.generateDisplayInformation = response.body;
        // 詳細情報初期値セット
        this.setFormControlValue(response.body);
        if (this.generateInputForm.controls.member_department_id) {
          this.dbOperationService
            .getNoTemplateDisplayData(MEMBERS_DETAIL_MASTER, response.body[0].data, true)
            .subscribe((response) => {
              if (response && response.body.member_organization_name) {
                this.generateInputList[0].column_code_list_multi = [{
                  name: response.body.member_organization_name,
                  value: response.body.member_organization_name
                }];
                this.generateInputForm.controls.member_department_id.setValue(response.body.member_organization_name);
              }
              // 画面ロードフラグをOFF(ロード終了)
              this.loadingState.loadSleepEnd(0.3, LOADING_KEY.GENERATE_INPUT);
            });
        } else {
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadSleepEnd(0.3, LOADING_KEY.GENERATE_INPUT);
        }
      });
  }

  /**
   * 複製用の詳細情報取得
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @param pkeyId 対象データのID
   */
  private duplicateGenerateDisplay(
    endPoint: string,
    templateId: number,
    pkeyId: string
  ) {
    // 複製用の詳細情報取得
    this.generateService
      .getGenerateDisplay(endPoint, templateId, pkeyId, true)
      .subscribe((response) => {
        // 詳細情報が取得されたか否か
        if (this.commonService.checkInvalidValue(response)) {
          // 入力項目情報が取得されなかった場合
          let currentScreen = window.location.pathname;
          if (currentScreen == '/pages/platform/c201') {
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.WARN,
                detail: this.commonService.msg(MESSAGE_CODE.E00035),
              })
            );
            this.reloadID.emit(CONSTANT.EMPTY_STRING);
          } else {
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.WARN,
                summary: this.commonService.msg(MESSAGE_CODE.E00003),
                detail: this.commonService.msg(MESSAGE_CODE.E00026),
              })
            );
            this.reloadID.emit(pkeyId);
          }
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd(LOADING_KEY.GENERATE_INPUT);
          return;
        } else if (this.commonService.checkNoneResponse(response)) {
          // 詳細情報が取得されなかった場合

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd(LOADING_KEY.GENERATE_INPUT);

          return;
        }
        this.generateDisplayInformation = response.body;
        // 詳細情報をプロパティに格納
        this.generateDisplayList = response.body;
        // 詳細情報初期値セット
        this.setFormControlValue(response.body);

        if (window.location.pathname.toLowerCase() == "/pages/platform/c201") {
          this.handleDeliveryType(pkeyId);
        }
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, LOADING_KEY.GENERATE_INPUT);
      });
  }

  handleDeliveryType(orderId: string) {
    this.deliveryTypeList = new Array();
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
      })
    );
    this.dbOperationService
      .getNoTemplateData(
        API_URL_GET_DELIVERY_TYPE + orderId
      )
      .subscribe((response) => {
        if (!this.commonService.checkNoneResponse(response)) {
          if (response.body == "") {
            this.deliveryTypeList = null;
          } else {
            this.deliveryTypeList = response.body;
          }
        } else {
          this.deliveryTypeList = null;
        }
      });
    this.loadingState.loadSleepEnd(0.3);
  }

  /**
   * 詳細情報初期値セット
   * @param generateDisplayInformation
   */
  private setFormControlValue(generateDisplayInformation: any[]) {
    // 入力項目分ループ
    for (const generateInput of this.generateInputList) {
      // 入力項目タイプを判定
      if (
        generateInput.input_type == INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE ||
        generateInput.input_type ==
        INPUT_INFORMATIONS_API_CONSTANT.NUMBER_TYPE ||
        generateInput.input_type == INPUT_INFORMATIONS_API_CONSTANT.DATE_TYPE ||
        generateInput.input_type ==
        INPUT_INFORMATIONS_API_CONSTANT.TEXTAREA_TYPE ||
        generateInput.input_type ==
        INPUT_INFORMATIONS_API_CONSTANT.PASSWORD_TYPE ||
        generateInput.input_type ==
        INPUT_INFORMATIONS_API_CONSTANT.DISABLE_EDIT ||
        generateInput.input_type ==
        INPUT_INFORMATIONS_API_CONSTANT.TIME
      ) {
        // 入力項目タイプが以下の場合
        // テキスト or
        // 数値 or
        // 日付 or
        // テキストエリア
        // パスワードの場合
        if (generateInput.input_type == INPUT_INFORMATIONS_API_CONSTANT.DISABLE_EDIT) {
          const element = document.getElementById(generateInput.column_id);
          let data = generateDisplayInformation.find(x => x.column_id == generateInput.column_id)?.data;
          if (generateInput.column_id == 'user_department_id') {
            data = generateInput.column_code_list_multi.find(x => x.value == data)?.name;
          }
          if (element && data) {
            element.innerText = data;
          }
        }

        // 詳細情報のカラム値をフォームコントロールに格納
        this.generateInputForm
          .get(generateInput.column_id)
          .setValue(
            this.commonService.getArrayObjectValue(
              generateDisplayInformation,
              DISPLAY_INFORMATIONS_API_CONSTANT.COLUMN_ID,
              DISPLAY_INFORMATIONS_API_CONSTANT.DATA,
              generateInput.column_id
            )
          );
      } else {
        // 入力項目タイプが以下の場合
        // ラジオボタン or
        // チェックボックス or
        // シングルセレクト or
        // マルチセレクトの場合

        // 詳細情報のカラム値(コード)を取得する
        const dataMulti = this.commonService.getArrayObjectValue(
          generateDisplayInformation,
          DISPLAY_INFORMATIONS_API_CONSTANT.COLUMN_ID,
          DISPLAY_INFORMATIONS_API_CONSTANT.DATA_MULTI,
          generateInput.column_id
        );

        // 入力項目タイプを判定
        if (
          generateInput.input_type ==
          INPUT_INFORMATIONS_API_CONSTANT.RADIO_TYPE ||
          generateInput.input_type ==
          INPUT_INFORMATIONS_API_CONSTANT.SINGLE_SELECT_TYPE
        ) {
          // 入力項目タイプが以下の場合
          // ラジオボタン or
          // シングルセレクトの場合
          if (generateInput.column_id == "delivery_kbn" && window.location.pathname.toLowerCase() == "/pages/platform/master/m201") {
            const data = generateDisplayInformation.find(x => x.column_id == generateInput.column_id);
            this.handlePulldownDeliveryKbn(data.data);
          }


          // カラム値(コード)を文字列に変換してフォームコントロールに格納
          this.generateInputForm
            .get(generateInput.column_id)
            .setValue(dataMulti.join(CONSTANT.COMMA));
        } else {
          // 入力項目タイプが以下の場合
          // チェックボックス or
          // マルチセレクトの場合

          // カラム値(コード)をフォームコントロールに格納
          this.generateInputForm
            .get(generateInput.column_id)
            .setValue(dataMulti);
        }
      }
    }
  }

  /**
   * ピッカーリスト.格納前情報
   * @param columnId カラムID
   */
  protected pickListSource(columnId: string): object[] {
    // カラムIDの入力項目コード情報を取得
    const generateInputDataMulti: object[] =
      this.commonService.getArrayObjectValue(
        this.generateInputList,
        INPUT_INFORMATIONS_API_CONSTANT.COLUMN_ID,
        INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI,
        columnId
      );

    // 画面.入力フォームにカラムIDの入力情報が存在するか否か
    if (!this.generateInputForm.controls[columnId].value) {
      // 画面.入力フォームにカラムIDの入力情報が存在しない場合

      // 入力項目コード情報を返却
      return generateInputDataMulti;
    }

    // 入力項目コード情報から詳細情報のコードを削除
    const returnDataMulti: any[] =
      this.commonService.deleteArrayObjectMultipleValue(
        generateInputDataMulti.slice(),
        INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_VALUE,
        this.generateInputForm.controls[columnId].value
      );

    // 詳細情報のコードが削除された入力項目コード情報を返却
    return returnDataMulti;
  }

  /**
   * ピッカーリスト.格納後情報
   * @param columnId カラムID
   */
  protected pickListTarget(columnId: string): object[] {
    // 画面.入力フォームにカラムIDの入力情報が存在するか否か
    if (!this.generateInputForm.controls[columnId].value) {
      // 画面.入力フォームにカラムIDの入力情報が存在しない場合

      // 空配列を返却
      return new Array();
    }

    // カラムIDの入力項目コード情報を取得
    const generateInputDataMulti: any[] =
      this.commonService.getArrayObjectValue(
        this.generateInputList,
        INPUT_INFORMATIONS_API_CONSTANT.COLUMN_ID,
        INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI,
        columnId
      );

    // 入力項目コード情報から詳細情報のコードを取得
    const returnDataMulti: any[] =
      this.commonService.createArrayObjectSearchValueString(
        generateInputDataMulti,
        INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_VALUE,
        this.generateInputForm.controls[columnId].value
      );

    // 詳細情報のコードに一致する入力項目コード情報を返却
    return returnDataMulti;
  }

  /**
   * ピッカーリスト.格納前情報から格納後情報に移動
   * @param columnId カラムID
   * @param moveData 移動情報
   */
  protected moveToTarget(columnId: string, moveData: any) {
    // 移動情報分ループ
    for (const item of moveData.items) {
      // 画面.入力フォームからカラムIDの入力情報を取得
      let formControlValue: Object[] =
        this.generateInputForm.controls[columnId].value;

      // 入力情報がnull or 空文字の場合
      if (!formControlValue) {
        // 配列初期化を実施
        formControlValue = new Array();
      }

      // 入力情報に移動情報のコード値を格納
      formControlValue.push(item.value);

      //  画面.入力フォームに入力情報を格納
      this.generateInputForm.get(columnId).setValue(formControlValue);
    }
  }

  /**
   * ピッカーリスト.格納後情報から格納前情報に移動
   * @param columnId カラムID
   * @param moveData 移動情報
   */
  protected moveToSource(columnId: string, moveData: any) {
    // 移動情報分ループ
    for (const item of moveData.items) {
      // 画面.入力フォームからカラムIDの入力情報を取得
      let formControlValue: Object[] =
        this.generateInputForm.controls[columnId].value;

      //  画面.入力フォームの入力情報から移動情報を削除
      this.generateInputForm
        .get(columnId)
        .setValue(formControlValue.filter((val) => val != item.value));
    }
  }

  /**
   * 入力項目値確認
   */
  public inputVerification() {
    // 入力エラーメッセージオブジェクトを初期化
    this.generateInputErrorMessageList = new Array();

    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: "",
        background_color: "",
        opacity: 0.3,
      }),
      LOADING_KEY.GENERATE_INPUT
    );

    let obj = this.generateInputForm.value;
    Object.keys(obj).forEach(function (key) {
      if (obj[key] == null) {
        obj[key] = "";
      }
    });

    /* 入力項目値バリデーションチェック */
    // 非同期同時実行リスト
    let task: Observable<any>[] = [
      /* 共通バリデーションチェック */
      this.generateService.multiValidationResult(
        this.generateInputList[0].table_id,
        obj
      ),
    ];

    // 業務入力チェックエンドポイントが存在するか否か
    if (this.endPointWorkInputCheck) {
      // 業務入力チェックエンドポイントが存在する場合

      task.push(
        /* 業務用バリデーションチェック */
        this.generateService.workValidationResult(
          this.endPointWorkInputCheck,
          this.pkeyId,
          this.generateInputForm.value
        )
      );
    }

    // 非同期同時実行
    forkJoin(task).subscribe((dataList) => {
      // レスポンスを結合
      const data = this.generateService.JoinValidationResponseList(dataList);
      // 画面ロードフラグをOFF(ロード終了)
      this.loadingState.loadEnd(LOADING_KEY.GENERATE_INPUT);
      let totalsize: number = 0;
      // 入力項目分ループ
      for (const item of this.generateInputList) {
        // 入力項目に該当するエラーメッセージが存在するか判定
        if (data.body[0].message[item.column_id]) {
          // エラーメッセージが存在する場合

          //  入力フォームエラー情報を生成
          let generateInputErrorMessage: GenerateInputErrorMessage = new GenerateInputErrorMessage();
          //  入力フォームエラー情報を格納
          generateInputErrorMessage.columnId = item.column_id;
          generateInputErrorMessage.columnName = item.column_name;
          generateInputErrorMessage.columnErrorMessage = data.body[0].message[item.column_id];
          this.generateInputErrorMessageList.push(generateInputErrorMessage);
        }
      }

      // 親コンポーネントへ入力フォームエラー情報を渡す
      this.generateInputErrorInformation.emit(this.generateInputErrorMessageList);
      // バリデーションチェック状態を確認
      if ("0" != data.body[0].result) {
        // チェック状態が異常の場合

        // 処理を終了
        return;
      }

      if (totalsize > CONSTANT.MAX_FILE_SIZE) {
        this.messageService.add({
          severity: TOAST.ERROR,
          summary: this.commonService.msg(MESSAGE_CODE.E00004),
          detail: this.commonService.msg(MESSAGE_CODE.E00014, CONSTANT.MAX_FILE_SIZE_text),
        });
        return;
      }

      // ページアンカーへの画面スクロールを実施
      this.pageTopScroll();

      /* 入力値確認データを作成 */
      // 入力値確認用リスト生成
      this.generateInputFormConfirmationList = new Array();

      // 入力項目分ループ
      for (const generateInput of this.generateInputList) {
        // 画面表示不可項目か否か
        if (generateInput.column_input_update == "4") {
          // 画面表示不可項目の場合
          continue;
        }

        // 入力値確認用オブジェクト
        let generateInputFormConfirmation: GenerateInputFormConfirmation = new GenerateInputFormConfirmation();

        // 入力項目タイプを判定
        if (generateInput.input_type == INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE ||
          generateInput.input_type == INPUT_INFORMATIONS_API_CONSTANT.NUMBER_TYPE ||
          generateInput.input_type == INPUT_INFORMATIONS_API_CONSTANT.DATE_TYPE ||
          generateInput.input_type == INPUT_INFORMATIONS_API_CONSTANT.TEXTAREA_TYPE ||
          generateInput.input_type == INPUT_INFORMATIONS_API_CONSTANT.DISABLE_EDIT ||
          generateInput.column_id == "hope_ship_time"
        ) {
          // 入力項目タイプが以下の場合
          // テキスト or
          // 数値 or
          // 日付 or
          // テキストエリアの場合

          // 入力項目値の名称を格納
          generateInputFormConfirmation.columnName = generateInput.column_name;

          // FormControlの入力値を格納
          if (generateInput.column_id == "user_department_id") {
            generateInputFormConfirmation.columnData =
              this.commonService.getArrayObjectValue(
                generateInput.column_code_list_multi,
                INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_VALUE,
                INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_NAME,
                this.generateInputForm.controls[generateInput.column_id].value
              );
          } else {
            generateInputFormConfirmation.columnData = this.generateInputForm.controls[generateInput.column_id].value;
          }

          // 入力値確認用オブジェクトを入力値確認用リストに追加
          this.generateInputFormConfirmationList.push(generateInputFormConfirmation);
          continue;
        } else if (generateInput.input_type == INPUT_INFORMATIONS_API_CONSTANT.TIME) {

          // 入力項目値の名称を格納
          generateInputFormConfirmation.columnName = generateInput.column_name;

          // FormControlの入力値を格納
          // generateInputFormConfirmation.columnData = this.generateInputForm.controls[generateInput.column_id].value;
          generateInputFormConfirmation.columnData = this.convert24HourTo12Hour(this.generateInputForm.controls[generateInput.column_id].value);

          // 入力値確認用オブジェクトを入力値確認用リストに追加
          this.generateInputFormConfirmationList.push(generateInputFormConfirmation);
          continue;
        } else if (generateInput.input_type == INPUT_INFORMATIONS_API_CONSTANT.PASSWORD_TYPE) {
          // パスワードの場合

          // 入力項目値の名称を格納
          generateInputFormConfirmation.columnName = generateInput.column_name;

          // FormControlの入力値を'●'に置換して格納
          generateInputFormConfirmation.columnData = "●".repeat(this.generateInputForm.controls[generateInput.column_id].value.length);
          // 入力値確認用オブジェクトを入力値確認用リストに追加
          this.generateInputFormConfirmationList.push(generateInputFormConfirmation);
          continue;
        } else if (generateInput.input_type == INPUT_INFORMATIONS_API_CONSTANT.FILE_TYPE || generateInput.input_type == "image") {
          //file type
          // 入力項目値の名称を格納
          generateInputFormConfirmation.columnName = generateInput.column_name;
          // FormControlの入力値を取得
          let value = this.generateInputForm.value[generateInput.column_id];
          // FormControlの入力値が取得不可(フォームが非活性の場合)
          if (undefined == value) {
            // 表示項目から値を取得
            value = this.commonService.getArrayObjectValue(
              this.generateDisplayList,
              DISPLAY_INFORMATIONS_API_CONSTANT.COLUMN_ID,
              DISPLAY_INFORMATIONS_API_CONSTANT.DATA,
              generateInput.column_id
            );
          }

          // FormControlの入力値を格納
          generateInputFormConfirmation.columnData = value;
          // 入力値確認用オブジェクトを入力値確認用リストに追加
          this.generateInputFormConfirmationList.push(generateInputFormConfirmation);
        } else if (
          generateInput.input_type == INPUT_INFORMATIONS_API_CONSTANT.RADIO_TYPE ||
          generateInput.input_type == INPUT_INFORMATIONS_API_CONSTANT.SINGLE_SELECT_TYPE
        ) {
          // 種類が以下の場合
          // ラジオボタン or
          // シングルセレクトの場合

          // 入力項目値の名称を格納
          generateInputFormConfirmation.columnName = generateInput.column_name;

          // FormControlの入力値を名称に変換して格納
          generateInputFormConfirmation.columnData =
            this.commonService.getArrayObjectValue(
              generateInput.column_code_list_multi,
              INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_VALUE,
              INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_NAME,
              this.generateInputForm.controls[generateInput.column_id].value
            );

          // 入力値確認用オブジェクトを入力値確認用リストに追加
          this.generateInputFormConfirmationList.push(generateInputFormConfirmation);
          continue;
        } else {
          // 種類が以下の場合
          // チェックボックス or
          // マルチセレクトの場合

          // 入力項目値の名称を格納
          generateInputFormConfirmation.columnName = generateInput.column_name;

          // FormGroupの入力値(複数)を名称に変換して格納
          generateInputFormConfirmation.columnData = this.commonService
            .getArrayObjectMultipleValue(
              generateInput.column_code_list_multi,
              INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_VALUE,
              INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_NAME,
              this.generateInputForm.controls[generateInput.column_id].value
            )
            .join(CONSTANT.FUllSIZE_COMMA);
          // 入力値確認用オブジェクトを入力値確認用リストに追加
          this.generateInputFormConfirmationList.push(generateInputFormConfirmation);
          continue;
        }
      }

      // 確認画面を表示
      this.confirmationFlag = true;
    });
  }

  private convert24HourTo12Hour(timeString) {
    if (timeString) {
      const [hourString, minute] = timeString.split(":");
      const hour = +hourString % 24;
      return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
    } else {
      return ""
    }
  }

  /**
   * 確認画面戻るボタン
   */
  protected returnConfirmationButton() {
    // 確認画面から入力画面へ遷移する
    this.confirmationFlag = false;

    // ページアンカーへの画面スクロールを実施
    setTimeout(this.fillDataFieldDisableEdit, 100, this.generateInputList, this.generateDisplayInformation);

    this.pageTopScroll();
  }

  /**
   * Fills the data field of input elements with the DISABLE_EDIT input type based on the provided input and data.
   *
   * @param {Array} input - The array of input objects.
   * @param {Array} data - The array of data objects.
   */
  fillDataFieldDisableEdit(input, data) {
    for (const generateInput of input) {
      // console.log("generateInput: ", generateInput);
      // 入力項目タイプを判定
      if (generateInput.input_type == INPUT_INFORMATIONS_API_CONSTANT.DISABLE_EDIT) {
        const element = document.getElementById(generateInput.column_id);
        let value = data.find(x => x.column_id == generateInput.column_id)?.data;

        if (generateInput.column_id == 'user_department_id') {
          value = generateInput.column_code_list_multi.find(x => x.value == value)?.name;
        }

        if (element && value) {
          element.innerText = value;
        }
      }
    }
  }

  /**
   * ページアンカーへの画面スクロール
   */
  private pageTopScroll() {
    // 入力エラーメッセージのページアンカーが存在するか否か
    if (document.getElementById("generateInputErrorTop")) {
      // 入力エラーメッセージのページアンカーが存在する場合

      // 入力エラーメッセージのページアンカーにスクロール
      let top = document.getElementById("generateInputErrorTop");
      top.scrollIntoView();
      top = null;
    } else {
      // 入力エラーメッセージのページアンカーが存在しない場合

      // 入力フォームのページアンカーにスクロール
      let top = document.getElementById("generateInputFormTop");
      top.scrollIntoView();
      top = null;
    }
  }

  /**
   * 入力フォーム情報返却
   */
  public async returnGenerateInputForm() {
    this.loadingState.loadStart();
    // 登録ボタン押下フラグをONにする(登録、戻るボタン非活性化)
    this.insertFlag = true;
    // 親コンポーネントへ入力フォーム情報を渡す
    this.generateInputInformation.emit(this.generateInputForm);
    this.loadingState.loadEnd();
  }

  /**
   * 入力フォーム状態初期化
   */
  public resetFlag() {
    // 入力エラーメッセージオブジェクトを初期化
    this.generateInputErrorMessageList = new Array();

    // 確認画面表示フラグを初期化
    this.confirmationFlag = false;

    // 登録ボタン押下フラグを初期化
    this.insertFlag = false;
  }

  /**
   * エラー項目チェック
   * @param columnId 対象項目
   * @returns true:エラーメッセージが存在する場合 false:エラーメッセージが存在しない場合
   */
  protected checkErrorItem(columnId: string): boolean {
    // エラーメッセージリストのエラーメッセージ存在チェック
    // 対象項目が存在する場合、trueを返却
    return this.generateInputErrorMessageList.some(
      (errorMessage) => errorMessage.columnId === columnId
    );
  }

  // remove fileUpload from list
  removeFile(file: File, uploader: FileUpload, colId) {
    const index = uploader.files.indexOf(file);
    uploader.remove(null, index);
    if (
      colId == "agenda_solution_icon_image" ||
      colId == "agenda_need_icon_image"
    ) {
      this.fileUploadedImage = null;
    }

    if (
      colId == "agenda_solution_document" ||
      colId == "agenda_need_document"
    ) {
      this.fileUploadDocument = null;
    }
    this.generateInputForm.controls[colId].setValue(CONSTANT.EMPTY_STRING);
    this.generateInputForm.controls[colId].markAsDirty();
    this.hasSelectedFile = false;
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array]);
    return blob;
  }

  handlePlaceholder(placeholder: string) {
    if (placeholder.length == 0) {
      return '　';
    }
    return placeholder;
  }

  blockNonInteger(event: KeyboardEvent, column_id: string) {
    // Allow only digit characters
    if (column_id == 'order_num') {
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault(); // Prevent any non-digit input
      }
    } else if (!/[0-9-]/.test(event.key)) {
      event.preventDefault(); // Prevent any non-digit input
    }
  }

  changeVehicleType() {
    const vehicle_type = this.generateInputForm.get('vehicle_type').value;
    let vehicleTypeDetail = this.listVehicleType.find(x => x.sp_code == vehicle_type);
    if (vehicleTypeDetail) {
      let options = this.listDeliveryCompany.filter(x => vehicleTypeDetail.sp_ext.split(",").includes(x.sp_code));
      this.generateInputForm.get('delivery_company').setValue(options[0]?.sp_code);
    }
  }

  getDeliveryCompanyOption(options) {
    const currentVehicleTypeId = this.generateInputForm.get('vehicle_type').value;
    let vehicleTypeDetail = this.listVehicleType.find(x => x.sp_code == currentVehicleTypeId);
    if (vehicleTypeDetail) {
      return options.filter(x => vehicleTypeDetail.sp_ext.split(",").includes(x.value));
    } else {
      return options;
    }
  }

  getVehicleTypeOption(options) {
    let currentDeliveryCompany = this.generateInputForm.get('delivery_company').value;
    if (currentDeliveryCompany != null && currentDeliveryCompany != undefined && currentDeliveryCompany != CONSTANT.EMPTY_STRING) {
      return this.listVehicleType.filter(x => x.sp_ext.split(",").includes(currentDeliveryCompany));
    }
    return this.listVehicleType;
  }

  changeDeliveryCompany() {
    const deliveryCompany = this.generateInputForm.get('delivery_company').value;
    const vehicleType = this.generateInputForm.get('vehicle_type').value;
    if (vehicleType == null || vehicleType == undefined || vehicleType == CONSTANT.EMPTY_STRING) {
      let options = this.listVehicleType.filter(x => x.sp_ext.split(",").includes(deliveryCompany));
      this.generateInputForm.get('vehicle_type').setValue(options[0]?.sp_code)
    }
  }

  handlePulldownDeliveryKbn(value) {
    if (value == DELIVERY_KBN.SPOT_3 || value == DELIVERY_KBN.SPOT_3_TEXT) {
      this.enableModeDeliverySpot();
    } else {
      this.disableModeDeliverySpot();
    }
  }

  enableModeDeliverySpot() {
    this.generateInputForm.controls["customer_code"].setValue("");
    this.generateInputForm.controls["delivery_kbn_name"].setValue("");
    this.generateInputForm.controls["vehicle_no"].setValue("");
    this.generateInputForm.controls["delivery_company_name"].setValue("");
    this.generateInputForm.controls["delivery_type"].setValue("");

    this.generateInputForm.controls["customer_code"].disable();
    this.generateInputForm.controls["delivery_kbn_name"].disable();
    this.generateInputForm.controls["vehicle_no"].disable();
    this.generateInputForm.controls["delivery_company_name"].disable();
    this.generateInputForm.controls["delivery_type"].disable();
  }

  disableModeDeliverySpot() {
    this.generateInputForm.controls["customer_code"].enable();
    this.generateInputForm.controls["delivery_kbn_name"].enable();
    this.generateInputForm.controls["vehicle_no"].enable();
    this.generateInputForm.controls["delivery_company_name"].enable();
    this.generateInputForm.controls["delivery_type"].enable();
  }
}
