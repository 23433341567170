import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { GenerateInputInformationComponent } from './generate-input-information/generate-input-information.component';
import { GENERATE_INPUT_TYPE, GENERATE_INPUT_TITLE } from './constant';
import { Router } from '@angular/router';
import { C201_TEMPLATE } from 'manager/template-constant';

@Component({
  selector: 'app-generate-input',
  templateUrl: './generate-input.component.html',
  styleUrls: ['./generate-input.component.scss'],
})

/**
 * 入力画面ダイアログ
 */
export class GenerateInputComponent implements OnInit {
  /** 入力情報 */
  @ViewChild(GenerateInputInformationComponent)
  generateInputInformationComponent: GenerateInputInformationComponent;

  // 更新対象ID
  @Output() reloadID = new EventEmitter<string>();

  /* 画面用プロパティ */
  // 入力画面ダイアログ表示フラグ
  inputModal: boolean;

  // ダイアログタイトル
  title: string;

  constructor(private router: Router,) { }

  ngOnInit(): void { }

  /**
   * 入力画面表示
   * @param inputType 登録種別（新規:new、編集:edit、複製:duplicate）
   * @param endPointInput 入力項目生成用エンドポイント
   * @param endPoint  REST APIエンドポイント
   * @param templateId テンプレートID
   * @param pkeyId 対象ID
   * @param inputQueryParameters 入力項目生成時の絞り込み条件
   * @param endPointWorkInputCheck 業務入力チェックエンドポイント
   */
  public initial(
    inputType: string,
    endPointInput: string,
    endPointGenerateDisplay: string,
    endPoint: string,
    templateId: number,
    pkeyId?: string,
    inputQueryParameters?: Object,
    endPointWorkInputCheck?: string
  ) {
    // 入力画面ダイアログ表示
    this.inputModal = true;

    // 登録種別の判定
    if (GENERATE_INPUT_TYPE.NEW == inputType) {
      // 登録種別が新規
      this.title = GENERATE_INPUT_TITLE.NEW;
    } else if (GENERATE_INPUT_TYPE.EDIT == inputType) {
      // 登録種別が編集
      this.title = GENERATE_INPUT_TITLE.EDIT;
    } else if (GENERATE_INPUT_TYPE.APPROVE == inputType) {
      // 登録種別が複製
      this.title = GENERATE_INPUT_TITLE.APPROVE;
    } else if (GENERATE_INPUT_TYPE.DUPLICATE == inputType) {
      // 登録種別が複製
      this.title = GENERATE_INPUT_TITLE.DUPLICATE;
    }

    // 入力情報表示
    this.generateInputInformationComponent.initial(
      inputType,
      endPointInput,
      endPointGenerateDisplay,
      endPoint,
      templateId,
      pkeyId,
      inputQueryParameters,
      endPointWorkInputCheck
    );
  }

  /**
   * 親画面へ返却する
   */
  public return(reloadID: string) {
    // 入力画面ダイアログを閉じる
    this.inputModal = false;

    // 更新対象IDにプライマリキーをセット
    this.reloadID.emit(reloadID);
  }
}
