<!-- 発注管理リスト -->
<app-breadcrumb [selectionLine]="cartSelected.length" [existData]="existData"></app-breadcrumb>

<!-- メイン領域-->
<mat-drawer-container class="main-container" autosize>

  <p-dataView #dv [value]="searchResultsList" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
    currentPageReportTemplate="{first}~{last}件表示({totalRecords}件中)" [rowsPerPageOptions]="[10, 50, 100]"
    styleClass="p-datatable-striped order-datatable" selectionMode="single" layout="list" [sortField]="sortField"
    [sortOrder]="sortOrder">
    <ng-template pTemplate="header">
      <div class="dv-header-button">
        <div class="p-d-flex p-jc-between">
          <div class="p-d-flex">
            <!-- 検索画面表示-->
            <button type="button" pButton icon="pi pi-search" (click)="search.toggle()" class="p-mr-2" pTooltip="検索"
              tooltipPosition="bottom"></button>
            <!-- カート追加 -->
            <button type="button" pButton label="選択" icon="pi pi-plus-circle" (click)="addCart()"
              class="p-mr-2 addCartButton"></button>
          </div>
        </div>
      </div>
      <ng-container *ngIf="dv.layout == 'list'">
        <div class="dv-header-table-header">
          <!-- dataViewヘッダー -->
          <p-table styleClass="p-datatable-sm generate-input">
            <!-- 一覧画面ヘッダー表示-->
            <ng-template pTemplate="header">
              <tr class="p-col-2">
                <!-- APIヘッダーの表示 -->
                <th class="inputCheckbox">
                </th>
                <ng-container *ngFor="let column of columnOrder">
                  <th [pSortableColumn]="column.field" (click)="onSortChange(column.field)"
                    *ngIf="column.field != 'user_user_id'">
                    {{ column.header }}
                    <p-sortIcon [field]="column.field"></p-sortIcon>
                  </th>
                </ng-container>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </ng-container>
    </ng-template>
    <ng-template let-searchResults let-rowIndex="rowIndex" pTemplate="listItem">
      <p-table #table [value]="[searchResults]" styleClass="p-table-striped order-table" selectionMode="single"
        dataKey="user_user_id" expandableRows="true" (onRowClick)="table.toggleRow($event.data)">
        <!-- 一覧画面ボディ表示-->
        <ng-template pTemplate="body">

          <tr class="p-col-2">
            <!-- チェックボックス -->
            <td class="inputCheckbox">
              <p-checkbox name="cartCheckBox" value="{{ searchResults.user_user_id }}" [(ngModel)]="cartSelected"
                (click)="onCheckItem()"></p-checkbox>
            </td>
            <ng-container *ngFor="let column of columnOrder; index as i" [ngSwitch]="true">
              <!-- 一覧項目値表示(詳細画面遷移項目) -->
              <td *ngIf="0 == i; else hideContent" style="display:none;" [pTooltip]="searchResults[column.field]" #tt
                (mouseenter)="onHover($event)" [tooltipDisabled]="tt.dataset.showtooltip!='true'" data-showTooltip=""
                showDelay="500" hideDelay="500">
                <label (click)="detail(searchResults[column.field])">
                  <u>{{ searchResults[column.field] }}</u>
                </label>
              </td>
              <!-- 一覧項目値表示 -->
              <ng-template #hideContent [ngSwitch]="column?.input_type">
                <td *ngSwitchCase="'NUMBER'" [pTooltip]="searchResults[column.field]" #tt (mouseenter)="onHover($event)"
                  [tooltipDisabled]="tt.dataset.showtooltip!='true'" data-showTooltip="" showDelay="500"
                  hideDelay="500">
                  {{ searchResults[column.field] | number }}
                </td>
                <td *ngSwitchDefault [pTooltip]="searchResults[column.field]" #tt (mouseenter)="onHover($event)"
                  [tooltipDisabled]="tt.dataset.showtooltip!='true'" data-showTooltip="" showDelay="500"
                  hideDelay="500">
                  {{ searchResults[column.field] }}
                </td>
              </ng-template>
            </ng-container>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
  </p-dataView>

  <!-- 検索画面-->
  <mat-drawer #search class="search-sidenav" mode="side">
    <!-- 検索画面非表示-->
    <button type="button" pButton icon="pi pi-arrow-left" (click)="search.toggle()" class="p-mr-2"></button>

    <!-- 検索画面[検索項目生成(アイテムアダプター)]-->
    <app-generate-search [searchEndPoint]="searchEndPoint" [searchTemplateId]="searchTemplateId"
      (generateSearchInformation)="searchResult(dv, $event)"></app-generate-search>
  </mat-drawer>
</mat-drawer-container>

<!-- 詳細画面[表示項目生成(アイテムアダプター)]-->
<app-generate-display></app-generate-display>

<!-- メッセージ -->
<app-message-common></app-message-common>

<!-- Toastメッセージ表示 -->
<p-toast position="top-center"></p-toast>