<div class="main">
    <!-- メインコンテンツ -->
    <div class="main-content">
      <div class="contact p-col-12 card pb-3">
        <div class="p-col-12 head">お問い合わせ</div>
        <p-divider type="solid"></p-divider>
        <div class="p-col-12 p-pt-3 p-pb-3">当サイトに関するお問い合わせは以下までご連絡ください。</div>
        <div class="p-col-12 p-d-flex">
            <div class="p-col-2 label">運営団体</div>
            <div class="p-col-10 pd-lr-0">NPO法人　会津エンジン
            </div>
        </div>
        <p-divider type="solid"></p-divider>
        <div class="p-col-12 p-d-flex">
            <div class="p-col-2 label">住所</div>
            <div class="p-col-10 pd-lr-0">〒965-0042<br>福島県会津若松市大町1-1-41（株式会社わーくすたいる内）

            </div>
        </div>
        <p-divider type="solid"></p-divider>
        <div class="p-col-12 p-d-flex">
            <div class="p-col-2 label">Email</div>
            <div class="p-col-10 pd-lr-0">enjin@work-style.co.jp
            </div>
        </div>
        <p-divider type="solid"></p-divider>
        <div class="p-col-12 p-d-flex">
            <div class="p-col-2 label">TEL</div>
            <div class="p-col-10 pd-lr-0">0242-85-6262
            </div>
        </div>
        <p-divider type="solid"></p-divider>
        <div class="p-col-12 p-d-flex">
            <div class="p-col-2 label">FAX</div>
            <div class="p-col-10 pd-lr-0">0242-85-6514
            </div>
        </div>
        <p-divider type="solid"></p-divider>
        <div class="p-col-12 p-d-flex">
            <div class="p-col-2 label">受付時間</div>
            <div class="p-col-10 pd-lr-0">平日9時～17時

            </div>
        </div>
      </div>
    </div>
    <div class="footer desktop">
        <div class="p-col-12 head p-d-flex">
            <ng-container *ngIf="mode ==0">
                <div class="p-col-5">
    
                </div>
                <div class="p-col-5">
                    <button
                        pButton
                        type="button"
                        label="プライバシーポリシー"
                        class="p-button-text"
                        (click)="null"
                    ></button>
                    |
                    <button
                    pButton
                    type="button"
                    label="マニュアル(学校ユーザー向け)"
                    class="p-button-text"
                    (click)="getManualSchool()"
                ></button>
                |
                <button
                    pButton
                    type="button"
                    label="マニュアル(支援ユーザー向け)"
                    class="p-button-text"
                    (click)="getManualSupport()"
                ></button>
                    |
                    <button
                        pButton
                        type="button"
                        label="お問い合わせ"
                        class="p-button-text"
                        (click)="toggleContact()"
                    ></button>
                </div>
            </ng-container>
            <ng-container *ngIf="mode ==1">
                <div class="p-col-12 p-d-flex p-jc-center pd-0">
                    <div class="p-col-4 p-jc-between p-d-flex pd-0">
                        <button
                            pButton
                            type="button"
                            label="お問い合わせ"
                            class="p-button-text"
                            (click)="toggleContact()"
                        ></button>
                        |
                        <button
                            pButton
                            type="button"
                            label="利用規約"
                            class="p-button-text"
                            (click)="null"
                        ></button>
                        |
                        <button
                            pButton
                            type="button"
                            label="プライバシーポリシー"
                            class="p-button-text"
                            (click)="null"
                        ></button>
                    </div>
                </div>
            </ng-container>
        </div>
            
        <div class="p-col-12 body p-grid p-m-0">
            <div class="p-col-12 p-d-flex cp-0"><div class="p-col-5"></div><div class="p-col-5">運営団体：NPO法人 会津エンジン</div></div>
            <div class="p-col-12 p-d-flex cp-0"><div class="p-col-5"></div><div class="p-col-5">〒965-0042</div></div>
            <div class="p-col-12 p-d-flex cp-0"><div class="p-col-5"></div><div class="p-col-5">福島県会津若松市大町1-1-41（株式会社わーくすたいる内）</div></div>
            <div class="p-col-12 p-d-flex cp-0"><div class="p-col-5"></div><div class="p-col-5">0242-85-6262</div></div>
            <div class="p-col-12 p-d-flex cp-0"><div class="p-col-5"></div><div class="p-col-5">受付時間：平日9時～17時</div></div>
        </div>
    </div>
    <div class="footer mobile">
        <div class="p-col-12 head p-d-flex p-jc-center">
            <ng-container *ngIf="mode ==0">
                
                <div class="p-col-7">
                    <button
                        pButton
                        type="button"
                        label="プライバシーポリシー"
                        class="p-button-text"
                        (click)="null"
                    ></button>
                    |
                    <button
                        pButton
                        type="button"
                        label="マニュアル(学校ユーザー向け)"
                        class="p-button-text"
                        (click)="getManualSchool()"
                    ></button>
                    |
                    <button
                        pButton
                        type="button"
                        label="マニュアル(支援ユーザー向け)"
                        class="p-button-text"
                        (click)="getManualSupport()"
                    ></button>
                    |
                    <button
                        pButton
                        type="button"
                        label="お問い合わせ"
                        class="p-button-text"
                        (click)="moveContact()"
                    ></button>
                </div>
            </ng-container>
            <ng-container *ngIf="mode ==1">
                <div class="p-col-12 p-d-flex p-jc-center pd-0">
                    <div class="p-col-4 p-jc-between p-d-flex pd-0">
                        <button
                            pButton
                            type="button"
                            label="お問い合わせ"
                            class="p-button-text"
                            (click)="moveContact()"
                        ></button>
                        |
                        <button
                            pButton
                            type="button"
                            label="利用規約"
                            class="p-button-text"
                            (click)="null"
                        ></button>
                        |
                        <button
                            pButton
                            type="button"
                            label="プライバシーポリシー"
                            class="p-button-text"
                            (click)="null"
                        ></button>
                    </div>
                </div>
            </ng-container>
        </div>
            
        <div class="p-col-12 body p-grid p-m-0 p-jc-center ">
            <div class="p-col-7">運営団体：NPO法人 会津エンジン</div>
            <div class="p-col-7">〒965-0042</div>
            <div class="p-col-7">福島県会津若松市大町1-1-41（株式会社わーくすたいる内）</div>
            <div class="p-col-7">0242-85-6262</div>
            <div class="p-col-7">受付時間：平日9時～17時</div>
        </div>
    </div>
</div>
