<p-toolbar *ngIf="headerFlag">
  <div class="p-toolbar-group-left">
    <!-- メニュー-->
    <!--メッセージモード以外の場合、表示-->
    <button #btn pButton type="button" icon="pi pi-bars" class="p-button-text p-button-secondary"
      (click)="menu.toggle($event)" *ngIf="headerFlag"></button>
    <p-menu #menu [model]="items" [popup]="true"></p-menu>

    <!-- TOP画面-->
    <!--メッセージモード以外の場合、表示-->
    <button pButton type="button" icon="pi pi-home" (click)="onHome()" class="p-button-text p-button-secondary"
      *ngIf="headerFlag"></button>
    <!-- ボタン無し時の空div -->
    <div class="noneButton" *ngIf="!headerFlag"></div>
  </div>

  <!-- システム名 -->
  <div class="p-toolbar-group-center">
    <p>{{headerTitle?headerTitle:'情報系荷物運搬便システム'}}</p>
  </div>

  <div class="p-toolbar-group-right">
    <a class="linktext-blue underline" (click)="openURI()">マニュアルはこちら</a>
    <!-- カート画面 -->
    <!--ログインユーザの組織種別が 2:寄贈先 or 9:その他 の場合、表示-->
    <!--メッセージモード以外の場合、表示-->
    <!-- ボタン無し時の空div -->
  </div>
</p-toolbar>