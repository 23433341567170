import { Component, HostListener, OnInit } from '@angular/core';
import { TITLE } from 'manager/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})

/**
 * フッダー
 */
export class FooterComponent implements OnInit {
  // タイトルフッダーを設定
  title: string = TITLE.TITLE_FOOTER;

  // 開設日を設定
  openingDate: string = TITLE.OPENING_DATE;

  constructor() {}

  ngOnInit(): void {}

  /**
   * スクロールをトップに戻す
   */
  public transitionPageTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  /**
   * 画面スクロール実行時に実行
   */
  // @HostListener('window:scroll', ['$event'])
  // protected onScroll(event) {
  //   // スクロール位置の高さが100以上の場合
  //   if (window.scrollY > 100) {
  //     // "トップにスクロール"ボタン"を表示
  //     document.getElementById('pageTopButton').removeAttribute('disabled');
  //   } else {
  //     // "トップにスクロール"ボタン"を非表示
  //     document.getElementById('pageTopButton').setAttribute('disabled', 'true');
  //   }
  // }
}
