/**
 * 入力項目生成定数
 */
// 入力画面フラグ
export const GENERATE_INPUT_TYPE = {
  // 新規
  NEW: 'new',
  // 編集
  EDIT: 'edit',
  // 複製
  DUPLICATE: 'duplicate',
  // 承認
  APPROVE: 'approve'
};

// 入力画面タイトル
export const GENERATE_INPUT_TITLE = {
  // 新規
  NEW: '新規',
  NEWM301:'支援内容を登録する',
  // 編集
  EDIT: '編集',
  EDITM301:'支援内容を編集する',
  // 複製
  DUPLICATE: '複製',
  DUPLICATEM301: '支援内容を複製する',
  APPROVE: '承認',
};


