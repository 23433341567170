import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, ConfirmationService } from 'primeng/api';
import { CONSTANT, DELIVERY_COLLECT_KBN, SCREEN_PATH } from '../../constant/constant';
import { MESSAGE_CODE } from '../../constant/message-constant';
import { CommonService } from '../../service/common.service';
import { DbOperationService } from '../../service/db-operation.service';
import { LoadingState } from '../loading/loading-state';
import { MessageCommonComponent } from '../message-common/message-common.component';
import { BREADCRUMB_ORDER } from './breadcrumb-constants';
import { API_URL_GET_WORK_PROGRESS } from 'manager/http-constants';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  /** メッセージ */
  @ViewChild(MessageCommonComponent)
  messageCommonComponent: MessageCommonComponent;

  // 選択行
  @Input() selectionLine: number;

  @Input() existData: boolean;

  // パンくずリスト
  items: MenuItem[] = [
    {
      label: BREADCRUMB_ORDER.SELECT_WORK_EMPLOYEE_BUSINESS,
    },
    {
      label: BREADCRUMB_ORDER.SELECT_WORK_EMPLOYEE_SALES,
    },
    {
      label: BREADCRUMB_ORDER.SELECT_WORK_ITEM,
    },
    {
      label: BREADCRUMB_ORDER.SELECT_WORK_DELIVERY_FROM,
    },
    {
      label: BREADCRUMB_ORDER.SELECT_WORK_DELIVERY_TO,
    },
    {
      label: BREADCRUMB_ORDER.INPUT_WORK_ORDER,
    },
    {
      label: BREADCRUMB_ORDER.QUANTITY_INPUT,
    },
  ];

  delivery_collect_kbn: string = DELIVERY_COLLECT_KBN.DELIVERY_0;

  checkExist: boolean = false;

  constructor(
    private dbOperationService: DbOperationService,
    private router: Router,
    private commonService: CommonService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    // 選択ボタンが押下されたか否か
    const { delivery_collect_kbn } = this.route.snapshot.queryParams;
    if (delivery_collect_kbn) {
      this.delivery_collect_kbn = delivery_collect_kbn;
    } else {
      this.delivery_collect_kbn = DELIVERY_COLLECT_KBN.DELIVERY_0;
    }
  }

  // パンくずリストのクリックイベント
  public async itemClicked(event) {
    const currentPath = window.location.pathname.toLowerCase();
    // console.log("currentPath: ", currentPath);
    const pathDestination = this.getDestinationPath(event.item.label);
    // console.log("pathDestination: ", pathDestination);

    const result = this.compareIndex(currentPath, pathDestination);
    // console.log("result: ", result);
    // console.log("this.existData: ", this.existData);

    if (result == 0) {
      // current page
      // console.log("current page");
      return;
    }

    if (result < 0) {
      // console.log("previous page");
      // previous page
    }

    if (result == 1 && !this.existData) {
      // console.log("next page but data not found");
      return;
    }

    if (result == 1 && this.existData) {
      // console.log("next page and data found");
      // next page
    }

    if (result > 1) {
      // next page
      // console.log("jump to more than next page");
      this.checkExist = false;
      await this.checkData(pathDestination);
      if (!this.checkExist) {
        return;
      }
    }

    /* 販促資材(パンくずリスト) */
    // 販促資材が押下されたか否か
    // // 選択行が存在するか否か
    if (!this.selectionLine) {
      // 選択行が存在しない場合
      // 発注管理_販促資材へ遷移
      this.router.navigate([pathDestination], { queryParams: { delivery_collect_kbn: this.delivery_collect_kbn } });
    } else {
      // 選択確認ダイアログを表示
      this.confirmationService.confirm({
        message: this.commonService.msg(MESSAGE_CODE.T00010),
        header: CONSTANT.EMPTY_ONE_STRING,
        icon: 'pi pi-info-circle',
        accept: () => {
          // 発注管理_販促資材へ遷移
          // 発注管理_販促資材へ遷移
          this.router.navigate([pathDestination], { queryParams: { delivery_collect_kbn: this.delivery_collect_kbn } });
        },
        reject: () => { },
      });
    }
  }

  private compareIndex(currentPath: string, destPath: string): number {
    const path: string[] = ['/pages/platform/c101', '/pages/platform/c1012',
      '/pages/platform/c111', '/pages/platform/c121',
      '/pages/platform/c131', '/pages/platform/cxxx',
      '/pages/platform/c141', '/pages/platform/c151'];

    const current = path.findIndex(x => x == currentPath);
    // console.log("current: ", current);
    const dest = path.findIndex(x => x == destPath);
    // console.log("dest: ", dest);

    return dest - current;
  }

  private getDestinationPath(label: string) {
    switch (label) {
      case BREADCRUMB_ORDER.SELECT_WORK_EMPLOYEE_BUSINESS:
        return SCREEN_PATH.C101;
      case BREADCRUMB_ORDER.SELECT_WORK_EMPLOYEE_SALES:
        return SCREEN_PATH.C1012;
      case BREADCRUMB_ORDER.SELECT_WORK_ITEM:
        return SCREEN_PATH.C111;
      case BREADCRUMB_ORDER.SELECT_WORK_DELIVERY_FROM:
        return SCREEN_PATH.C121;
      case BREADCRUMB_ORDER.SELECT_WORK_DELIVERY_TO:
        return SCREEN_PATH.C131;
      case BREADCRUMB_ORDER.INPUT_WORK_ORDER:
        return SCREEN_PATH.CXXX;
      case BREADCRUMB_ORDER.QUANTITY_INPUT:
        return SCREEN_PATH.C141;
      default:
        return SCREEN_PATH.C101;
    }
  }

  private async checkData(pathDestination: string) {
    return new Promise((resolve) => {
      this.dbOperationService
        .getNoTemplateData(
          API_URL_GET_WORK_PROGRESS,
          {
            delivery_collect_kbn: [this.delivery_collect_kbn]
          }
        )
        .subscribe((response) => {
          // コード値の一覧情報が存在するか否か
          if (!this.commonService.checkNoneResponse(response) && response.body.indexOf(pathDestination) != -1) {
            this.checkExist = true;
          }
          resolve(null);
        });
    });
  }
}
