<!-- 入力フォームページアンカー -->
<a id="generateInputFormTop"></a>
<!-- 入力フォーム -->
<div class="generateInputForm" *ngIf="!confirmationFlag">
  <form [formGroup]="generateInputForm" autocomplete="off">
    <!-- 入力情報出力領域 -->
    <p-table [value]="generateInputList" styleClass="p-datatable-sm generate-input">
      <!-- 入力情報タイトル -->
      <ng-template pTemplate="header">
        <tr class="desktop">
          <th colspan="2">入力</th>
        </tr>
      </ng-template>
      <!-- 入力情報出力箇所 -->
      <ng-template pTemplate="body" let-generateInputInformation>

        <!-- 画面表示不可以外の場合 -->
        <tr *ngIf="generateInputInformation.column_input_update != '4'">
          <!-- カラム名称 -->
          <td class="label-center p-px-3">
            <label class="p-col-fixed p-d-flex p-p-0">
              <div class="p-col">
                {{ generateInputInformation.column_name }}
              </div>

              <!-- 項目定義マスタ.入力必須有無に'1'が存在する場合、必須を表示 -->
              <div *ngIf="generateInputInformation.column_input_require == '1'" class="p-tag p-tag-danger require">
                必須
              </div>
            </label>
          </td>

          <!-- 入力項目 -->
          <td class="input-form">
            <!-- 入力項目タイプを判定 -->
            <!-- テキスト -->

            <div class="ui-fluid" *ngIf="generateInputInformation.input_type == 'disableedit'" class="disable-edit">
              <p id="{{generateInputInformation.column_id}}"></p>
              <input [name]="generateInputInformation.column_id" type="hidden"
                [maxlength]="generateInputInformation.column_hsize" [class.errorItem]="
                  checkErrorItem(generateInputInformation.column_id)
                " [formControlName]="generateInputInformation.column_id" pInputText autocomplete="off"
                [placeholder]="handlePlaceholder(generateInputInformation.column_memo)" />
            </div>

            <div class="ui-fluid" *ngIf="generateInputInformation.input_type == 'text'">
              <input [name]="generateInputInformation.column_id" type="text"
                [maxlength]="generateInputInformation.column_hsize" [class.errorItem]="
                  checkErrorItem(generateInputInformation.column_id)
                " [formControlName]="generateInputInformation.column_id" pInputText autocomplete="off"
                [placeholder]="handlePlaceholder(generateInputInformation.column_memo)" />
            </div>

            <div class="ui-fluid" *ngIf="generateInputInformation.input_type == 'time'">
              <input [name]="generateInputInformation.column_id" type="time"
                [maxlength]="generateInputInformation.column_hsize" [class.errorItem]="
                              checkErrorItem(generateInputInformation.column_id)
                            " [formControlName]="generateInputInformation.column_id" pInputText autocomplete="off"
                [placeholder]="handlePlaceholder(generateInputInformation.column_memo)" />
            </div>

            <!-- ファイル -->
            <!-- file -->
            <div class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'file' || generateInputInformation.input_type == 'image'">
              <input type="hidden" [name]="generateInputInformation.column_id"
                [formControlName]="generateInputInformation.column_id" pInputText autocomplete="off" />
              <p-fileUpload #fileUpload [customUpload]="true" (onSelect)="
                  uploadFile(
                    $event,
                    generateInputInformation.column_id,
                    fileUpload
                  )
                " [showUploadButton]="false" [showCancelButton]="false" [multiple]="false" class="btn-upload">
                <ng-template pTemplate="content">
                  <div class="file-content"
                    *ngIf="(generateInputInformation.column_id == 'agenda_solution_icon_image' || generateInputInformation.column_id == 'agenda_need_icon_image') && fileUploadedImage">
                    <div class="file_name">{{ fileUploadedImage.name }}</div>
                    <div>
                      <i class="pi pi-times"
                        (click)="removeFile(fileUploadedImage, fileUpload, generateInputInformation.column_id)"></i>
                    </div>
                  </div>
                  <div class="file-content"
                    *ngIf="(generateInputInformation.column_id == 'agenda_solution_document' || generateInputInformation.column_id == 'agenda_need_document') && fileUploadDocument">
                    <div class="file_name">{{ fileUploadDocument.name }}</div>

                    <div>
                      <i class="pi pi-times"
                        (click)="removeFile(fileUploadDocument, fileUpload, generateInputInformation.column_id)"></i>
                    </div>
                  </div>
                </ng-template>
              </p-fileUpload>
            </div>

            <!-- 日付 -->
            <div class="ui-fluid" *ngIf="generateInputInformation.input_type == 'date'">
              <p-calendar [name]=" generateInputInformation.column_id" [showIcon]="true" [showButtonBar]="true"
                [yearNavigator]="true" [monthNavigator]="true" [readonlyInput]="true" dateFormat="yy/mm/dd"
                dataType="string" appendTo="body" [readonlyInput]="true" yearRange="2000:2999" [inputStyleClass]="
                  checkErrorItem(generateInputInformation.column_id)
                    ? 'errorItem'
                    : ''
                " [formControlName]="generateInputInformation.column_id" translate
                [placeholder]="handlePlaceholder(generateInputInformation.column_memo)">
              </p-calendar>
            </div>

            <!-- 数字 -->
            <div class="ui-fluid" *ngIf="generateInputInformation.input_type == 'number'">
              <input [name]="generateInputInformation.column_id" type="number" [class.errorItem]="
                checkErrorItem(generateInputInformation.column_id)
              " [formControlName]="generateInputInformation.column_id" pInputText autocomplete="off"
                [placeholder]="handlePlaceholder(generateInputInformation.column_memo)"
                (keypress)="blockNonInteger($event, generateInputInformation.column_id)" />
            </div>

            <!-- チェックボックス -->
            <div class="ui-fluid p-d-flex p-grid desktop" *ngIf="generateInputInformation.input_type == 'checkbox'"
              [class.errorItemBorder]="
                checkErrorItem(generateInputInformation.column_id)
              ">
              <div *ngFor="
                  let code_list of generateInputInformation.column_code_list_multi
                ">
                <p-checkbox [name]="generateInputInformation.column_id" [label]="code_list.name"
                  [value]="code_list.value" [formControl]="
                    generateInputForm.controls[
                      generateInputInformation.column_id
                    ]
                  " class="p-col">
                </p-checkbox>
              </div>
            </div>

            <div class="ui-fluid mobile" *ngIf="generateInputInformation.input_type == 'checkbox'"
              [class.errorItemBorder]="
                checkErrorItem(generateInputInformation.column_id)
              ">
              <div *ngFor="
                  let code_list of generateInputInformation.column_code_list_multi
                ">
                <p-checkbox [name]="generateInputInformation.column_id" [label]="code_list.name"
                  [value]="code_list.value" [formControl]="
                    generateInputForm.controls[
                      generateInputInformation.column_id
                    ]
                  " class="p-col">
                </p-checkbox>
              </div>
            </div>

            <!-- ラジオボタン -->
            <div class="ui-fluid" *ngIf="generateInputInformation.input_type == 'radio'" [class.errorItemBorder]="
                checkErrorItem(generateInputInformation.column_id)
              ">
              <div class="p-field-radiobutton" *ngFor="
                  let column of generateInputInformation.column_code_list_multi
                ">
                <p-radioButton [name]="generateInputInformation.column_id" [label]="column.name" [value]="column.value"
                  [formControlName]="generateInputInformation.column_id">
                </p-radioButton>
              </div>
            </div>

            <!-- シングルセレクト-->
            <div class="ui-fluid" *ngIf="generateInputInformation.input_type == 'single_select'">
              <p-dropdown #dd [panelStyle]="{'width':dd?.width?dd.width:'100px'}"
                [name]="generateInputInformation.column_id"
                *ngIf="generateInputInformation.column_id == 'hope_ship_time'" [options]="deliveryTypeList"
                [showClear]="true" [filter]="
                      generateInputInformation.column_code_list_multi.length >= 10
                    " [appendTo]="'body'" emptyFilterMessage="検索結果が存在しません" [styleClass]="
                      checkErrorItem(generateInputInformation.column_id)
                        ? 'errorItem'
                        : ''
                    " [formControl]="
                      generateInputForm.controls[
                        generateInputInformation.column_id
                      ]
                    " [placeholder]="handlePlaceholder(generateInputInformation.column_memo)">
              </p-dropdown>
              <p-dropdown #dd [panelStyle]="{'width':dd?.width?dd.width:'100px'}"
                [name]="generateInputInformation.column_id" *ngIf="generateInputInformation.column_id == 'vehicle_type'"
                (onChange)="changeVehicleType()" 
                [options]="getVehicleTypeOption(generateInputInformation.column_code_list_multi)"
                optionLabel="sp_name" optionValue="sp_code" [showClear]="true" [filter]="
                      generateInputInformation.column_code_list_multi.length >= 10
                    " [appendTo]="'body'" emptyFilterMessage="検索結果が存在しません" [styleClass]="
                      checkErrorItem(generateInputInformation.column_id)
                        ? 'errorItem'
                        : ''
                    " [formControl]="
                      generateInputForm.controls[
                        generateInputInformation.column_id
                      ]
                    " [placeholder]="handlePlaceholder(generateInputInformation.column_memo)">
              </p-dropdown>
              <p-dropdown #dd [panelStyle]="{'width':dd?.width?dd.width:'100px'}"
                [name]="generateInputInformation.column_id"
                *ngIf="generateInputInformation.column_id == 'delivery_company'"
                (onChange)="changeDeliveryCompany()" 
                [options]="getDeliveryCompanyOption(generateInputInformation.column_code_list_multi)" optionLabel="name"
                optionValue="value" [showClear]="true" [filter]="
                      generateInputInformation.column_code_list_multi.length >= 10
                    " [appendTo]="'body'" emptyFilterMessage="検索結果が存在しません" [styleClass]="
                      checkErrorItem(generateInputInformation.column_id)
                        ? 'errorItem'
                        : ''
                    " [formControl]="
                      generateInputForm.controls[
                        generateInputInformation.column_id
                      ]
                    " [placeholder]="handlePlaceholder(generateInputInformation.column_memo)">
              </p-dropdown>
              <p-dropdown #dd [panelStyle]="{'width':dd?.width?dd.width:'100px'}"
                [name]="generateInputInformation.column_id" *ngIf="generateInputInformation.column_id == 'delivery_kbn'"
                [options]="generateInputInformation.column_code_list_multi" optionLabel="name" optionValue="value"
                (onChange)="handlePulldownDeliveryKbn($event.value)" [showClear]="true" [filter]="
                      generateInputInformation.column_code_list_multi.length >= 10
                    " [appendTo]="'body'" emptyFilterMessage="検索結果が存在しません" [styleClass]="
                      checkErrorItem(generateInputInformation.column_id)
                        ? 'errorItem'
                        : ''
                    " [formControl]="
                      generateInputForm.controls[
                        generateInputInformation.column_id
                      ]
                    " [placeholder]="handlePlaceholder(generateInputInformation.column_memo)">
              </p-dropdown>
              <p-dropdown #dd [panelStyle]="{'width':dd?.width?dd.width:'100px'}"
                [name]="generateInputInformation.column_id" *ngIf="
                  generateInputInformation.column_id != 'hope_ship_time' && 
                  generateInputInformation.column_id != 'delivery_company' && 
                  generateInputInformation.column_id != 'delivery_kbn' && 
                  generateInputInformation.column_id != 'vehicle_type'"
                [options]="generateInputInformation.column_code_list_multi" optionLabel="name" optionValue="value"
                [showClear]="true" [filter]="
                      generateInputInformation.column_code_list_multi.length >= 10
                    " [appendTo]="'body'" emptyFilterMessage="検索結果が存在しません" [styleClass]="
                      checkErrorItem(generateInputInformation.column_id)
                        ? 'errorItem'
                        : ''
                    " [formControl]="
                      generateInputForm.controls[
                        generateInputInformation.column_id
                      ]
                    " [placeholder]="handlePlaceholder(generateInputInformation.column_memo)">
              </p-dropdown>
            </div>

            <!-- マルチセレクト -->
            <div class="ui-fluid" *ngIf="generateInputInformation.input_type == 'multiple_select'">
              <p-multiSelect [name]="generateInputInformation.column_id"
                [options]="generateInputInformation.column_code_list_multi" defaultLabel="　"
                emptyFilterMessage="検索結果が存在しません" optionLabel="name" [appendTo]="'body'" [styleClass]="
                  checkErrorItem(generateInputInformation.column_id)
                    ? 'errorItem'
                    : ''
                " [formControl]="
                  generateInputForm.controls[generateInputInformation.column_id]
                " [placeholder]="handlePlaceholder(generateInputInformation.column_memo)">
              </p-multiSelect>
            </div>

            <!-- テキストエリア -->
            <div class="ui-fluid" *ngIf="generateInputInformation.input_type == 'textarea'">
              <textarea [name]="generateInputInformation.column_id" [maxlength]="generateInputInformation.column_hsize"
                rows="10" [class.errorItem]="
                  checkErrorItem(generateInputInformation.column_id)
                " [formControlName]="generateInputInformation.column_id" pInputTextarea autocomplete="off"
                [placeholder]="handlePlaceholder(generateInputInformation.column_memo)"></textarea>
            </div>

            <!-- パスワード -->
            <div class="ui-fluid" *ngIf="generateInputInformation.input_type == 'password'">
              <input [name]="generateInputInformation.column_id" type="password"
                [maxlength]="generateInputInformation.column_hsize" [class.errorItem]="
                  checkErrorItem(generateInputInformation.column_id)
                " [formControlName]="generateInputInformation.column_id" pInputText autocomplete="off"
                [placeholder]="handlePlaceholder(generateInputInformation.column_memo)" />
            </div>

            <!-- エラーメッセージ出力箇所 -->
            <app-validator-message [control]="
                generateInputForm.get(generateInputInformation.column_id)
              ">
            </app-validator-message>
          </td>
        </tr>

        <!-- ピッカーリスト -->
        <!-- TODO 条件仮置き-->
        <tr *ngIf="generateInputInformation.input_type == 'test'">
          <td colspan="2">
            <br />
            <p-pickList [source]="pickListSource(generateInputInformation.column_id)"
              [target]="pickListTarget(generateInputInformation.column_id)"
              [sourceHeader]="generateInputInformation.column_name" targetHeader="選択リスト" [dragdrop]="true"
              [showSourceControls]="false" [showTargetControls]="false" filterBy="name" (onMoveToTarget)="
                moveToTarget(generateInputInformation.column_id, $event)
              " (onMoveToSource)="
                moveToSource(generateInputInformation.column_id, $event)
              " (onMoveAllToTarget)="
                moveToTarget(generateInputInformation.column_id, $event)
              " (onMoveAllToSource)="
                moveToSource(generateInputInformation.column_id, $event)
              ">
              <ng-template let-code pTemplate="item">
                {{ code.name }}
              </ng-template>
            </p-pickList>
            <br />
          </td>
        </tr>
      </ng-template>
    </p-table>
  </form>

  <!-- ボタンエリア -->
  <div class="buttonArea">
    <button class="button p-button-br-7" type="submit" label="入力チェック" (click)="inputVerification()"
      [disabled]="!generateInputForm.valid" pButton></button>
  </div>
</div>

<!-- 入力確認 -->
<div class="generateInputConfirmation" *ngIf="confirmationFlag">
  <!-- 確認情報出力領域 -->
  <p-table [value]="generateInputFormConfirmationList" styleClass="p-datatable-sm">
    <!-- 確認情報タイトル -->
    <ng-template pTemplate="header">
      <tr>
        <th colspan="2">確認</th>
      </tr>
    </ng-template>
    <!-- 確認情報出力箇所 -->
    <ng-template pTemplate="body" let-generateInputFormConfirmation>
      <tr>
        <!-- カラム名称 -->
        <td class="label-center p-px-3">
          <label class="p-col-fixed p-p-0">
            {{ generateInputFormConfirmation._columnName }}
          </label>
        </td>
        <!-- 入力項目値 -->
        <td class="ui-fluid confirmationData">
          {{ generateInputFormConfirmation._columnData }}
        </td>
      </tr>
    </ng-template>
  </p-table>

  <!-- ボタンエリア -->
  <div class="buttonArea desktop">
    <button class="button p-button-br-7" type="button" label="登録" (click)="returnGenerateInputForm()"
      [disabled]="insertFlag" pButton></button>
    <button class="button p-button-br-7" type="button" label="戻る" (click)="returnConfirmationButton()"
      [disabled]="insertFlag" pButton></button>
  </div>

  <div class="buttonArea p-d-flex mobile p-jc-center">
    <button class="p-col-5 button p-button-br-7 p-mr-2" type="button" label="登録" (click)="returnGenerateInputForm()"
      [disabled]="insertFlag" pButton></button>
    <button class="p-col-5 button p-button-br-7" type="button" label="戻る" (click)="returnConfirmationButton()"
      [disabled]="insertFlag" pButton></button>
  </div>
</div>