import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-img-preview',
  templateUrl: './img-preview.component.html',
  styleUrls: ['./img-preview.component.scss']
})
export class ImgPreviewComponent implements OnInit  {

  constructor() {
  }

  isShowPreviewImg:boolean = false;
  previewImg:any = {};

  ngOnInit(): void {
  }
  viewImg(path:string,name:string){
    this.previewImg = {
      path: path,
      name: name
    }
    this.isShowPreviewImg = true;
  }
  
  downloadFile(url: string){
    window.open(url, "_blank");
  }
}
