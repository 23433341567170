<!-- 発注管理リスト -->
<app-breadcrumb [selectionLine]="cartSelected.length" [existData]="existData"></app-breadcrumb>

<p-messages *ngIf="dispErrors" severity="error">
  <ng-template pTemplate>
    <div class="error-message-box p-d-flex p-jc-between">
      <div class="p-message-detail p-d-flex p-flex-column">
        <span *ngFor="let message of errorMessages">
          <ng-container *ngIf="message.index || message.index === 0">
            {{ message.index }}行目：{{ message.columnName }}：{{
            message.detail
            }}
          </ng-container>
          <ng-container *ngIf="!message.index && message.index !== 0">
            {{ message.detail }}
          </ng-container>
        </span>
      </div>
    </div>
  </ng-template>
</p-messages>

<!-- メイン領域-->
<mat-drawer-container class="main-container" autosize>

  <p-dataView #dv [value]="searchResultsList" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
    currentPageReportTemplate="{first}~{last}件表示({totalRecords}件中)" [rowsPerPageOptions]="[10, 50, 100]"
    styleClass="p-datatable-striped order-datatable" selectionMode="single" layout="list" [sortField]="sortField"
    [sortOrder]="sortOrder">
    <ng-template pTemplate="header">
      <div class="dv-header-button">
        <div class="p-d-flex p-jc-between">
          <div class="p-d-flex">
          </div>
          <div class="p-as-center p-d-flex">
            <button type="button" pButton label="確認" icon="" (click)="addCart()" class="p-mr-2"></button>
          </div>
        </div>
      </div>
      <ng-container *ngIf="dv.layout == 'list'">
        <div class="dv-header-table-header">
          <!-- dataViewヘッダー -->
          <p-table styleClass="p-datatable-sm generate-input">
            <!-- 一覧画面ヘッダー表示-->
            <ng-template pTemplate="header">
              <tr class="p-col-2">
                <!-- APIヘッダーの表示 -->
                <ng-container *ngFor="let column of columnOrder">
                  <th>
                    {{ column.header }}
                  </th>
                </ng-container>
                <th>
                  操作
                </th>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </ng-container>
    </ng-template>
    <ng-template let-searchResults let-rowIndex="rowIndex" pTemplate="listItem">
      <p-table #table [value]="[searchResults]" styleClass="p-table-striped order-table" selectionMode="single"
        dataKey="delivery_id" expandableRows="true" (onRowClick)="table.toggleRow($event.data)">
        <!-- 一覧画面ボディ表示-->
        <ng-template pTemplate="body">
          <tr class="p-col-2">
            <!-- チェックボックス -->
            <ng-container *ngFor="let column of columnOrder; index as i" [ngSwitch]="true">
              <!-- 一覧項目値表示(詳細画面遷移項目) -->
              <td *ngIf="0 == i; else hideContent">
                <label (click)="detail(searchResults[column.field])">
                  <u style="text-decoration: none;">{{ searchResults[column.field] }}</u>
                </label>
              </td>
              <!-- 一覧項目値表示 -->
              <ng-template #hideContent [ngSwitch]="column?.input_type">
                <td *ngSwitchCase="'NUMBER'" [pTooltip]="searchResults[column.field]" #tt (mouseenter)="onHover($event)"
                  [tooltipDisabled]="tt.dataset.showtooltip!='true'" data-showTooltip="" showDelay="500"
                  hideDelay="500">
                  {{ searchResults[column.field] | number }}
                </td>
                <td *ngIf="
                column?.field != 'division_name_from' && 
                column?.field != 'user_name_from' && 
                column?.field != 'note_from' && 
                column?.field != 'division_name_to' && 
                column?.field != 'user_name_to' && 
                column?.field != 'use_spot' && 
                column?.field != 'note_to'
                " [pTooltip]="searchResults[column.field]" #tt (mouseenter)="onHover($event)"
                  [tooltipDisabled]="tt.dataset.showtooltip!='true'" data-showTooltip="" showDelay="500"
                  hideDelay="500">
                  {{ searchResults[column.field] }}
                </td>
                <td *ngIf="
                column?.field === 'division_name_from' || 
                column?.field === 'user_name_from' || 
                column?.field === 'note_from' || 
                column?.field === 'division_name_to' || 
                column?.field === 'user_name_to' || 
                column?.field === 'note_to'
                " [pTooltip]="searchResults[column.field]" #tt (mouseenter)="onHover($event)"
                  [tooltipDisabled]="tt.dataset.showtooltip!='true'" data-showTooltip="" showDelay="500"
                  hideDelay="500">
                  <input type="text" [(ngModel)]="searchResults[column.field]" [maxlength]="getMaxlength(column.field)"
                    style="max-width: 100%;" class="p-inputtext p-component ng-pristine ng-valid ng-touched"
                    id="{{rowIndex + 1 + column.field}}" [placeholder]="getPlaceholder(column.field)">
                </td>
                <td *ngIf="column?.field === 'use_spot'" style="text-align: center;"
                  [pTooltip]="searchResults[column.field]" #tt (mouseenter)="onHover($event)"
                  [tooltipDisabled]="tt.dataset.showtooltip!='true'" data-showTooltip="" showDelay="500"
                  hideDelay="500">
                  <p-checkbox [(ngModel)]="searchResults[column.field]" [binary]="true" value="true"
                    [disabled]="searchResults['delivery_kbn_id_from'] == '3' || searchResults['delivery_kbn_id_to'] == '3'"
                    id="{{rowIndex + 1 + column.field}}"></p-checkbox>
                </td>
              </ng-template>
            </ng-container>
            <td>
              <button pButton icon="pi pi-times" class="p-button-rounded p-button-danger" pTooltip="削除"
                (click)="delete(searchResults['customer_name_from'], searchResults['customer_name_to'])"
                tooltipPosition="bottom"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
  </p-dataView>

  <!-- 検索画面-->
  <mat-drawer #search class="search-sidenav" mode="side">
    <!-- 検索画面非表示-->
    <button type="button" pButton icon="pi pi-arrow-left" (click)="search.toggle()" class="p-mr-2"></button>

    <!-- 検索画面[検索項目生成(アイテムアダプター)]-->
  </mat-drawer>
</mat-drawer-container>

<!-- 詳細画面[表示項目生成(アイテムアダプター)]-->
<app-generate-display></app-generate-display>

<!-- メッセージ -->
<app-message-common></app-message-common>

<!-- 削除ダイアログ-->
<app-delete-dialog (reloadID)="moveC121()"></app-delete-dialog>

<!-- Toastメッセージ表示 -->
<p-toast position="top-center"></p-toast>