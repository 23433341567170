<!-- メイン -->
<div class="main">
  <!-- メインコンテンツ -->
  <div class="main-content" [class.platform-large-text]="getTextSize()">
    <!-- メッセージ -->
    <div class="message">
      <!-- メッセージ本文 -->
      <div class="message-text">
        <div class="p-d-flex p-jc-center">
          <i class="pi pi-check-circle"></i><br />
        </div>
        <div class="message-margin"></div>
        <div class="p-d-flex p-jc-center black-color">設定完了</div>
      </div>
    </div>

    <!-- 本文 -->
    <div class="main-text p-d-flex p-jc-center">
      ＩＤ・パスワード設定が完了しました。パスワードの反映には数分かかることがございます。
    </div>

    <!-- ボタンエリア -->
    <div class="p-d-flex p-jc-center button-area">
      <button pButton type="button" label="ログイン画面へ" class="button-default-size button1 btn-confirm"
        (click)="moveB301()"></button>
    </div>
  </div>
</div>