/**
 * ヘッダータイトル設定
 */

/**
 * TOP
 */
// TOP画面
export const TopComponent = {
  default: 'TOP',
};
