declare const PDFDocument: any;
declare const blobStream: any;

import { Injectable } from '@angular/core';
import { PDFConstant } from './pdf.constant';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { DELIVERY_COLLECT_KBN, DELIVERY_KBN } from '../constant/constant';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  private doc: any;
  private stream: any;
  private data: any;
  private pageWidth: number = 0;
  private pageHeight: number = 0;
  private contentWidth = 0;
  private contentY = 0;
  private contentX = 0;

  constructor(private http: HttpClient) { }

  init() {
    this.doc = new PDFDocument({
      size: 'A4',
    });
    this.stream = this.doc.pipe(blobStream());
  }

  loadFont() {
    const fontRegular = this.http.get(PDFConstant.FONT_REGULAR_PATH, {
      responseType: 'arraybuffer',
      observe: 'body',
    });
    const fontBold = this.http.get(PDFConstant.FONT_BOLD_PATH, {
      responseType: 'arraybuffer',
      observe: 'body',
    });

    return new Promise<any>((resolve) => {
      forkJoin([fontRegular, fontBold]).subscribe((response) => {
        this.doc.registerFont(PDFConstant.FONT_REGULAR, response[0]);
        this.doc.registerFont(PDFConstant.FONT_BOLD, response[1]);
        resolve(null);
      });
    });
  }

  private _initDocument() {
    this.pageWidth = PDFConstant.PAGE_BOTTOM_X - PDFConstant.PAGE_TOP_X;
    this.pageHeight = PDFConstant.PAGE_BOTTOM_Y - PDFConstant.PAGE_TOP_Y;
  }

  breakWord(newArray: string[], text: string, width: number) {
    let result = text;

    while (this.doc.widthOfString(text) > width) {

      while (this.doc.widthOfString(result) > width) {
        result = result.slice(0, result.length - 1);
      }

      newArray.push(result + '\n');

      text = text.replace(result, '');

      result = text;
    }

    newArray.push(text);

    return newArray;
  }

  handleBreakWord(arrText: string[], width: number) {
    let newArray = [];

    arrText.forEach(element => {
      this.breakWord(newArray, element, width);
    });

    return newArray.join('');
  }

  handleText(text: string, width: number, line: number) {
    while (text.includes("\t")) {
      text = text.replace("\t", "    ")
    }

    text = this.handleBreakWord(text.split("\n"), width - 10)

    if (text.includes("\n")) {
      let textArr = text.split("\n")
      if (textArr.length > line) {
        textArr = textArr.slice(0, line)
        text = textArr.join("\n").slice(0, -3) + '...'
      }
    }

    const totalWidth = width * line;
    const widthOfText = this.doc.widthOfString(text);

    if (widthOfText > totalWidth) {
      while (this.doc.widthOfString(text) > totalWidth) {
        text = text.slice(0, text.length - 1);
      }

      text = text.slice(0, -3) + '...';
    }
    return text;
  }

  export(data: any) {
    return new Promise<null>((resolve) => {
      let orderDetail;
      this._initDocument();

      if (data[0].delivery_kbn == DELIVERY_KBN.SPOT_3_TEXT) {
        for (let i = 0; i < data.length; i++) {
          orderDetail = data[i];
          for (let j = 0; j < Number(orderDetail.order_num); j++) {
            if (i > 0 || j > 0) {
              this.doc.addPage();
            }
            this._renderMainContent(PDFConstant.PAGE1, orderDetail);
            this.doc.addPage();
            this._renderMainContent(PDFConstant.PAGE2, orderDetail);
          }
        }
      } else if (data[0].delivery_collect_kbn == DELIVERY_COLLECT_KBN.PICKUP_1) {
        for (let i = 0; i < data.length; i++) {
          orderDetail = data[i];
          for (let j = 0; j < Number(orderDetail.order_num); j++) {
            if (i > 0 || j > 0) {
              this.doc.addPage();
            }
            this._renderPickupContent(PDFConstant.PAGE1, orderDetail);
            this.doc.addPage();
            this._renderPickupContent(PDFConstant.PAGE2, orderDetail);
          }
        }
      } else {
        for (let i = 0; i < data.length; i++) {
          orderDetail = data[i];
          for (let j = 0; j < Number(orderDetail.order_num); j++) {
            if (i > 0 || j > 0) {
              this.doc.addPage();
            }
            this._renderDeliveryContent(PDFConstant.PAGE1, orderDetail);
            this.doc.addPage();
            this._renderDeliveryContent(PDFConstant.PAGE2, orderDetail);
            this.doc.addPage();
            this._renderDeliveryContent(PDFConstant.PAGE3, orderDetail);
          }
        }
      }

      this.doc.end();
      this.stream.on('finish', () => {
        const blob = this.stream.toBlob('application/pdf');
        this.stream = null;
        this.doc = null;
        resolve(blob);
      });
    });
  }

  private _renderMainContent(page: string, data) {
    this.contentX = PDFConstant.PAGE_TOP_X + PDFConstant.CONTENT_PADDING_X;
    this.contentY = PDFConstant.CONTENT_Y;
    this.contentWidth = PDFConstant.PAGE_BOTTOM_X - PDFConstant.CONTENT_PADDING_X - this.contentX;

    this.contentY += PDFConstant.LINE_HEIGHT;
    this.doc.moveTo(this.contentX, this.contentY).font(PDFConstant.FONT_REGULAR).fontSize(8);

    const blockPadding = 50;
    const blockWidth = this.contentWidth - blockPadding * 2;
    const blockLeft = blockWidth / 3;

    let title = PDFConstant.COMMON_HEADER_1;
    if (page == PDFConstant.PAGE2) {
      title = PDFConstant.COMMON_HEADER_2;
    }

    this.doc.font(PDFConstant.FONT_BOLD).fontSize(10).fill('black').text(title, 32, 20, {
      width: 300,
      align: 'justify',
    });

    this.doc.font(PDFConstant.FONT_REGULAR).fontSize(12).fill('black').text('荷物運搬便システム', 470, 10, {
      width: 300,
      align: 'justify',
    });

    const cellHeight = 20;
    const columnLeft = 125;

    const widthLabelCell = 80;
    const widthValueCell = 170;

    const widthContentValueCell = widthValueCell - 10;

    const startXLabelCellOfLeftTable = 30;
    const startXValueCellOfLeftTable = 110;

    const startXLabelCellOfRightTable = 320;
    const startXValueCellOfRightTable = 400;

    let startYOfLeftTable = 58;
    let startYOfRightTable = 118;

    this.doc.font(PDFConstant.FONT_REGULAR).fontSize(8).fill('black').text('配送票', 32, 45, {
      width: blockLeft,
      align: 'justify',
    });

    startYOfLeftTable -= 2;

    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).lineWidth(0.1).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('発行日', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell + 40, cellHeight).stroke();
    this.doc.fill('black').text(data.create_datetime.replace("/", "年").replace("/", "月") + "日", startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;

    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('問合せ番号', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell + 40, cellHeight).stroke();
    this.doc.fill('black').text(data.order_id, startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;

    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('CODE', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell + 40, cellHeight).stroke();
    this.doc.fill('black').text("", startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight + 2;

    if (page == "page2") {
      //Right Fake 1
      this.doc.rect(startXLabelCellOfRightTable, 56, widthLabelCell, cellHeight).fill('#D9D9D9').stroke();
      this.doc.rect(startXLabelCellOfRightTable, 56, widthLabelCell, cellHeight).stroke();
      this.doc.fill('black').text('集荷No.', startXLabelCellOfRightTable + 3, 62, {
        width: columnLeft,
        align: 'justify',
      });

      this.doc.rect(startXValueCellOfRightTable, 56, widthValueCell, cellHeight).stroke();
      this.doc.fill('black').text("", startXValueCellOfRightTable + 3, 62, {
        width: widthContentValueCell,
      });

      this.doc.rect(startXLabelCellOfRightTable, 76, widthLabelCell, cellHeight).fill('#D9D9D9').stroke();
      this.doc.rect(startXLabelCellOfRightTable, 76, widthLabelCell, cellHeight).stroke();
      this.doc.fill('black').text('配達No.', startXLabelCellOfRightTable + 3, 82, {
        width: columnLeft,
        align: 'justify',
      });

      this.doc.rect(startXValueCellOfRightTable, 76, widthValueCell, cellHeight).stroke();
      this.doc.fill('black').text("", startXValueCellOfRightTable + 3, 82, {
        width: widthContentValueCell,
      });
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    this.doc.font(PDFConstant.FONT_REGULAR).fontSize(8).fill('black').text('届先', 32, startYOfLeftTable, {
      width: blockLeft,
      align: 'justify',
    });

    startYOfLeftTable += 10;

    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('配送希望日', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(data.hope_delivery_date, startXValueCellOfLeftTable + 3, startYOfLeftTable + 2, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;

    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight * 3).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight * 3).stroke();
    this.doc.fill('black').text('所在地\n（ビル名・階数）', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight * 3).stroke();
    this.doc.fill('black').text(this.handleText(data.prefectures_to + '　' + data.address1_to + '　' + data.address2_to, widthContentValueCell, 4), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight * 3;

    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();

    this.doc.fill('black').text(page == 'page1' ? '会社名' : '御名称', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.customer_name_to, widthContentValueCell - 20, 1), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    this.doc.rect(startXLabelCellOfLeftTable + widthLabelCell + widthValueCell + 20, startYOfLeftTable - 10, cellHeight - 1, cellHeight + cellHeight).fill('#F1F1F1').stroke();
    this.doc.rect(startXLabelCellOfLeftTable + widthLabelCell + widthValueCell + 20, startYOfLeftTable - 10, cellHeight - 1, cellHeight + cellHeight).stroke('gray');

    this.doc.polygon(
      [startXLabelCellOfLeftTable + widthLabelCell + widthValueCell + 20, startYOfLeftTable - 30],
      [startXLabelCellOfLeftTable + widthLabelCell + widthValueCell + 20, startYOfLeftTable + cellHeight + 30],
      [startXLabelCellOfLeftTable + widthLabelCell + widthValueCell + 5, startYOfLeftTable + 10]).fill('#F1F1F1').stroke('black');
    this.doc.polygon(
      [startXLabelCellOfLeftTable + widthLabelCell + widthValueCell + 20, startYOfLeftTable - 30],
      [startXLabelCellOfLeftTable + widthLabelCell + widthValueCell + 20, startYOfLeftTable + cellHeight + 30],
      [startXLabelCellOfLeftTable + widthLabelCell + widthValueCell + 5, startYOfLeftTable + 10]).stroke('gray');

    this.doc.rect(startXLabelCellOfLeftTable + widthLabelCell + widthValueCell + 18, startYOfLeftTable - 9.5, cellHeight - 1, cellHeight + cellHeight - 1).fill('#F1F1F1').stroke();

    startYOfLeftTable += cellHeight;

    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight * 2.5).fill('#D9D9D9').stroke('black');
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight * 2.5).stroke();
    this.doc.fill('black').text(page == 'page1' ? '部署名' : '部署等', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight * 2.5).stroke();
    this.doc.fill('black').text(this.handleText(data.division_name_to, widthContentValueCell, 3), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight * 2.5;

    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('ご担当', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.fill('black').text('様', startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
      align: 'right',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.user_name_to, widthContentValueCell, 1), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;

    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('届先詳細', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.note_to, widthContentValueCell, 1), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;

    ////////////////////////////////////////////////////////////////////////////////////
    this.doc.font(PDFConstant.FONT_REGULAR).fontSize(8).fill('black').text('差出元', 322, startYOfRightTable, {
      width: blockLeft,
      align: 'justify',
    });

    startYOfRightTable += 10;

    this.doc.rect(startXLabelCellOfRightTable, startYOfRightTable, widthLabelCell, cellHeight).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfRightTable, startYOfRightTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('準備完了時間', startXLabelCellOfRightTable + 3, startYOfRightTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfRightTable, startYOfRightTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.hope_ship_date + ' ' + data.hope_delivery_time, widthContentValueCell, 1), startXValueCellOfRightTable + 3, startYOfRightTable + 4, {
      width: widthContentValueCell,
    });

    startYOfRightTable += cellHeight;

    this.doc.rect(startXLabelCellOfRightTable, startYOfRightTable, widthLabelCell, cellHeight * 3).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfRightTable, startYOfRightTable, widthLabelCell, cellHeight * 3).stroke();
    this.doc.fill('black').text('所在地\n（ビル名・階数）', startXLabelCellOfRightTable + 3, startYOfRightTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfRightTable, startYOfRightTable, widthValueCell, cellHeight * 3).stroke();
    this.doc.fill('black').text(this.handleText(data.prefectures_from + '　' + data.address1_from + '　' + data.address2_from, widthContentValueCell, 4), startXValueCellOfRightTable + 3, startYOfRightTable + 4, {
      width: widthContentValueCell,
    });

    startYOfRightTable += cellHeight * 3;

    this.doc.rect(startXLabelCellOfRightTable, startYOfRightTable, widthLabelCell, cellHeight).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfRightTable, startYOfRightTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text(page == 'page1' ? '会社名' : '御名称', startXLabelCellOfRightTable + 3, startYOfRightTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfRightTable, startYOfRightTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.customer_name_from, widthContentValueCell - 20, 1), startXValueCellOfRightTable + 3, startYOfRightTable + 4, {
      width: widthContentValueCell,
    });

    startYOfRightTable += cellHeight;

    this.doc.rect(startXLabelCellOfRightTable, startYOfRightTable, widthLabelCell, cellHeight * 2.5).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfRightTable, startYOfRightTable, widthLabelCell, cellHeight * 2.5).stroke();
    this.doc.fill('black').text(page == 'page1' ? '部署名' : '部署等', startXLabelCellOfRightTable + 3, startYOfRightTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfRightTable, startYOfRightTable, widthValueCell, cellHeight * 2.5).stroke();
    this.doc.fill('black').text(this.handleText(data.division_name_from, widthContentValueCell, 3), startXValueCellOfRightTable + 3, startYOfRightTable + 4, {
      width: widthContentValueCell,
    });

    startYOfRightTable += cellHeight * 2.5;

    this.doc.rect(startXLabelCellOfRightTable, startYOfRightTable, widthLabelCell, cellHeight).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfRightTable, startYOfRightTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('ご担当', startXLabelCellOfRightTable + 3, startYOfRightTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.fill('black').text('様', startXValueCellOfRightTable + 3, startYOfRightTable + 4, {
      width: widthContentValueCell,
      align: 'right',
    });

    this.doc.rect(startXValueCellOfRightTable, startYOfRightTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.user_name_from, widthContentValueCell, 1), startXValueCellOfRightTable + 3, startYOfRightTable + 4, {
      width: widthContentValueCell,
    });

    startYOfRightTable += cellHeight;

    //Right Fake 6
    this.doc.rect(startXLabelCellOfRightTable, startYOfRightTable, widthLabelCell, cellHeight).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfRightTable, startYOfRightTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('差出元詳細', startXLabelCellOfRightTable + 3, startYOfRightTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    //Right Fake 6 value
    this.doc.rect(startXValueCellOfRightTable, startYOfRightTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.note_from, widthContentValueCell, 1), startXValueCellOfRightTable + 3, startYOfRightTable + 4, {
      width: widthContentValueCell,
    });

    startYOfRightTable += cellHeight;

    startYOfLeftTable += 2;
    ///////////////////////////////////////////////////////////////////////////////////////
    this.doc.font(PDFConstant.FONT_REGULAR).fontSize(8).fill('black').text('荷姿・個数', 32, startYOfLeftTable, {
      width: blockLeft,
      align: 'justify',
    });

    let order_num_category_1 = 0
    let order_num_category_2 = 0
    let order_num_category_3 = 0
    let order_num_category_4 = 0
    let order_num_category_5 = 0
    let order_num_category_6 = 0

    if (data.item_category == '1' || data.item_category == '荷物①：封筒') {
      order_num_category_1 = 1;
    } else if (data.item_category == '2' || data.item_category == '荷物②：包') {
      order_num_category_2 = 1;
    } else if (data.item_category == '3' || data.item_category == '荷物③：カバン') {
      order_num_category_3 = 1;
    } else if (data.item_category == '4' || data.item_category == '荷物④：筒') {
      order_num_category_4 = 1;
    } else if (data.item_category == '5' || data.item_category == '荷物⑤：段ボール') {
      order_num_category_5 = 1;
    } else if (data.item_category == '6' || data.item_category == '荷物⑥：その他') {
      order_num_category_6 = 1;
    }

    startYOfLeftTable += 10;

    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('荷物①：封筒', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(order_num_category_1 + ' 個', startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
      align: 'right'
    });

    startYOfLeftTable += cellHeight;

    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('荷物②：包', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(order_num_category_2 + ' 個', startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
      align: 'right'
    });

    startYOfLeftTable += cellHeight;

    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('荷物③：カバン', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(order_num_category_3 + ' 個', startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
      align: 'right'
    });

    startYOfLeftTable += cellHeight;

    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('荷物④：筒', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(order_num_category_4 + ' 個', startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
      align: 'right'
    });

    startYOfLeftTable += cellHeight;

    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('荷物⑤：段ボール', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(order_num_category_5 + ' 個', startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
      align: 'right'
    });

    startYOfLeftTable += cellHeight;

    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('荷物⑥：その他', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(order_num_category_6 + ' 個', startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
      align: 'right'
    });

    startYOfLeftTable += cellHeight;

    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight * 2.5).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight * 2.5).stroke();
    this.doc.fill('black').text('荷物備考', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight * 2.5).stroke();
    this.doc.fill('black').text(this.handleText(data.item_note, widthContentValueCell, 3), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight * 2.5;

    startYOfRightTable += 2;
    ///////////////////////////////////////////////////////////////////////////////////////
    this.doc.font(PDFConstant.FONT_REGULAR).fontSize(8).fill('black').text('配送方法', 322, startYOfRightTable, {
      width: blockLeft,
      align: 'justify',
    });

    startYOfRightTable += 10;

    this.doc.rect(startXLabelCellOfRightTable, startYOfRightTable, widthLabelCell, cellHeight * 2).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfRightTable, startYOfRightTable, widthLabelCell, cellHeight * 2).stroke();
    this.doc.fill('black').text('配送方法', startXLabelCellOfRightTable + 3, startYOfRightTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfRightTable, startYOfRightTable, widthValueCell, cellHeight * 2).stroke();
    this.doc.fill('black').text(data.vehicle_type, startXValueCellOfRightTable + 3, startYOfRightTable + 4, {
      width: widthContentValueCell,
    });

    startYOfRightTable += cellHeight * 2;

    startYOfRightTable += 28;
    ///////////////////////////////////////////////////////////////////////////////////////
    this.doc.font(PDFConstant.FONT_REGULAR).fontSize(8).fill('black').text('備考欄', 322, startYOfRightTable, {
      width: blockLeft,
      align: 'justify',
    });

    startYOfRightTable += 12;

    this.doc.rect(startXLabelCellOfRightTable, startYOfRightTable, 250, cellHeight * 4.5).stroke();
    this.doc.fill('black').text(this.handleText(data.delivery_note, 250, 4), startXLabelCellOfRightTable + 2, startYOfRightTable + 4, {
      width: 250,
    });

    startYOfRightTable += cellHeight * 4.5;


    startYOfLeftTable += 2;
    ///////////////////////////////////////////////////////////////////////////////////////
    this.doc.font(PDFConstant.FONT_REGULAR).fontSize(8).fill('black').text('依頼主', 32, startYOfLeftTable, {
      width: blockLeft,
      align: 'justify',
    });

    startYOfLeftTable += 10;

    //Tabel 4 Left Fake 1
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('会社名', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    //Tabel 4 Left Fake 1 value
    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.company_name_sales, widthContentValueCell, 1), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;

    //Tabel 4 Left Fake 2
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight * 3).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight * 3).stroke();
    this.doc.fill('black').text('部署名', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    //Tabel 4 Left Fake 2 value
    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight * 3).stroke();
    this.doc.fill('black').text(this.handleText(data.omit_division_name_sales, 155, 3), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: 155,
    });

    startYOfLeftTable += cellHeight * 3;

    //Tabel 4 Left Fake 3
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('担当者名', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    //Tabel 4 Left Fake 3 value
    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.full_name_sales, widthContentValueCell, 1), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;

    //Tabel 4 Left Fake 4
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('電話番号', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    //Tabel 4 Left Fake 4 value
    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(data.tel_sales, startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.fill('black').text('※連絡優先順：発行者⇒依頼主', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += 15;
    startYOfRightTable += 2;
    ///////////////////////////////////////////////////////////////////////////////////////
    this.doc.font(PDFConstant.FONT_REGULAR).fontSize(8).fill('black').text('発行者', 322, startYOfRightTable, {
      width: blockLeft,
      align: 'justify',
    });

    startYOfRightTable += 10;

    //Tabel 4 Right Fake 1
    this.doc.rect(startXLabelCellOfRightTable, startYOfRightTable, widthLabelCell, cellHeight).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfRightTable, startYOfRightTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('会社名', startXLabelCellOfRightTable + 3, startYOfRightTable + 4, {
      width: columnLeft,
      align: 'middle',
    });

    //Tabel 4 Right Fake 1 value
    this.doc.rect(startXValueCellOfRightTable, startYOfRightTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.company_name_sales_business, widthContentValueCell, 1), startXValueCellOfRightTable + 3, startYOfRightTable + 4, {
      width: widthContentValueCell,
    });

    startYOfRightTable += cellHeight;

    //Tabel 4 Right Fake 2
    this.doc.rect(startXLabelCellOfRightTable, startYOfRightTable, widthLabelCell, cellHeight * 3).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfRightTable, startYOfRightTable, widthLabelCell, cellHeight * 3).stroke();
    this.doc.fill('black').text('部署名', startXLabelCellOfRightTable + 3, startYOfRightTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    //Tabel 4 Right Fake 2 value
    this.doc.rect(startXValueCellOfRightTable, startYOfRightTable, widthValueCell, cellHeight * 3).stroke();
    this.doc.fill('black').text(this.handleText(data.omit_division_name_business, 155, 3), startXValueCellOfRightTable + 3, startYOfRightTable + 4, {
      width: 155,
    });

    startYOfRightTable += cellHeight * 3;

    //Tabel 4 Right Fake 3
    this.doc.rect(startXLabelCellOfRightTable, startYOfRightTable, widthLabelCell, cellHeight).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfRightTable, startYOfRightTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('担当者名', startXLabelCellOfRightTable + 3, startYOfRightTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    //Tabel 4 Right Fake 3 value
    this.doc.rect(startXValueCellOfRightTable, startYOfRightTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.full_name_business, widthContentValueCell, 1), startXValueCellOfRightTable + 3, startYOfRightTable + 4, {
      width: widthContentValueCell,
    });

    startYOfRightTable += cellHeight;

    //Tabel 4 Right Fake 4
    this.doc.rect(startXLabelCellOfRightTable, startYOfRightTable, widthLabelCell, cellHeight).fill('#D9D9D9').stroke();
    this.doc.rect(startXLabelCellOfRightTable, startYOfRightTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('電話番号', startXLabelCellOfRightTable + 3, startYOfRightTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    //Tabel 4 Right Fake 4 value
    this.doc.rect(startXValueCellOfRightTable, startYOfRightTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(data.tel_sales_business, startXValueCellOfRightTable + 3, startYOfRightTable + 4, {
      width: widthContentValueCell,
    });

    startYOfRightTable += cellHeight;

    if (page == 'page2') {
      startYOfLeftTable += 2;
      ///////////////////////////////////////////////////////////////////////////////////////
      this.doc.font(PDFConstant.FONT_REGULAR).fontSize(8).fill('black').text('業者記入欄', 32, startYOfLeftTable, {
        width: blockLeft,
        align: 'justify',
      });

      startYOfLeftTable += 10;

      //Tabel 5 Left Fake 1
      this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).fill('#D9D9D9').stroke();
      this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
      this.doc.fill('black').text('基本運賃', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
        width: columnLeft,
        align: 'justify',
      });

      //Tabel 5 Left Fake 1 value
      this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
      this.doc.fill('black').text("円", startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
        width: widthValueCell - 4,
        align: 'right',
      });

      startYOfLeftTable += cellHeight;

      //Tabel 5 Left Fake 2
      this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).fill('#D9D9D9').stroke();
      this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
      this.doc.fill('black').text('時間外割増', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
        width: columnLeft,
        align: 'justify',
      });

      //Tabel 5 Left Fake 2 value
      this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
      this.doc.fill('black').text("円", startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
        width: widthValueCell - 4,
        align: 'right',
      });

      startYOfLeftTable += cellHeight;

      //Tabel 5 Left Fake 3
      this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).fill('#D9D9D9').stroke();
      this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
      this.doc.fill('black').text('荷待料金', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
        width: columnLeft,
        align: 'justify',
      });

      //Tabel 5 Left Fake 3 value
      this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
      this.doc.fill('black').text("円", startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
        width: widthValueCell - 4,
        align: 'right',
      });

      startYOfLeftTable += cellHeight;

      //Tabel 5 Left Fake 4
      this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).fill('#D9D9D9').stroke();
      this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
      this.doc.fill('black').text('高速料金', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
        width: columnLeft,
        align: 'justify',
      });

      //Tabel 5 Left Fake 4 value
      this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
      this.doc.fill('black').text("円", startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
        width: widthValueCell - 4,
        align: 'right',
      });

      startYOfLeftTable += cellHeight;

      //Tabel 5 Left Fake 5
      this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).fill('#D9D9D9').stroke();
      this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
      this.doc.fill('black').text('貸切料金', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
        width: columnLeft,
        align: 'justify',
      });

      //Tabel 5 Left Fake 5 value
      this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
      this.doc.fill('black').text("円", startXValueCellOfLeftTable + 3, startYOfLeftTable + 5, {
        width: widthValueCell - 4,
        align: 'right',
      });

      startYOfRightTable += 2;
      startYOfRightTable += 15;
      ///////////////////////////////////////////////////////////////////////////////////////
      this.doc.font(PDFConstant.FONT_REGULAR).fontSize(8).fill('black').text('サイン欄', 322, startYOfRightTable, {
        width: blockLeft,
        align: 'justify',
      });

      startYOfRightTable += 10;

      //Tabel 4 Right Fake 1
      this.doc.rect(startXLabelCellOfRightTable, startYOfRightTable, 83.33, cellHeight).fill('#D9D9D9').stroke();
      this.doc.rect(startXLabelCellOfRightTable, startYOfRightTable, 83.33, cellHeight).stroke();
      this.doc.fill('black').text('受領のご確認', startXLabelCellOfRightTable + 3, startYOfRightTable + 4, {
        width: columnLeft,
        align: 'middle',
      });

      //Tabel 4 Right Fake 2
      this.doc.rect(startXLabelCellOfRightTable + 83.33, startYOfRightTable, 83.33, cellHeight).fill('#D9D9D9').stroke();
      this.doc.rect(startXLabelCellOfRightTable + 83.33, startYOfRightTable, 83.33, cellHeight).stroke();
      this.doc.fill('black').text('荷待ち時刻', startXLabelCellOfRightTable + 83.33 + 3, startYOfRightTable + 4, {
        width: columnLeft,
        align: 'middle',
      });

      //Tabel 4 Right Fake 3
      this.doc.rect(startXLabelCellOfRightTable + 83.33 + 83.33, startYOfRightTable, 83.33, cellHeight).fill('#D9D9D9').stroke();
      this.doc.rect(startXLabelCellOfRightTable + 83.33 + 83.33, startYOfRightTable, 83.33, cellHeight).stroke();
      this.doc.fill('black').text('荷待ちサイン', startXLabelCellOfRightTable + 83.33 + 83.33 + 3, startYOfRightTable + 4, {
        width: columnLeft,
        align: 'middle',
      });

      startYOfRightTable += cellHeight;

      //Tabel 4 Right Fake 1 value
      this.doc.rect(startXLabelCellOfRightTable, startYOfRightTable, 83.33, cellHeight * 3).stroke();
      this.doc.fill('black').text("", startXLabelCellOfRightTable + 3, startYOfRightTable + 4, {
        width: 83.33,
      });

      //Tabel 4 Right Fake 1 value
      this.doc.rect(startXLabelCellOfRightTable + 83.33, startYOfRightTable, 83.33, cellHeight * 3).stroke();
      this.doc.fill('black').text("", startXLabelCellOfRightTable + 83.33 + 3, startYOfRightTable + 4, {
        width: 83.33,
      });

      //Tabel 4 Right Fake 1 value
      this.doc.rect(startXLabelCellOfRightTable + 83.33 + 83.33, startYOfRightTable, 83.33, cellHeight * 3).stroke();
      this.doc.fill('black').text("", startXLabelCellOfRightTable + 83.33 + 83.33 + 3, startYOfRightTable + 4, {
        width: 83.33,
      });
    }
  }

  private _renderPickupContent(page: string, data) {
    this.contentX = PDFConstant.PAGE_TOP_X + PDFConstant.CONTENT_PADDING_X;
    this.contentY = PDFConstant.CONTENT_Y;
    this.contentWidth = PDFConstant.PAGE_BOTTOM_X - PDFConstant.CONTENT_PADDING_X - this.contentX;
    this.contentY += PDFConstant.LINE_HEIGHT;
    this.doc.moveTo(this.contentX, this.contentY).font(PDFConstant.FONT_REGULAR).fontSize(8);
    const blockPadding = 50;
    const blockWidth = this.contentWidth - blockPadding * 2;
    const blockLeft = blockWidth / 3;

    const cellHeight = 20;
    const columnLeft = 125;

    const widthLabelCell = 150;
    const widthValueCell = 380;
    const widthContentValueCell = 370;

    const startXLabelCellOfLeftTable = 30;
    const startXValueCellOfLeftTable = widthLabelCell + startXLabelCellOfLeftTable;

    let startYOfLeftTable = 70;

    if (page == PDFConstant.PAGE2) {
      this.doc.font(PDFConstant.FONT_BOLD).fontSize(13).fill('black').text('集荷依頼票　入力用記載内容自動反映・要印刷 2/2', 32, 20, {
        width: 500,
        align: 'justify',
      });
    }

    this.doc.font(PDFConstant.FONT_REGULAR).fontSize(12).fill('black').text('荷物運搬便システム', 470, 10, {
      width: 300,
      align: 'justify',
    });

    let header1 = PDFConstant.PICKUP_HEADER_1_1;
    let header2 = PDFConstant.PICKUP_HEADER_1_2;
    if (page == PDFConstant.PAGE2) {
      header1 = PDFConstant.PICKUP_HEADER_2_1;
      header2 = PDFConstant.PICKUP_HEADER_2_2;
    }

    this.doc.font(PDFConstant.FONT_BOLD).fontSize(13).fill('black').text(header1, 32, 40, {
      width: 300,
      align: 'justify',
    });

    this.doc.font(PDFConstant.FONT_BOLD).fontSize(13).fill('black').text(header2, widthLabelCell + widthValueCell - this.doc.widthOfString(header2), 40, {
      width: this.doc.widthOfString(header2),
      align: 'right',
    });

    if (page == PDFConstant.PAGE1) {

      this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).lineWidth(0.1).stroke();
      this.doc.fill('black').font(PDFConstant.FONT_REGULAR).fontSize(8).text('得意先CD', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
        width: columnLeft,
        align: 'justify',
      });

      this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
      this.doc.fill('black').text(data.customer_code_from, startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
        width: widthContentValueCell,
      });

      startYOfLeftTable += cellHeight;

      this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
      this.doc.fill('black').text('振番', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
        width: columnLeft,
        align: 'justify',
      });

      this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
      this.doc.fill('black').text("記入不要", startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
        width: widthContentValueCell,
      });

      startYOfLeftTable += cellHeight;
      this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
      this.doc.fill('black').text('営業担当社員CD', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
        width: columnLeft,
        align: 'justify',
      });

      this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
      this.doc.fill('black').text(data.employee_id_sales, startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
        width: widthContentValueCell,
      });
    } else {
      startYOfLeftTable = 90;
    }

    startYOfLeftTable += cellHeight * 1.5;
    ///////////////////////////////////////////////////////////////////////////////////////
    this.doc.font(PDFConstant.FONT_REGULAR).fontSize(8).fill('black').text('1. 集荷便', 32, startYOfLeftTable, {
      width: blockLeft,
      align: 'justify',
    });

    this.doc.font(PDFConstant.FONT_REGULAR).fontSize(8).fill('black').text('伝票番号：' + data.order_id, 185, startYOfLeftTable, {
      width: widthContentValueCell,
      align: 'justify',
    });

    startYOfLeftTable += cellHeight - 10;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).lineWidth(0.1).stroke();
    this.doc.fill('black').text('出発時刻', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.hope_ship_time, widthContentValueCell, 1), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('配送希望日', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(data.hope_delivery_date.replace("/", "年").replace("/", "月") + "日", startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('コース名', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.delivery_kbn_name, widthContentValueCell, 1), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight * 1.5;
    ///////////////////////////////////////////////////////////////////////////////////////
    this.doc.font(PDFConstant.FONT_REGULAR).fontSize(8).fill('black').text('２．集荷先', 32, startYOfLeftTable, {
      width: blockLeft,
      align: 'justify',
    });

    startYOfLeftTable += cellHeight - 10;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('会社名', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.customer_name_from, widthContentValueCell, 1), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('部課名', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.division_name_from, widthContentValueCell, 1), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('担当者様名', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.user_name_from, widthContentValueCell, 1), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });
    this.doc.fill('black').text('様', startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
      align: 'right',
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('住所', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.prefectures_from + '　' + data.address1_from, widthContentValueCell, 1), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('ビル名', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.address2_from, widthContentValueCell, 1), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('階数', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text("", startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight * 2).stroke();
    this.doc.fill('black').text('集荷先詳細', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight * 2).stroke();
    this.doc.fill('black').text(this.handleText(data.note_from, widthContentValueCell, 3), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight * 2 * 1.5;
    ///////////////////////////////////////////////////////////////////////////////////////
    this.doc.font(PDFConstant.FONT_REGULAR).fontSize(8).fill('black').text('３．荷物送付・転送先（記入ない場合はすべて小石川メールセンター留）', 32, startYOfLeftTable, {
      width: widthLabelCell + widthValueCell,
      align: 'justify',
    });

    startYOfLeftTable += cellHeight - 10;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('会社名', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.customer_name_to, widthContentValueCell, 1), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('部課名', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.division_name_to, widthContentValueCell, 1), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('担当者様名', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });
    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.user_name_to, widthContentValueCell, 1), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('住所', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.prefectures_to + '　' + data.address1_to, widthContentValueCell, 1), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('ビル名', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.address2_to, widthContentValueCell, 1), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('階数', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text("", startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight * 2).stroke();
    this.doc.fill('black').text('届先詳細', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight * 2).stroke();
    this.doc.fill('black').text(this.handleText(data.note_to, widthContentValueCell, 3), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight * 2 * 1.5;
    ///////////////////////////////////////////////////////////////////////////////////////
    this.doc.font(PDFConstant.FONT_REGULAR).fontSize(8).fill('black').text('４．依頼主', 32, startYOfLeftTable, {
      width: widthLabelCell + widthValueCell,
      align: 'justify',
    });

    startYOfLeftTable += cellHeight - 10;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('発送者', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.company_name_sales, widthContentValueCell, 1), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell * 0.6, cellHeight * 5).stroke();
    this.doc.fill('black').text('営業担当', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXLabelCellOfLeftTable + widthLabelCell * 0.6, startYOfLeftTable, widthLabelCell * 0.4, cellHeight * 3).stroke();
    this.doc.fill('black').text('部署', startXLabelCellOfLeftTable + 3 + (widthLabelCell * 0.6), startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight * 3).stroke();
    this.doc.fill('black').text(this.handleText(data.omit_division_name_sales, widthContentValueCell, 3), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    this.doc.rect(startXLabelCellOfLeftTable + widthLabelCell * 0.6, startYOfLeftTable + cellHeight * 3, widthLabelCell * 0.4, cellHeight).stroke();
    this.doc.fill('black').text('氏名', startXLabelCellOfLeftTable + 3 + (widthLabelCell * 0.6), startYOfLeftTable + cellHeight * 3 + 4, {
      width: columnLeft,
      align: 'justify',
    });
    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable + cellHeight * 3, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.full_name_sales, widthContentValueCell, 3), startXValueCellOfLeftTable + 3, startYOfLeftTable + cellHeight * 3 + 4, {
      width: widthContentValueCell,
    });

    this.doc.rect(startXLabelCellOfLeftTable + widthLabelCell * 0.6, startYOfLeftTable + cellHeight * 4, widthLabelCell * 0.4, cellHeight).stroke();
    this.doc.fill('black').text('TEL', startXLabelCellOfLeftTable + 3 + (widthLabelCell * 0.6), startYOfLeftTable + cellHeight * 4 + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable + cellHeight * 4, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(data.tel_sales, startXValueCellOfLeftTable + 3, startYOfLeftTable + cellHeight * 4 + 4, {
      width: widthContentValueCell,
    });


    startYOfLeftTable += cellHeight * 5;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell * 0.6, cellHeight * 2).stroke();
    this.doc.fill('black').text('実務担当', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXLabelCellOfLeftTable + widthLabelCell * 0.6, startYOfLeftTable, widthLabelCell * 0.4, cellHeight).stroke();
    this.doc.fill('black').text('氏名', startXLabelCellOfLeftTable + 3 + (widthLabelCell * 0.6), startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });
    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.full_name_business, widthContentValueCell, 1), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable + widthLabelCell * 0.6, startYOfLeftTable, widthLabelCell * 0.4, cellHeight).stroke();
    this.doc.fill('black').text('TEL', startXLabelCellOfLeftTable + 3 + (widthLabelCell * 0.6), startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });
    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(data.tel_sales_business, startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });
  }

  private _renderDeliveryContent(page: string, data) {
    this.contentX = PDFConstant.PAGE_TOP_X + PDFConstant.CONTENT_PADDING_X;
    this.contentY = PDFConstant.CONTENT_Y;
    this.contentWidth = PDFConstant.PAGE_BOTTOM_X - PDFConstant.CONTENT_PADDING_X - this.contentX;
    this.contentY += PDFConstant.LINE_HEIGHT;
    this.doc.moveTo(this.contentX, this.contentY).font(PDFConstant.FONT_REGULAR).fontSize(8);
    const blockPadding = 50;
    const blockWidth = this.contentWidth - blockPadding * 2;
    const blockLeft = blockWidth / 3;

    const cellHeight = 20;
    const columnLeft = 125;

    const widthLabelCell = 150;
    const widthValueCell = 380;
    const widthContentValueCell = 370;

    const startXLabelCellOfLeftTable = 30;
    const startXValueCellOfLeftTable = widthLabelCell + startXLabelCellOfLeftTable;

    let startYOfLeftTable = 70;

    let header1 = PDFConstant.DELIVERY_HEADER_1_1;
    let header2 = PDFConstant.DELIVERY_HEADER_1_2;
    if (page == PDFConstant.PAGE2) {
      header1 = PDFConstant.DELIVERY_HEADER_2_1;
      header2 = PDFConstant.DELIVERY_HEADER_2_2;
    } else if (page == PDFConstant.PAGE3) {
      header1 = PDFConstant.DELIVERY_HEADER_3_1;
      header2 = PDFConstant.DELIVERY_HEADER_3_2;
    }

    if (page == PDFConstant.PAGE2) {
      this.doc.font(PDFConstant.FONT_BOLD).fontSize(13).fill('black').text('配達依頼票　入力用記載内容自動反映・要印刷 2/3', 32, 20, {
        width: 500,
        align: 'justify',
      });
    } else if (page == PDFConstant.PAGE3) {
      this.doc.font(PDFConstant.FONT_BOLD).fontSize(13).fill('black').text('配達依頼票　入力用記載内容自動反映・要印刷 3/3', 32, 20, {
        width: 500,
        align: 'justify',
      });
    }

    this.doc.font(PDFConstant.FONT_REGULAR).fontSize(12).fill('black').text('荷物運搬便システム', 470, 10, {
      width: 300,
      align: 'justify',
    });

    this.doc.font(PDFConstant.FONT_BOLD).fontSize(13).fill('black').text(header1, 32, 40, {
      width: 300,
      align: 'justify',
    });

    this.doc.font(PDFConstant.FONT_BOLD).fontSize(13).fill('black').text(header2, widthLabelCell + widthValueCell - this.doc.widthOfString(header2), 40, {
      width: this.doc.widthOfString(header2),
      align: 'right',
    });

    if (page == PDFConstant.PAGE1) {
      this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).lineWidth(0.1).stroke();
      this.doc.fill('black').font(PDFConstant.FONT_REGULAR).fontSize(8).text('得意先CD', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
        width: columnLeft,
        align: 'justify',
      });

      this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
      this.doc.fill('black').text(data.customer_code_to, startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
        width: widthValueCell - 50,
      });

      startYOfLeftTable += cellHeight;

      this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
      this.doc.fill('black').text('振番', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
        width: columnLeft,
        align: 'justify',
      });

      this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
      this.doc.fill('black').text("記入不要", startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
        width: widthContentValueCell,
      });

      startYOfLeftTable += cellHeight;
      this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
      this.doc.fill('black').text('営業担当社員CD', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
        width: columnLeft,
        align: 'justify',
      });

      this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
      this.doc.fill('black').text(data.employee_id_sales, startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
        width: widthContentValueCell,
      });
    } else {
      startYOfLeftTable = 90;
    }

    startYOfLeftTable += cellHeight * 1.5;
    ///////////////////////////////////////////////////////////////////////////////////////
    this.doc.font(PDFConstant.FONT_REGULAR).fontSize(8).fill('black').text('1. 配送便', 32, startYOfLeftTable, {
      width: blockLeft,
      align: 'justify',
    });

    this.doc.font(PDFConstant.FONT_REGULAR).fontSize(8).fill('black').text('伝票番号：' + data.order_id, 185, startYOfLeftTable, {
      width: widthContentValueCell,
      align: 'justify',
    });

    startYOfLeftTable += cellHeight - 10;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).lineWidth(0.1).stroke();
    this.doc.fill('black').text('発送希望時刻', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.hope_ship_time, widthContentValueCell, 1), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('配送希望日', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(data.hope_delivery_date.replace("/", "年").replace("/", "月") + "日", startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('コース名', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.delivery_kbn_name, widthContentValueCell, 1), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight * 1.5;
    ///////////////////////////////////////////////////////////////////////////////////////
    this.doc.font(PDFConstant.FONT_REGULAR).fontSize(8).fill('black').text('２．御得意先様', 32, startYOfLeftTable, {
      width: blockLeft,
      align: 'justify',
    });

    startYOfLeftTable += cellHeight - 10;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('会社名', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.customer_name_to, widthContentValueCell, 1), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('部課名', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.division_name_to, widthContentValueCell, 1), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('担当者様名', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.user_name_to, widthContentValueCell, 1), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    this.doc.fill('black').text('様', startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
      align: 'right',
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('住所', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.prefectures_to + '　' + data.address1_to, widthContentValueCell, 1), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('ビル名', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.address2_to, widthContentValueCell, 1), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('階数', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text('', startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight * 2).stroke();
    this.doc.fill('black').text('届先詳細', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight * 2).stroke();
    this.doc.fill('black').text(this.handleText(data.note_to, widthContentValueCell, 3), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight * 2 * 1.5;
    ///////////////////////////////////////////////////////////////////////////////////////
    this.doc.font(PDFConstant.FONT_REGULAR).fontSize(8).fill('black').text('３．送付荷物内容', 32, startYOfLeftTable, {
      width: widthLabelCell + widthValueCell,
      align: 'justify',
    });

    let order_num_category_1 = 0
    let order_num_category_2 = 0
    let order_num_category_3 = 0
    let order_num_category_4 = 0
    let order_num_category_5 = 0
    let order_num_category_6 = 0

    if (data.item_category == '1' || data.item_category == '荷物①：封筒') {
      order_num_category_1 = 1;
    } else if (data.item_category == '2' || data.item_category == '荷物②：包') {
      order_num_category_2 = 1;
    } else if (data.item_category == '3' || data.item_category == '荷物③：カバン') {
      order_num_category_3 = 1;
    } else if (data.item_category == '4' || data.item_category == '荷物④：筒') {
      order_num_category_4 = 1;
    } else if (data.item_category == '5' || data.item_category == '荷物⑤：段ボール') {
      order_num_category_5 = 1;
    } else if (data.item_category == '6' || data.item_category == '荷物⑥：その他') {
      order_num_category_6 = 1;
    }

    startYOfLeftTable += cellHeight - 10;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('荷物①：封筒', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(order_num_category_1, startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('荷物②：包', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(order_num_category_2, startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('荷物③：カバン', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(order_num_category_3, startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('荷物④：筒', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(order_num_category_4, startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('荷物⑤：段ボール', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(order_num_category_5, startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('荷物⑥：その他', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(order_num_category_6, startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight * 2).stroke();
    this.doc.fill('black').text('荷物についての補足', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight * 2).stroke();
    this.doc.fill('black').text(this.handleText(data.item_note, widthContentValueCell, 3), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight * 2 * 1.5;
    ///////////////////////////////////////////////////////////////////////////////////////
    this.doc.font(PDFConstant.FONT_REGULAR).fontSize(8).fill('black').text('４．発送元', 32, startYOfLeftTable, {
      width: widthLabelCell + widthValueCell,
      align: 'justify',
    });

    startYOfLeftTable += cellHeight - 10;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell, cellHeight).stroke();
    this.doc.fill('black').text('発送者', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.company_name_sales, widthContentValueCell, 1), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell * 0.6, cellHeight * 5).stroke();
    this.doc.fill('black').text('営業担当', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXLabelCellOfLeftTable + widthLabelCell * 0.6, startYOfLeftTable, widthLabelCell * 0.4, cellHeight * 3).stroke();
    this.doc.fill('black').text('部署', startXLabelCellOfLeftTable + 3 + (widthLabelCell * 0.6), startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });
    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight * 3).stroke();
    this.doc.fill('black').text(this.handleText(data.omit_division_name_sales, widthContentValueCell, 5), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    this.doc.rect(startXLabelCellOfLeftTable + widthLabelCell * 0.6, startYOfLeftTable + cellHeight * 3, widthLabelCell * 0.4, cellHeight).stroke();
    this.doc.fill('black').text('氏名', startXLabelCellOfLeftTable + 3 + (widthLabelCell * 0.6), startYOfLeftTable + cellHeight * 3 + 4, {
      width: columnLeft,
      align: 'justify',
    });
    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable + cellHeight * 3, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.full_name_sales, widthContentValueCell, 1), startXValueCellOfLeftTable + 3, startYOfLeftTable + cellHeight * 3 + 4, {
      width: widthContentValueCell,
    });

    this.doc.rect(startXLabelCellOfLeftTable + widthLabelCell * 0.6, startYOfLeftTable + cellHeight * 4, widthLabelCell * 0.4, cellHeight).stroke();
    this.doc.fill('black').text('TEL', startXLabelCellOfLeftTable + 3 + (widthLabelCell * 0.6), startYOfLeftTable + cellHeight * 4 + 4, {
      width: columnLeft,
      align: 'justify',
    });
    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable + cellHeight * 4, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(data.tel_sales, startXValueCellOfLeftTable + 3, startYOfLeftTable + cellHeight * 4 + 4, {
      width: widthContentValueCell,
    });


    startYOfLeftTable += cellHeight * 5;
    this.doc.rect(startXLabelCellOfLeftTable, startYOfLeftTable, widthLabelCell * 0.6, cellHeight * 2).stroke();
    this.doc.fill('black').text('実務担当', startXLabelCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });

    this.doc.rect(startXLabelCellOfLeftTable + widthLabelCell * 0.6, startYOfLeftTable, widthLabelCell * 0.4, cellHeight).stroke();
    this.doc.fill('black').text('氏名', startXLabelCellOfLeftTable + 3 + (widthLabelCell * 0.6), startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });
    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(this.handleText(data.full_name_business, widthContentValueCell, 1), startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });

    startYOfLeftTable += cellHeight;
    this.doc.rect(startXLabelCellOfLeftTable + widthLabelCell * 0.6, startYOfLeftTable, widthLabelCell * 0.4, cellHeight).stroke();
    this.doc.fill('black').text('TEL', startXLabelCellOfLeftTable + 3 + (widthLabelCell * 0.6), startYOfLeftTable + 4, {
      width: columnLeft,
      align: 'justify',
    });
    this.doc.rect(startXValueCellOfLeftTable, startYOfLeftTable, widthValueCell, cellHeight).stroke();
    this.doc.fill('black').text(data.tel_sales_business, startXValueCellOfLeftTable + 3, startYOfLeftTable + 4, {
      width: widthContentValueCell,
    });
  }
}
