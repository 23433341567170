<!-- メイン領域-->
<mat-drawer-container class="main-container" autosize>
  <!-- 一覧画面表示領域-->
  <div class="details-layout" #master>
    <p-table #table [value]="searchResultsList" [paginator]="true" [rows]="10" [(first)]="pages"
      [showCurrentPageReport]="true" currentPageReportTemplate="{first}~{last}件表示({totalRecords}件中)"
      [rowsPerPageOptions]="[10, 50, 100]" styleClass="p-datatable-striped master-datatable" selectionMode="single">
      <ng-template pTemplate="caption">
        <div class="p-d-flex p-jc-between">
          <div class="p-d-flex button-bar">
            <!-- 検索画面表示-->
            <button type="button" pButton icon="pi pi-search" (click)="search.toggle()" class="p-mr-2 p-button-br-7"
              pTooltip="検索" tooltipPosition="bottom"></button>

            <!-- 新規登録表示-->
            <button type="button" pButton icon="pi pi-plus" (click)="new()" class="p-mr-2 p-button-br-7 button-mobile"
              *ngIf="departmentType === '9' || departmentType === '3'"></button>
          </div>
          <button type="button" pButton icon="pi pi-file-o" (click)="exportCSV()" class="p-mr-2 p-button-br-7"
            pTooltip="全件CSV" tooltipPosition="bottom"></button>
        </div>
      </ng-template>

      <!-- 一覧画面ヘッダー表示-->
      <ng-template pTemplate="header">
        <tr>
          <!-- APIヘッダーの表示 -->

          <ng-container *ngFor="let column of columnOrder; index as i">
            <th [pSortableColumn]="column.field" [ngClass]="{'small-col':searchTemplateId==1001 && i == 0 }"
              class="p-text-bold">
              {{ column.header }}
              <p-sortIcon [field]="column.field"></p-sortIcon>
            </th>
          </ng-container>
          <!-- 操作ヘッダーの表示 -->
          <th class="btn-area p-text-bold" *ngIf="authorizeCrudButton('header')">
            <p>操作</p>
          </th>
        </tr>
      </ng-template>
      <!-- 一覧画面ボディ表示-->
      <ng-template pTemplate="body" let-searchResults>
        <tr>
          <!-- 一覧表示-->
          <ng-container *ngFor="let column of columnOrder; index as i">
            <!-- 一覧項目値表示(詳細画面遷移項目) -->
            <td *ngIf="0 == i; else hideContent" [ngClass]="{'small-col':searchTemplateId==1001 && i == 0 }">
              <label (click)="detail(searchResults[column.field])">
                <u>{{ searchResults[column.field] }}</u>
              </label>
            </td>

            <!-- 一覧項目値表示 -->
            <ng-template #hideContent>
              <td (mouseenter)="onHover($event)" [ngSwitch]="column?.input_type"
                [pTooltip]="searchResults[column.field]" #tt [tooltipDisabled]="tt.dataset.showtooltip!='true'"
                data-showTooltip="" showDelay="500" hideDelay="500">
                <span *ngSwitchCase="'NUMBER'">
                  {{ searchResults[column.field] | number }}
                </span>
                <span *ngSwitchCase="'IMAGE'">
                  <img [src]="searchResults[column.field]" alt="image" class="min-img" />
                </span>
                <span *ngSwitchDefault>
                  {{ searchResults[column.field] }}
                </span>
              </td>

            </ng-template>
          </ng-container>

          <!-- 操作表示 -->
          <td class="btn-area">

            <!-- 編集ボタン -->
            <button *ngIf="authorizeCrudButton('edit', searchResults['delivery_kbn'])" pButton (click)="edit(searchResults[columnOrder[0].field])"
              icon="pi pi-pencil" class="p-button-rounded p-button-success" pTooltip="編集"
              tooltipPosition="bottom"></button>

            <!-- 複製ボタン -->
            <button *ngIf="authorizeCrudButton('duplicate', searchResults['delivery_kbn'])" pButton (click)="duplicate(searchResults[columnOrder[0].field])"
              icon="pi pi-clone" class="p-button-rounded p-button-info" pTooltip="複製" tooltipPosition="bottom"
              [disabled]='searchResults["member_department_type"] == "支援実施者（個人）" && router?.url?.startsWith("/pages/platform/master/m201")'></button>

            <!-- 削除ボタン -->
            <button *ngIf="authorizeCrudButton('delete', searchResults['delivery_kbn'])" pButton (click)="
                  delete(searchResults[columnOrder[0].field], searchResults)
                " icon="pi pi-trash" class="p-button-rounded p-button-danger" pTooltip="削除"
              tooltipPosition="bottom"></button>

          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <!-- 検索画面-->

  <mat-drawer #search class="search-sidenav" mode="side">
    <!-- 検索画面非表示-->
    <button type="button" pButton icon="pi pi-arrow-left" (click)="search.toggle()"
      class="p-mr-2 p-button-br-7"></button>

    <!-- 検索画面[検索項目生成(アイテムアダプター)]-->
    <app-generate-search [searchEndPoint]="searchEndPoint" [searchTemplateId]="searchTemplateId"
      (generateSearchInformation)="searchResult($event)"></app-generate-search>
  </mat-drawer>
</mat-drawer-container>

<!-- 詳細画面[表示項目生成(アイテムアダプター)]-->
<app-generate-display (reloadID)="searchReplacement($event)"></app-generate-display>

<!-- 登録画面[入力項目生成(アイテムアダプター)]-->
<app-generate-input (reloadID)="searchReplacement($event)"></app-generate-input>

<!-- 削除ダイアログ-->
<app-delete-dialog (reloadID)="searchReplacement($event)"></app-delete-dialog>

<!-- Toastメッセージ表示 -->
<p-toast position="top-center"></p-toast>