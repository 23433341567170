import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivationStart, NavigationEnd, Router, RouterEvent } from "@angular/router";
import { EMPTY, Observable, throwError } from "rxjs";
import { catchError, filter, tap } from "rxjs/operators";
import {
  ROUTING_ERROR,
  HTTP_ERROR,
  MESSAGE_CODE,
} from "src/app/shared/constant/message-constant";
import {
  ACCESS_TOKEN,
  COMPONENT_VALUE_KEY,
  NO_LOGIN_SCREENS,
} from "src/app/shared/constant/constant";
import { LoginService } from "src/app/shared/service/login.service";
import { SESSION_KEY } from "src/app/shared/constant/session-constants";
import * as routing from "manager/routing-constant";
import * as headerTitle from "manager/header-title-constant";
import { CommonService } from "./common.service";
import { LoadingState } from "../html-parts/loading/loading-state";
import {
  MessageData,
  ToastMessageData,
} from "../html-parts/message-common/message-data";
import { TOAST } from "../constant/primeng-constants";
import { TITLE } from "manager/environment";
import { DbOperationService } from "./db-operation.service";
import { CONFIRM_FORGOT_PASSWORD, FORGOT_PASSWORD, LOGIN } from "manager/http-constants";

/**
 * ルーティング遷移時の介入
 */
@Injectable({
  providedIn: "root",
})
export class Routing {
  constructor(
    private readonly _router: Router,
    private loginService: LoginService,
    private commonService: CommonService,
    private dbOperationService: DbOperationService
  ) {
    // ナビゲーションが開始された時。
    // ※ルーティング遷移判定
    // ※スクロールトップ処理
    this._router.events
      .pipe(filter((event: ActivationStart) => event instanceof ActivationStart))
      .subscribe((event) => {
        // スクロールトップ
        let jumpChatTop = document.getElementById("jump-page-top");
        if (jumpChatTop) {
          jumpChatTop.scrollIntoView();
          jumpChatTop = null;
        }

        // Auth0ログインユーザトークン報処理
        {
          // セッションにログイン状態を格納
          // Auth0ログイン中か否か
          if (this.loginService.getAuth0LoginState()) {
            /* ログインユーザー情報 */
            // セッションからログインユーザ情報取得
            const loginUser = JSON.parse(
              window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
            );

            // セッションにログインユーザ情報が存在するか否か
            if (!loginUser) {
              // セッションにログインユーザが存在しない場合(初回表示時)

              if (NO_LOGIN_SCREENS.includes(event.snapshot.routeConfig.path)) {
                return;
              }
              // ログインユーザ情報取得処理
              this.loginService.getLoginUser().subscribe((response) => {
                // ログインユーザ情報がユーザマスタに存在するか否か
                if (this.commonService.checkNoneResponse(response)) {
                  // ユーザマスタに存在しない場合

                  // 不正なユーザの為、ログアウト処理
                  this.loginService.logout(MESSAGE_CODE.E90000);

                  // 処理を終了
                  return;
                } else {
                  // ユーザマスタに存在する場合
                  // 正常なユーザの為、セッションにユーザ情報を格納
                  window.sessionStorage.setItem(
                    SESSION_KEY.loginUserInformation,
                    JSON.stringify(response.body)
                  );

                  // ログインメッセージ
                  this.loginService.loginMessage(response.body?.full_name);
                }

                // タイトルヘッダーを設定を実施
                // TODO 内閣府未使用
                // this.setTitleHeader(event);

                // 画面遷移権限チェックを実施
                this.checkRouting(response.body, event);
              });
            } else {
              // タイトルヘッダーを設定を実施
              // TODO 内閣府未使用
              // this.setTitleHeader(event);

              // 画面遷移権限チェックを実施
              this.checkRouting(loginUser, event);
            }
          } else {
            // 画面遷移権限チェックを実施
            this.checkRouting(null, event);
          }
        }

        /* 全辞書情報 */
        // セッションから全辞書情報取得
        const dicList = JSON.parse(
          window.sessionStorage.getItem(SESSION_KEY.dicList)
        );

        // セッションに全辞書情報が存在するか否か
        // if (!dicList) {
        // セッションに全辞書情報が存在しない場合(初回表示時)

        // 全辞書情報取得処理
        // this.dbOperationService.getDicLists().subscribe((response) => {
        // 全辞書情報が存在するか否か
        // if (this.commonService.checkNoneResponse(response)) {
        // 全辞書情報に存在しない場合

        // セッションにnullを格納
        //   window.sessionStorage.setItem(SESSION_KEY.dicList, null);
        // } else {
        // 全辞書情報に存在する場合

        // セッションに全辞書情報を格納
        // window.sessionStorage.setItem(
        //   SESSION_KEY.dicList,
        //   JSON.stringify(response.body)
        //   );
        // }
        // });
        // }
      });
  }

  /**
   * タイトルヘッダーを設定
   * @param event 遷移先コンポーネント情報
   */
  private setTitleHeader(event) {
    // 定数からヘッダータイトルを取得
    const headerTitleInformation =
      headerTitle[event.snapshot.routeConfig.component.name];

    // ヘッダータイトルが設定されているか否か
    if (!headerTitleInformation) {
      // ヘッダータイトルが設定されていない場合

      // タイトルフッダーをヘッダータイトルにセットする
      this.commonService.setComponentValue(
        TITLE.TITLE_FOOTER,
        COMPONENT_VALUE_KEY.HEADER_TITLE
      );

      return;
    }

    // ヘッダータイトルにパスパラメータが設定されているか否か
    if (headerTitleInformation[event.snapshot._routerState.url.slice(1)]) {
      // パスパラメータが設定されていない場合

      // 設定されているヘッダータイトルをセットする
      this.commonService.setComponentValue(
        headerTitleInformation[event.snapshot._routerState.url.slice(1)],
        COMPONENT_VALUE_KEY.HEADER_TITLE
      );

      return;
    }

    // デフォルト設定されているヘッダータイトルをセットする
    this.commonService.setComponentValue(
      headerTitleInformation.default,
      COMPONENT_VALUE_KEY.HEADER_TITLE
    );
  }

  /**
   * 画面遷移権限チェック
   * @param loginUser ログインユーザ
   * @param event 遷移先コンポーネント情報
   */
  private checkRouting(loginUser, event) {
    // 定数から権限情報を取得
    const routingCheckInformationList: Object[] =
      routing[event.snapshot.routeConfig.component.name];
    // 権限情報が設定されているか否か
    if (!routingCheckInformationList) {
      // 権限情報が設定されていない場合

      // 画面遷移許可
      return;
    }

    // 権限情報の中身が設定されているか否か
    if (!routingCheckInformationList.length) {
      // 権限情報の中身が設定されていない場合

      // 画面遷移許可
      return;
    }

    /* 権限情報の判定 */
    // 権限情報リスト分ループ
    Routing: for (const routingCheckInformation of routingCheckInformationList) {
      /* 権限情報に値が1つ以上設定されている判定 */
      // 権限情報設定フラグ
      let routingCheckInformationFlag: boolean;

      // 権限情報分ループ
      for (const routingCheckKey in routingCheckInformation) {
        // 権限情報の項目に1つ以上、値が存在するか否か
        if (routingCheckInformation[routingCheckKey]) {
          // 権限情報の項目に1つ以上、値が存在する場合

          // 権限情報設定フラグをONにする
          routingCheckInformationFlag = true;
          break;
        }
      }

      // 権限情報設定フラグをOFFの場合
      if (!routingCheckInformationFlag) {
        // 次の権限情報リストのループを実施
        continue;
      }

      // ログインユーザが未ログインの場合
      if (!loginUser) {
        // 次の権限情報リストのループを実施
        continue;
      }

      /* ログインユーザの権限判定 */
      // 権限情報分ループ
      for (const routingCheckKey in routingCheckInformation) {
        // 権限情報の項目が存在する かつ
        // 権限情報の項目とログインユーザの権限項目が一致するか否か
        if (
          routingCheckInformation[routingCheckKey] &&
          routingCheckInformation[routingCheckKey] != loginUser[routingCheckKey]
        ) {
          // 権限項目が一致しない場合

          // 次の権限情報のループを実施
          continue Routing;
        }
      }
      // 全ての権限情報の項目とログインユーザ情報の権限項目が一致した場合

      // 画面遷移許可
      return;
    }

    // 権限が設定済み かつ ログインユーザ権限と権限情報が一致しない場合

    // エラーメッセージを出力
    console.error(ROUTING_ERROR.ROUTING_AUTHORITY_ERROR);

    // 不正な権限の為、ログアウト処理
    this.loginService.logout(MESSAGE_CODE.E90001);

    return;
  }
}

/**
 * Httpリクエスト時の介入
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private loginService: LoginService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.loginService.getAuth0LoginState() && !['https://xxxxx.s3.amazonaws.com/'].includes(req.url)) {
      req = req.clone({
        setHeaders: {
          Authorization: 'Bearer ' + this.loginService.getAuth0LoginToken(),
        },
      });
      const dataJwt = this.parseJwt(this.loginService.getAuth0LoginToken())
      if (!dataJwt.iat || (new Date() > new Date(dataJwt.iat * 1000 + ACCESS_TOKEN.TIME_EXPIRE))) {
        console.log("Token has expired")
        sessionStorage.clear();
        localStorage.clear();
        this.loginService.logout(MESSAGE_CODE.N90001);
        return EMPTY
      }
    }

    // リクエストを実施
    return next.handle(req);
  }

  parseJwt(token: any) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }
}

/**
 * Httpレスポンス時の介入
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private loginService: LoginService,
    private commonService: CommonService,
    private loadingState: LoadingState,
    private messageData: MessageData
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const req = request.clone();
    return next.handle(req).pipe(
      // tapオペレータでレスポンスの流れを傍受する
      tap((res) => {
        // 通常レスポンスの場合
        if (res instanceof HttpResponse) {
          // レスポンスが正常に返却されているか判定
          if ((null == res.body || 0 == res.body.length) && !res.ok) {
            // レスポンスが存在しない場合

            // コンソールにエラー出力
            console.error(HTTP_ERROR.RESPONSE_NONE);
            console.error(
              this.commonService.msg(
                MESSAGE_CODE.E00007,
                res.status,
                JSON.stringify(res.body),
                res.url
              )
            );
            console.error(res);
          }
        }
      }),
      // lambdaで異常ステータスが返却された場合
      catchError((res) => {
        // 異常ステータスのステータス判定
        if ([LOGIN, CONFIRM_FORGOT_PASSWORD, FORGOT_PASSWORD].includes(res.url)) {
          return throwError(res);
        }
        switch (res.status) {
          case 400:
            console.error(HTTP_ERROR.HTTP_400);
            console.error(
              this.commonService.msg(MESSAGE_CODE.E00008, res.status, res.error)
            );
            break;

          case 401:
            console.error(HTTP_ERROR.HTTP_401);
            console.error(
              this.commonService.msg(MESSAGE_CODE.E00008, res.status, res.error)
            );
            // 認証エラーの為、ログアウト処理
            this.loginService.logout(MESSAGE_CODE.N90001);
            break;

          case 403:
            console.error(HTTP_ERROR.HTTP_401);
            console.error(
              this.commonService.msg(MESSAGE_CODE.E00008, res.status, res.error)
            );
            // 不正パラメータでの取得の為、ログアウト処理
            this.loginService.logout(MESSAGE_CODE.E90002);
            break;

          case 404:
            console.error(HTTP_ERROR.HTTP_404);
            console.error(
              this.commonService.msg(MESSAGE_CODE.E00008, res.status, res.error)
            );
            break;

          case 412:
            console.error(HTTP_ERROR.HTTP_412);
            console.error(
              this.commonService.msg(MESSAGE_CODE.E00008, res.status, res.error)
            );
            // システム稼働時間外の為、ログアウト処理
            this.loginService.logout(MESSAGE_CODE.N90002);
            break;

          case 422:
            console.error(HTTP_ERROR.HTTP_422);
            console.error(
              this.commonService.msg(MESSAGE_CODE.E00008, res.status, res.error)
            );
            break;

          case 500:
            console.error(HTTP_ERROR.HTTP_500);
            console.error(
              this.commonService.msg(MESSAGE_CODE.E00008, res.status, res.error)
            );
            break;
          default:
            console.error(HTTP_ERROR.ERROR);
            console.error(
              this.commonService.msg(MESSAGE_CODE.E00008, res.status, res.error)
            );
            break;
        }

        // 異常メッセージ
        this.messageData.toastMessage(
          new ToastMessageData({
            severity: TOAST.ERROR,
            summary: this.commonService.msg(MESSAGE_CODE.E00003),
            detail: this.commonService.msg(MESSAGE_CODE.E80002),
            position: TOAST.BOTTOM_RIGHT,
            life: 60000,
          })
        );

        // 画面ロードフラグをOFF(ロード強制終了)
        this.loadingState.loadForcedEnd();

        return throwError(res);
      })
    );
  }
}
