import { Component, OnInit, ViewChild } from '@angular/core';
import { GenerateDisplayComponent } from 'src/app/shared/generate/generate-display/generate-display.component';
import { MessageCommonComponent } from 'src/app/shared/html-parts/message-common/message-common.component';
import { GenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import { CONSTANT, DATE_FORMAT, DELIVERY_COLLECT_KBN, LOADING_KEY } from 'src/app/shared/constant/constant';
import { LoadData, LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import { ExportFileService } from 'src/app/shared/html-parts/export-file/export-file.service';
import { DatePipe } from '@angular/common';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { C121_TEMPLATE } from 'manager/template-constant';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { DataView } from 'primeng/dataview';
import {
  API_URL_ADD_SPOT_DELIVERY,
  API_URL_DELIVERY,
  API_URL_GENERATE_SEARCH,
  API_URL_INPUT_MASTER,
  API_URL_WORK_DELIVERY_FROM,
} from 'manager/http-constants';
import { GenerateInputComponent } from 'src/app/shared/generate/generate-input/generate-input.component';
import { GENERATE_INPUT_TYPE } from 'src/app/shared/generate/generate-input/constant';

@Component({
  selector: 'app-c121',
  templateUrl: './c121.component.html',
  styleUrls: ['./c121.component.scss']
})
export class C121Component implements OnInit {
  /** 詳細画面 */
  @ViewChild(GenerateDisplayComponent)
  generateDisplayItemsComponent: GenerateDisplayComponent;

  /** メッセージ */
  @ViewChild(MessageCommonComponent)
  messageCommonComponent: MessageCommonComponent;

  @ViewChild('dv')
  dv: DataView;
  // 検索項目生成エンドポイント
  public searchEndPoint: string = API_URL_GENERATE_SEARCH;

  /** 新規登録、編集、複製画面 */
  @ViewChild(GenerateInputComponent)
  generateInputComponent: GenerateInputComponent;

  // 検索項目生成テンプレートID
  public searchTemplateId: number = C121_TEMPLATE.SEARCH_TEMPLATE_ID;

  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnOrder: any[];

  // 画面検索結果一覧格納先
  searchResultsList: any[];

  // list checked of delivery-search
  searchCheckbox: any[];

  // ページ数
  pages: number = 0;

  delivery_collect_kbn: string = DELIVERY_COLLECT_KBN.DELIVERY_0;

  delivery_kbn: number = 4;

  // カート選択リスト格納先
  cartSelected: any[] = [];

  // 検索条件保持
  generateSearchItems: Object;

  // 全選択チェックボックス
  allChecked: boolean = false;

  // checked value of items search
  checkedArr: any[];

  // dataView ソートカラム名
  sortField: string;

  // dataView ソート順 (1:昇順, -1:降順)
  sortOrder: number;

  existData: boolean = false;

  originalFirstDeliveryId: string = null;

  constructor(
    private dbOperationService: DbOperationService,
    private router: Router,
    private commonService: CommonService,
    public datePipe: DatePipe,
    private loadingState: LoadingState,
    private exportFileService: ExportFileService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    const { delivery_collect_kbn } = this.route.snapshot.queryParams;
    if (delivery_collect_kbn) {
      this.delivery_collect_kbn = delivery_collect_kbn;
    } else {
      this.router.navigate(['/pages/platform/c121'], { queryParams: { delivery_collect_kbn: DELIVERY_COLLECT_KBN.DELIVERY_0 } });
    }

    // ヘッダー情報取得処理(画面用)
    this.dbOperationService
      .getHeaderList(C121_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID)
      .subscribe((response) => {
        this.columnOrder = response.body;
      });

    // 検索処理を実施
    this.searchResult();

    this.getWorkDeliveryFrom();
  }

  /**
   * 検索処理
   * @param table テーブル状態
   * @param generateSearchItems 検索条件
   */
  public searchResult(table?: DataView, generateSearchItems?: Object) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
      })
    );

    let delivery_kbn = '';
    if (this.delivery_collect_kbn == DELIVERY_COLLECT_KBN.DELIVERY_0) {
      delivery_kbn = '3,4';
    } else {
      delivery_kbn = '1,2,3'
    }

    if (generateSearchItems == null || generateSearchItems == undefined) {
      generateSearchItems = {
        delivery_kbn: delivery_kbn,
      }
    }

    if (String(generateSearchItems['delivery_kbn']).length == 0) {
      generateSearchItems['delivery_kbn'] = delivery_kbn
    }

    // CSV出力用に検索条件を保持
    this.generateSearchItems = generateSearchItems;

    // 販促資材マスタ一覧取得処理(画面用)
    this.dbOperationService
      .getForkJoinData(
        C121_TEMPLATE.TABLE_NAME,
        API_URL_DELIVERY,
        C121_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID,
        generateSearchItems,
        true
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 販促資材マスタ一覧情報のJSONをオブジェクトに格納する
          this.searchResultsList = response.body;
          this.dv.first = 0;
          if (this.originalFirstDeliveryId != null && this.searchResultsList[0].delivery_id != this.originalFirstDeliveryId) {
            this.cartSelected = new Array(String(this.searchResultsList[0].delivery_id));
            this.originalFirstDeliveryId = null;
          }
        } else {
          this.searchResultsList = [];
        }
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3);
      });
    // ページ数を初期化
    this.pages = 0;
  }

  public getWorkDeliveryFrom() {
    this.dbOperationService
      .getNoTemplateData(
        API_URL_WORK_DELIVERY_FROM,
        {
          delivery_collect_kbn: [this.delivery_collect_kbn]
        }
      )
      .subscribe((response) => {
        if (!this.commonService.checkNoneResponse(response)) {
          this.checkedArr = response.body;
          this.cartSelected = this.checkedArr.map(i => i.delivery_id.toString());
          this.existData = true;
        } else {
          this.cartSelected = [];
        }
      });
  }

  /**
  * 新規登録画面表示
  */
  public new() {
    // 新規登録画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.NEW,
      API_URL_INPUT_MASTER,
      API_URL_DELIVERY,
      API_URL_ADD_SPOT_DELIVERY,
      C121_TEMPLATE.INPUT_DELIVERY_SPOT_TEMPLATE_ID,
      null
    );
  }

  /**
   * 再検索処理＆データ置換
   * @param pkeyId コード値
   */
  public searchReplacement(pkeyId: string) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
      })
    );
    // 返却されたコード値が存在するか否か
    if (null == pkeyId || undefined == pkeyId) {
      // 返却されたコード値が存在しない場合
      this.originalFirstDeliveryId = this.searchResultsList[0].delivery_id;

      // 検索処理を実施
      this.searchResult();
      return;
    }

    // 営業日マスタ一覧取得処理(画面用)
    this.dbOperationService
      .getSingleData(
        API_URL_DELIVERY,
        C121_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID,
        this.columnOrder[0].field,
        pkeyId
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (this.commonService.checkNoneResponse(response)) {
          // 一覧情報が存在しない場合

          // 検索結果一覧から該当データを削除
          this.searchResultsList = this.searchResultsList.filter(
            (val) => val[this.columnOrder[0].field] !== pkeyId
          );

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadSleepEnd(0.3);
        } else {
          // 一覧情報が存在する場合

          // 検索結果一覧格納先から対象のデータの行数を抽出
          var indexTargetData = this.searchResultsList.indexOf(
            this.searchResultsList.find((results) => {
              return results[this.columnOrder[0].field] === pkeyId;
            })
          );

          // 検索結果一覧情報のJSONをオブジェクトに置換する
          this.searchResultsList.splice(indexTargetData, 1, response.body[0]);

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadSleepEnd(0.3);
        }
      });
  }

  /**
 * 検索処理
 * @param generateSearchItems 検索条件
 */
  selectRow(event: any) {
    // 届け先資材(WORK)一覧取得処理(画面用)

  }

  /**
   * 詳細情報画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  public detail(pkeyId: string) {
    // 詳細画面表示
    const inputGenerateDisplay: GenerateDisplay = new GenerateDisplay();
    inputGenerateDisplay.endPoint = API_URL_DELIVERY;
    inputGenerateDisplay.templateId = C121_TEMPLATE.OUTPUT_TEMPLATE_ID;
    this.generateDisplayItemsComponent.initial(inputGenerateDisplay, pkeyId);
  }

  /**
   * カート追加
   */
  public addCart() {
    // カートが選択されているか否か
    if (!this.cartSelected) {
      // カートが選択されていない場合
      return;
    }

    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: CONSTANT.EMPTY_STRING,
        background_color: CONSTANT.EMPTY_STRING,
        opacity: 0.3,
      }),
      LOADING_KEY.DATA_INSERT
    );

    // カート登録
    this.dbOperationService
      .insertData(API_URL_WORK_DELIVERY_FROM, {
        delivery_id: this.cartSelected.join(CONSTANT.COMMA),
        delivery_collect_kbn: this.delivery_collect_kbn,
      })
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd(LOADING_KEY.DATA_INSERT);
        this.messageCommonComponent.clearToastMessage();

        // レスポンスを判定
        if (this.messageCommonComponent.responseToastMessage(response, true, '1件を選択してください。')) {
          // レスポンスが正常終了の場合

          // 発注管理_届先選択へ遷移
          this.router.navigate(['pages/platform/c131'], { queryParams: { delivery_collect_kbn: this.delivery_collect_kbn } });
        }
      });
  }

  /**
   * CSV出力
   */
  public exportCSV() {
    // csvファイル名の設定
    const fileName =
      '差出元情報一覧' +
      CONSTANT.UNDERBAR +
      this.datePipe.transform(new Date(), DATE_FORMAT.DATE_HYPHEN);

    // CSVファイル出力
    this.exportFileService.exportTemplateCsv(
      fileName,
      C121_TEMPLATE.TABLE_NAME,
      API_URL_DELIVERY,
      C121_TEMPLATE.OUTPUT_TEMPLATE_ID,
      this.generateSearchItems,
      true
    );
  }
  /**
   * Download PDF file
   */
  public downloadPdf(filePath: string) {
    window.location.href = filePath;
  }

  protected onCheckItem(dv: DataView) {
    const currentValue = this.cartSelected.pop();
    if (currentValue) {
      this.cartSelected = new Array(currentValue);
    } else {
      this.cartSelected = new Array();
    }
  }

  /**
 * dataView ソート処理
 */
  protected onSortChange(column: string) {
    let setOrder;
    if (this.sortField == column) {
      setOrder = this.sortOrder == 1 ? -1 : 1;
    } else {
      setOrder = 1
    }
    this.sortOrder = setOrder;
    this.sortField = column;
  }

  /**
 * check overflow of element
 * @param el html element of list 
 * @returns 
*/
  isOverflow(el: HTMLElement): boolean {
    return el.clientWidth < el.scrollWidth;
  }

  onHover(e) {
    if (this.isOverflow(e.target)) {
      e.target.dataset.showtooltip = 'true'
    };
  }
}
