<!-- 発注管理リスト -->
<app-breadcrumb [selectionLine]="cartSelected.length" [existData]="existData"></app-breadcrumb>

<p-messages *ngIf="dispErrors" severity="error">
  <ng-template pTemplate>
    <div class="error-message-box p-d-flex p-jc-between">
      <div class="p-message-detail p-d-flex p-flex-column">
        <span *ngFor="let message of errorMessages">
          <ng-container *ngIf="message.index || message.index === 0">
            {{ message.index }}行目：{{ message.columnName }}：{{
            message.detail
            }}
          </ng-container>
          <ng-container *ngIf="!message.index && message.index !== 0">
            {{ message.detail }}
          </ng-container>
        </span>
      </div>
    </div>
  </ng-template>
</p-messages>

<!-- メイン領域-->
<mat-drawer-container class="main-container" autosize>

  <p-dataView *ngIf="deliveryKbn != '3'" #dv [value]="searchResultsList" [paginator]="true" [rows]="10"
    [showCurrentPageReport]="true" currentPageReportTemplate="{first}~{last}件表示({totalRecords}件中)"
    [rowsPerPageOptions]="[10, 50, 100]" styleClass="p-datatable-striped order-datatable" selectionMode="single"
    layout="list" [sortField]="sortField" [sortOrder]="sortOrder">
    <ng-template pTemplate="header">
      <div class="dv-header-button">
        <div class="p-d-flex p-jc-between">
          <div class="p-d-flex">
          </div>
          <div class="p-as-center p-d-flex">
            <button type="button" pButton label="確認" icon="" (click)="addCart()" class="p-mr-2"></button>
          </div>
        </div>
      </div>
      <ng-container *ngIf="dv.layout == 'list'">
        <div class="dv-header-table-header">
          <!-- dataViewヘッダー -->
          <p-table styleClass="p-datatable-sm generate-input">
            <!-- 一覧画面ヘッダー表示-->
            <ng-template pTemplate="header">
              <tr class="p-col-2">
                <!-- APIヘッダーの表示 -->
                <ng-container *ngFor="let column of columnOrder">
                  <th [pSortableColumn]="column.field" (click)="onSortChange(column.field)">
                    {{ column.header }}
                  </th>
                </ng-container>
                <th>
                  操作
                </th>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </ng-container>
    </ng-template>
    <ng-template let-searchResults let-rowIndex="rowIndex" pTemplate="listItem">
      <p-table #table [value]="[searchResults]" styleClass="p-table-striped order-table" selectionMode="single"
        dataKey="delivery_id" expandableRows="true" (onRowClick)="table.toggleRow($event.data)">
        <!-- 一覧画面ボディ表示-->
        <ng-template pTemplate="body">
          <tr class="p-col-2" id="tableResult">
            <!-- チェックボックス -->
            <ng-container *ngFor="let column of columnOrder; index as i" [ngSwitch]="true">
              <td *ngIf="0 == i; else hideContent" [pTooltip]="searchResults[column.field]" #tt
                (mouseenter)="onHover($event)" [tooltipDisabled]="tt.dataset.showtooltip!='true'" data-showTooltip=""
                showDelay="500" hideDelay="500" class="overflow">
                {{ searchResults[column.field] }}
              </td>
              <!-- 一覧項目値表示 -->
              <ng-template #hideContent [ngSwitch]="column?.input_type">
                <td *ngSwitchCase="'NUMBER'" [pTooltip]="searchResults[column.field]" #tt (mouseenter)="onHover($event)"
                  [tooltipDisabled]="tt.dataset.showtooltip!='true'" data-showTooltip="" showDelay="500"
                  hideDelay="500">
                  {{ searchResults[column.field] | number }}
                </td>
                <td *ngIf="
                column?.field != 'order_num' && 
                column?.field != 'vehicle_type' && 
                column?.field != 'delivery_company' && 
                column?.field != 'hope_delivery_date' && 
                column?.field != 'hope_ship_time' && 
                column?.field != 'hope_delivery_time' && 
                column?.field != 'hope_ship_date' && 
                column?.field != 'spot_reason' && 
                column?.field != 'delivery_note' && 
                column?.field != 'vehicle_type'" class="overflow" [pTooltip]="searchResults[column.field]" #tt
                  (mouseenter)="onHover($event)" [tooltipDisabled]="tt.dataset.showtooltip!='true'" data-showTooltip=""
                  showDelay="500" hideDelay="500">
                  {{ searchResults[column.field] }}
                </td>
                <td *ngIf="column?.field == 'order_num'">
                  <input type="number" [(ngModel)]="searchResults[column.field]" style="max-width: 60%;"
                    class="p-inputtext p-component ng-pristine ng-valid ng-touched" id="{{rowIndex + 1 + column.field}}"
                    [placeholder]="getPlaceholder(column.field)" [maxlength]="getMaxlength(column.field)"
                    (keypress)="blockNonInteger($event)">
                </td>
                <td *ngIf="column?.field == 'vehicle_type'">
                  <p-dropdown [options]="listVehicleType" optionLabel="sp_name" optionValue="sp_code" [showClear]="true"
                    styleClass="information-Form-dropdown p-datatable-striped master-datatable center"
                    [style]="{'max-width': '100%'}" [(ngModel)]="searchResults[column.field]"
                    (onChange)="changeVehicleType($event, rowIndex)" id="{{rowIndex + 1 + column.field}}"
                    [placeholder]="getPlaceholder(column.field)" #dd
                    [panelStyle]="{'width':dd?.width?dd.width:'100px'}"></p-dropdown>
                </td>
                <td *ngIf="column?.field == 'delivery_company'">
                  <p-dropdown [options]="getDeliveryCompanyOption(rowIndex)" optionLabel="sp_name" optionValue="sp_code"
                    [showClear]="true"
                    styleClass="information-Form-dropdown p-datatable-striped master-datatable center"
                    [style]="{'max-width': '100%'}" [(ngModel)]="searchResults[column.field]"
                    id="{{rowIndex + 1 + column.field}}" [placeholder]="getPlaceholder(column.field)" #dd
                    [panelStyle]="{'width':dd?.width?dd.width:'100px'}"></p-dropdown>
                </td>
                <td *ngIf="column?.field == 'hope_ship_time'">
                  <p-dropdown [options]="listHopeShipTime" [showClear]="true"
                    styleClass="information-Form-dropdown p-datatable-striped master-datatable center"
                    [style]="{'width': '100%'}" [(ngModel)]="searchResults[column.field]"
                    id="{{rowIndex + 1 + column.field}}" [placeholder]="getPlaceholder(column.field)" #dd
                    [panelStyle]="{'width':dd?.width?dd.width:'100px'}"></p-dropdown>
                </td>
                <td *ngIf="column?.field == 'hope_delivery_date' || column?.field == 'hope_ship_date'">
                  <p-calendar [showIcon]="true" [yearNavigator]="true" [monthNavigator]="true" [readonlyInput]="true"
                    dateFormat="yy/mm/dd" dataType="string" appendTo="body" yearRange="1900:2999"
                    [(ngModel)]="searchResults[column.field]" translate id="{{rowIndex + 1 + column.field}}"
                    [placeholder]="getPlaceholder(column.field)"></p-calendar>
                </td>
                <td *ngIf="column?.field == 'hope_delivery_time'">
                  <input type="time" [(ngModel)]="searchResults[column.field]" style="width: 100%;"
                    class="p-inputtext p-component ng-pristine ng-valid ng-touched" id="{{rowIndex + 1 + column.field}}"
                    [placeholder]="getPlaceholder(column.field)">
                </td>
                <td *ngIf="column?.field == 'spot_reason'">
                  <input type="text" [(ngModel)]="searchResults[column.field]" style="max-width: 100%;"
                    class="p-inputtext p-component ng-pristine ng-valid ng-touched" id="{{rowIndex + 1 + column.field}}"
                    [placeholder]="getPlaceholder(column.field)" [maxlength]="getMaxlength(column.field)">
                </td>
                <td *ngIf="column?.field == 'delivery_note'" style="text-align: center;">
                  <textarea [(ngModel)]="searchResults[column.field]" rows="1" style="max-width: 100%;"
                    class="p-inputtext p-component ng-pristine ng-valid ng-touched" id="{{rowIndex + 1 + column.field}}"
                    [placeholder]="getPlaceholder(column.field)" [maxlength]="getMaxlength(column.field)">
                </textarea>
              </ng-template>
            </ng-container>
            <td>
              <button pButton icon="pi pi-times" class="p-button-rounded p-button-danger" pTooltip="削除"
                (click)="delete(searchResults['item_id'], searchResults['item_category'], searchResults['delivery_kbn_name'])"
                tooltipPosition="bottom"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
  </p-dataView>

  <p-dataView *ngIf="deliveryKbn == '3'" #dv [value]="searchResultsList" [paginator]="true" [rows]="10"
    [showCurrentPageReport]="true" currentPageReportTemplate="{first}~{last}件表示({totalRecords}件中)"
    [rowsPerPageOptions]="[10, 50, 100]" styleClass="p-datatable-striped order-datatable" selectionMode="single"
    layout="list" [sortField]="sortField" [sortOrder]="sortOrder">

    <ng-template pTemplate="header">
      <div class="dv-header-button">
        <div class="p-d-flex p-jc-between">
          <div class="p-d-flex">
          </div>
          <div class="p-as-center p-d-flex">
            <button type="button" pButton label="確認" icon="" (click)="addCart()" class="p-mr-2"></button>
          </div>
        </div>
      </div>
      <ng-container *ngIf="dv.layout == 'list'">
        <div class="dv-header-table-header">
          <!-- dataViewヘッダー -->
          <p-table styleClass="p-datatable-sm generate-input">
            <!-- 一覧画面ヘッダー表示-->
            <ng-template pTemplate="header">
              <tr class="p-col-2">
                <!-- APIヘッダーの表示 -->
                <ng-container *ngFor="let column of columnOrder">

                  <th *ngIf="column.field == 'order_num'" style="width: 7%;" [pSortableColumn]="column.field"
                    (click)="onSortChange(column.field)">
                    {{ column.header }}
                  </th>
                  <th *ngIf="column.field == 'delivery_kbn_name'" style="width: 3%;" [pSortableColumn]="column.field"
                    (click)="onSortChange(column.field)">
                    {{ column.header }}
                  </th>
                  <th *ngIf="column.field == 'vehicle_type'" style="width: 10%;" [pSortableColumn]="column.field"
                    (click)="onSortChange(column.field)">
                    {{ column.header }}
                  </th>
                  <th *ngIf="column.field == 'delivery_company'" style="width: 15%;" [pSortableColumn]="column.field"
                    (click)="onSortChange(column.field)">
                    {{ column.header }}
                  </th>
                  <th *ngIf="column?.field == 'hope_delivery_date' || column?.field == 'hope_ship_date'"
                    style="width: 11%;" [pSortableColumn]="column.field" (click)="onSortChange(column.field)">
                    {{ column.header }}
                  </th>
                  <th *ngIf="column.field == 'delivery_note'" style="width: 10%;" [pSortableColumn]="column.field"
                    (click)="onSortChange(column.field)">
                    {{ column.header }}
                  </th>
                  <th *ngIf="column?.field == 'hope_delivery_time'" style="width: 8%;" [pSortableColumn]="column.field"
                    (click)="onSortChange(column.field)">
                    {{ column.header }}
                  </th>
                  <th *ngIf="
                  column.field != 'order_num' && 
                  column.field != 'vehicle_type' && 
                  column.field != 'delivery_company' && 
                  column.field != 'hope_delivery_date' && 
                  column.field != 'hope_ship_date' && 
                  column.field != 'delivery_note' && 
                  column.field != 'delivery_kbn_name' &&
                  column.field != 'hope_delivery_time'
                  " [pSortableColumn]="column.field" (click)="onSortChange(column.field)">
                    {{ column.header }}
                  </th>

                </ng-container>
                <th style="width: 5%;">
                  操作
                </th>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </ng-container>
    </ng-template>
    <ng-template let-searchResults let-rowIndex="rowIndex" pTemplate="listItem">
      <p-table #table [value]="[searchResults]" styleClass="p-table-striped order-table" selectionMode="single"
        dataKey="delivery_id" expandableRows="true" (onRowClick)="table.toggleRow($event.data)">
        <!-- 一覧画面ボディ表示-->
        <ng-template pTemplate="body">
          <tr class="p-col-2" id="tableResult">
            <!-- チェックボックス -->
            <ng-container *ngFor="let column of columnOrder; index as i" [ngSwitch]="true">
              <td *ngIf="0 == i; else hideContent" [pTooltip]="searchResults[column.field]" #tt
                (mouseenter)="onHover($event)" [tooltipDisabled]="tt.dataset.showtooltip!='true'" data-showTooltip=""
                showDelay="500" hideDelay="500" class="overflow">
                {{ searchResults[column.field] }}
              </td>
              <!-- 一覧項目値表示 -->
              <ng-template #hideContent [ngSwitch]="column?.input_type">
                <td *ngSwitchCase="'NUMBER'" [pTooltip]="searchResults[column.field]" #tt (mouseenter)="onHover($event)"
                  [tooltipDisabled]="tt.dataset.showtooltip!='true'" data-showTooltip="" showDelay="500"
                  hideDelay="500">
                  {{ searchResults[column.field] | number }}
                </td>
                <td *ngIf="
                column?.field != 'order_num' && 
                column?.field != 'vehicle_type' && 
                column?.field != 'delivery_company' && 
                column?.field != 'hope_delivery_date' && 
                column?.field != 'hope_ship_time' && 
                column?.field != 'hope_delivery_time' && 
                column?.field != 'hope_ship_date' && 
                column?.field != 'spot_reason' && 
                column?.field != 'delivery_note' && 
                column?.field != 'delivery_kbn_name' && 
                column?.field != 'vehicle_type'" class="overflow" [pTooltip]="searchResults[column.field]" #tt
                  (mouseenter)="onHover($event)" [tooltipDisabled]="tt.dataset.showtooltip!='true'" data-showTooltip=""
                  showDelay="500" hideDelay="500">
                  {{ searchResults[column.field] }}
                </td>
                <td *ngIf="column?.field == 'delivery_kbn_name'" style="width: 3%;" class="overflow"
                  [pTooltip]="searchResults[column.field]" #tt (mouseenter)="onHover($event)"
                  [tooltipDisabled]="tt.dataset.showtooltip!='true'" data-showTooltip="" showDelay="500"
                  hideDelay="500">
                  {{ searchResults[column.field] }}
                </td>
                <td *ngIf="column?.field == 'order_num'" style="width: 7%;">
                  <input type="number" [(ngModel)]="searchResults[column.field]" style="max-width: 60%;"
                    class="p-inputtext p-component ng-pristine ng-valid ng-touched" id="{{rowIndex + 1 + column.field}}"
                    [placeholder]="getPlaceholder(column.field)" [maxlength]="getMaxlength(column.field)"
                    (keypress)="blockNonInteger($event)">
                </td>
                <td *ngIf="column?.field == 'vehicle_type'" style="width: 10%;">
                  <p-dropdown [options]="getVehicleTypeOption(rowIndex)" optionLabel="sp_name" optionValue="sp_code" [showClear]="true"
                    styleClass="information-Form-dropdown p-datatable-striped master-datatable center"
                    [style]="{'width': '100%'}" [(ngModel)]="searchResults[column.field]"
                    (onChange)="changeVehicleType($event, rowIndex)" id="{{rowIndex + 1 + column.field}}"
                    [placeholder]="getPlaceholder(column.field)" #dd
                    [panelStyle]="{'width':dd?.width?dd.width:'100px'}"></p-dropdown>
                </td>
                <td *ngIf="column?.field == 'delivery_company'" style="width: 15%;">
                  <p-dropdown [options]="getDeliveryCompanyOption(rowIndex)" optionLabel="sp_name" optionValue="sp_code"
                    [showClear]="true"
                    (onChange)="changeDeliveryCompany($event, rowIndex)"
                    styleClass="information-Form-dropdown p-datatable-striped master-datatable center"
                    [style]="{'width': '100%'}" [(ngModel)]="searchResults[column.field]"
                    id="{{rowIndex + 1 + column.field}}" [placeholder]="getPlaceholder(column.field)" #dd
                    [panelStyle]="{'width':dd?.width?dd.width:'100px'}"></p-dropdown>
                </td>
                <td *ngIf="column?.field == 'hope_ship_time'">
                  <p-dropdown [options]="listHopeShipTime" [showClear]="true"
                    styleClass="information-Form-dropdown p-datatable-striped master-datatable center"
                    [style]="{'width': '100%'}" [(ngModel)]="searchResults[column.field]"
                    id="{{rowIndex + 1 + column.field}}" [placeholder]="getPlaceholder(column.field)" #dd
                    [panelStyle]="{'width':dd?.width?dd.width:'100px'}"></p-dropdown>
                </td>
                <td *ngIf="column?.field == 'hope_delivery_date' || column?.field == 'hope_ship_date'"
                  style="width: 11%;">
                  <p-calendar [showIcon]="true" [yearNavigator]="true" [monthNavigator]="true" [readonlyInput]="true"
                    dateFormat="yy/mm/dd" dataType="string" appendTo="body" yearRange="1900:2999"
                    [(ngModel)]="searchResults[column.field]" translate id="{{rowIndex + 1 + column.field}}"
                    [placeholder]="getPlaceholder(column.field)"></p-calendar>
                </td>
                <td *ngIf="column?.field == 'hope_delivery_time'" style="width: 8%;">
                  <input type="time" [(ngModel)]="searchResults[column.field]" style="width: 100%;"
                    class="p-inputtext p-component ng-pristine ng-valid ng-touched" id="{{rowIndex + 1 + column.field}}"
                    [placeholder]="getPlaceholder(column.field)">
                </td>
                <td *ngIf="column?.field == 'spot_reason'">
                  <input type="text" [(ngModel)]="searchResults[column.field]" style="max-width: 100%;"
                    class="p-inputtext p-component ng-pristine ng-valid ng-touched" id="{{rowIndex + 1 + column.field}}"
                    [placeholder]="getPlaceholder(column.field)" [maxlength]="getMaxlength(column.field)">
                </td>
                <td *ngIf="column?.field == 'delivery_note'" style="text-align: center; width: 10%;">
                  <textarea [(ngModel)]="searchResults[column.field]" rows="1" style="max-width: 100%;"
                    class="p-inputtext p-component ng-pristine ng-valid ng-touched" id="{{rowIndex + 1 + column.field}}"
                    [placeholder]="getPlaceholder(column.field)" [maxlength]="getMaxlength(column.field)" rows="2">
                </textarea>
              </ng-template>
            </ng-container>
            <td style="width: 5%;">
              <button pButton icon="pi pi-times" class="p-button-rounded p-button-danger" pTooltip="削除"
                (click)="delete(searchResults['item_id'], searchResults['item_category'], searchResults['delivery_kbn_name'])"
                tooltipPosition="bottom"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
  </p-dataView>

  <!-- 検索画面-->
  <mat-drawer #search class="search-sidenav" mode="side">
    <!-- 検索画面非表示-->
    <button type="button" pButton icon="pi pi-arrow-left" (click)="search.toggle()" class="p-mr-2"></button>

    <!-- 検索画面[検索項目生成(アイテムアダプター)]-->
  </mat-drawer>
</mat-drawer-container>

<!-- メッセージ -->
<app-message-common></app-message-common>

<!-- 削除ダイアログ-->
<app-delete-dialog (reloadID)="moveC111()"></app-delete-dialog>

<!-- Toastメッセージ表示 -->
<p-toast position="top-center"></p-toast>