import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { forkJoin, Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import {
  HEADER_LIST,
  API_URL_RUN_COUNT,
  API_URL_DIC_LISTS,
  API_URL_MULTIVALIDATION_RESULT,
  API_URL_RUN_COUNT_PUB,
} from "manager/http-constants";
import { CommonService } from "./common.service";
import { CONSTANT } from "../constant/constant";
import { REQUEST_IDENTIFIER } from "../html-parts/export-file/constant";
import { LoadingState } from "../html-parts/loading/loading-state";

@Injectable({
  providedIn: "root",
})

/**
 * 共通処理_DB操作
 */
export class DbOperationService {
  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private loadingState: LoadingState
  ) {}

  /**
   * ヘッダー項目取得
   * @param templateId テンプレートID
   * @returns JSONデータ
   */
  public getHeaderList(templateId: number): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(HEADER_LIST, "?TemplateID=", templateId);

    // 取得データの返却
    return this.http
      .get(url, { observe: "response" })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * 辞書情報取得　引数連番値
   * @dicId 辞書ID
   * @returns JSONデータ
   */
  public getDicLists(...dicId: any[]): Observable<any> {
    // 取得データの返却
    return this.http
      .post(
        API_URL_DIC_LISTS,
        { dic_id: dicId.length ? dicId.join() : CONSTANT.EMPTY_STRING },
        { observe: "response" }
      )
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * 辞書情報取得　引数配列用
   * @dicId 辞書ID 配列用
   * @returns JSONデータ
   */
  public getDicList(dicId: any[]): Observable<any> {
    // 取得データの返却
    return this.http
      .post(
        API_URL_DIC_LISTS,
        { dic_id: dicId.length ? dicId.join() : CONSTANT.EMPTY_STRING },
        { observe: "response" }
      )
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * DBデータ検索
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @param searchConditions 検索条件入力値
   * @param getCode コード値取得 (true:コード値取得、false:論理値取得)
   * @param runCount ページ番号
   * @returns JSONデータ
   * ※テンプレートIDの値が1つの場合、通常レスポンス
   * 　FamilyTemplateIdの場合、親と子のレスポンスが返却
   */
  public getData(
    endPoint: string,
    templateId: number | FamilyTemplateId,
    searchConditions?: any,
    getCode?: boolean,
    runCount?: number
  ): Observable<any> {
    // URLに渡すテンプレートID
    let useTemplateId;

    // テンプレートID型判定
    if ("[object Object]" == Object.prototype.toString.call(templateId)) {
      // オブジェクト型の場合

      // FamilyTemplateIdをparentTemplateId,childTemplateIdの文字列に変換
      useTemplateId = Object.values(templateId).join();
    } else {
      // 数値型の場合

      // templateIdを格納
      useTemplateId = templateId;
    }

    // LambdaのEndpointを生成
    const url = this.commonService.url(
      endPoint,
      "?TemplateID=",
      useTemplateId,
      // 検索条件入力値が存在するか否か
      searchConditions
        ? // 検索条件入力値が存在する場合
          CONSTANT.AMPERSAND + new URLSearchParams(searchConditions).toString()
        : // 検索条件入力値が存在しない場合
          CONSTANT.EMPTY_STRING,
      // コード値取得が存在するか否か
      getCode
        ? // コード値取得が存在する場合
          CONSTANT.AMPERSAND + "CodeConvFLAG=1"
        : // コード値取得が存在しない場合
          CONSTANT.AMPERSAND + "CodeConvFLAG=0",
      // ページ番号が存在するか否か
      runCount
        ? // ページ番号が存在する場合
          CONSTANT.AMPERSAND + "RunCount=" + runCount
        : // ページ番号が存在しない場合
          CONSTANT.EMPTY_STRING
    );

    // 一覧情報を返却する
    return this.http
      .get(url, { observe: "response" })
      .pipe(map((res: HttpResponse<any>) => res));
  }
  /**
   * DBデータ検索
   * @param endPoint REST APIエンドポイント
   * @param searchConditions 検索条件入力値
   * @param runCount ページ番号
   * @returns JSONデータ
   * ※テンプレートIDの値が1つの場合、通常レスポンス
   * 　FamilyTemplateIdの場合、親と子のレスポンスが返却
   */
  public postDataNoTemplate(
    endPoint: string,
    searchConditions?: Object,
    runCount?: number
  ): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(endPoint);
    const runcount = { RunCount: runCount };
    const conditionsList = Object.assign(runcount, searchConditions);
    // 一覧情報を返却する
    return this.http
      .post(url, conditionsList, { observe: "response" })
      .pipe(map((res: HttpResponse<any>) => res));
  }
  /**
   * DBデータ検索
   * @param endPoint REST APIエンドポイント
   * @param searchConditions 検索条件入力値
   * @param runCount ページ番号
   * @returns JSONデータ
   * ※テンプレートIDの値が1つの場合、通常レスポンス
   * 　FamilyTemplateIdの場合、親と子のレスポンスが返却
   */
  public getDataNoTemplate(
    endPoint: string,
    searchConditions?: any,
    runCount?: number
  ): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(
      endPoint,
      // 検索条件入力値が存在するか否か
      searchConditions
        ? // 検索条件入力値が存在する場合
        Object.values(searchConditions)
        : // 検索条件入力値が存在しない場合
          CONSTANT.EMPTY_STRING,
      // ページ番号が存在するか否か
      runCount
        ? // ページ番号が存在する場合
          "?RunCount=" + runCount
        : // ページ番号が存在しない場合
          CONSTANT.EMPTY_STRING,
      
    );

    // 一覧情報を返却する
    return this.http
      .get(url, { observe: "response" })
      .pipe(map((res: HttpResponse<any>) => res));
  }
  /**
   * DBデータ検索
   * @param endPoint REST APIエンドポイント
   * @param searchConditions 検索条件入力値
   * @param getCode コード値取得 (true:コード値取得、false:論理値取得)
   * @param runCount ページ番号
   * @returns JSONデータ
   * ※テンプレートIDの値が1つの場合、通常レスポンス
   */
  public gettNoTemplateData(
    endPoint: string,
    searchConditions?: any,
    getCode?: boolean,
    runCount?: number
  ): Observable<any> {
    // URLに渡すテンプレートID
    let useTemplateId;

    // 一覧情報を返却する
    return this.http
      .post(this.commonService.url(endPoint), searchConditions, {
        observe: "response",
      })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * DBデータ検索(分割取得)
   * @param table 対象テーブル
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @param searchConditions 検索条件入力値
   * @param getCode コード値取得 (true:コード値取得、false:論理値取得)
   * @returns JSONデータ
   */
  public getForkJoinData(
    table: string,
    endPoint: string,
    templateId: number,
    searchConditions?: any,
    getCode?: boolean
  ): Subject<any> {
    // 返却用リスト
    let returnResponse = new Subject<any>();

    /* API分割リクエストを生成(DBデータ検索用) */
    this.createForkJoinTask(
      table,
      endPoint,
      templateId,
      searchConditions,
      getCode
    ).subscribe((task) => {
      // 非同期同時実行
      forkJoin(task).subscribe((responseList) => {
        // 返却用レスポンス
        let response: any = new Object();

        // response分ループ
        responseList.forEach((responseData, index) => {
          // ループ回数が0回目か否か
          if (!index) {
            // ループ回数が0回目の場合

            // status等の込みデータを格納
            response = responseData;
          } else {
            // ループ回数が1回目以降の場合

            // コード値の一覧情報が存在するか否か
            if (!this.commonService.checkNoneResponse(responseData)) {
              // コード値の一覧情報が存在する場合

              // 返却用レスポンスにレスポンスボディをマージする
              response.body = response.body.concat(responseData.body);
            }
          }
        });

        // 結合されたresponseを返却
        returnResponse.next(response);
      });
    });

    return returnResponse;
  }
  /**
   * DBデータ検索(分割取得)
   * @param table 対象テーブル
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @param searchConditions 検索条件入力値
   * @param getCode コード値取得 (true:コード値取得、false:論理値取得)
   * @returns JSONデータ
   */
  public getForkJoinDataNoTemplate(
    table: string,
    endPoint: string,
    searchConditions?: any
  ): Subject<any> {
    // 返却用リスト
    let returnResponse = new Subject<any>();

    /* API分割リクエストを生成(DBデータ検索用) */
    this.createForkJoinTaskNoTemplate(
      table,
      endPoint,
      searchConditions
    ).subscribe((task) => {
      // 非同期同時実行
      forkJoin(task).subscribe((responseList) => {
        // 返却用レスポンス
        let response: any = new Object();

        // response分ループ
        responseList.forEach((responseData, index) => {
          // ループ回数が0回目か否か
          if (!index) {
            // ループ回数が0回目の場合

            // status等の込みデータを格納
            response = responseData;
          } else {
            // ループ回数が1回目以降の場合

            // コード値の一覧情報が存在するか否か
            if (!this.commonService.checkNoneResponse(responseData)) {
              // コード値の一覧情報が存在する場合

              // 返却用レスポンスにレスポンスボディをマージする
              response.body = response.body.concat(responseData.body);
            }
          }
        });

        // 結合されたresponseを返却
        returnResponse.next(response);
      });
    });

    return returnResponse;
  }

  /**
 * DBデータ検索(分割取得)
 * @param table 対象テーブル
 * @param endPoint REST APIエンドポイント
 * @param templateId テンプレートID
 * @param searchConditions 検索条件入力値
 * @param getCode コード値取得 (true:コード値取得、false:論理値取得)
 * @returns JSONデータ
 */
  public getForkJoinPubDataNoTemplate(
    table: string,
    endPoint: string,
    searchConditions?: any
  ): Subject<any> {
    // 返却用リスト
    let returnResponse = new Subject<any>();

    /* API分割リクエストを生成(DBデータ検索用) */
    this.createForkJoinTaskPubNoTemplate(
      table,
      endPoint,
      searchConditions
    ).subscribe((task) => {
      // 非同期同時実行
      forkJoin(task).subscribe((responseList) => {
        // 返却用レスポンス
        let response: any = new Object();

        // response分ループ
        responseList.forEach((responseData, index) => {
          // ループ回数が0回目か否か
          if (!index) {
            // ループ回数が0回目の場合

            // status等の込みデータを格納
            response = responseData;
          } else {
            // ループ回数が1回目以降の場合

            // コード値の一覧情報が存在するか否か
            if (!this.commonService.checkNoneResponse(responseData)) {
              // コード値の一覧情報が存在する場合

              // 返却用レスポンスにレスポンスボディをマージする
              response.body = response.body.concat(responseData.body);
            }
          }
        });

        // 結合されたresponseを返却
        returnResponse.next(response);
      });
    });

    return returnResponse;
  }

   /**
   * DBデータ検索(分割取得)
   * @param table 対象テーブル
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @param searchConditions 検索条件入力値
   * @param getCode コード値取得 (true:コード値取得、false:論理値取得)
   * @returns JSONデータ
   */
    public getForkJoinDataNoTemplateLastestRuncount(
      table: string,
      endPoint: string,
      searchConditions?: any
    ): Subject<any> {
      // 返却用リスト
      let returnResponse = new Subject<any>();
  
      /* API分割リクエストを生成(DBデータ検索用) */
      this.createForkJoinTaskNoTemplateLastestRuncount(
        table,
        endPoint,
        searchConditions
      ).subscribe((task) => {
        // 非同期同時実行
        forkJoin(task).subscribe((responseList) => {
          // 返却用レスポンス
          let response: any = new Object();
  
          // response分ループ
          responseList.forEach((responseData, index) => {
            // ループ回数が0回目か否か
            if (!index) {
              // ループ回数が0回目の場合
  
              // status等の込みデータを格納
              response = responseData;
            } else {
              // ループ回数が1回目以降の場合
  
              // コード値の一覧情報が存在するか否か
              if (!this.commonService.checkNoneResponse(responseData)) {
                // コード値の一覧情報が存在する場合
  
                // 返却用レスポンスにレスポンスボディをマージする
                response.body = response.body.concat(responseData.body);
              }
            }
          });
  
          // 結合されたresponseを返却
          returnResponse.next(response);
        });
      });
  
      return returnResponse;
    }
  /**
   * API分割リクエストを生成(DBデータ検索用)
   * @param table 対象テーブル
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @param searchConditions 検索条件入力値
   * @param getCode コード値取得 (true:コード値取得、false:論理値取得)
   * @returns Observable<any>[]
   */
  public createForkJoinTask(
    table: string,
    endPoint: string,
    templateId: number,
    searchConditions?: any,
    getCode?: boolean
  ): Subject<Observable<any>[]> {
    // 返却用非同期同時実行リスト
    let task = new Subject<Observable<any>[]>();

    /* ブロック実行必要回数の取得API */
    this.http
      .get(
        this.commonService.url(
          API_URL_RUN_COUNT,
          "?TableName=",
          table,
          CONSTANT.AMPERSAND,
          "TemplateID=",
          templateId,
          // 検索条件入力値が存在するか否か
          searchConditions
            ? // 検索条件入力値が存在する場合
              CONSTANT.AMPERSAND +
                new URLSearchParams(searchConditions).toString()
            : // 検索条件入力値が存在しない場合
              CONSTANT.EMPTY_STRING
        ),
        { observe: "response" }
      )
      .pipe(map((res: HttpResponse<any>) => res))
      .subscribe((response) => {
        // 非同期同時実行リスト
        let taskList: Observable<any>[] = new Array();

        // 結果ページ数分ループを実施
        for (let runCount = 1; runCount <= response.body.RunCount; runCount++) {
          // 非同期実行リストを生成
          taskList.push(
            /* DBデータ検索 */
            this.getData(
              endPoint,
              templateId,
              searchConditions,
              getCode,
              runCount
            )
          );
        }
        // 非同期同時実行リストを返却
        task.next(taskList);
      });

    return task;
  }
  /**
   * API分割リクエストを生成(DBデータ検索用)
   * @param table 対象テーブル
   * @param endPoint REST APIエンドポイント
   * @param searchConditions 検索条件入力値
   * @returns Observable<any>[]
   */
  public createForkJoinTaskNoTemplate(
    table: string,
    endPoint: string,
    searchConditions?: any
  ): Subject<Observable<any>[]> {
    // 返却用非同期同時実行リスト
    let task = new Subject<Observable<any>[]>();

    /* ブロック実行必要回数の取得API */
    this.http
      .get(
        this.commonService.url(
          API_URL_RUN_COUNT,
          "?TableName=",
          table,
          // 検索条件入力値が存在するか否か
          searchConditions
            ? // 検索条件入力値が存在する場合
              CONSTANT.AMPERSAND +
                new URLSearchParams(searchConditions).toString()
            : // 検索条件入力値が存在しない場合
              CONSTANT.EMPTY_STRING
        ),
        { observe: "response" }
      )
      .pipe(map((res: HttpResponse<any>) => res))
      .subscribe((response) => {
        // 非同期同時実行リスト
        let taskList: Observable<any>[] = new Array();
        if (table === "chat" || table === "chat_detail") {
          // 結果ページ数分ループを実施
          for (
            let runCount = 1;
            runCount <= response.body.RunCount;
            runCount++
          ) {
            // 非同期実行リストを生成
            taskList.push(
              /* DBデータ検索 */
              this.getDataNoTemplate(endPoint, searchConditions, runCount)
            );
          }
        } else {
          // 結果ページ数分ループを実施
          for (
            let runCount = 1;
            runCount <= response.body.RunCount;
            runCount++
          ) {
            // 非同期実行リストを生成
            taskList.push(
              /* DBデータ検索 */
              this.postDataNoTemplate(endPoint, searchConditions, runCount)
            );
          }
        }

        // 非同期同時実行リストを返却
        task.next(taskList);
      });

    return task;
  }

  /**
   * API分割リクエストを生成(DBデータ検索用)
   * @param table 対象テーブル
   * @param endPoint REST APIエンドポイント
   * @param searchConditions 検索条件入力値
   * @returns Observable<any>[]
   */
  public createForkJoinTaskPubNoTemplate(
    table: string,
    endPoint: string,
    searchConditions?: any
  ): Subject<Observable<any>[]> {
    // 返却用非同期同時実行リスト
    let task = new Subject<Observable<any>[]>();

    /* ブロック実行必要回数の取得API */
    this.http
      .get(
        this.commonService.url(
          API_URL_RUN_COUNT_PUB,
          "?TableName=",
          table,
          // 検索条件入力値が存在するか否か
          searchConditions
            ? // 検索条件入力値が存在する場合
              CONSTANT.AMPERSAND +
                new URLSearchParams(searchConditions).toString()
            : // 検索条件入力値が存在しない場合
              CONSTANT.EMPTY_STRING
        ),
        { observe: "response" }
      )
      .pipe(map((res: HttpResponse<any>) => res))
      .subscribe((response) => {
        // 非同期同時実行リスト
        let taskList: Observable<any>[] = new Array();
        if (table === "chat" || table === "chat_detail") {
          // 結果ページ数分ループを実施
          for (
            let runCount = 1;
            runCount <= response.body.RunCount;
            runCount++
          ) {
            // 非同期実行リストを生成
            taskList.push(
              /* DBデータ検索 */
              this.getDataNoTemplate(endPoint, searchConditions, runCount)
            );
          }
        } else {
          // 結果ページ数分ループを実施
          for (
            let runCount = 1;
            runCount <= response.body.RunCount;
            runCount++
          ) {
            // 非同期実行リストを生成
            taskList.push(
              /* DBデータ検索 */
              this.postDataNoTemplate(endPoint, searchConditions, runCount)
            );
          }
        }

        // 非同期同時実行リストを返却
        task.next(taskList);
      });

    return task;
  }
  /**
   * API分割リクエストを生成(DBデータ検索用)
   * @param table 対象テーブル
   * @param endPoint REST APIエンドポイント
   * @param searchConditions 検索条件入力値
   * @returns Observable<any>[]
   */
   public createForkJoinTaskNoTemplateLastestRuncount(
    table: string,
    endPoint: string,
    searchConditions?: any
  ): Subject<Observable<any>[]> {
    // 返却用非同期同時実行リスト
    let task = new Subject<Observable<any>[]>();

    /* ブロック実行必要回数の取得API */
    this.http
      .get(
        this.commonService.url(
          API_URL_RUN_COUNT,
          "?TableName=",
          table,
          // 検索条件入力値が存在するか否か
          searchConditions
            ? // 検索条件入力値が存在する場合
              CONSTANT.AMPERSAND +
                new URLSearchParams(searchConditions).toString()
            : // 検索条件入力値が存在しない場合
              CONSTANT.EMPTY_STRING
        ),
        { observe: "response" }
      )
      .pipe(map((res: HttpResponse<any>) => res))
      .subscribe((response) => {
        // 非同期同時実行リスト
        let taskList: Observable<any>[] = new Array();
          // 結果ページ数分ループを実施
            // 非同期実行リストを生成
            taskList.push(
              /* DBデータ検索 */
              this.getDataNoTemplate(endPoint, searchConditions, 1))
       
        // 非同期同時実行リストを返却
        task.next(taskList);
      });

    return task;
  }
  /**
   * DBデータを1件返却
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @param code コードカラム
   * @param codeValue コード値
   */
  public getSingleData(
    endPoint: string,
    templateId: number | FamilyTemplateId,
    code: string,
    codeValue: string | number
  ): Observable<any> {
    // URLに渡すテンプレートID
    let useTemplateId;

    // テンプレートID型判定
    if ("[object Object]" == Object.prototype.toString.call(templateId)) {
      // オブジェクト型の場合

      // FamilyTemplateIdをparentTemplateId,childTemplateIdの文字列に変換
      useTemplateId = Object.values(templateId).join();
    } else {
      // 数値型の場合

      // templateIdを格納
      useTemplateId = templateId;
    }

    // LambdaのEndpointを格納先
    const url = this.commonService.url(
      endPoint,
      "?TemplateID=",
      useTemplateId,
      CONSTANT.AMPERSAND,
      code,
      CONSTANT.EQUAL,
      codeValue
    );

    // 一覧情報を返却する
    return this.http
      .get(url, { observe: "response" })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * DBデータ検索 ※テンプレート未使用
   * @param endPoint REST APIエンドポイント
   * @param searchConditions 検索条件入力値
   * @param method true:POST通信、false:GET通信
   * @returns JSONデータ
   * ※テンプレートIDの値が1つの場合、通常レスポンス
   */
  public getNoTemplateData(
    endPoint: string,
    searchConditions?: any,
    method?: boolean
  ): Observable<any> {
    // メソッド判定
    if (method) {
      // POST通信の場合

      // 一覧情報を返却する
      return this.http
        .post(endPoint, searchConditions, { observe: "response" })
        .pipe(map((res: HttpResponse<any>) => res));
    } else {
      // GET通信の場合
      // LambdaのEndpointを格納先
      const url = this.commonService.url(
        endPoint,
        // 検索条件入力値が存在するか否か
        searchConditions
          ? // 検索条件入力値が存在する場合
            CONSTANT.QUESTION + new URLSearchParams(searchConditions).toString()
          : // 検索条件入力値が存在しない場合
            CONSTANT.EMPTY_STRING
      );
      // 一覧情報を返却する
      return this.http
        .get(url, { observe: "response" })
        .pipe(map((res: HttpResponse<any>) => res));
    }
  }

  

  // TODO 後で共通化
  /**
   * 更新　HTTP PUT メソッドを実行する
   *
   * @param endPoint REST APIエンドポイント
   * @param data 選択データ
   * @returns JSONデータ
   */
  public editData(
    endPoint: string,
    pkeyId: string | number,
    data: string
  ): Observable<any> {
    // LambdaのEndpointを格納先
    const url = this.commonService.url(endPoint, pkeyId);

    return this.http
      .put(url, data, { observe: "response" })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * DBデータを1件返却(詳細レスポンス)
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @param pkeyId IDコード
   */
  public getDisplayData(
    endPoint: string,
    templateId: number,
    pkeyId: string | number
  ): Observable<any> {
    // LambdaのEndpointを格納先
    const url = this.commonService.url(
      endPoint,
      pkeyId,
      "?TemplateID=",
      templateId
    );

    // 詳細情報を返却する
    return this.http
      .get(url, { observe: "response" })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * DBデータを1件返却(詳細レスポンス) ※テンプレート未使用
   * @param endPoint REST APIエンドポイント
   * @param pkeyId IDコード
   * @param method true:POST通信、false:GET通信
   */
  public getNoTemplateDisplayData(
    endPoint: string,
    pkeyId: string | number,
    method?: boolean
  ): Observable<any> {
    // LambdaのEndpointを格納先
    const url = this.commonService.url(endPoint, pkeyId);

    // メソッド判定
    if (method) {
      // POST通信の場合

      // 詳細情報を返却する
      return this.http
        .post(url, null, { observe: "response" })
        .pipe(map((res: HttpResponse<any>) => res));
    } else {
      // GET通信の場合

      // 詳細情報を返却する
      return this.http
        .get(url, { observe: "response" })
        .pipe(map((res: HttpResponse<any>) => res));
    }
  }
    /**
   * DBデータを1件返却(詳細レスポンス) ※テンプレート未使用
   * @param endPoint REST APIエンドポイント
   * @param pkeyId IDコード
   */
    public getNoTemplateDisplayDataMultipleArgPost(
      endPoint: string,
      // method?: boolean,
      ...pkeyId: string[] | number[]
    ): Observable<any> {
      // LambdaのEndpointを格納先
      const url = this.commonService.url(endPoint, ...pkeyId);

      return this.http
        .get(url, { observe: "response" })
        .pipe(map((res: HttpResponse<any>) => res));
    }

  /**
   * 入力項目値バリデーションチェック
   * @param tableId テーブル物理名
   * @param inputItem 入力データ
   * @returns JSONデータ
   */
  multiValidationResult(tableId: string, inputItem: object): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(API_URL_MULTIVALIDATION_RESULT, tableId);

    // 取得データの返却
    return this.http
      .post(url, inputItem, { observe: "response" })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * 業務入力項目値バリデーションチェック
   * @param endPoint 業務入力項目値バリデーションチェックエンドポイント
   * @param pkeyId 対象データのID
   * @param inputItem 入力データ
   * @returns JSONデータ
   */
  workValidationResult(
    endPoint: string,
    pkeyId: string,
    inputItem: object
  ): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(endPoint, pkeyId);

    // 取得データの返却
    return this.http
      .post(url, inputItem, { observe: "response" })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * DBデータ登録
   * @param endPoint REST APIエンドポイント
   * @param insertData 登録データ
   * @returns JSONデータ
   */
  public insertData(endPoint: string, data: object): Observable<any> {
    // 登録結果を返却する
    return this.http
      .post(this.commonService.url(endPoint), data, { observe: "response" })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * DBデータ更新(1件)
   * @param endPoint REST APIエンドポイント
   * @param pkeyId 対象データのID
   * @param updateData 更新データ
   * @returns JSONデータ
   */
  public updateData(
    endPoint: string,
    pkeyId: string | number,
    updateData?: object
  ): Observable<any> {
    // LambdaのEndpointを格納先
    const url = this.commonService.url(endPoint, pkeyId);

    // 更新結果を返却する
    return this.http
      .put(url, updateData ? updateData : new Object(), { observe: "response" })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * DBデータ更新(複数件)
   * @param endPoint REST APIエンドポイント
   * @param updateData 更新データ
   * @returns JSONデータ
   */
  public updateMultipleData(
    endPoint: string,
    updateData: object
  ): Observable<any> {
    // LambdaのEndpointを格納先
    const url = this.commonService.url(endPoint);

    // 更新結果を返却する
    return this.http
      .put(url, updateData, { observe: "response" })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * DBデータ削除(1件)
   * @param endPoint REST APIエンドポイント
   * @param pkeyId 対象データのID
   * @returns JSONデータ
   */
  public deleteData(
    endPoint: string,
    pkeyId: string | number
  ): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(endPoint, pkeyId);

    // 削除結果を返却する
    return this.http
      .delete(url, { observe: "response" })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * ファイル登録
   * @param endPoint APIエンドポイント
   * @param fileName 登録するファイル名
   * @param file ファイル情報
   */
  public insertFile(
    endPoint: string,
    fileName: string,
    file: any
  ): Subject<string> {
    // ファイルID
    let fileId = new Subject<string>();

    // ファイル情報(base64)取得処理
    this.commonService.getBase64(file).then((fileInformation) => {
      // ファイル情報(base64)が返却後、処理を続行

      // ファイル登録を実施
      this.http
        .post(
          endPoint,
          {
            filename: fileName,
            base64enc: fileInformation.replace(/^data:.*?;base64,/, ""),
          },
          { observe: "response" }
        )
        .pipe(map((res: HttpResponse<any>) => res))
        .subscribe((response) => {
          // ファイルIDを設定
          fileId.next(response.body.file_id);
        });
    });

    // ファイルIDを返却
    return fileId;
  }

  /**
   * ファイル取得(base64形式)
   * @param endPoint REST APIエンドポイント
   * @param request リクエスト形式
   * @param searchConditions 検索条件入力値
   * @returns BASE64データ
   */
  public getFile(
    endPoint: string,
    request: string,
    searchConditions?: any
  ): Observable<any> {
    // LambdaのEndpointを格納
    let url;

    // リクエスト形式がPOSTか否か
    if (REQUEST_IDENTIFIER.POST == request) {
      // リクエスト形式がPOSTの場合

      // ファイル情報(base64形式)を返却する
      return this.http
        .post(url, searchConditions, {
          observe: "response",
          responseType: "text",
        })
        .pipe(map((res: HttpResponse<any>) => res));
    }

    // リクエスト形式がGETの場合

    // 検索条件入力値が存在しているか否か
    if (searchConditions) {
      // 検索条件入力値が存在している場合

      // 検索項目値でURLを生成
      url = this.commonService.url(
        endPoint,
        CONSTANT.QUESTION,
        new URLSearchParams(searchConditions).toString()
      );
    } else {
      // URLを生成
      url = this.commonService.url(endPoint);
    }

    // 一覧情報を返却する
    return this.http
      .get(url, { observe: "response", responseType: "text" })
      .pipe(map((res: HttpResponse<any>) => res));
  }

}

/** 親子レスポンス_テンプレートオブジェクト */
export class FamilyTemplateId {
  // 親テンプレート
  private _parentTemplateId: number = 0;

  // 子テンプレート
  private _childTemplateId: number;

  constructor(init?: Partial<FamilyTemplateId>) {
    Object.assign(this, init);
  }

  set parentTemplateId(parentTemplateId: number) {
    this._parentTemplateId = parentTemplateId;
  }

  get parentTemplateId(): number {
    return this._parentTemplateId;
  }

  set childTemplateId(childTemplateId: number) {
    this._childTemplateId = childTemplateId;
  }

  get child(): number {
    return this._childTemplateId;
  }
}
