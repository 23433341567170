import { Component, OnInit, ViewChild } from '@angular/core';
import { GenerateDisplayComponent } from 'src/app/shared/generate/generate-display/generate-display.component';
import { MessageCommonComponent } from 'src/app/shared/html-parts/message-common/message-common.component';
import { CONSTANT, DELIVERY_COLLECT_KBN, LOADING_KEY } from 'src/app/shared/constant/constant';
import { LoadData, LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import { ExportFileService } from 'src/app/shared/html-parts/export-file/export-file.service';
import { DatePipe } from '@angular/common';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CXXX_TEMPLATE } from 'manager/template-constant';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { DataView } from 'primeng/dataview';
import {
    API_URL_WORK_DELIVERY_DETAIL,
    API_URL_PREVIEW_WORK_DELIVERY_DETAIL,
    API_URL_DELETE_WORK,
    API_URL_INPUT_MASTER,
} from 'manager/http-constants';
import { DeleteDialogComponent } from 'src/app/shared/html-parts/delete-dialog/delete-dialog.component';
import { MessageData } from 'src/app/shared/html-parts/message-common/message-data';
import { ConfirmationService } from 'primeng/api';

@Component({
    selector: 'app-cxxx',
    templateUrl: './cxxx.component.html',
    styleUrls: ['./cxxx.component.scss']
})
export class CXXXComponent implements OnInit {
    /** 詳細画面 */
    @ViewChild(GenerateDisplayComponent)
    generateDisplayItemsComponent: GenerateDisplayComponent;

    /** メッセージ */
    @ViewChild(MessageCommonComponent)
    messageCommonComponent: MessageCommonComponent;

    @ViewChild('dv')
    dv: DataView;
    // 検索項目生成エンドポイント

    /** 削除ダイアログ */
    @ViewChild(DeleteDialogComponent)
    deleteDialogComponent: DeleteDialogComponent;

    /* 画面用プロパティ */
    // 画面ヘッダー情報格納先
    columnOrder: any[];

    // 画面検索結果一覧格納先
    searchResultsList: any[];

    // list checked of delivery-search
    searchCheckbox: any[];

    // ページ数
    pages: number = 0;

    delivery_collect_kbn: string = DELIVERY_COLLECT_KBN.DELIVERY_0;

    delivery_kbn: number = 4;

    // カート選択リスト格納先
    cartSelected: any[] = [];

    // checked value of items search
    checkedArr: any[];

    // dataView ソートカラム名
    sortField: string;

    // dataView ソート順 (1:昇順, -1:降順)
    sortOrder: number;

    errorMessages: {
        index: number;
        columnId: string;
        columnName: string;
        detail: string;
    }[] = [];

    dispErrors: boolean = false;

    generateInputItems: any[];

    existData: boolean = false;

    constructor(
        private dbOperationService: DbOperationService,
        private router: Router,
        private commonService: CommonService,
        public datePipe: DatePipe,
        private loadingState: LoadingState,
        private messageData: MessageData,
        private exportFileService: ExportFileService,
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
    ) { }

    ngOnInit() {
        // 画面ロードフラグをON(ロード中状態)
        this.loadingState.loadStart();

        const { delivery_collect_kbn } = this.route.snapshot.queryParams;
        if (delivery_collect_kbn) {
            this.delivery_collect_kbn = delivery_collect_kbn;
        } else {
            this.router.navigate(['/pages/platform/cxxx'], { queryParams: { delivery_collect_kbn: DELIVERY_COLLECT_KBN.DELIVERY_0 } });
        }

        // ヘッダー情報取得処理(画面用)
        this.dbOperationService
            .getHeaderList(CXXX_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID)
            .subscribe((response) => {
                this.columnOrder = response.body;
            });

        // 検索処理を実施
        this.searchResult();

        this.getGenerateInput();

        this.dispErrors = false;
    }

    /**
     * 検索処理
     * @param table テーブル状態
     */
    public searchResult(table?: DataView) {
        // 画面カスタムロードフラグをON(ロード中状態)
        this.loadingState.customLoadStart(
            new LoadData({
                loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
            })
        );

        // CSV出力用に検索条件を保持
        // 販促資材マスタ一覧取得処理(画面用)
        this.dbOperationService
            .getNoTemplateData(
                API_URL_WORK_DELIVERY_DETAIL,
                {
                    delivery_collect_kbn: [this.delivery_collect_kbn]
                }
            )
            .subscribe((response) => {
                // コード値の一覧情報が存在するか否か
                if (!this.commonService.checkNoneResponse(response)) {
                    // 販促資材マスタ一覧情報のJSONをオブジェクトに格納する
                    this.searchResultsList = response.body;
                    this.existData = true;
                } else {
                    this.dbOperationService
                        .getNoTemplateData(
                            API_URL_PREVIEW_WORK_DELIVERY_DETAIL,
                            {
                                delivery_collect_kbn: [this.delivery_collect_kbn]
                            }
                        )
                        .subscribe((response) => {
                            // コード値の一覧情報が存在するか否か
                            if (!this.commonService.checkNoneResponse(response)) {
                                this.searchResultsList = response.body;
                                this.dv.first = 0;
                            } else {
                                this.searchResultsList = [];
                            }
                        });
                }
                this.loadingState.loadSleepEnd(0.3);
                // 画面ロードフラグをOFF(ロード終了)
            });
        // ページ数を初期化
        this.pages = 0;
    }

    /**
     * カート追加
     */
    public addCart() {
        const requestBody = {
            delivery_collect_kbn: this.delivery_collect_kbn,
            division_name_from: this.searchResultsList[0].division_name_from,
            user_name_from: this.searchResultsList[0].user_name_from,
            note_from: this.searchResultsList[0].note_from,
            division_name_to: this.searchResultsList[0].division_name_to,
            user_name_to: this.searchResultsList[0].user_name_to,
            note_to: this.searchResultsList[0].note_to,
            use_spot: this.searchResultsList[0].use_spot ? "1" : "0",
        }

        if (this.searchResultsList[0].delivery_kbn_id_from == '3' || this.searchResultsList[0].delivery_kbn_id_to == '3' || !this.searchResultsList[0].use_spot) {
            // 画面カスタムロードフラグをON(ロード中状態)
            this.loadingState.customLoadStart(
                new LoadData({
                    loadingText: CONSTANT.EMPTY_STRING,
                    background_color: CONSTANT.EMPTY_STRING,
                    opacity: 0.3,
                }),
                LOADING_KEY.DATA_INSERT
            );

            // カート登録
            this.dbOperationService
                .insertData(API_URL_WORK_DELIVERY_DETAIL, requestBody)
                .subscribe((response) => {
                    // 画面ロードフラグをOFF(ロード終了)
                    this.loadingState.loadEnd(LOADING_KEY.DATA_INSERT);
                    this.clearErrorCss();
                    this.messageCommonComponent.clearToastMessage();

                    // レスポンスを判定
                    if (this.messageCommonComponent.responseToastMessage(response)) {
                        // 発注管理_届先選択へ遷移
                        this.router.navigate(['pages/platform/c141'], { queryParams: { delivery_collect_kbn: this.delivery_collect_kbn } });
                    }
                    else {
                        this.callBackOnAddOrderError(response.body);
                    }
                });
        } else {
            this.confirmationService.confirm({
                message: this.commonService.msg(
                    MESSAGE_CODE.D00012,
                ),
                accept: () => {
                    // 画面カスタムロードフラグをON(ロード中状態)
                    this.loadingState.customLoadStart(
                        new LoadData({
                            loadingText: CONSTANT.EMPTY_STRING,
                            background_color: CONSTANT.EMPTY_STRING,
                            opacity: 0.3,
                        }),
                        LOADING_KEY.DATA_INSERT
                    );

                    // カート登録
                    this.dbOperationService
                        .insertData(API_URL_WORK_DELIVERY_DETAIL, requestBody)
                        .subscribe((response) => {
                            // 画面ロードフラグをOFF(ロード終了)
                            this.loadingState.loadEnd(LOADING_KEY.DATA_INSERT);
                            this.clearErrorCss();
                            this.messageCommonComponent.clearToastMessage();
                            // レスポンスを判定
                            if (this.messageCommonComponent.responseToastMessage(response)) {
                                // 発注管理_届先選択へ遷移
                                this.router.navigate(['pages/platform/c141'], { queryParams: { delivery_collect_kbn: this.delivery_collect_kbn } });
                            }
                            else {
                                this.callBackOnAddOrderError(response.body);
                            }
                        });
                },
                reject: () => {
                    for (const element of this.searchResultsList) {
                        element.use_spot = false;
                    }
                },
            });
        }
    }

    clearErrorCss() {
        const keys = Object.keys(this.searchResultsList[0]);
        let index = 1;
        for (let i = 0; i < this.searchResultsList.length; i++) {
            for (let j = 0; j < keys.length; j++) {
                const id = index + keys[j]
                let element = document.getElementById(id);
                if (element) {
                    if (element.nodeName === 'P-DROPDOWN') {
                        element = element.getElementsByTagName('div')[0];
                    } else if (element.nodeName === 'P-CALENDAR') {
                        element = element.getElementsByTagName('span')[0];
                    }
                    element.style.setProperty('border', '1px solid #ced4da');
                    element.style.setProperty('border-radius', '3px');
                }
            }
            index++;
        }
    }

    callBackOnAddOrderError(error: any) {
        this.dispErrors = true;
        // formControlにエラーを入れる作戦はページングでいったり来たりするとinvalid扱いにならないことから断念。
        this.errorMessages = error.map((e) => {
            if (e.row_no && e.column_id) {
                const index = e.row_no;
                const columnId = e.column_id;
                const { message } = e;
                const colName = e.column_name;
                return { index, columnId, columnName: colName, detail: message };
            } else {
                return {
                    index: null,
                    columnId: null,
                    columnName: null,
                    detail: e.message,
                };
            }
        });

        for (const error of this.errorMessages) {
            let element = document.getElementById(error.index + error.columnId);
            if (element) {
                if (element.nodeName === 'P-DROPDOWN') {
                    element = element.getElementsByTagName('div')[0];
                } else if (element.nodeName === 'P-CALENDAR') {
                    element = element.getElementsByTagName('span')[0];
                }
                element.style.setProperty('border', '1px solid red');
                element.style.setProperty('border-radius', '3px');
            }
        }
    }

    /**
     * Download PDF file
     */
    public downloadPdf(filePath: string) {
        window.location.href = filePath;
    }

    /**
   * 削除処理
   * @param pkeyId IDコード(クリックされたID)
   */
    delete(customer_name_from: string, customer_name_to: string) {
        if (customer_name_from.length > 20) {
            customer_name_from = customer_name_from.substring(0, 17) + '...'
        }
        if (customer_name_to.length > 20) {
            customer_name_to = customer_name_to.substring(0, 17) + '...'
        }
        const title = "荷物";
        const content = "差出名称: " + customer_name_from + "<br>届先名称: " + customer_name_to
        // 削除ダイアログにより削除処理
        this.deleteDialogComponent.deleteDialog(
            API_URL_DELETE_WORK,
            content,
            this.delivery_collect_kbn,
            title
        );
    }

    moveC121() {
        this.router.navigate(['pages/platform/c121'], { queryParams: { delivery_collect_kbn: this.delivery_collect_kbn } });
    }

    /**
     * check overflow of element
     * @param el html element of list 
     * @returns 
    */
    isOverflow(el: HTMLElement): boolean {
        return el.clientWidth < el.scrollWidth;
    }

    onHover(e) {
        if (this.isOverflow(e.target)) {
            e.target.dataset.showtooltip = 'true'
        }
    }

    public getGenerateInput() {
        this.dbOperationService
            .getNoTemplateData(
                API_URL_INPUT_MASTER,
                {
                    TemplateID: CXXX_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID
                }
            )
            .subscribe((response) => {
                if (!this.commonService.checkNoneResponse(response)) {
                    this.generateInputItems = response.body;
                } else {
                    this.generateInputItems = [];
                }
            });
    }

    getPlaceholder(column_id: string) {
        return this.generateInputItems.find(x => x.column_id == column_id).column_memo;
    }

    getMaxlength(column_id: string) {
        return this.generateInputItems.find(x => x.column_id == column_id).column_hsize;
    }
}
