import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { CommonService } from '../service/common.service';
import { map } from 'rxjs/operators';
import { CONFIRM_FORGOT_PASSWORD, FORGOT_PASSWORD, LOGIN } from 'manager/http-constants';
import { LoginService } from '../service/login.service';
import { MESSAGE_CODE } from '../constant/message-constant';
import { SESSION_KEY } from '../constant/session-constants';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-new-login',
  templateUrl: './new-login.component.html',
  styleUrls: ['./new-login.component.scss'],
  animations: [
    trigger('onOff', [
      transition(':enter', [style({
        opacity: 0,
        transform: 'translatex(-10%)'
      }),
      animate(200)
    ])
    ]),
    // trigger('loading', [
    //   state('void', style({ opacity: 0 })),
    //   state('*', style({ opacity: 1 })),
    //   transition('void => *', [animate('0.5s 0.5s ease-in')]),
    //   transition('* => void', [animate('0.5s ease-in')])
    // ])
 ]
})
export class NewLoginComponent implements OnInit {
  loginForm: FormGroup = this.formBuilder.group({
    email: this.formBuilder.control('', [Validators.required]),
    password: this.formBuilder.control('', [Validators.required]),
  });
  forgotPasswordForm: FormGroup = this.formBuilder.group({
    email: this.formBuilder.control('',[this.userEmailValidator()])
  });
  changePasswordForm: FormGroup = this.formBuilder.group({
    email: this.formBuilder.control('', [Validators.required]),
    code: this.formBuilder.control('', [Validators.required]),
    password: this.formBuilder.control('', [Validators.required]),
    re_password: this.formBuilder.control('', [Validators.required]),
  });

  title:string = 'ログイン';
  loginButtonLabel: string = 'ログイン';
  Modes:any[]=[
    {
      code:0,
      buttonTitle:'ログイン',
      title:'ログイン'
    },
    {
      code:1,
      buttonTitle:'パスワードを更新する',
      title:'パスワードを忘れですか？'
    },
    {
      code:2,
      buttonTitle:'パスワードを更新する',
      title:'パスワード変更'
    },
  ];
  msgTypes:any[]=[
    {code:0,name:'success'},
    {code:1,name:'warn'},
    {code:2,name:'error'},
  ]
  message:string=''
  messageType:number=0;
  screenMode = 0;
  isLoading = false;
  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    public commonService: CommonService,
    private loginService: LoginService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {

  }
  changeScreenMode(code:number){
    if (this.screenMode == 0) {
      this.forgotPasswordForm.controls.email.setValue(this.loginForm.controls.email.value)
    }
    if(code != this.Modes[2].code){
      this.changePasswordForm.reset();
    }
    this.screenMode = this.Modes[code].code;
    this.title = this.Modes[code].title;
    this.loginButtonLabel = this.Modes[code].buttonTitle;
    this.message = "";
  }
  forgetPassword(){
    if(this.forgotPasswordForm.controls.email.value.match(/^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)){
      this.excuteForgetPassword().subscribe(
        (_data) => {
          this.isLoading = false
          this.changePasswordForm.controls.email.setValue(this.forgotPasswordForm.controls.email.value)
          this.changeScreenMode(2);
        },
        (_error) => {
          // TODO
          this.isLoading = false
          this.messageType = 2
          this.message = ""
        }
      );
    }else{
      this.isLoading = false
      this.messageType = 2
      this.message = "半角英数字，記号（-_+.@）のみ入力可能です。　（例） hoge_o@hoge.com"
    }
  }
  excuteForgetPassword(): Observable<any> {
    this.isLoading = true;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let options = { headers: headers };
    // ログインユーザ情報を返却する
    return this.http
      .post(this.commonService.url(FORGOT_PASSWORD), this.forgotPasswordForm.value, options)
      .pipe(map((data: any) => data));
  }
  changePassword(){
    this.excuteChangePassword().subscribe(
      (_data) => {
        this.isLoading = false
        this.changeScreenMode(0);
        this.messageType = 0
        this.message = "パスワードが更新されました。"
      },
      (_error) => {
        // TODO
        this.isLoading = false
        this.messageType = 2
        this.message = "パスワード変更に失敗しました"
      }
    );
  }
  excuteChangePassword(): Observable<any> {
    this.isLoading = true;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let options = { headers: headers };
    // ログインユーザ情報を返却する
    return this.http
      .post(this.commonService.url(CONFIRM_FORGOT_PASSWORD), this.changePasswordForm.value, options)
      .pipe(map((data: any) => data));
  }
  checkDisabledMainButton(){
    let form: FormGroup = null;
    if (this.screenMode==this.Modes[0].code) {
      form = this.loginForm;
    }
    if (this.screenMode==this.Modes[1].code) {
      form = this.forgotPasswordForm;
    }
    if (this.screenMode==this.Modes[2].code) {
      form = this.changePasswordForm;
    }
    return form.valid;
  }
  login(){
    this.excuteLogin().subscribe(
      (data) => {
        this.loginService.setCredentials(data.IdToken);
        this.getLoginUser()
      },
      (_error) => {
        this.isLoading = false
        this.messageType = 2
        this.message = "メールアドレスもしくはパスワードが間違っています。"
      }
    );
  }

  excuteLogin(): Observable<any> {
    this.isLoading = true;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let options = { headers: headers };
    // ログインユーザ情報を返却する
    return this.http
      .post(this.commonService.url(LOGIN), this.loginForm.value, options)
      .pipe(map((data: any) => data));
  }
  
  getLoginUser() {
    this.loginService.getLoginUser().subscribe((response) => {
      if (this.commonService.checkNoneResponse(response)) {
        this.loginService.logout(MESSAGE_CODE.E90000);
        this.isLoading = false
        return;
      } else {
        window.sessionStorage.setItem(SESSION_KEY.loginUserInformation, JSON.stringify(response.body));
        this.isLoading = false
        if (this.route.snapshot.queryParams.redirect) {
          window.location.href = this.route.snapshot.queryParams.redirect
        } else {
          window.location.href = window.location.origin + '/top'
        }
      }
    });
  }
  /**
   * パスワードチェック
   * @param input true:画面入力 false:初回
   */
  public checkCode(input?: boolean): void {
    // 画面入力されたか否か
    if (input) {
      // 画面入力された場合

      // 変更検知をtrueにする(エラーメッセージ表示有無フラグ)
      this.changePasswordForm.get('code').markAsDirty();
    }

    // 画面.入力フォーム.パスワードを取得
    const code = this.changePasswordForm.get('code').value;

    // 画面.入力フォーム.パスワードが未入力か否か
    if (!code) {
      // 未入力の場合

      // 必須項目エラー
      this.changePasswordForm.controls['code'].setErrors({
        message: '必須項目です。必ず入力してください。',
      });

      return;
    }
    return;
  }

  /**
   * パスワードチェック
   * @param input true:画面入力 false:初回
   */
  public checkPassword(input?: boolean): void {
    // 画面入力されたか否か
    if (input) {
      // 画面入力された場合

      // 変更検知をtrueにする(エラーメッセージ表示有無フラグ)
      this.changePasswordForm.get('password').markAsDirty();
    }

    // 画面.入力フォーム.パスワードを取得
    const password = this.changePasswordForm.get('password').value;

    // 画面.入力フォーム.パスワードが未入力か否か
    if (!password) {
      // 未入力の場合

      // 必須項目エラー
      this.changePasswordForm.controls['password'].setErrors({
        message: '必須項目です。必ず入力してください。',
      });

      return;
    }

    // 文字列が以下に一致するか判定
    // 少なくとも1つの小文字
    // 少なくとも1つの大文字
    // 少なくとも1つの数値
    // 8文字以上
    if (
      !password.match(
        '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})[a-zA-Z0-9]+$'
      )
    ) {
      // 一致しない場合

      // パスワード設定エラー
      this.changePasswordForm.controls['password'].setErrors({
        message: 'パスワードが設定できません',
      });

      return;
    }

    // エラーメッセージを空にする
    this.changePasswordForm.controls['password'].setErrors(null);

    // 確認パスワードチェックを実施
    this.checkRePassword();

    return;
  }
  
  /**
   * 確認パスワードチェック
   * @param input true:画面入力 false:初回
   */
  public checkRePassword(input?: boolean): void {
    // 画面入力されたか否か
    if (input) {
      // 画面入力された場合

      // 変更検知をtrueにする(エラーメッセージ表示有無フラグ)
      this.changePasswordForm.get('re_password').markAsDirty();
    }

    // 画面.入力フォーム.パスワード確認を取得
    const re_password = this.changePasswordForm.get(
      're_password'
    ).value;

    // 画面.入力フォーム.パスワード確認が未入力か否か
    if (!re_password) {
      // 未入力の場合

      // 必須項目エラー
      this.changePasswordForm.controls['re_password'].setErrors({
        message: '必須項目です。必ず入力してください。',
      });

      return;
    }

    // 画面.入力フォーム.パスワード確認がパスワードと同様か確認
    if (this.changePasswordForm.get('password').value != re_password) {
      // 同様でない場合
      this.changePasswordForm.controls['re_password'].setErrors({
        message: 'パスワードの値と一致していません',
      });
      return;
    }else{
      // エラーメッセージを空にする
      this.changePasswordForm.controls['re_password'].setErrors(null);
      return;
      
    }
  }

  userEmailValidator(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
          return null;
      }

      const matched = control.value.match(/^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);

      return !matched ? {message: '半角英数字，記号（-_+.@）のみ入力可能です。　（例） hoge_o@hoge.com'}: null;
    }
  }
}
