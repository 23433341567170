import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DbOperationService } from '../../service/db-operation.service';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { CommonService } from '../../service/common.service';
import { SCREEN_CONSTANT, CONSTANT, LOADING_KEY } from 'src/app/shared/constant/constant';
import { LoadData, LoadingState } from '../loading/loading-state';
import { MessageData, ToastMessageData } from '../message-common/message-data';
import { Location } from "@angular/common";

@Component({
  providers: [ConfirmationService],
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
})

/**
 * 削除ダイアログ
 */
export class DeleteDialogComponent implements OnInit {
  constructor(
    private confirmationService: ConfirmationService,
    private dbOperationService: DbOperationService,
    private commonService: CommonService,
    private messageService: MessageService,
    private loadingState: LoadingState,
    private messageData: MessageData,
    private location: Location,
  ) { }

  ngOnInit(): void { }

  // 更新対象ID
  @Output() reloadID = new EventEmitter<string>();

  /**
   * 削除処理
   * @param endPoint REST APIエンドポイント
   * @param codeName コード名
   * @param codeValue コード値
   */
  public deleteDialog(endPoint: string, codeName: string, codeValue: string, title: string) {
    let messageCode = MESSAGE_CODE.D00002;
    let messageCodeForToastSuccess = MESSAGE_CODE.D00001;
    if (title == 'キャンセルしてもよろしいですか?') {
      messageCode = MESSAGE_CODE.D00013;
      messageCodeForToastSuccess = MESSAGE_CODE.D00015;
    } else if (window.location.pathname.toLowerCase() == "/pages/platform/cxxx" || window.location.pathname.toLowerCase() == "/pages/platform/c141") {
      messageCode = MESSAGE_CODE.D00014;
    }
    this.confirmationService.confirm({
      message: this.commonService.msg(messageCode, codeName, codeValue, title),
      header: CONSTANT.EMPTY_ONE_STRING,
      icon: 'pi pi-info-circle',
      accept: () => {
        // 画面カスタムロードフラグをON(ロード中状態)
        this.loadingState.customLoadStart(
          new LoadData({
            loadingText: '',
            background_color: '',
            opacity: 0.3,
          }),
          LOADING_KEY.DELETE
        );

        this.dbOperationService
          .deleteData(endPoint, codeValue)
          .subscribe((response) => {
            // APIレスポンスの判定
            if (this.commonService.checkInvalidValue(response)) {
              this.messageData.toastMessage(
                new ToastMessageData({
                  severity: TOAST.WARN,
                  summary: this.commonService.msg(MESSAGE_CODE.E00003),
                  detail: this.commonService.msg(MESSAGE_CODE.E00026),
                })
              );
              this.reloadID.emit(null);
            } else if (!this.commonService.checkRunningNormallyResponse(response)) {
              // APIが正常終了の場合
              // 正常メッセージ
              this.messageService.add({
                severity: TOAST.ERROR,
                summary: this.commonService.msg(
                  MESSAGE_CODE.T00004,
                  codeName,
                  codeValue
                ),
                detail: this.commonService.msg(MESSAGE_CODE.E00005),
              });
            } else {
              this.messageService.add({
                severity: TOAST.SUCCESS,
                summary: this.commonService.msg(
                  MESSAGE_CODE.T00004,
                  codeName,
                  codeValue
                ),
                detail: this.commonService.msg(messageCodeForToastSuccess, ''),
              });
            }
            // 画面ロードフラグをOFF(ロード終了)
            this.loadingState.loadEnd(LOADING_KEY.DELETE);

            // 親画面に更新対象IDを設定
            this.reloadID.emit(codeValue);
          });
      },
    });
  }

  /**
   * 削除処理（画面上出すコード値、コード名と主キーが異なる場合）
   * @param endPoint REST APIエンドポイント
   * @param dispCodeArray 表示するコード名とコード値のリスト
   * @param pKey 主キー
   */
  public deleteByPkeyDialog(
    endPoint: string,
    dispCodeArray: { codeName: string; codeValue: string }[],
    pkey: string
  ) {
    const message = dispCodeArray.map(
      (one) => one.codeName + CONSTANT.COLON + one.codeValue
    );

    this.confirmationService.confirm({
      message: this.commonService.msg(
        MESSAGE_CODE.D00003,
        message.join(SCREEN_CONSTANT.LINE_CODE)
      ),
      header: CONSTANT.EMPTY_ONE_STRING,
      icon: 'pi pi-info-circle',
      accept: () => {
        // 画面カスタムロードフラグをON(ロード中状態)
        this.loadingState.customLoadStart(
          new LoadData({
            loadingText: '',
            background_color: '',
            opacity: 0.3,
          }),
          LOADING_KEY.DELETE
        );

        this.dbOperationService
          .deleteData(endPoint, pkey)
          .subscribe((response) => {
            // APIレスポンスの判定
            if (this.commonService.checkRunningNormallyResponse(response)) {
              // APIが正常終了の場合

              // 正常メッセージ
              this.messageService.add({
                severity: TOAST.SUCCESS,
                summary: message.join(CONSTANT.HYPHEN),
                detail: this.commonService.msg(MESSAGE_CODE.D00001),
              });
            } else {
              // APIが異常終了の場合

              // 異常メッセージ
              this.messageService.add({
                severity: TOAST.ERROR,
                summary: message.join(CONSTANT.HYPHEN),
                detail: this.commonService.msg(MESSAGE_CODE.E00005),
              });
            }

            // 画面ロードフラグをOFF(ロード終了)
            this.loadingState.loadEnd(LOADING_KEY.DELETE);

            // 親画面に更新対象IDを設定
            this.reloadID.emit(pkey);
          });
      },
    });
  }

  /**
   * キャンセル処理
   * @param endPoint REST APIエンドポイント
   * @param codeName コード名
   * @param codeValue コード値
   * @param updateValue 更新情報
   */
  public cancelDialog(
    endPoint: string,
    codeName: string,
    codeValue: string,
    updateValue: {}
  ) {
    this.confirmationService.confirm({
      message: this.commonService.msg(MESSAGE_CODE.U00004, codeName, codeValue),
      header: CONSTANT.EMPTY_ONE_STRING,
      icon: 'pi pi-info-circle',
      accept: () => {
        // 画面カスタムロードフラグをON(ロード中状態)
        this.loadingState.customLoadStart(
          new LoadData({
            loadingText: '',
            background_color: '',
            opacity: 0.3,
          }),
          LOADING_KEY.DELETE
        );

        this.dbOperationService
          .updateData(endPoint, codeValue, updateValue)
          .subscribe((response) => {
            // APIレスポンスの判定
            if (this.commonService.checkRunningNormallyResponse(response)) {
              // APIが正常終了の場合

              // 正常メッセージ
              this.messageService.add({
                severity: TOAST.SUCCESS,
                summary: this.commonService.msg(
                  MESSAGE_CODE.T00004,
                  codeName,
                  codeValue
                ),
                detail: this.commonService.msg(MESSAGE_CODE.U00003),
              });
            } else {
              // APIが異常終了の場合

              // 異常メッセージ
              this.messageService.add({
                severity: TOAST.ERROR,
                summary: this.commonService.msg(
                  MESSAGE_CODE.T00004,
                  codeName,
                  codeValue
                ),
                detail: this.commonService.msg(MESSAGE_CODE.E00006),
              });
            }

            // 画面ロードフラグをOFF(ロード終了)
            this.loadingState.loadEnd(LOADING_KEY.DELETE);

            // 親画面に更新対象IDを設定
            this.reloadID.emit(codeValue);
          });
      },
    });
  }

  public deleteOwnerDialog(endPoint: string, orgName: string, codeValue: string) {
    this.confirmationService.confirm({
      message: this.commonService.msg(MESSAGE_CODE.D00007, orgName),
      header: CONSTANT.EMPTY_ONE_STRING,
      icon: 'pi pi-info-circle',
      accept: () => {
        // 画面カスタムロードフラグをON(ロード中状態)
        this.loadingState.customLoadStart(
          new LoadData({
            loadingText: '',
            background_color: '',
            opacity: 0.3,
          }),
          LOADING_KEY.DELETE
        );

        this.dbOperationService
          .deleteData(endPoint, codeValue)
          .subscribe((response) => {
            // APIレスポンスの判定
            if (this.commonService.checkRunningNormallyResponse(response)) {
              // APIが正常終了の場合

              // 正常メッセージ
              this.messageService.add({
                severity: TOAST.SUCCESS,
                summary: this.commonService.msg(
                  MESSAGE_CODE.D00008,
                ),
                detail: this.commonService.msg(MESSAGE_CODE.D00001, ''),
              });
            } else {
              // APIが異常終了の場合

              // 異常メッセージ
              this.messageService.add({
                severity: TOAST.ERROR,
                summary: this.commonService.msg(
                  MESSAGE_CODE.T00004,
                  CONSTANT.EMPTY_ONE_STRING,
                  codeValue
                ),
                detail: this.commonService.msg(MESSAGE_CODE.E00005),
              });
            }
            // 画面ロードフラグをOFF(ロード終了)
            this.loadingState.loadEnd(LOADING_KEY.DELETE);

            // 親画面に更新対象IDを設定
            this.reloadID.emit(codeValue);
          });
      },
    });
  }
}
