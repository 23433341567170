import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

/** コンポーネント */
// 新規入会時のパスワード設定
import { A212Component } from "./platform/a212/a212.component";
// 新規入会時のパスワード設定完了
import { A213Component } from "./platform/a213/a213.component";
// ログアウト
import { LogoutComponent } from "./logout/logout.component";

import { M301Component } from "./platform/master/m301/m301.component";
// MASTER COMPONENT
import { M401Component } from "./platform/master/m401/m401.component";
import { M201Component } from "./platform/master/m201/m201.component";
import { M101Component } from "./platform/master/m101/m101.component";
import { M501Component } from "./platform/master/m501/m501.component";
import { LandingComponent } from "./platform/landing/landing.component";
import { ContactComponent } from "./platform/contact/contact.component";
import { AuthenticationGuard } from "../shared/service/authentication.guard";
import { NewLoginComponent } from "../shared/new-login/new-login.component";
import { TopComponent } from "./platform/top/top.component";
import { C101Component } from "./platform/c101/c101.component";
import { C101_2Component } from "./platform/c101_2/c101_2.component";
import { C111Component } from "./platform/c111/c111.component";
import { C121Component } from "./platform/c121/c121.component";
import { C131Component } from "./platform/c131/c131.component";
import { C141Component } from "./platform/c141/c141.component";
import { CXXXComponent } from "./platform/cxxx/cxxx.component";
import { C151Component } from "./platform/c151/c151.component";
import { C201Component } from "./platform/c201/c201.component";

const routes: Routes = [
  {
    // 初期画面
    path: "",
    // component: HomeComponent,
    redirectTo: "top",
    pathMatch: "full",
  },
  {
    // top
    path: "top",
    component: TopComponent,
    data: { headerTitle: 'Top' }
  },
  { path: 'login', component: NewLoginComponent, data: { title: 'Login' } },
  {
    // 新規入会時のパスワード設定
    // クエリパラメータ
    // email (eメールアドレス)
    path: "pages/platform/a212",
    component: A212Component,
  },
  {
    // 新規入会時のパスワード設定完了
    path: "pages/platform/a213",
    component: A213Component,
  },
  {
    path: "pages/platform/c101",
    component: C101Component,
    canActivate: [AuthenticationGuard],
    data: { headerTitle: '営業担当' }
  },
  {
    path: "pages/platform/c1012",
    component: C101_2Component,
    canActivate: [AuthenticationGuard],
    data: { headerTitle: '実務担当' }
  },
  {
    path: "pages/platform/c111",
    component: C111Component,
    canActivate: [AuthenticationGuard],
    data: { headerTitle: '資材選択' }
  },
  {
    path: "pages/platform/c121",
    component: C121Component,
    canActivate: [AuthenticationGuard],
    data: { headerTitle: '差出元情報' }
  },
  {
    path: "pages/platform/c131",
    component: C131Component,
    canActivate: [AuthenticationGuard],
    data: { headerTitle: '届先情報' }
  },
  {
    path: "pages/platform/cxxx",
    component: CXXXComponent,
    canActivate: [AuthenticationGuard],
    data: { headerTitle: '差出・届先詳細' }
  },
  {
    path: "pages/platform/c141",
    component: C141Component,
    canActivate: [AuthenticationGuard],
    data: { headerTitle: '数量・納期' }
  },
  {
    path: "pages/platform/c151",
    component: C151Component,
    canActivate: [AuthenticationGuard],
    data: { headerTitle: '全体確認' }
  },
  {
    path: "pages/platform/c201",
    component: C201Component,
    canActivate: [AuthenticationGuard],
    data: { breadcrumbTitle: '初期表示時は実務担当者で絞り込んでいます', headerTitle: '配送・集荷履歴' }
  },
  {
    // 会員主催イベント一覧・CSVダウンロード
    path: "pages/platform/master/m201",
    component: M201Component,
    canActivate: [AuthenticationGuard],
    data: { headerTitle: '配送・集荷先' }
  },
  {
    // 会員主催イベント一覧・CSVダウンロード
    path: "pages/platform/master/m101",
    component: M101Component,
    canActivate: [AuthenticationGuard],
    data: { headerTitle: '資材' }
  },
  {
    // 会員主催イベント一覧・CSVダウンロード
    path: "pages/platform/master/m301",
    component: M301Component,
    canActivate: [AuthenticationGuard],
    data: { headerTitle: 'ユーザ' }
  },

  {
    // 会員主催イベント一覧・CSVダウンロード
    path: "pages/platform/master/m401",
    component: M401Component,
    canActivate: [AuthenticationGuard],
    data: { headerTitle: '部門' }
  },
  {
    // List of informations master
    path: "pages/platform/master/m501",
    component: M501Component,
    canActivate: [AuthenticationGuard],
    data: { headerTitle: 'お知らせ' }
  },

  {
    // landing
    path: "pages/platform/landing",
    component: LandingComponent,
    // canActivate: [AuthenticationGuard],
  },
  {
    // landing
    path: "pages/platform/contact",
    component: ContactComponent,
    // canActivate: [AuthenticationGuard],
  },
  {
    // ログアウト画面
    path: "pages/logout",
    component: LogoutComponent,
  },
  { path: 'login', component: NewLoginComponent },

  {
    path: '**',
    component: NewLoginComponent,
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule { }
