/**
 * ルート権限設定
 */
/**
 * 注意
 * 画面を追加する場合は"Component"名で定数追加
 * 内部は配列でユーザ権限設定(user-authority-constant.ts)の対象ユーザを設定
 * 空配列の場合、権限チェックは実施しない
 */
/**
 * TOP
 */

import {
  USER_1,
  USER_2,
  USER_3,
  USER_4,
} from './user-authority-constant';

// TOP画面
export const TopComponent = [USER_1, USER_2, USER_3, USER_4];

/** --------------------------------------------------------- */

/**
 * マスタ管理
 */

export const M101Component = [USER_3, USER_4];

export const M201Component = [USER_3, USER_4];

export const M301Component = [USER_3, USER_4, USER_1];

export const M401Component = [USER_3, USER_4];

export const M501Component = [USER_3, USER_4];

/** --------------------------------------------------------- */

// 新規入会時のパスワード設定
export const A212Component = [];

// 新規入会時のパスワード設定完了
export const A213Component = [];

// 課題・ソリューション登録(C101)
// 課題・ソリューション登録確認(C102)
export const C101Component = [USER_1];

export const C1012Component = [USER_1];

export const C111Component = [USER_1];

export const C121Component = [USER_1];

export const C131Component = [USER_1];

export const CXXXComponent = [USER_1];

export const C141Component = [USER_1];

export const C151Component = [USER_1];

// 課題・ソリューション一覧
export const C201Component = [USER_1, USER_2, USER_3, USER_4];
