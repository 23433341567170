/**
 * 画面用共通定数
 */
/** 定数 */
export const CONSTANT = {
  // ハイフン
  HYPHEN: '-',
  // アンダーバー
  UNDERBAR: '_',
  // カンマ
  COMMA: ',',
  // コロン
  COLON: ':',
  // ピリオド
  PERIOD: '.',
  // クエスチョン
  QUESTION: '?',
  // アンパサンド
  AMPERSAND: '&',
  // ダブルクォーテーション
  DOUBLE_QUOTATION: '"',
  // イコール
  EQUAL: '=',
  // 空文字
  EMPTY_STRING: '',
  // 空文字(1文字)
  EMPTY_ONE_STRING: ' ',
  // 左_波括弧
  LEFT_BRACE: '{',
  // 右_波括弧
  RIGHT_BRACE: '}',
  // Max uploaded file size: 4MB
  MAX_FILE_SIZE: 4194304,
  // Max uploaded file size: 4MB
  MAX_FILE_SIZE_text: '4MB',
  // カンマ
  FUllSIZE_COMMA: '、',
  // teacher class id 
  TEACHER_ID: '1'
};

/** 数字 */
export const NUMBER = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
};

/** 日付形式 */
export const DATE_FORMAT = {
  // 年月日
  DATE: 'yyyyMMdd',
  // 年月日(ハイフン)
  DATE_HYPHEN: 'yyyy-MM-dd',
  // 年月日時分秒(ハイフン)
  DATETIME_HYPHEN: 'yyyy-MM-dd-HHmmss',
  // 年月日(アンダーバー)
  DATE_UNDERBAR: 'yyyy_MM_dd',
  // 年月日時分秒
  DATETIME: 'yyyyMMddHHmmss',
};

/** ファイル拡張子 */
export const FILE_EXTENSION = {
  // CSVファイル
  CSV: '.csv',
  // CSVファイル
  CSV2: '.CSV',
  // TSVファイル
  TSV: '.tsv',
  // TSVファイル
  TSV2: '.TSV',
  // Excelファイル
  EXCEL: '.xlsx',
  // Excelファイル
  EXCEL2: '.XLSX',
  // PDF(小文字)ファイル
  PDF: '.pdf',
  //PDF(大文字)ファイル
  PDF2: '.PDF',
  // ZIPファイル
  ZIP: '.zip',
  // ZIPファイル
  ZIP2: '.ZIP',
  // PPTファイル
  PPT: '.ppt',
  // PPTファイル
  PPT2: '.PPT',
  // PPTXファイル
  PPTX: '.pptx',
  // PPTXファイル
  PPTX2: '.PPTX',
};

/** 画像拡張子 */
export const IMAGE_EXTENSION = {
  // JPG画像
  JPG: '.jpg',
  // JPG画像
  JPG2: '.JPG',
  // JPEG画像
  JPEG: '.jpeg',
  // JPEG画像
  JPEG2: '.JPEG',
  // PNG画像
  PNG: '.png',
  // PNG画像
  PNG2: '.PNG',
  // GIF画像
  GIF: '.gif',
  // GIF画像
  GIF2: '.GIF',
};

/** 画面定数 */
export const SCREEN_CONSTANT = {
  LINE_CODE: '<br>',
};

/** ファイル定数 */
export const FILE_CONSTANT = {
  LINE_CODE: '\r\n',
  TAB: '\t',
};

/** 文字コード */
export const CHARACTER_CODE = {
  SJIS: 'Shift_JIS',
};

/** 画像無しファイル名 */
export const NO_IMAGE_FILE_NAME = 'NoImage.jpg';

/** ローディングキー */
export const LOADING_KEY = {
  // ヘッダーメニュー
  HEADER_MENU: 'HEADER_MENU',
  // TOP画面.お知らせ
  TOP_NOTICE: 'TOP_NOTICE',
  // アイテムアダプター詳細
  GENERATE_DISPLAY: 'GENERATE_DISPLAY',
  // アイテムアダプター入力
  GENERATE_INPUT: 'GENERATE_INPUT',
  // アイテムアダプター検索
  GENERATE_SEARCH: 'GENERATE_SEARCH',
  // データ登録
  DATA_INSERT: 'DATA_INSERT',
  // データ更新
  DATA_UPDATE: 'DATA_UPDATE',
  // 削除
  DELETE: 'DELETE',
  // キャンセル
  CANCEL: 'CANCEL',
  // ファイルアップロード
  FILE_UPLOAD: 'FILE_UPLOAD',
  // ファイルダウンロード
  FILE_DOWNLOAD: 'FILE_DOWNLOAD',
};

/** コンポーネント値渡しキー */
export const COMPONENT_VALUE_KEY = {
  // ヘッダータイトル
  HEADER_TITLE: 'HEADER_TITLE',
};

// dicId Number
export const DICID_NUMBER = {
  // dicId number of the group type
  GROUP_TYPE: 1,
  // dicId number of the target grade
  TARGET_GRADE: 106,
  // dicId number of the pratice format
  PRACTICE_FORMAT: 105,
  // dicId number of the oublic/private
  PUBLIC_PRIVATE: 109,
};

export const CATEGORIES = {
  WORK_EXPERIENCE: '職場体験',
  CLASS_SUPPORT: '授業支援',
  CLUB_ACTIVITY_SUPPORT: '部活動支援',
  OTHERS: 'その他（授業外）',
}

export const CHATSTATUSES = {
  REQUEST_PROPOSAL: { name: "依頼中/提案中", code: "0" },
  REQUEST: { name: "依頼中", code: "0" },
  PROPOSAL: { name: "提案中", code: "0" },
  ADJUSTMENT: { name: "調整中", code: "1" },
  DECISION: { name: "実施決定", code: "2" },
  IMPLEMENTED: { name: "実施済", code: "3" },
  END: { name: "終了", code: "4" },
};

export const MATCH_TYPE = {
  AGENDA_SOLUTION: "1",
  AGENDA_NEED: "0",
  AGENDA_SOLUTION_TXT: "agenda_solution",
  AGENDA_NEED_TXT: "agenda_need",
};

export const MATCH_STATUSES = {
  REQUEST: "依頼中",
  PROPOSE: "提案中",
  ADJUSTMENT: "調整中",
  DECISION: "実施決定",
  IMPLEMENTED: "実施済",
  END: "終了",
  EVALUATION_ACHIEVEMENT_UNREGISTERED: "評価・実績未登録",
  UNRATED: "評価未登録",
}

export const NO_LOGIN_SCREENS = [
  'pages/platform/landing',
  'pages/platform/contact',
  'pages/platform/a212',
  'pages/platform/a213',
  'pages/platform/a214',
]

export const ROLES = {
  SALES_1: '1',
  SALES_1_NUM: 1,
  SALES_1_TEXT: "営業",

  TRANSIT_2: '2',
  TRANSIT_2_NUM: 2,
  TRANSIT_2_TEXT: "配車室",

  COMMON_3: '3',
  COMMON_3_NUM: 3,
  COMMON_3_TEXT: "総務",

  ADMIN_9: '9',
  ADMIN_9_NUM: 9,
  ADMIN_9_TEXT: "事務局",
}

export const ORDER_STATUS = {
  REQUESTED_1: "1",
  REQUESTED_1_NUM: 1,
  REQUESTED_1_TEXT: "依頼中",

  ARRANGED_2: "2",
  ARRANGED_2_NUM: 2,
  ARRANGED_2_TEXT: "手配済",

  CANCELLED_9: "9",
  CANCELLED_9_NUM: 9,
  CANCELLED_9_TEXT: "キャンセル",
}

export const DELIVERY_KBN = {
  AREA_1: "1",
  AREA_1_NUM: 1,
  AREA_1_TEXT: "エリア便",

  ROUTE_2: "2",
  ROUTE_2_NUM: 2,
  ROUTE_2_TEXT: "ルート便",

  SPOT_3: "3",
  SPOT_3_NUM: 3,
  SPOT_3_TEXT: "スポット便",

  BASE_4: "4",
  BASE_4_NUM: 4,
  BASE_4_TEXT: "拠点",
}

export const DELIVERY_COLLECT_KBN = {
  DELIVERY_0: "0",
  PICKUP_1: "1",
}

export const RESPONSE_DATA_WHEN_ADD_ORDER_DUMMY = [
  {
    "delivery_id_from": "75",
    "customer_code_from": "4_BASE",
    "customer_unique_code_from": "1",
    "delivery_kbn": "1",
    "delivery_kbn_name": "Delivery Kbn Name 1_AREA",
    "customer_name_from": "Customer name 4_BASE",
    "division_name_from": "division_name_division_name_division_name_division_name_division_name_division_name_division_name_division_name_division_name_division_name_division_name_division_name_division_name_division_name_divi",
    "user_name_from": "user_name_from_user_name_from_user_name_from_user_name_from_user_name_from_user_name_from_user_name_from_user_name_from_user_name_from_user_name_from_user_name_from_user_name_from_user_name_from_user_",
    "postal_no_from": "",
    "prefectures_from": "",
    "address1_from": "4_BASE_Address 1_4_BASE_Address 1_4_BASE_Address 1_4_BASE_Address 1_4_BASE_Address 1_4_BASE_Address 1_4_BASE_Address 1_4_BASE_Address 1_4_BASE_Address 1_4_BASE_Address 1_4_BASE_Address 1_4_BASE_Address 1_4_BASE_Address 1_4_BASE_Address 1_4_BASE_Address 1_4_BASE_Address 1_4_BASE_Address 1_4_BASE_Address 1_4_BASE_Address 1_4_BASE_Address 1_4_BASE_Address 1_4_BASE_Address 1_4_BASE_Address 1_4_BASE_Ad",
    "address2_from": "4_BASE_address2_4_BASE_address2_4_BASE_address2_4_BASE_address2_4_BASE_address2_4_BASE_address2_4_BASE_address2_4_BASE_address2_4_BASE_address2_4_BASE_address2_4_BASE_address2_4_BASE_address2_4_BASE_address2_4_BASE_address2_4_BASE_address2_4_BASE_address2_4_BASE_address2_4_BASE_address2_4_BASE_address2_4_BASE_address2_4_BASE_address2_4_BASE_address2_4_BASE_address2_4_BASE_address2_4_BASE_address2_",
    "address3_from": "",
    "note_from": "note_from_note_from_note_from_note_from_note_from_note_from_note_from_note_from_note_from_note_from_note_from_note_from_note_from_note_from_note_from_note_from_note_from_note_from_note_from_note_from_",
    "delivery_id_to": "72",
    "customer_code_to": "1_AREA",
    "customer_unique_code_to": "1",
    "customer_name_to": "Customer name 1_AREA",
    "division_name_to": "division_name_to_division_name_to_division_name_to_division_name_to_division_name_to_division_name_to_division_name_to_division_name_to_division_name_to_division_name_to_division_name_to_division_name",
    "user_name_to": "user_name_to_user_name_to_user_name_to_user_name_to_user_name_to_user_name_to_user_name_to_user_name_to_user_name_to_user_name_to_user_name_to_user_name_to_user_name_to_user_name_to_user_name_to_user_",
    "postal_no_to": "",
    "prefectures_to": "3",
    "address1_to": "1_AREA_Address 1_1_AREA_Address 1_1_AREA_Address 1_1_AREA_Address 1_1_AREA_Address 1_1_AREA_Address 1_1_AREA_Address 1_1_AREA_Address 1_1_AREA_Address 1_1_AREA_Address 1_1_AREA_Address 1_1_AREA_Address 1_1_AREA_Address 1_1_AREA_Address 1_1_AREA_Address 1_1_AREA_Address 1_1_AREA_Address 1_1_AREA_Address 1_1_AREA_Address 1_1_AREA_Address 1_1_AREA_Address 1_1_AREA_Address 1_1_AREA_Address 1_1_AREA_Ad",
    "address2_to": "1_AREA_Address 2_1_AREA_Address 2_1_AREA_Address 2_1_AREA_Address 2_1_AREA_Address 2_1_AREA_Address 2_1_AREA_Address 2_1_AREA_Address 2_1_AREA_Address 2_1_AREA_Address 2_1_AREA_Address 2_1_AREA_Address 2_1_AREA_Address 2_1_AREA_Address 2_1_AREA_Address 2_1_AREA_Address 2_1_AREA_Address 2_1_AREA_Address 2_1_AREA_Address 2_1_AREA_Address 2_1_AREA_Address 2_1_AREA_Address 2_1_AREA_Address 2_1_AREA_Ad",
    "address3_to": "",
    "use_spot": "0",
    "item_id": "37",
    "item_category": "3",
    "item_note": "Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_note_37Item_not",
    "order_num": "1",
    "vehicle_type": "",
    "delivery_company": "",
    "hope_delivery_date": "2024-02-29 00:00:00",
    "hope_ship_time": "Thu4",
    "hope_delivery_time": "",
    "spot_reason": "",
    "delivery_note": "",
    "user_id_sales": "60",
    "company_name_sales": "HIEUDX01",
    "division_id_sales": "HIEUDX01",
    "omit_division_name_sales": "HIEUDX01",
    "employee_id_sales": "78",
    "full_name_sales": "01_SALES_full_name",
    "tel_sales": "0559810689",
    "email_sales": "fs.hieudx+01@gmail.com",
    "user_id_business": "139",
    "company_name_sales_business": "HIEUDX01",
    "division_id_business": "HIEUDX01",
    "omit_division_name_business": "HIEUDX01",
    "employee_id_business": "12",
    "full_name_business": "01_business_name",
    "tel_sales_business": "",
    "email_business": "fs.hieudx+011@gmail.com",
    "note_to": "note_to_note_to_note_to_note_to_note_to_note_to_note_to_note_to_note_to_note_to_note_to_note_to_note_to_note_to_note_to_note_to_note_to_note_to_note_to_note_to_note_to_note_to_note_to_note_to_note_to_",
    "status": "1",
    "delivery_collect_kbn": "0",
    "create_datetime": "2024-02-29 00:00:00",
    "update_datetime": "2024-02-29 00:00:00",
    "update_function": "AddOrder",
    "create_user_id": "60",
    "update_user_id": "60",
    "order_id": "304"
  }
]

export const SCREEN_PATH = {
  C101: '/pages/platform/c101',
  C1012: '/pages/platform/c1012',
  C111: '/pages/platform/c111',
  C121: '/pages/platform/c121',
  C131: '/pages/platform/c131',
  CXXX: '/pages/platform/cxxx',
  C141: '/pages/platform/c141',
  C151: '/pages/platform/c151',
}

export const ACCESS_TOKEN = {
  TIME_EXPIRE: 28800000
}
