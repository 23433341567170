import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClient } from "@angular/common/http";

/** モジュール */
// ルーティング
import { AppRoutingModule } from "./app-routing.module";

// 共通モジュール
import { PagesModule } from "./pages/pages.module";
import { SharedModule } from "./shared/shared.module";

/** 定数 */
import { auth0_dev, auth0_test, auth0_prod, auth0_stagging, auth0_demo, TEST_DOMAIN, PROD_DOMAIN, STAGING_DOMAIN, DEMO_DOMAIN, TPDEV_DOMAIN, auth0_tpdev } from "manager/environment";

/** コンポーネント */
import { AppComponent } from "./app.component";

/** サービス */
import {
  AuthInterceptor,
  ErrorInterceptor,
} from "src/app/shared/service/interceptor.service";

/** ngx-translate */
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

/** ngx-page-scroll */
import { NgxPageScrollCoreModule } from "ngx-page-scroll-core";
import { NgxPageScrollModule } from "ngx-page-scroll";

import {
  CLOSE_LOGIC1_DEV_DOMAIN,
  CLOSE_LOGIC2_DEV_DOMAIN,
  CLOSE_LOGIC1_TEST_DOMAIN,
  CLOSE_LOGIC2_TEST_DOMAIN,
  CLOSE_LOGIC1_STAGING_DOMAIN,
  CLOSE_LOGIC2_STAGING_DOMAIN,
  CLOSE_LOGIC1_PROD_DOMAIN,
  CLOSE_LOGIC2_PROD_DOMAIN,
  CLOSE_LOGIC1_DEMO_DOMAIN,
  CLOSE_LOGIC2_DEMO_DOMAIN,
  CLOSE_LOGIC1_TPDEV_DOMAIN,
  CLOSE_LOGIC2_TPDEV_DOMAIN
} from "../../manager/environment";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    PagesModule,
    SharedModule,
    NgxPageScrollCoreModule,
    NgxPageScrollModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

export function getAuthenticateRequiredUrls() {
  const allowUrls = [
    CLOSE_LOGIC1_DEV_DOMAIN,
    CLOSE_LOGIC2_DEV_DOMAIN,
    CLOSE_LOGIC1_TEST_DOMAIN,
    CLOSE_LOGIC2_TEST_DOMAIN,
    CLOSE_LOGIC1_STAGING_DOMAIN,
    CLOSE_LOGIC2_STAGING_DOMAIN,
    CLOSE_LOGIC1_PROD_DOMAIN,
    CLOSE_LOGIC2_PROD_DOMAIN,
    CLOSE_LOGIC1_DEMO_DOMAIN,
    CLOSE_LOGIC2_DEMO_DOMAIN,
    CLOSE_LOGIC1_TPDEV_DOMAIN,
    CLOSE_LOGIC2_TPDEV_DOMAIN,
  ];

  const tokenOptions: any = {
    audience:
      PROD_DOMAIN == window.location.host ? auth0_prod.AUDIENCE
        : TEST_DOMAIN == window.location.host ? auth0_test.AUDIENCE
          : STAGING_DOMAIN == window.location.host ? auth0_stagging.AUDIENCE
            : DEMO_DOMAIN == window.location.host ? auth0_demo.AUDIENCE
              : TPDEV_DOMAIN == window.location.host ? auth0_tpdev.AUDIENCE
                : auth0_dev.AUDIENCE,
  };

  const urls: any[] = [];
  for (const domain of allowUrls) {
    urls.push({ uri: domain + "*", tokenOptions: tokenOptions });
  }

  return urls;
}
