<!-- 検索フォーム -->
<div class="GenerateSearch">
  <form [formGroup]="generateSearchForm" autocomplete="off">
    <!-- 検索情報出力領域 -->
    <ng-container *ngFor="let generateSearch of generateSearchList">
      <!-- 検索項目タイプを判定 -->
      <!-- テキスト -->
      <div class="text" *ngIf="generateSearch.search_type == 'text'">
        <div class="textName" *ngIf="generateSearch.column_name.includes('（')">
          {{ generateSearch.column_name.split('（')[0] }} <br>
          （{{ generateSearch.column_name.split('（')[1] }}
        </div>
        <div class="textName" *ngIf="!generateSearch.column_name.includes('（')">
          {{ generateSearch.column_name }}
        </div>
        <div class="textValue" *ngIf="generateSearch.column_id != 'email_business'">
          <input [name]="generateSearch.column_id" type="text" size="30" [formControlName]="generateSearch.column_id"
            pInputText autocomplete="off" [placeholder]="handlePlaceholder(generateSearch.column_memo)" />
        </div>
        <div class="textValue" *ngIf="generateSearch.column_id == 'email_business'">
          <input [name]="generateSearch.column_id" type="text" size="30" [formControlName]="generateSearch.column_id"
            pInputText autocomplete="off" [(ngModel)]="email_business"
            [placeholder]="handlePlaceholder(generateSearch.column_memo)" />
        </div>
      </div>

      <!-- 日付 -->
      <div class="date"
        *ngIf="generateSearch.search_type == 'date' && generateSearch.column_id != 'hope_delivery_date' && generateSearch.column_id != 'hope_ship_date'">
        <div class="dateName">{{ generateSearch.column_name }}</div>
        <div class="dateValue">
          <div class="p-d-flex p-ai-center">
            <div class="label">
              開始:
            </div>
            <div class="calendar">
              <p-calendar [name]="generateSearch.column_id + '_FROM'" [showIcon]="true" [readonlyInput]="true"
                dateFormat="yy/mm/dd" dataType="string" appendTo="body"
                [formControlName]="generateSearch.column_id + '_FROM'" translate
                [placeholder]="handlePlaceholder(generateSearch.column_memo)"></p-calendar>
            </div>
          </div>
          <br />
          <div class="verticalTilde">〜</div>
          <div class="p-d-flex p-ai-center">
            <div class="label">
              終了:
            </div>
            <div class="calendar">
              <p-calendar [name]="generateSearch.column_id + '_TO'" [showIcon]="true" [readonlyInput]="true"
                dateFormat="yy/mm/dd" dataType="string" appendTo="body"
                [formControlName]="generateSearch.column_id + '_TO'" translate
                [placeholder]="handlePlaceholder(generateSearch.column_memo)"></p-calendar>
            </div>
          </div>
        </div>
      </div>

      <div class="date"
        *ngIf="generateSearch.search_type == 'date' && (generateSearch.column_id == 'hope_delivery_date' || generateSearch.column_id == 'hope_ship_date')">
        <div class="dateName">{{ generateSearch.column_name }}</div>
        <div class="dateValue_hope_delivery_date">
          <div class="p-d-flex p-ai-center">
            <div class="calendar">
              <p-calendar [name]="generateSearch.column_id" [showIcon]="true" [readonlyInput]="true"
                dateFormat="yy/mm/dd" dataType="string" appendTo="body" [formControlName]="generateSearch.column_id"
                translate [placeholder]="handlePlaceholder(generateSearch.column_memo)"
                [showButtonBar]="true"></p-calendar>
            </div>
          </div>
        </div>
      </div>

      <!-- 数値 -->
      <div class="number" *ngIf="generateSearch.search_type == 'number'">
        <div class="numberName">{{ generateSearch.column_name }}</div>
        <div class="numberValue">
          開始:<input [name]="generateSearch.column_id + '_FROM'" [formControlName]="generateSearch.column_id + '_FROM'"
            type="number" pInputText autocomplete="off" [placeholder]="handlePlaceholder(generateSearch.column_memo)" />
          <br />
          <div class="verticalTilde">〜</div>
          終了:<input [name]="generateSearch.column_id + '_TO'" [formControlName]="generateSearch.column_id + '_TO'"
            type="number" pInputText autocomplete="off" [placeholder]="handlePlaceholder(generateSearch.column_memo)" />
        </div>
      </div>

      <!-- チェックボックス -->
      <div class="checkbox" *ngIf="generateSearch.search_type == 'checkbox'">
        <div class="checkboxName">
          {{ generateSearch.column_name }}
        </div>
        <div class="checkBoxValue">
          <ng-container *ngFor="let code_list of generateSearch.code_list_multi">
            <p class="p-field-checkbox">
              <p-checkbox [name]="generateSearch.column_id" [label]="code_list.name" [value]="code_list.value"
                [formControl]="
                  generateSearchForm.controls[generateSearch.column_id]
                " [placeholder]="handlePlaceholder(generateSearch.column_memo)"></p-checkbox>
            </p>
          </ng-container>
        </div>
      </div>

      <!-- プルダウン -->
      <div class="pulldown" *ngIf="generateSearch.search_type == 'pulldown'">
        <div class="pulldownName">
          {{ generateSearch.column_name }}
        </div>
        <div class="pulldownValue" *ngIf="generateSearch.column_id == 'delivery_kbn'">
          <p-multiSelect [name]="generateSearch.column_id" [options]="filterDeliveryKbn(generateSearch.code_list_multi)"
            optionLabel="name" optionValue="value" [formControlName]="generateSearch.column_id" appendTo="body"
            [placeholder]="handlePlaceholder(generateSearch.column_memo)"></p-multiSelect>
        </div>
        <div class="pulldownValue" *ngIf="generateSearch.column_id != 'delivery_kbn'">
          <p-multiSelect [name]="generateSearch.column_id" [options]="generateSearch.code_list_multi" optionLabel="name"
            optionValue="value" [formControlName]="generateSearch.column_id"
            [placeholder]="handlePlaceholder(generateSearch.column_memo)"></p-multiSelect>
        </div>
      </div>

      <!-- テキストエリア -->
      <div class="list" *ngIf="generateSearch.search_type == 'list'">
        <div class="listName">
          {{ generateSearch.column_name }}
        </div>
        <div class="listValue">
          <textarea [name]="generateSearch.column_id" rows="10" maxlength="1000"
            [formControlName]="generateSearch.column_id" pInputTextarea autocomplete="off"
            [placeholder]="handlePlaceholder(generateSearch.column_memo)"></textarea>
        </div>
      </div>
    </ng-container>
  </form>

  <!-- ボタンエリア -->
  <div class="buttonArea">
    <input pButton type="submit" value="検索" class="p-button-raised p-mr-2 button p-button-br-7" (click)="onSubmit()" />
    <input pButton type="submit" value="クリア" class="p-button-raised p-mr-2 button p-button-br-7" (click)="reset()" />
  </div>
</div>