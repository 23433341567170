import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { GenerateService } from "src/app/shared/generate/generate.service";
import {
  GenerateDisplay,
  OutputGenerateDisplay,
} from "src/app/shared/generate/generate-display/generate-display-information/generate-display-information";
import { CommonService } from "src/app/shared/service/common.service";
import { LoadingState } from "src/app/shared/html-parts/loading/loading-state";
import { LOADING_KEY } from "src/app/shared/constant/constant";
import { SESSION_KEY } from "manager/environment";
import {
  API_URL_DETAIL_ITEM,
} from "manager/http-constants";
import { ITEM_M101_TEMPLATE } from "manager/template-constant";
import { MessageData, ToastMessageData } from "src/app/shared/html-parts/message-common/message-data";
import { TOAST } from "src/app/shared/constant/primeng-constants";
import { MESSAGE_CODE } from "src/app/shared/constant/message-constant";

@Component({
  selector: "app-generate-display-information",
  templateUrl: "./generate-display-information.component.html",
  styleUrls: ["./generate-display-information.component.scss"],
})

/**
 * 詳細情報
 */
export class GenerateDisplayInformationComponent implements OnInit {
  // 詳細情報出力オブジェクトリスト(画面表示情報)
  outputGenerateDisplayList: OutputGenerateDisplay[] = new Array();
  classId: string = JSON.parse(
    window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
  )?.department_type;
  pkeyId: string;

  @Input() detailsNav: boolean;
  @Output() reloadID = new EventEmitter<string>();

  applicationId: string;
  noReload: boolean = false;

  constructor(
    private generateService: GenerateService,
    private commonService: CommonService,
    private messageData: MessageData,
    private loadingState: LoadingState
  ) { }

  ngOnInit(): void { }

  /**
   * 詳細情報表示
   * @param generateDisplay 詳細情報入力オブジェクト(オブジェクトorリスト)
   * @param pkeyId 選択対象ID
   */
  public initial(generateDisplay: any, pkeyId?: string) {
    // 詳細情報出力オブジェクトリスト(画面表示情報)を初期化
    this.outputGenerateDisplayList = new Array();
    this.pkeyId = pkeyId;
    // 型判定
    if ("[object Object]" == Object.prototype.toString.call(generateDisplay)) {
      // オブジェクト型の場合

      // 詳細情報取得を実施
      this.getDisplayInformation(generateDisplay, pkeyId, 0);
    } else if (
      "[object Array]" == Object.prototype.toString.call(generateDisplay)
    ) {
      // リスト型の場合

      // 詳細情報取得を複数実施
      generateDisplay.forEach((element, index) => {
        this.getDisplayInformation(
          element,
          // 詳細情報オブジェクトにpkeyIdが設定されているか否か
          element.pkeyId
            ? // 詳細情報オブジェクトにpkeyIdが設定されている場合
            element.pkeyId
            : // 上記以外の場合、第一引数のpkeyIdを使用
            pkeyId,
          index
        );
      });
    }
  }

  /**
   * 詳細情報取得
   * @param generateDisplay 詳細情報入力オブジェクト(オブジェクトorリスト)
   * @param pkeyId 選択対象ID
   * @param index 出力順番
   */
  private getDisplayInformation(
    generateDisplay: any,
    pkeyId: string,
    index: number
  ) {
    // 選択対象IDが存在するか否か
    if (!pkeyId) {
      // 選択対象IDが存在しない場合
      // initialにおいて、詳細情報入力オブジェクトにpkeyIdが存在しない場合 かつ
      // 第二引数が存在しない場合
      return;
    }

    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(LOADING_KEY.GENERATE_DISPLAY + index);

    // 詳細情報出力オブジェクト(画面表示情報)を生成
    let outputGenerateDisplay: OutputGenerateDisplay =
      new OutputGenerateDisplay();

    // 詳細情報出力オブジェクト.タイトルを設定(初期値は"詳細")
    outputGenerateDisplay.title = generateDisplay.title;

    // 詳細情報取得処理
    this.generateService
      .getGenerateDisplay(
        generateDisplay.endPoint,
        generateDisplay.templateId,
        pkeyId
      )
      .subscribe((response) => {
        // 詳細情報が取得されたか否か
        if (this.commonService.checkInvalidValue(response)) {
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00003),
              detail: this.commonService.msg(MESSAGE_CODE.E00026),
            })
          )
          this.reloadID.emit(this.pkeyId);
        }
        else if (!this.commonService.checkNoneResponse(response)) {
          // 詳細情報が取得された場合

          // 詳細情報出力オブジェクト.詳細出力情報を設定
          if (generateDisplay.templateId == 3303) {
            response.body.map((field: any) => {
              if (field.column_id == "member_application_id") {
                this.applicationId = field.data;
                response.body.splice(response.body.indexOf(field), 1);
              }
            });

          }
          outputGenerateDisplay.generateDisplay = response.body;

          // 詳細情報出力オブジェクトを詳細情報出力オブジェクトリストに追加
          this.outputGenerateDisplayList.splice(
            index,
            0,
            outputGenerateDisplay
          );
        }

        else {
          location.reload();
        }
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(
          0.3,
          LOADING_KEY.GENERATE_DISPLAY + index
        );
      });
  }

  public handleMissingImage(event: Event) {
    (event.target as HTMLImageElement).style.display = "none";
  }

  /**
   * 詳細情報画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  public detail() {
    return new Promise<any>((resolve) => {
      this.outputGenerateDisplayList = new Array();
      // 詳細画面表示
      let generateDisplay: GenerateDisplay = new GenerateDisplay();
      generateDisplay.endPoint = API_URL_DETAIL_ITEM;
      generateDisplay.templateId = ITEM_M101_TEMPLATE.OUTPUT_TEMPLATE_ID;
      this.getDisplayInformation(generateDisplay, this.pkeyId, 0);
      resolve(null);
    })

  }

  public async approval() {
    await this.detail();
    this.noReload = true;
    this.reloadID.emit(this.pkeyId);
  }
  reject() {
    this.noReload = false;
    this.reloadID.emit(this.pkeyId);
  }
}
