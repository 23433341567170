import { DatePipe } from "@angular/common";
import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  API_URL_DELIVERY,
  API_URL_INPUT_MASTER,
  API_URL_GENERATE_SEARCH,
  DELIVERY_DELETE_MASTER,
  API_URL_DELIVERY_DUPLICATE
} from "manager/http-constants";
import { DELIVERY_M201_TEMPLATE } from "manager/template-constant";
import { GenerateDisplay } from "src/app/shared/generate/generate-display/generate-display-information/generate-display-information";
import { GenerateDisplayComponent } from "src/app/shared/generate/generate-display/generate-display.component";
import { GENERATE_INPUT_TYPE } from "src/app/shared/generate/generate-input/constant";
import { GenerateInputComponent } from "src/app/shared/generate/generate-input/generate-input.component";
import { DeleteDialogComponent } from "src/app/shared/html-parts/delete-dialog/delete-dialog.component";
import { LoadingState } from "src/app/shared/html-parts/loading/loading-state";
import { CommonService } from "src/app/shared/service/common.service";
import { DbOperationService } from "src/app/shared/service/db-operation.service";
import { USER_CONSTANT } from "./constant";
import { CONSTANT, DELIVERY_KBN, ROLES } from "src/app/shared/constant/constant";
import { ExportFileService } from "src/app/shared/html-parts/export-file/export-file.service";
import { SESSION_KEY } from "manager/environment";

@Component({
  selector: "app-m201",
  templateUrl: "../master.component.html",
  styleUrls: ["../master.component.scss", "../../platform.component.scss"],
})
export class M201Component implements OnInit {
  /** 詳細画面 */
  @ViewChild(GenerateDisplayComponent)
  generateDisplayItemsComponent: GenerateDisplayComponent;

  /** 新規登録、編集、複製画面 */
  @ViewChild(GenerateInputComponent)
  generateInputComponent: GenerateInputComponent;

  /** 削除ダイアログ */
  @ViewChild(DeleteDialogComponent)
  deleteDialogComponent: DeleteDialogComponent;

  departmentType: any = JSON.parse(window.sessionStorage.getItem(SESSION_KEY.loginUserInformation))?.department_type;

  // 検索項目生成エンドポイント
  public searchEndPoint: string = API_URL_GENERATE_SEARCH;

  // 検索項目生成テンプレートID
  public searchTemplateId: number = DELIVERY_M201_TEMPLATE.SEARCH_TEMPLATE_ID;

  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnOrder: any[] = new Array();

  // 画面検索結果一覧格納先
  searchResultsList: any[] = new Array();

  // 検索条件保持
  generateSearchItems: Object;

  //set first page = 1
  pages: number = 0;
  innerWidth: any;
  checkMobile: boolean = false;
  isShowSearchMobile: boolean = false;
  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    public datePipe: DatePipe,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private exportFileService: ExportFileService

  ) {
    //  super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    /* ヘッダー情報取得処理(画面用) */
    this.dbOperationService
      .getHeaderList(DELIVERY_M201_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID)
      .subscribe((response) => {
        if (this.innerWidth > 450) {
          this.columnOrder = response.body
        }
        else {
          this.columnOrder = response.body.slice(0, 4);
          let temp = this.columnOrder.pop()
          this.columnOrder.unshift(temp)
          this.checkMobile = true
        }
      });

    // 検索処理を実施
    this.searchResult();
  }

  /**
   * 検索処理
   * @param generateSearchItems 検索条件
   */
  public searchResult(generateSearchItems?: Object) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.loadStart(API_URL_DELIVERY);


    // CSV出力用に検索条件を保持
    this.generateSearchItems = generateSearchItems;

    /* 課題・ソリューション一覧取得処理(画面用) */
    this.dbOperationService
      .getForkJoinData(
        USER_CONSTANT.TABLE,
        API_URL_DELIVERY,
        DELIVERY_M201_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID,
        generateSearchItems,
        true
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 課題・ソリューション一覧情報のJSONをオブジェクトに格納する
          this.searchResultsList = response.body;
        } else {
          this.searchResultsList = new Array();
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, API_URL_DELIVERY);
        //return to first page
        this.pages = 0;
      });
  }
  /**
     * check overflow of element
     * @param el html element of list 
     * @returns 
     */
  isOverflow(el: HTMLElement): boolean {
    return el.clientWidth < el.scrollWidth;
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
  /**
   * 新規登録画面表示
   */
  public new() {
    // 新規登録画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.NEW,
      API_URL_INPUT_MASTER,
      API_URL_DELIVERY,
      API_URL_DELIVERY,
      DELIVERY_M201_TEMPLATE.INPUT_TEMPLATE_ID,
      null
    );
  }

  /**
   * 編集画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  public edit(pkeyId: string) {
    // 編集画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.EDIT,
      API_URL_INPUT_MASTER,
      API_URL_DELIVERY,
      API_URL_DELIVERY,
      DELIVERY_M201_TEMPLATE.INPUT_TEMPLATE_ID,
      pkeyId
    );
  }
  /**
   * 複製画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  public duplicate(pkeyId: string) {
    // 複製画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.DUPLICATE,
      API_URL_INPUT_MASTER,
      API_URL_DELIVERY_DUPLICATE,
      API_URL_DELIVERY,
      DELIVERY_M201_TEMPLATE.INPUT_TEMPLATE_ID,
      pkeyId
    );
  }
  /**
   * 詳細情報画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  protected detail(pkeyId: string) {
    // 詳細画面表示
    let generateDisplay: GenerateDisplay = new GenerateDisplay();
    generateDisplay.endPoint = API_URL_DELIVERY;
    generateDisplay.templateId = DELIVERY_M201_TEMPLATE.OUTPUT_TEMPLATE_ID;
    this.generateDisplayItemsComponent.initial(generateDisplay, pkeyId);
  }

  /**
   * 削除処理
   * @param pkeyId IDコード(クリックされたID)
   */
  public delete(pkeyId: string, user: any) {
    const title = "配送・集荷先";
    // 削除ダイアログにより削除処理
    if (user.isOwner === 1) {
      this.deleteDialogComponent.deleteOwnerDialog(
        DELIVERY_DELETE_MASTER,
        user.member_department_id,
        pkeyId,
      );
    } else {
      this.deleteDialogComponent.deleteDialog(
        DELIVERY_DELETE_MASTER,
        this.columnOrder[0].header,
        pkeyId,
        title
      );
    }

  }

  /**
   * プライマリーカラムを取得
   */
  protected getPkeyColumn(): string {
    return this.commonService.getPkeyColumn(this.columnOrder);
  }

  /**
   * 再検索処理＆データ置換
   * @param pkeyId コード値
   */
  public searchReplacement(pkeyId: string) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.loadStart(API_URL_DELIVERY);


    // 返却されたコード値が存在するか否か
    if (null == pkeyId || undefined == pkeyId) {
      // 返却されたコード値が存在しない場合

      // 検索処理を実施
      this.searchResult();

      return;
    }

    // 営業日マスタ一覧取得処理(画面用)
    this.dbOperationService
      .getSingleData(
        API_URL_DELIVERY,
        DELIVERY_M201_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID,
        this.columnOrder[0].field,
        pkeyId
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (this.commonService.checkNoneResponse(response)) {
          // 一覧情報が存在しない場合

          // 検索結果一覧から該当データを削除
          this.searchResultsList = this.searchResultsList.filter(
            (val) => val[this.columnOrder[0].field] !== pkeyId
          );

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadSleepEnd(0.3, API_URL_DELIVERY);
        } else {
          // 一覧情報が存在する場合
          // 検索結果一覧格納先から対象のデータの行数を抽出
          var indexTargetData = this.searchResultsList.indexOf(
            this.searchResultsList.find((results) => {
              return results[this.columnOrder[0].field] === pkeyId;
            })
          );

          // 検索結果一覧情報のJSONをオブジェクトに置換する
          this.searchResultsList.splice(indexTargetData, 1, response.body[0]);

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadSleepEnd(0.3, API_URL_DELIVERY);
        }
      });
  }
  /**
 * CSV出力
 */
  public exportCSV() {
    // csvファイル名の設定
    const fileName = USER_CONSTANT.CSV_FILENAME + CONSTANT.UNDERBAR + this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    // CSVファイル出力
    this.exportFileService.exportTemplateCsv(
      fileName,
      USER_CONSTANT.TABLE,
      API_URL_DELIVERY,
      DELIVERY_M201_TEMPLATE.OUTPUT_TEMPLATE_ID,
      this.generateSearchItems,
      true,
    );
  }

  public authorizeCrudButton(buttonName?: string, delivery_kbn?: string) {
    const departmentType = JSON.parse(window.sessionStorage.getItem(SESSION_KEY.loginUserInformation))?.department_type;

    if (departmentType == ROLES.ADMIN_9 || departmentType == ROLES.COMMON_3) {
      return true;
    }

    return false;
  }

  onHover(e) {
    if (this.isOverflow(e.target)) {
      e.target.dataset.showtooltip = 'true'
    };
  }
}
