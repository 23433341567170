import { Component, OnInit, ViewChild } from '@angular/core';
import { GenerateDisplayComponent } from 'src/app/shared/generate/generate-display/generate-display.component';
import { MessageCommonComponent } from 'src/app/shared/html-parts/message-common/message-common.component';
import { GenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import { CONSTANT, DELIVERY_COLLECT_KBN, LOADING_KEY } from 'src/app/shared/constant/constant';
import { LoadData, LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import { ExportFileService } from 'src/app/shared/html-parts/export-file/export-file.service';
import { DatePipe } from '@angular/common';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { C101_TEMPLATE } from 'manager/template-constant';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { DataView } from 'primeng/dataview';
import {
  API_URL_USER,
  API_URL_GENERATE_SEARCH,
  API_URL_WORK_EMPLOYEE_SALES,
} from 'manager/http-constants';

@Component({
  selector: 'app-c101',
  templateUrl: './c101.component.html',
  styleUrls: ['./c101.component.scss']
})
export class C101Component implements OnInit {
  /** 詳細画面 */
  @ViewChild(GenerateDisplayComponent)
  generateDisplayItemsComponent: GenerateDisplayComponent;

  /** メッセージ */
  @ViewChild(MessageCommonComponent)
  messageCommonComponent: MessageCommonComponent;

  @ViewChild('dv')
  dv: DataView;
  // 検索項目生成エンドポイント
  public searchEndPoint: string = API_URL_GENERATE_SEARCH;

  // 検索項目生成テンプレートID
  public searchTemplateId: number = C101_TEMPLATE.SEARCH_TEMPLATE_ID;

  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnOrder: any[];

  // 画面検索結果一覧格納先
  searchResultsList: any[];

  // list checked of delivery-search
  searchCheckbox: any[];

  // ページ数
  pages: number = 0;

  delivery_collect_kbn: string = DELIVERY_COLLECT_KBN.DELIVERY_0;

  // カート選択リスト格納先
  cartSelected: any[] = [];

  // 検索条件保持
  generateSearchItems: Object;

  // 全選択チェックボックス
  allChecked: boolean = false;

  // checked value of items search
  checkedArr: any[];

  // dataView ソートカラム名
  sortField: string;

  // dataView ソート順 (1:昇順, -1:降順)
  sortOrder: number;

  existData: boolean = false;

  constructor(
    private dbOperationService: DbOperationService,
    private router: Router,
    private commonService: CommonService,
    public datePipe: DatePipe,
    private loadingState: LoadingState,
    private exportFileService: ExportFileService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    const { delivery_collect_kbn } = this.route.snapshot.queryParams;
    if (delivery_collect_kbn) {
      this.delivery_collect_kbn = delivery_collect_kbn;
    } else {
      this.router.navigate(['/pages/platform/c101'], { queryParams: { delivery_collect_kbn: DELIVERY_COLLECT_KBN.DELIVERY_0 } });
    }

    // ヘッダー情報取得処理(画面用)
    this.dbOperationService
      .getHeaderList(C101_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID)
      .subscribe((response) => {
        this.columnOrder = response.body;
      });

    // 検索処理を実施
    this.searchResult();

    this.getWorkEmployeeSales();
  }

  /**
   * 検索処理
   * @param table テーブル状態
   * @param generateSearchItems 検索条件
   */
  public searchResult(table?: DataView, generateSearchItems?: Object) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
      })
    );
    // 販促資材マスタ一覧取得処理(画面用)
    this.dbOperationService
      .getForkJoinData(
        C101_TEMPLATE.TABLE_NAME,
        API_URL_USER,
        C101_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID,
        generateSearchItems,
        true
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 販促資材マスタ一覧情報のJSONをオブジェクトに格納する
          this.searchResultsList = response.body;
          this.dv.first = 0;
        } else {
          this.searchResultsList = [];
        }
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3);
      });
    // ページ数を初期化
    this.pages = 0;
  }


  /**
   * 検索処理
   * @param table テーブル状態
   * @param generateSearchItems 検索条件
   */
  public getWorkEmployeeSales() {
    // 届け先資材(WORK)一覧取得処理(画面用)
    this.dbOperationService
      .getNoTemplateData(
        API_URL_WORK_EMPLOYEE_SALES,
        {
          delivery_collect_kbn: [this.delivery_collect_kbn]
        }
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 届け先資材(WORK)一覧情報のJSONをオブジェクトに格納する
          this.checkedArr = response.body;
          this.cartSelected = this.checkedArr.map(i => i.user_id.toString());
          this.existData = true;
        } else {
          this.cartSelected = [];
        }
      });
  }

  /**
 * 検索処理
 * @param generateSearchItems 検索条件
 */
  selectRow(event: any) {
    // 届け先資材(WORK)一覧取得処理(画面用)

  }

  /**
   * 詳細情報画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  public detail(pkeyId: string) {
    // 詳細画面表示
    const inputGenerateDisplay: GenerateDisplay = new GenerateDisplay();
    inputGenerateDisplay.endPoint = API_URL_USER;
    inputGenerateDisplay.templateId = C101_TEMPLATE.OUTPUT_TEMPLATE_ID;
    this.generateDisplayItemsComponent.initial(inputGenerateDisplay, pkeyId);
  }

  /**
   * カート追加
   */
  public addCart() {
    // カートが選択されているか否か
    if (!this.cartSelected) {
      // カートが選択されていない場合
      return;
    }

    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: CONSTANT.EMPTY_STRING,
        background_color: CONSTANT.EMPTY_STRING,
        opacity: 0.3,
      }),
      LOADING_KEY.DATA_INSERT
    );

    // カート登録
    this.dbOperationService
      .insertData(API_URL_WORK_EMPLOYEE_SALES, {
        user_id: this.cartSelected.join(CONSTANT.COMMA),
        delivery_collect_kbn: this.delivery_collect_kbn,
      })
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd(LOADING_KEY.DATA_INSERT);
        this.messageCommonComponent.clearToastMessage();

        // レスポンスを判定
        if (this.messageCommonComponent.responseToastMessage(response, true, "1件を選択してください。")) {
          // レスポンスが正常終了の場合

          // 発注管理_届先選択へ遷移
          this.router.navigate(['pages/platform/c1012'], { queryParams: { delivery_collect_kbn: this.delivery_collect_kbn } });
        }
      });
  }

  /**
   * Download PDF file
   */
  public downloadPdf(filePath: string) {
    window.location.href = filePath;
  }

  /**
 * チェックボックス全選択
 */

  protected onCheckItem() {
    const currentValue = this.cartSelected.pop();
    if (currentValue) {
      this.cartSelected = new Array(currentValue);
    } else {
      this.cartSelected = new Array();
    }
  }

  /**
 * dataView ソート処理
 */
  protected onSortChange(column: string) {
    let setOrder;
    if (this.sortField == column) {
      setOrder = this.sortOrder == 1 ? -1 : 1;
    } else {
      setOrder = 1
    }
    this.sortOrder = setOrder;
    this.sortField = column;
  }

  /**
 * check overflow of element
 * @param el html element of list 
 * @returns 
*/
  isOverflow(el: HTMLElement): boolean {
    return el.clientWidth < el.scrollWidth;
  }

  onHover(e) {
    if (this.isOverflow(e.target)) {
      e.target.dataset.showtooltip = 'true'
    };
  }
}
