<!-- 郵便番号検索ダイアログ -->
<p-dialog
  header="郵便番号検索"
  [modal]="true"
  [(visible)]="postalNoDialogFlag"
  styleClass="postal-no-dialog"
>
  <div class="p-d-flex p-jc-center">
    <!-- 送信コメント -->
    <input
      type="text"
      pInputText
      class="freeword-inputtext"
      [formControl]="freeword"
      [ngClass]='checkInputMaxBoundary(freeword)?"invalid-length":""'
      autocomplete="off"
    />
    <!-- 送信ボタン -->
    <button
      pButton
      type="button"
      label="検索"
      class="button1 freeword-button"
      (click)="searchPostalNo()"
    ></button>
  </div>

  <!-- リスト表示 -->
  <p-table
    #table
    [value]="postalNoList"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{first}~{last}件を表示中(全{totalRecords}件)"
    [rowsPerPageOptions]="[10, 50, 100]"
    emptyMessage="データが存在しません"
    selectionMode="single"
    styleClass="list-area p-datatable-gridlines postal-no-list"
  >
    <ng-template pTemplate="header">
      <tr>
        <th class="postal_no">郵便番号</th>
        <th class="prefectures">都道府県</th>
        <th class="city">所在地（市区町村）</th>
        <th class="town_area">所在地（町域）</th>
        <th class="address_building">所在地（番地・建物）</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-postalNoData>
      <tr (click)="setPostalNo(postalNoData)">
        <td>{{ postalNoData.postal_no }}</td>
        <td>{{ postalNoData.prefectures }}</td>
        <td>{{ postalNoData.city }}</td>
        <td>{{ postalNoData.town_area }}</td>
        <td>{{ postalNoData.address_building }}</td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>
