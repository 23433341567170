import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/** モジュール */
// ルーティング
import { PagesRoutingModule } from './pages-routing.module';

// 共通
import { SharedModule } from 'src/app//shared/shared.module';
import { LibraryModule } from 'src/app/library/library.module';

/** コンポーネント */
// Platform系統Component基幹
import { PlatformComponent } from './platform/platform.component';

// 新規入会時のパスワード設定
import { A212Component } from './platform/a212/a212.component';
// 新規入会時のパスワード設定完了
import { A213Component } from './platform/a213/a213.component';
// 共通部品
import { ApprovalDialogComponent } from './platform/common/approval-dialog/approval-dialog.component';
import { RoleDialogComponent } from './platform/common/role-dialog/role-dialog.component';
import { EvaluationDialogComponent } from './platform/common/evaluation-dialog/evaluation-dialog.component';
import { DatetimeDialogComponent } from './platform/common/datetime-dialog/datetime-dialog.component';
import { PostalNoDialogComponent } from './platform/common/postal-no-dialog/postal-no-dialog.component';
import { ImgPreviewComponent } from './platform/common/img-preview/img-preview.component';
import { A210StepsComponent } from './platform/a200-common/a210-steps/a210-steps.component';
// ログアウト
import { LogoutComponent } from './logout/logout.component';
// Master component
import { M501Component } from './platform/master/m501/m501.component';
import { M301Component } from './platform/master/m301/m301.component';
import { M401Component } from './platform/master/m401/m401.component';
import { M201Component } from './platform/master/m201/m201.component';
import { M101Component } from './platform/master/m101/m101.component';
import { C331DialogComponent } from './platform/common/c331-dialog/c331-dialog.component';
import { C341DialogComponent } from './platform/common/c341-dialog/c341-dialog.component';
import { ConfirmRejectMatchDialogComponent } from './platform/common/confirm-reject-match-dialog/confirm-reject-match-dialog.component';
import { MemoDialogComponent } from './platform/common/memo-dialog/memo-dialog.component';
import { LandingComponent } from './platform/landing/landing.component';
import { ContactComponent } from './platform/contact/contact.component';
import { TopComponent } from './platform/top/top.component';
import { NoticeListComponent } from './platform/top/notice/notice-list/notice-list.component';
import { NoticeComponent } from './platform/top/notice/notice.component';
import { C101Component } from './platform/c101/c101.component';
import { C101_2Component } from './platform/c101_2/c101_2.component';
import { C111Component } from './platform/c111/c111.component';
import { C121Component } from './platform/c121/c121.component';
import { C131Component } from './platform/c131/c131.component';
import { C141Component } from './platform/c141/c141.component';
import { CXXXComponent } from './platform/cxxx/cxxx.component';
import { C151Component } from './platform/c151/c151.component';
import { C201Component } from './platform/c201/c201.component';

@NgModule({
  declarations: [
    // Platform系統Component基幹
    PlatformComponent,
    // 新規入会時のパスワード設定
    A212Component,
    // 新規入会時のパスワード設定完了
    A213Component,
    
    // 入会申請詳細
    C101Component,
    C101_2Component,
    C111Component,
    C121Component,
    C131Component,
    C141Component,
    CXXXComponent,
    C151Component,
    C201Component,

    M101Component,
    M201Component,
    M301Component,
    M401Component,
    M501Component,

    // 共通部品
    RoleDialogComponent,
    EvaluationDialogComponent,
    DatetimeDialogComponent,
    PostalNoDialogComponent,
    C331DialogComponent,
    C341DialogComponent,
    ConfirmRejectMatchDialogComponent,
    A210StepsComponent,
    // ログアウト
    LogoutComponent,
    MemoDialogComponent,
    LandingComponent,
    ContactComponent,
    ImgPreviewComponent,
    TopComponent,
    NoticeListComponent,
    NoticeComponent,
  ],
  imports: [CommonModule, PagesRoutingModule, SharedModule, LibraryModule],
})
export class PagesModule {}
