<!-- メイン領域-->
<mat-drawer-container class="main-container" autosize>

  <p-dataView id="dataView" #dv [value]="searchResultsList" [paginator]="true" [rows]="10" [(first)]="pages"
    [showCurrentPageReport]="true" currentPageReportTemplate="{first}~{last}件表示({totalRecords}件中)"
    [rowsPerPageOptions]="[10, 50, 100]" styleClass="p-datatable-striped order-datatable" selectionMode="single"
    layout="list" [sortField]="sortField" [sortOrder]="sortOrder">
    <ng-template pTemplate="header">
      <div class="dv-header-button">
        <div class="p-d-flex p-jc-between">
          <div class="p-d-flex">
            <!-- 検索画面表示-->
            <button type="button" pButton icon="pi pi-search" (click)="search.toggle()" class="p-mr-2" pTooltip="検索"
              tooltipPosition="bottom"></button>
            <!-- カート追加 -->
          </div>
          <div class="p-as-center p-d-flex">
            <!-- 全件csv出力-->
            <button type="button" pButton label="一括承認" icon="" (click)="batchApprove()" class="p-mr-2"
              *ngIf="authorizeButtonBatchApprove()"></button>
            <button type="button" pButton icon="pi pi-file-o" (click)="exportCSV()" class="p-mr-2" pTooltip="全件CSV"
              tooltipPosition="bottom"></button>
          </div>
        </div>
      </div>
      <ng-container *ngIf="dv.layout == 'list'">
        <div class="dv-header-table-header">
          <!-- dataViewヘッダー -->
          <p-table styleClass="p-datatable-sm generate-input">
            <!-- 一覧画面ヘッダー表示-->
            <ng-template pTemplate="header">
              <tr class="p-col-2">
                <!-- APIヘッダーの表示 -->
                <th class="inputCheckbox"
                  *ngIf="(user.department_type == '2' || user.department_type == '3' || user.department_type == '9')">
                  <p-checkbox name="checkAll" [(ngModel)]="allChecked" [binary]="true"
                    (click)="setCheckAll(dv, $event)"></p-checkbox>
                </th>
                <ng-container *ngFor="let column of columnOrder">
                  <th [pSortableColumn]="column.field" (click)="onSortChange(column.field)">
                    {{ column.header }}
                    <p-sortIcon [field]="column.field"></p-sortIcon>
                  </th>
                </ng-container>
                <th class="btn-area p-text-bold" style="text-align: center;">
                  <p>操作</p>
                </th>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </ng-container>
    </ng-template>
    <ng-template let-searchResults let-rowIndex="rowIndex" pTemplate="listItem">
      <p-table #table [value]="[searchResults]" styleClass="p-table-striped order-table" selectionMode="single"
        dataKey="delivery_id" expandableRows="true" (onRowClick)="table.toggleRow($event.data)">
        <!-- 一覧画面ボディ表示-->
        <ng-template pTemplate="body">
          <tr class="p-col-2" id="tableResult" [ngClass]="searchResults['status'] == 'キャンセル' ? 'cancelled': ''">
            <td class="inputCheckbox"
              *ngIf="(user.department_type == '2' || user.department_type == '3' || user.department_type == '9')">
              <p-checkbox *ngIf="authorizeCheckbox(searchResults['status'], searchResults['delivery_kbn'])"
                name="cartCheckBox" value="{{ searchResults.order_id }}" [(ngModel)]="cartSelected"
                (click)="onCheckItem()"></p-checkbox>
            </td>
            <ng-container *ngFor="let column of columnOrder; index as i" [ngSwitch]="true">
              <!-- 一覧項目値表示(詳細画面遷移項目) -->
              <td *ngIf="0 == i; else hideContent" [pTooltip]="searchResults[column.field]" #tt
                (mouseenter)="onHover($event)" [tooltipDisabled]="tt.dataset.showtooltip!='true'" data-showTooltip=""
                showDelay="500" hideDelay="500">
                <label (click)="detail(searchResults[column.field], searchResults['delivery_kbn'])">
                  <u>{{ searchResults[column.field] }}</u>
                </label>
              </td>
              <!-- 一覧項目値表示 -->
              <ng-template #hideContent [ngSwitch]="column?.input_type">
                <td *ngSwitchCase="'NUMBER'" [pTooltip]="searchResults[column.field]" #tt (mouseenter)="onHover($event)"
                  [tooltipDisabled]="tt.dataset.showtooltip!='true'" data-showTooltip="" showDelay="500"
                  hideDelay="500">
                  {{ searchResults[column.field] | number }}
                </td>
                <td *ngSwitchDefault [pTooltip]="searchResults[column.field]" #tt (mouseenter)="onHover($event)"
                  [tooltipDisabled]="tt.dataset.showtooltip!='true'" data-showTooltip="" showDelay="500"
                  hideDelay="500">
                  {{ searchResults[column.field] }}
                </td>
              </ng-template>
            </ng-container>
            <td class="btn-area">
              <!-- 編集ボタン -->
              <button
                *ngIf="authorizeButtonApprove(searchResults['status'], searchResults['delivery_kbn'], searchResults['user_id_sales'], searchResults['user_id_business'])"
                pButton (click)="approve(searchResults['order_id'], searchResults['delivery_kbn'])" icon="pi pi-refresh"
                class="p-button-rounded p-button-info" pTooltip="承認" tooltipPosition="bottom"></button>
              <!-- 編集ボタン -->
              <button *ngIf="authorizeButtonEdit(searchResults['status'], searchResults['user_id_sales'], searchResults['user_id_business'])" pButton
                (click)="edit(searchResults['order_id'], searchResults['delivery_kbn'])" icon="pi pi-pencil"
                class="p-button-rounded p-button-success" pTooltip="編集" tooltipPosition="bottom"
                [disabled]="user.department_type != '3' && user.department_type != '9' && user.department_type != '1'"></button>
              <!-- 複製ボタン -->
              <button *ngIf="authorizeButtonDuplicate(searchResults['status'], searchResults['user_id_sales'], searchResults['user_id_business'])" pButton
                (click)="duplicate(searchResults['order_id'], searchResults['delivery_kbn'])" icon="pi pi-clone"
                class="p-button-rounded p-button-info" pTooltip="複製" tooltipPosition="bottom"
                [disabled]="user.department_type != '3' && user.department_type != '9' && user.department_type != '1'"></button>
              <!-- 削除ボタン -->
              <button *ngIf="authorizeButtonDelete(searchResults['status'], searchResults['user_id_sales'], searchResults['user_id_business'])" pButton
                (click)="delete(searchResults[columnOrder[0].field], searchResults)" icon="pi pi-trash"
                class="p-button-rounded p-button-danger" pTooltip="キャンセル" tooltipPosition="bottom"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
  </p-dataView>

  <!-- 検索画面-->
  <mat-drawer #search class="search-sidenav" mode="side">
    <!-- 検索画面非表示-->
    <button type="button" pButton icon="pi pi-arrow-left" (click)="search.toggle()" class="p-mr-2"></button>

    <!-- 検索画面[検索項目生成(アイテムアダプター)]-->
    <app-generate-search [searchEndPoint]="searchEndPoint" [searchTemplateId]="searchTemplateId"
      (generateSearchInformation)="searchResult(dv, $event)"></app-generate-search>
  </mat-drawer>
</mat-drawer-container>

<!-- 詳細画面[表示項目生成(アイテムアダプター)]-->
<app-generate-display></app-generate-display>

<!-- 登録画面[入力項目生成(アイテムアダプター)]-->
<app-generate-input (reloadID)="searchReplacement($event)"></app-generate-input>

<!-- 削除ダイアログ-->
<app-delete-dialog (reloadID)="searchReplacement($event)"></app-delete-dialog>

<!-- Toastメッセージ表示 -->
<p-toast position="top-center"></p-toast>