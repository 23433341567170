import { Component, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { GenerateDisplayComponent } from 'src/app/shared/generate/generate-display/generate-display.component';
import { MessageCommonComponent } from 'src/app/shared/html-parts/message-common/message-common.component';
import { GenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import { CONSTANT, DATE_FORMAT, DELIVERY_KBN, LOADING_KEY, ORDER_STATUS, ROLES } from 'src/app/shared/constant/constant';
import { LoadData, LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import { ExportFileService } from 'src/app/shared/html-parts/export-file/export-file.service';
import { DatePipe } from '@angular/common';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { C201_TEMPLATE } from 'manager/template-constant';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { DataView } from 'primeng/dataview';
import {
  API_URL_APPROVE_ORDER,
  API_URL_BATCH_APPROVE_ORDER,
  API_URL_GENERATE_SEARCH,
  API_URL_INPUT_MASTER,
  API_URL_ORDER,
} from 'manager/http-constants';
import { GenerateInputComponent } from 'src/app/shared/generate/generate-input/generate-input.component';
import { GENERATE_INPUT_TYPE } from 'src/app/shared/generate/generate-input/constant';
import { SESSION_KEY } from 'manager/environment';
import { DeleteDialogComponent } from "src/app/shared/html-parts/delete-dialog/delete-dialog.component";
import { DomSanitizer } from '@angular/platform-browser';
import { PdfService } from 'src/app/shared/service/pdf.service';
import { MessageData } from 'src/app/shared/html-parts/message-common/message-data';

@Component({
  selector: 'app-c201',
  templateUrl: './c201.component.html',
  styleUrls: ['./c201.component.scss']
})
export class C201Component implements OnInit {
  /** 詳細画面 */
  @ViewChild(GenerateDisplayComponent)
  generateDisplayItemsComponent: GenerateDisplayComponent;

  @ViewChild('dv')
  dv: DataView;
  // 検索項目生成エンドポイント
  public searchEndPoint: string = API_URL_GENERATE_SEARCH;

  /** 新規登録、編集、複製画面 */
  @ViewChild(GenerateInputComponent)
  generateInputComponent: GenerateInputComponent;

  /** 削除ダイアログ */
  @ViewChild(DeleteDialogComponent)
  deleteDialogComponent: DeleteDialogComponent;

  // 検索項目生成テンプレートID
  public searchTemplateId: number = C201_TEMPLATE.SEARCH_TEMPLATE_ID;

  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnOrder: any[];

  // 画面検索結果一覧格納先
  searchResultsList: any[];

  // list checked of delivery-search
  searchCheckbox: any[];

  // ページ数
  pages: number = 0;

  // カート選択リスト格納先
  cartSelected: any[] = [];

  // 検索条件保持
  generateSearchItems: Object;

  // 全選択チェックボックス
  allChecked: boolean = false;

  // checked value of items search
  checkedArr: any[];

  // dataView ソートカラム名
  sortField: string;

  // dataView ソート順 (1:昇順, -1:降順)
  sortOrder: number;

  user: any = null;

  constructor(
    private dbOperationService: DbOperationService,
    private router: Router,
    private commonService: CommonService,
    public datePipe: DatePipe,
    private loadingState: LoadingState,
    private exportFileService: ExportFileService,
    private route: ActivatedRoute,
    private pdfService: PdfService,
    protected sanitizer: DomSanitizer,
    private messageData: MessageData,
  ) { }

  ngOnInit() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    this.user = JSON.parse(window.sessionStorage.getItem(SESSION_KEY.loginUserInformation));

    // ヘッダー情報取得処理(画面用)
    this.dbOperationService
      .getHeaderList(C201_TEMPLATE.TEMPLATE_HEADER_LIST)
      .subscribe((response) => {
        this.columnOrder = response.body;
      });

    // 検索処理を実施
    this.searchResult();
  }

  /**
   * 検索処理
   * @param table テーブル状態
   * @param generateSearchItems 検索条件
   */
  public searchResult(table?: DataView, generateSearchItems?: Object) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
      })
    );

    if (!generateSearchItems && this.user?.department_type == ROLES.SALES_1) {
      generateSearchItems = {
        email_business: this.user.email
      }
    }

    this.generateSearchItems = generateSearchItems;

    // 販促資材マスタ一覧取得処理(画面用)
    this.dbOperationService
      .getForkJoinData(
        C201_TEMPLATE.TABLE_NAME,
        API_URL_ORDER,
        C201_TEMPLATE.TEMPLATE_SEARCH_RESULT,
        this.generateSearchItems,
        true
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 販促資材マスタ一覧情報のJSONをオブジェクトに格納する
          this.searchResultsList = response.body;
          this.dv.first = 0;
        } else {
          this.searchResultsList = [];
        }
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3);
      });
    // ページ数を初期化
  }

  /**
   * 再検索処理＆データ置換
   * @param pkeyId コード値
   */
  public searchReplacement(pkeyId: string) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
      })
    );

    if (pkeyId == CONSTANT.EMPTY_STRING) {
      this.loadingState.loadSleepEnd(0.3);
      return;
    }

    // 返却されたコード値が存在するか否か
    if (null == pkeyId || undefined == pkeyId) {
      // 返却されたコード値が存在しない場合
      // 検索処理を実施
      this.searchResult();
      return;
    }

    // 営業日マスタ一覧取得処理(画面用)
    this.dbOperationService
      .getSingleData(
        API_URL_ORDER,
        C201_TEMPLATE.TEMPLATE_DETAIL_FOR_APPROVE,
        this.columnOrder[0].field,
        pkeyId
      )
      .subscribe(async (response) => {
        // コード値の一覧情報が存在するか否か
        if (this.commonService.checkNoneResponse(response)) {
          // 一覧情報が存在しない場合

          // 検索結果一覧から該当データを削除
          this.searchResultsList = this.searchResultsList.filter(
            (val) => val[this.columnOrder[0].field] !== pkeyId
          );

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadSleepEnd(0.3);
        } else {
          // 一覧情報が存在する場合

          // 検索結果一覧格納先から対象のデータの行数を抽出
          this.searchResultsList = this.searchResultsList.filter(item => item.order_id != pkeyId);
          this.searchResultsList.unshift(response.body[0]);
          this.dv.first = 0;
          this.gotoTop();

          // 検索結果一覧情報のJSONをオブジェクトに置換する
          if (
            (response.body[0].update_function == 'ApproveOrder' && response.body[0].status == ORDER_STATUS.ARRANGED_2_TEXT && response.body[0].delivery_kbn == DELIVERY_KBN.SPOT_3_TEXT) ||
            ((response.body[0].update_function == 'EditOrder.edit' || response.body[0].update_function == 'AddDuplicateOrder') && (response.body[0].delivery_kbn == DELIVERY_KBN.AREA_1_TEXT || response.body[0].delivery_kbn == DELIVERY_KBN.ROUTE_2_TEXT))
          ) {
            await this.outputPdf(response.body)
          }
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadSleepEnd(0.3);
        }
      });
  }

  gotoTop() {
    let top = document.getElementById("dataView");
    if (top) {
      top.scrollIntoView();
    }
  }

  async outputPdf(data) {
    const newTab = window.open('', '_blank');

    this.pdfService.init();
    await this.pdfService.loadFont();
    const blob = await this.pdfService.export(data);

    const blobUrl = URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl));

    newTab.document.body.appendChild(iframe);
    iframe.contentWindow.print();
  }

  /**
  * 検索処理
  * @param generateSearchItems 検索条件
  */
  selectRow(event: any) {
    // 届け先資材(WORK)一覧取得処理(画面用)

  }

  /**
   * 詳細情報画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  public detail(pkeyId: string, delivery_kbn: string) {
    // 詳細画面表示
    const inputGenerateDisplay: GenerateDisplay = new GenerateDisplay();
    inputGenerateDisplay.endPoint = API_URL_ORDER;
    delivery_kbn == DELIVERY_KBN.SPOT_3_TEXT ? inputGenerateDisplay.templateId = C201_TEMPLATE.OUTPUT_TEMPLATE_ID_USE_SPOT_1 : inputGenerateDisplay.templateId = C201_TEMPLATE.OUTPUT_TEMPLATE_ID_USE_SPOT_0;
    this.generateDisplayItemsComponent.initial(inputGenerateDisplay, pkeyId);
  }

  /**
 * 編集画面表示
 * @param pkeyId IDコード(クリックされたID)
 */
  public edit(pkeyId: string, delivery_kbn: string) {
    // 編集画面表示
    const templateId = delivery_kbn == DELIVERY_KBN.SPOT_3_TEXT ? C201_TEMPLATE.TEMPLATE_EDIT_USE_SPOT_1 : C201_TEMPLATE.TEMPLATE_EDIT_USE_SPOT_0;
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.EDIT,
      API_URL_INPUT_MASTER,
      API_URL_ORDER,
      API_URL_ORDER,
      templateId,
      pkeyId
    );
  }

  /**
 * 編集画面表示
 * @param pkeyId IDコード(クリックされたID)
 */
  public approve(pkeyId: string) {
    // 編集画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.APPROVE,
      API_URL_INPUT_MASTER,
      API_URL_ORDER,
      API_URL_APPROVE_ORDER,
      C201_TEMPLATE.TEMPLATE_APPROVE,
      pkeyId
    );
  }

  /**
   * CSV出力
   */
  public exportCSV() {
    // csvファイル名の設定
    const fileName =
      '配送・集荷履歴一覧' +
      CONSTANT.UNDERBAR +
      this.datePipe.transform(new Date(), DATE_FORMAT.DATE_HYPHEN);

    // CSVファイル出力
    this.exportFileService.exportTemplateCsv(
      fileName,
      C201_TEMPLATE.TABLE_NAME,
      API_URL_ORDER,
      C201_TEMPLATE.TEMPLATE_EXPORT_CSV,
      this.generateSearchItems,
      true
    );
  }
  /**
   * Download PDF file
   */
  public downloadPdf(filePath: string) {
    window.location.href = filePath;
  }

  /**
 * dataView ソート処理
 */
  protected onSortChange(column: string) {
    let setOrder;
    if (this.sortField == column) {
      setOrder = this.sortOrder == 1 ? -1 : 1;
    } else {
      setOrder = 1
    }
    this.sortOrder = setOrder;
    this.sortField = column;
  }

  /**
  * check overflow of element
  * @param el html element of list 
  * @returns 
  */
  isOverflow(el: HTMLElement): boolean {
    return el.clientWidth < el.scrollWidth;
  }

  onHover(e) {
    if (this.isOverflow(e.target)) {
      e.target.dataset.showtooltip = 'true'
    };
  }

  authorizeButtonApprove(status: string, delivery_kbn: string, user_id_sales: string, user_id_business: string) {

    if ((this.user?.department_type == ROLES.COMMON_3 || this.user?.department_type == ROLES.ADMIN_9) &&
      (status == ORDER_STATUS.REQUESTED_1_TEXT || status == ORDER_STATUS.ARRANGED_2_TEXT)) {
      return true;
    }

    if (this.user?.department_type == ROLES.TRANSIT_2 &&
      (status == ORDER_STATUS.REQUESTED_1_TEXT || status == ORDER_STATUS.ARRANGED_2_TEXT) &&
      (delivery_kbn == DELIVERY_KBN.AREA_1_TEXT || delivery_kbn == DELIVERY_KBN.ROUTE_2_TEXT)) {
      return true;
    }

    if (this.user?.department_type == ROLES.SALES_1 &&
      status == ORDER_STATUS.REQUESTED_1_TEXT &&
      delivery_kbn == DELIVERY_KBN.SPOT_3_TEXT &&
      (this.user?.user_id == String(user_id_sales) || this.user?.user_id == String(user_id_business))
    ) {
      return true;
    }

    return false;
  }

  authorizeButtonEdit(status: string, user_id_sales: string, user_id_business: string) {
    if ((this.user?.department_type == ROLES.COMMON_3 || this.user?.department_type == ROLES.ADMIN_9) &&
      status == ORDER_STATUS.REQUESTED_1_TEXT) {
      return true;
    }

    if (this.user?.department_type == ROLES.SALES_1 &&
      status == ORDER_STATUS.REQUESTED_1_TEXT &&
      (this.user?.user_id == String(user_id_sales) || this.user?.user_id == String(user_id_business))
    ) {
      return true;
    }

    return false;
  }

  authorizeButtonDuplicate(status: string, user_id_sales: string, user_id_business: string) {
    if ((this.user?.department_type == ROLES.COMMON_3 || this.user?.department_type == ROLES.ADMIN_9) &&
      status == ORDER_STATUS.REQUESTED_1_TEXT) {
      return true;
    }

    if (this.user?.department_type == ROLES.SALES_1 &&
      (this.user?.user_id == String(user_id_sales) || this.user?.user_id == String(user_id_business))) {
      return true;
    }

    return false
  }

  authorizeButtonDelete(status: string, user_id_sales: string, user_id_business: string) {
    if (this.user?.department_type == ROLES.SALES_1 &&
      status == ORDER_STATUS.REQUESTED_1_TEXT &&
      (this.user?.user_id == String(user_id_sales) || this.user?.user_id == String(user_id_business))
    ) {
      return true;
    } else {
      return false
    }
  }

  authorizeButtonBatchApprove() {
    if (this.user?.department_type == ROLES.TRANSIT_2 || this.user?.department_type == ROLES.COMMON_3 || this.user?.department_type == ROLES.ADMIN_9) {
      return true;
    } else {
      return false
    }
  }

  /**
 * 削除処理
 * @param pkeyId IDコード(クリックされたID)
 */
  public delete(pkeyId: string, user: any) {
    const title = "キャンセルしてもよろしいですか?";
    // 削除ダイアログにより削除処理
    this.deleteDialogComponent.deleteDialog(
      API_URL_ORDER,
      this.columnOrder[0].header,
      pkeyId,
      title
    );
  }

  /**
 * 複製画面表示
 * @param pkeyId IDコード(クリックされたID)
 */
  public duplicate(pkeyId: string, delivery_kbn: string) {
    // 複製画面表示
    const templateId = delivery_kbn == DELIVERY_KBN.SPOT_3_TEXT ? C201_TEMPLATE.TEMPLATE_DUPLICATE_USE_SPOT_1 : C201_TEMPLATE.TEMPLATE_DUPLICATE_USE_SPOT_0;
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.DUPLICATE,
      API_URL_INPUT_MASTER,
      API_URL_ORDER,
      API_URL_ORDER + pkeyId + '/duplicate/',
      templateId,
      pkeyId
    );
  }

  public batchApprove() {
    // カートが選択されているか否か
    if (!this.cartSelected) {
      // カートが選択されていない場合
      return;
    }

    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: CONSTANT.EMPTY_STRING,
        background_color: CONSTANT.EMPTY_STRING,
        opacity: 0.3,
      }),
      LOADING_KEY.DATA_INSERT
    );

    // カート登録
    this.dbOperationService
      .updateMultipleData(API_URL_BATCH_APPROVE_ORDER, {
        order_id: this.cartSelected.join(CONSTANT.COMMA),
      })
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd(LOADING_KEY.DATA_INSERT);
        this.messageData.clearAllToastMessage();

        // レスポンスを判定
        if (this.messageData.responseToastMessageCustom(response, true, '1件を選択してください。')) {
          // レスポンスが正常終了の場合

          // 発注管理_届先選択へ遷移
          this.searchResult(this.dv, {});
          this.cartSelected = [];
          this.allChecked = false;
          this.dv.first = 0;
        }
      });
  }

  protected onCheckItem() {
    this.refreshCheckItems();
  }

  // Handle checked item checkall
  protected refreshCheckItems() {
    const displayItems = this.searchResultsList.slice(this.dv.first, this.dv.first + this.dv.rows);
    const totalChecked = displayItems.length == 0 ? 0 : displayItems.filter(i => this.cartSelected.indexOf(i.order_id.toString()) !== -1).length;
    this.allChecked = (totalChecked == displayItems.length);
  }

  /**
* チェックボックス全選択
*/
  protected setCheckAll(dv: DataView, event: PointerEvent) {
    if (!this.allChecked) {
      this.cartSelected = [];
    } else {
      this.cartSelected = this.searchResultsList.slice(dv.first, dv.first + dv.rows).filter((order) => this.authorizeCheckbox(order.status, order.delivery_kbn)).map(i => i.order_id.toString());
    }
  }

  public authorizeCheckbox(status: string, delivery_kbn: string) {
    if ((this.user?.department_type == ROLES.TRANSIT_2 || this.user?.department_type == ROLES.COMMON_3 || this.user?.department_type == ROLES.ADMIN_9) &&
      status == ORDER_STATUS.REQUESTED_1_TEXT &&
      (delivery_kbn == DELIVERY_KBN.AREA_1_TEXT || delivery_kbn == DELIVERY_KBN.ROUTE_2_TEXT)) {
      return true;
    }

    return false;
  }
}
