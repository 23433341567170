import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageCommonComponent } from 'src/app/shared/html-parts/message-common/message-common.component';
import { CONSTANT, DELIVERY_COLLECT_KBN, LOADING_KEY } from 'src/app/shared/constant/constant';
import { LoadData, LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import { DatePipe } from '@angular/common';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { C141_TEMPLATE, CXXX_TEMPLATE } from 'manager/template-constant';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { DataView } from 'primeng/dataview';
import {
  API_URL_PREVIEW_WORK_ORDER,
  API_URL_WORK_ORDER,
  API_URL_WORK_DELIVERY_DETAIL,
  API_URL_DELETE_WORK,
  API_URL_INPUT_MASTER,
} from 'manager/http-constants';
import { MessageData } from 'src/app/shared/html-parts/message-common/message-data';
import { DeleteDialogComponent } from 'src/app/shared/html-parts/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-c141',
  templateUrl: './c141.component.html',
  styleUrls: ['./c141.component.scss']
})
export class C141Component implements OnInit {
  /** メッセージ */
  @ViewChild(MessageCommonComponent)
  messageCommonComponent: MessageCommonComponent;

  @ViewChild('dv')
  dv: DataView;

  /** 削除ダイアログ */
  @ViewChild(DeleteDialogComponent)
  deleteDialogComponent: DeleteDialogComponent;

  // 検索項目生成テンプレートID
  public searchTemplateId: number = C141_TEMPLATE.SEARCH_TEMPLATE_ID;

  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnOrder: any[];

  // 画面検索結果一覧格納先
  searchResultsList: any[];

  // list checked of delivery-search
  searchCheckbox: any[];

  // ページ数
  pages: number = 0;

  delivery_collect_kbn: string = DELIVERY_COLLECT_KBN.DELIVERY_0;

  delivery_kbn: number = 4;

  use_spot: number;

  SEARCH_RESULTS_TEMPLATE_ID: number;

  // カート選択リスト格納先
  cartSelected: any[] = [];

  listHopeShipTime: any[] = [];

  listVehicleType: any[] = [];

  listDeliveryCompany: any[] = [];

  listWorkDeliveryDetail: any[] = [];

  // 検索条件保持
  generateSearchItems: Object;

  // 全選択チェックボックス
  allChecked: boolean = false;

  // checked value of items search
  checkedArr: any[];

  // dataView ソートカラム名
  sortField: string;

  // dataView ソート順 (1:昇順, -1:降順)
  sortOrder: number;

  errorMessages: {
    index: number;
    columnId: string;
    columnName: string;
    detail: string;
  }[] = [];

  dispErrors: boolean = false;

  generateInputItems: any[];

  existData: boolean = false;

  deliveryKbn: string = "";

  constructor(
    private dbOperationService: DbOperationService,
    private router: Router,
    private commonService: CommonService,
    public datePipe: DatePipe,
    private loadingState: LoadingState,
    private route: ActivatedRoute,
    private messageData: MessageData,
  ) { }

  async ngOnInit() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    const { delivery_collect_kbn } = this.route.snapshot.queryParams;
    if (delivery_collect_kbn) {
      this.delivery_collect_kbn = delivery_collect_kbn;
    } else {
      this.router.navigate(['/pages/platform/c141'], { queryParams: { delivery_collect_kbn: DELIVERY_COLLECT_KBN.DELIVERY_0 } });
    }

    await this.getWorkDeliveryDetail();

    this.SEARCH_RESULTS_TEMPLATE_ID = this.use_spot == 1 ? C141_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID_USE_SPOT_1 : C141_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID_USE_SPOT_0;

    // ヘッダー情報取得処理(画面用)
    this.dbOperationService
      .getHeaderList(this.SEARCH_RESULTS_TEMPLATE_ID)
      .subscribe((response) => {
        this.columnOrder = response.body;
      });

    await this.getPulldownItem();

    // 検索処理を実施
    this.searchResult();

    this.getGenerateInput();

    this.dispErrors = false;
  }

  /**
 * Get Work Delivery Detail
 * This function is used to get the work delivery detail information and update this.use_spot
 * @returns {Promise<any>} A promise that returns the response data
 */
  getWorkDeliveryDetail() {
    // 画面カスタムロードフラグをON(ロード中状態)
    return new Promise((resolve) => {
      this.dbOperationService
        .getData(
          API_URL_WORK_DELIVERY_DETAIL,
          CXXX_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID,
          {
            delivery_collect_kbn: [this.delivery_collect_kbn]
          }
        )
        .subscribe((response) => {
          // コード値の一覧情報が存在するか否か
          if (!this.commonService.checkNoneResponse(response)) {
            // 取得したデータをuse_spotに代入
            this.use_spot = response.body[0].use_spot === true ? 1 : 0;
            this.listWorkDeliveryDetail = response.body;
          }
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadSleepEnd(0.3);
          resolve(null);
        });
    });
  }

  /**
   * 検索処理
   * @param table テーブル状態
   * @param generateSearchItems 検索条件
   */
  public searchResult(table?: DataView, generateSearchItems?: Object) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
      })
    );
    let data = [];

    // 販促資材マスタ一覧取得処理(画面用)
    this.dbOperationService
      .getData(
        API_URL_PREVIEW_WORK_ORDER,
        this.SEARCH_RESULTS_TEMPLATE_ID,
        {
          delivery_collect_kbn: [this.delivery_collect_kbn]
        },
        true
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 販促資材マスタ一覧情報のJSONをオブジェクトに格納する
          data = response.body;
        } else {
          data = [];
        }

        this.dbOperationService
          .getNoTemplateData(
            API_URL_WORK_ORDER,
            {
              delivery_collect_kbn: [this.delivery_collect_kbn]
            }
          )
          .subscribe((response) => {
            // コード値の一覧情報が存在するか否か
            if (!this.commonService.checkNoneResponse(response)) {
              for (let i = 0; i < response.body.length; i++) {
                const index = data.map(e => e.item_id).indexOf(response.body[i].item_id);
                data[index] = response.body[i];
              }
            }
            this.searchResultsList = data;
            this.deliveryKbn = data[0]?.delivery_kbn_id;
            this.dv.first = 0;
            this.existData = true;
          });
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3);
      });
    // ページ数を初期化
    this.pages = 0;
  }

  public getGenerateInput() {
    this.dbOperationService
      .getNoTemplateData(
        API_URL_INPUT_MASTER,
        {
          TemplateID: this.use_spot == 1 ? C141_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID_USE_SPOT_1 : C141_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID_USE_SPOT_0
        }
      )
      .subscribe((response) => {
        if (!this.commonService.checkNoneResponse(response)) {
          this.generateInputItems = response.body;
        } else {
          this.generateInputItems = [];
        }
      });
  }

  /**
   * カート追加
   */
  public addCart() {
    // カートが選択されているか否か
    if (this.searchResultsList[0] === undefined) {
      // カートが選択されていない場合
      return;
    }

    const requestBody = this.searchResultsList.map((item) => {
      let order_num = item.order_num ?? '';
      if (order_num != '') {
        order_num = order_num.toLocaleString('fullwide', { useGrouping: false });
      }

      return {
        item_id: item.item_id,
        delivery_collect_kbn: this.delivery_collect_kbn,
        order_num: order_num,
        vehicle_type: item.vehicle_type ?? '',
        delivery_company: item.delivery_company ?? '',
        hope_delivery_date: item.hope_delivery_date,
        hope_ship_date: item.hope_ship_date,
        hope_ship_time: item.hope_ship_time ?? '',
        hope_delivery_time: item.hope_delivery_time,
        spot_reason: item.spot_reason,
        delivery_note: item.delivery_note,
        delivery_kbn_id: item.delivery_kbn_id,
        delivery_kbn_name: item.delivery_kbn_name
      }
    });

    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: CONSTANT.EMPTY_STRING,
        background_color: CONSTANT.EMPTY_STRING,
        opacity: 0.3,
      }),
      LOADING_KEY.DATA_INSERT
    );

    // カート登録
    this.dbOperationService
      .insertData(API_URL_WORK_ORDER, requestBody)
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd(LOADING_KEY.DATA_INSERT);
        this.clearErrorCss();
        this.messageCommonComponent.clearToastMessage();

        // レスポンスを判定
        if (this.messageCommonComponent.responseToastMessage(response)) {
          // レスポンスが正常終了の場合

          // 発注管理_届先選択へ遷移
          this.router.navigate(['pages/platform/c151'], { queryParams: { delivery_collect_kbn: this.delivery_collect_kbn } });
        }
        else {
          this.callBackOnAddOrderError(response.body);
        }
      });
  }

  clearErrorCss() {
    const keys = Object.keys(this.searchResultsList[0]);
    let index = 1;
    for (let i = 0; i < this.searchResultsList.length; i++) {
      for (let j = 0; j < keys.length; j++) {
        const id = index + keys[j]
        let element = document.getElementById(id);
        if (element) {
          if (element.nodeName === 'P-DROPDOWN') {
            element = element.getElementsByTagName('div')[0];
          } else if (element.nodeName === 'P-CALENDAR') {
            element = element.getElementsByTagName('span')[0];
          }
          element.style.setProperty('border', '1px solid #ced4da');
          element.style.setProperty('border-radius', '3px');
        }
      }
      index++;
    }
  }

  callBackOnAddOrderError(error: any) {
    this.dispErrors = true;
    // formControlにエラーを入れる作戦はページングでいったり来たりするとinvalid扱いにならないことから断念。
    this.errorMessages = error.map((e) => {
      if (e.row_no && e.column_id) {
        const index = e.row_no;
        const columnId = e.column_id;
        const { message } = e;
        const colName = e.column_name;
        return { index, columnId, columnName: colName, detail: message };
      } else {
        return {
          index: null,
          columnId: null,
          columnName: null,
          detail: e.message,
        };
      }
    });

    for (let i = 0; i < this.errorMessages.length; i++) {
      const id = this.errorMessages[i].index + this.errorMessages[i].columnId
      let element = document.getElementById(id);
      if (element) {
        if (element.nodeName === 'P-DROPDOWN') {
          element = element.getElementsByTagName('div')[0];
        } else if (element.nodeName === 'P-CALENDAR') {
          element = element.getElementsByTagName('span')[0];
        }
        element.style.setProperty('border', '1px solid red');
        element.style.setProperty('border-radius', '3px');
      }
    }
  }

  /**
 * dataView ソート処理
 */
  protected onSortChange(column: string) {
    let setOrder;
    if (this.sortField == column) {
      setOrder = this.sortOrder == 1 ? -1 : 1;
    } else {
      setOrder = 1
    }
    this.sortOrder = setOrder;
    this.sortField = column;
  }

  public getPulldownItem() {
    if (this.listWorkDeliveryDetail[0].delivery_type) {
      this.listHopeShipTime = String(this.listWorkDeliveryDetail[0].delivery_type).split(",");
    } else {
      this.listHopeShipTime = null;
    }

    return new Promise<any>((resolve) => {
      this.dbOperationService.getDicLists(C141_TEMPLATE.DIC_VEHICLE_TYPE + "," + C141_TEMPLATE.DIC_DELIVERY_COMPANY).subscribe((response) => {
        if (!this.commonService.checkNoneResponse(response)) {
          this.listVehicleType = response.body.filter(x => x.sp_dic_id == C141_TEMPLATE.DIC_VEHICLE_TYPE);
          this.listDeliveryCompany = response.body.filter(x => x.sp_dic_id === C141_TEMPLATE.DIC_DELIVERY_COMPANY);
        } else {
          this.listVehicleType = null;
          this.listDeliveryCompany = null;
        }
        resolve(null);
      });
    });
  }

  /**
  * 削除処理
  * @param pkeyId IDコード(クリックされたID)
  */
  delete(item_id: string, item_category: string, delivery_kbn_name: string) {
    if (delivery_kbn_name.length > 20) {
      delivery_kbn_name = delivery_kbn_name.substring(0, 17) + "...";
    }
    const title = "荷物";
    const content = "荷物種別：" + item_category + "<br>便名：" + delivery_kbn_name
    // 削除ダイアログにより削除処理
    this.deleteDialogComponent.deleteDialog(
      API_URL_DELETE_WORK + this.delivery_collect_kbn + "/",
      content,
      item_id,
      title
    );
  }

  moveC111() {
    this.router.navigate(['pages/platform/c111'], { queryParams: { delivery_collect_kbn: this.delivery_collect_kbn } });
  }

  /**
   * check overflow of element
   * @param el html element of list 
   * @returns 
  */
  isOverflow(el: HTMLElement): boolean {
    return el.clientWidth < el.scrollWidth;
  }

  onHover(e) {
    if (this.isOverflow(e.target)) {
      e.target.dataset.showtooltip = 'true'
    };
  }

  changeVehicleType(val, row: number) {
    let vehicleTypeDetail = this.listVehicleType.find(x => x.sp_code == val.value);
    if (vehicleTypeDetail) {
      let options = this.listDeliveryCompany.filter(x => vehicleTypeDetail.sp_ext.split(",").includes(x.sp_code));
      this.searchResultsList[row].delivery_company = options[0]?.sp_code;
    }
  }

  changeDeliveryCompany(val, row: number) {
    if (this.searchResultsList[row].vehicle_type == null || this.searchResultsList[row].vehicle_type == null || this.searchResultsList[row].vehicle_type == CONSTANT.EMPTY_STRING) {
      let currentDeliveryCompany = val.value;
      if (currentDeliveryCompany) {
        let options = this.listVehicleType.filter(x => x.sp_ext.split(",").includes(currentDeliveryCompany));
        this.searchResultsList[row].vehicle_type = options[0]?.sp_code;
      }
    }
  }

  getDeliveryCompanyOption(row) {
    if (row != null && row != undefined) {
      let currentVehicleTypeId = this.searchResultsList[row].vehicle_type;
      let vehicleTypeDetail = this.listVehicleType.find(x => x.sp_code == currentVehicleTypeId);
      if (vehicleTypeDetail) {
        return this.listDeliveryCompany.filter(x => vehicleTypeDetail.sp_ext.split(",").includes(x.sp_code));
      } else {
        return this.listDeliveryCompany;
      }
    }
  }

  getVehicleTypeOption(row) {
    let currentDeliveryCompany = this.searchResultsList[row]?.delivery_company;
    if (row != null && row != undefined) {
      if (currentDeliveryCompany != null && currentDeliveryCompany != undefined && currentDeliveryCompany != CONSTANT.EMPTY_STRING) {
        return this.listVehicleType.filter(x => x.sp_ext.split(",").includes(currentDeliveryCompany));
      }
    }
    return this.listVehicleType;
  }

  getPlaceholder(column_id: string) {
    return this.generateInputItems.find(x => x.column_id == column_id).column_memo;
  }

  getMaxlength(column_id: string) {
    return this.generateInputItems.find(x => x.column_id == column_id).column_hsize;
  }

  blockNonInteger(event: KeyboardEvent) {
    // Allow only digit characters
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault(); // Prevent any non-digit input
    }
  }
}
