import { Injectable } from '@angular/core';
import {
  FormControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { INPUT_INFORMATIONS_API_CONSTANT } from '../constant/api-constant';
import { ValidatorService } from './validator.service';

@Injectable({
  providedIn: 'root',
})
export class Validator {
  constructor(private validatorService: ValidatorService) { }

  /**
   * 共通バリデーションチェック(カスタムバリデーター)
   * @param tblId テーブル名
   * @param colId カラム名
   * @param inputType 入力項目タイプ
   */
  public CheckValidator(
    tblId: string,
    colId: string,
    inputType: string
  ): AsyncValidatorFn {
    return (
      control: FormControl
    ):
      | Promise<ValidationErrors | null>
      | Observable<ValidationErrors | null> => {
      // 入力項目値格納先
      let inputValue;
      if (INPUT_INFORMATIONS_API_CONSTANT.CHECKBOX_TYPE == inputType) {
        inputValue = control.value;
      } else if (INPUT_INFORMATIONS_API_CONSTANT.NUMBER_TYPE == inputType) {
        let { value } = control;
        value = value.toLocaleString('fullwide', { useGrouping: false })
        if (value.length > 10 && colId == 'order_num') {
          control.setValue(value.substring(0, 10));
        }
        inputValue = value.substring(0, 10);

      }
      else if (typeof (control.value) == "object" && control.value) {
        inputValue = control.value["value"];
      } else {
        inputValue = control.value;
        if (inputValue != null && inputValue.length > 1000) {
          return of({ 'invalidLength': true });
        }
      }
      // チェックAPIを呼び出し
      return this.validatorService
        .getValidatorResult(tblId, colId, inputValue)
        .pipe(
          map((response) => {
            /* 入力文字列の置換 */
            // 入力タイプを判定
            if ((INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE == inputType ||
              INPUT_INFORMATIONS_API_CONSTANT.NUMBER_TYPE == inputType ||
              INPUT_INFORMATIONS_API_CONSTANT.TEXTAREA_TYPE == inputType) && inputValue != response.body.value) {
              control.setValue(response.body.value);
            }

            // エラーメッセージ出力先格納変数
            let errors = new Object();
            // エラーメッセージが存在するか否か
            if (response.body.message) {
              // エラーメッセージが存在する場合

              // エラーメッセージ出力先格納変数にエラーメッセージを格納
              errors['message'] = response.body.message;
            }
            return errors;
          })
        );
    }
  }
}
