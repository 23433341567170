<p-dialog
  header="ニーズ詳細"
  [(visible)]="boardFlag"
  [modal]="true"
  [responsive]="true"
  [styleClass]="'main-dialog'"
  (onHide)="closeDialog()"
>
  <div class="main-height">
    <!-- メイン -->

    <!-- メインコンテンツ -->
    <div class="main-content">
      <div class="p-fluid p-formgrid p-grid screen-support">
        <div class="p-col-12">
          <div class="p-fluid p-formgrid p-grid support-header">
            <div class="p-col-12">
              <p-card
                class="support-header-card"
              >
                <div class="support-header-card-show">
                  <div
                    class="p-fluid p-formgrid p-grid teacher-content"
                  >
                    <div class="p-col-4 image">
                      <div
                        class="support-header-card-detail-img"
                        [ngStyle]="{
                          'background-image':
                            'url(' + detailTask?.icon_image + ')'
                        }"
                      ></div>
                    </div>
                    <div class="p-col-8">
                      <span
                        class="tittle-teacher text-white-color"
                        [ngClass]="setClasses(detailTask?.category)"
                        >{{ detailTask?.category }}
                      </span>
                      <div class="box-content">
                        <h6 class="title-header bold letter-spacing-1">
                          {{ detailTask?.title }}
                        </h6>
                        <h6 class="elementary-school letter-spacing-1">
                          {{ detailTask?.organization_name }}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </p-card>
            </div>

            <div class="p-col-12">
              <div class="support-separate"></div>
            </div>

            <div class="p-col-12">
              <div class="support-table-detail">
                <div class="support-line-first">
                  <div class="p-fluid p-formgrid p-grid">
                    <div class="p-col-3 large-title">
                      <span class="title-overview">希望内容</span>
                    </div>
                    <div class="p-col-9 description-title-overview">
                      <span>
                        {{ detailTask?.expected_content }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="p-col-12">
              <hr class="sperate-between-line" />
            </div>

            <div class="p-col-12">
              <div class="support-table-detail">
                <div class="support-line-third">
                  <div class="p-fluid p-formgrid p-grid">
                    <div class="p-col-3 large-title">
                      <span class="title-overview">希望日・時期</span>
                    </div>
                    <div class="p-col-9 description-title-overview">
                      <span>
                        {{ detailTask?.expected_datetime }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="p-col-12">
              <hr class="sperate-between-line" />
            </div>

            <div class="p-col-12">
              <div class="support-table-detail">
                <div class="support-line-second">
                  <div class="p-fluid p-formgrid p-grid">
                    <div class="p-col-3 large-title">
                      <span class="title-overview no-wrap"
                        >希望人数
                      </span>
                    </div>
                    <div class="p-col-9 description-title-overview">
                      <span>
                        {{ detailTask?.expected_people_number }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="p-col-12">
              <hr class="sperate-between-line" />
            </div>

            <div class="p-col-12">
              <div class="support-table-detail">
                <div class="support-line-fourth">
                  <div class="p-fluid p-formgrid p-grid">
                    <div class="p-col-3 large-title">
                      <span class="title-overview no-wrap"
                        >対象学年</span
                      >
                    </div>
                    <div class="p-col-9 description-title-overview">
                      <span>
                        {{ detailTask?.target_grade }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="p-col-12">
              <hr class="sperate-between-line" />
            </div>

            <div class="p-col-12">
              <div class="support-table-detail">
                <div class="support-line-fourth">
                  <div class="p-fluid p-formgrid p-grid">
                    <div class="p-col-3 large-title">
                      <span class="title-overview no-wrap"
                        >実施形態</span
                      >
                    </div>
                    <div class="p-col-9 description-title-overview">
                      <span>
                        {{ detailTask?.implementation_format }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-col-12">
              <hr class="sperate-between-line" />
            </div>

            <div class="p-col-12">
              <div class="support-table-detail">
                <div class="support-line-sixth">
                  <div class="p-fluid p-formgrid p-grid">
                    <div class="p-col-3 large-title">
                      <span class="title-overview">資料</span>
                    </div>
                    <div class="p-col-9 description-title-overview">
                      <a [href]="detailTask?.document?.url" class="linktext-blue underline">
                        {{ detailTask?.document?.name }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="p-col-12">
              <hr class="sperate-between-line" />
            </div>

            <div class="p-col-12">
              <div class="support-table-detail">
                <div class="support-line-fifth">
                  <div class="p-fluid p-formgrid p-grid">
                    <div class="p-col-3 large-title">
                      <span class="title-overview">備考</span>
                    </div>
                    <div class="p-col-9 description-title-overview">
                      <span>
                        {{ detailTask?.remark }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="p-col-12">
              <div class="support-separate"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
