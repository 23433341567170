import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { SESSION_KEY, environment_i18n } from 'manager/environment';
import { Routing } from './shared/service/interceptor.service';
import { Title } from '@angular/platform-browser';
import { TITLE } from 'manager/environment';
import { ActivatedRoute, ActivationStart, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ROLES } from './shared/constant/constant';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // メッセージ用ヘッダー判定フラグ
  headerFlag: boolean;
  breadcrumbTitle: string = '';
  constructor(
    private config: PrimeNGConfig,
    private translateService: TranslateService,
    private routing: Routing,
    private titleService: Title,
    private route: ActivatedRoute,
    private readonly _router: Router
  ) {
    this.deviceOrientation();
    window.addEventListener('resize', e => {
      this.deviceOrientation();
    })
    window.addEventListener('orientationchange', e => {
      this.deviceOrientation();
    });
    // ページのヘッダ情報を変更

    this.titleService.setTitle(TITLE.TITLE_BAR);

    // ルーティング遷移時のチェック処理
    this.routing;

    // ルーティング遷移時のコンポーネント判定
    this._router.events
      .pipe(filter((event) => event instanceof ActivationStart))
      .subscribe((event: any) => {
        // 遷移先コンポーネントがNewLoginComponentの場合、ヘッダーのアイコンを非表示にする
        this.headerFlag =
          event.snapshot.routeConfig.component.name == 'NewLoginComponent'
            ? false
            : true;
      });
    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.breadcrumbTitle = this.route.root.firstChild.snapshot.data['breadcrumbTitle']
      });
    this.translateService.setDefaultLang(environment_i18n.defaultLang);

    // PrimeNGを日本語化
    this.translateService
      .get('primeng')
      .subscribe((res) => this.config.setTranslation(res));
  }

  ngOnInit() { }
  deviceOrientation() {
    document.body.classList.value = '';
    if ((window.innerWidth < 450 || window.innerHeight < 450)) {
      document.documentElement.style.removeProperty('--app-height');
      document.documentElement.style.removeProperty('--app-width');
      document.documentElement.style.removeProperty('--inner-height');
      switch (screen.orientation.angle) {
        case 90:
          document.body.classList.add('landscape');
          document.documentElement.style.setProperty('--app-height', `${window.innerWidth}px`);
          document.documentElement.style.setProperty('--app-width', `450px`);
          document.documentElement.style.setProperty('--inner-height', `${window.innerHeight}px`);
          break;
        case 270:
          document.body.classList.add('landscape');
          document.documentElement.style.setProperty('--app-height', `${window.innerWidth}px`);
          document.documentElement.style.setProperty('--app-width', `450px`);
          document.documentElement.style.setProperty('--inner-height', `${window.innerHeight}px`);
          break;
        default:
          document.body.classList.add('portrait');
          document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
          document.documentElement.style.setProperty('--app-width', `${window.innerWidth}px`);
          document.documentElement.style.setProperty('--inner-height', `${window.innerHeight}px`);
          break;
      }
    } else {
      document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
      document.documentElement.style.setProperty('--app-width', `${window.innerWidth}px`);
    }
  }

  controlCreadcrumbTitle() {
    if (window.location.pathname.toLowerCase() == "/pages/platform/c201") {
      const userLogin = JSON.parse(window.sessionStorage.getItem(SESSION_KEY.loginUserInformation));
      if (userLogin?.department_type != ROLES.SALES_1) {
        return false;
      }
    }
    return true;
  }
}
