import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { USER_AUTHINFOS } from "manager/http-constants";
import { SESSION_KEY } from "src/app/shared/constant/session-constants";
import { map } from "rxjs/operators";
import { MESSAGE_CODE } from "src/app/shared/constant/message-constant";
import { CommonService } from "./common.service";
import {
  MessageData,
  ToastMessageData,
} from "../html-parts/message-common/message-data";
import { TOAST } from "../constant/primeng-constants";

const credentialsKey = 'access_token';

@Injectable({
  providedIn: "root",
})

/**
 * ログイン処理
 */
export class LoginService {
  private _credentials: any;
  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private messageData: MessageData
  ) {
    const savedCredentials = localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      this._credentials = savedCredentials;
    }
  }

  /**
   * ログアウト処理
   * @param messageCode メッセージコード
   */
  public logout(messageCode?: string): void {
    sessionStorage.clear();
    localStorage.clear();

    // メッセージコードが存在するか否か
    if (!messageCode) {
      // メッセージコードが存在しない場合

      // メッセージコード(ログアウトしました)を標準セット
      messageCode = MESSAGE_CODE.N90000;
    }

    // セッションにログアウト画面用のメッセージコードを格納
    window.sessionStorage.setItem(SESSION_KEY.loginOutMessageCode, messageCode)
    
    const urlLogout = window.location.origin + '/login?redirect_uri={0}'
    window.location.href = urlLogout.replace('{0}', window.location.origin + '/top')
  }

  /**
   * ログインユーザ情報取得
   * @returns JSONデータ
   */
  public getLoginUser(): Observable<any> {
    // ログインユーザ情報を返却する
    return this.http
      .get(this.commonService.url(USER_AUTHINFOS), { observe: "response" })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * ログインメッセージ
   * @param loginUserName ログインユーザ名
   */
  public loginMessage(loginUserName?: string): void {
    // ログインメッセージを出力
    this.messageData.toastMessage(
      new ToastMessageData({
        severity: TOAST.INFO,
        summary: this.commonService.msg(MESSAGE_CODE.N80000),
        detail: this.commonService.msg(MESSAGE_CODE.N80001, loginUserName),
        position: TOAST.TOP_RIGHT,
        life: 5000,
      })
    );
  }

  /**
   * ログアウトメッセージ
   */
  public logoutMessage(): void {
    // ログアウトメッセージを出力
    this.messageData.toastMessage(
      new ToastMessageData({
        severity: TOAST.INFO,
        summary: this.commonService.msg(MESSAGE_CODE.N80002),
        detail: this.commonService.msg(MESSAGE_CODE.N80003),
        position: TOAST.TOP_RIGHT,
        life: 5000,
      })
    );
  }

  public getAuth0LoginState(): any {
    return !!this.credentials;
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): any {
    return this._credentials;
  }

  public getAuth0LoginToken(): Observable<any> {
    return this.credentials;
  }

  /**
   * Sets the user credentials.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   */
  setCredentials(credentials?: string) {
    this._credentials = credentials || null;

    if (credentials) {
      localStorage.setItem(credentialsKey, credentials);
    } else {
      localStorage.removeItem(credentialsKey);
    }
  }

  
  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(code: any, redirect_uri: any): Observable<any> {
    // Replace by proper authentication call
    const AUTH_TOKEN = 'https://staging-transport.auth.ap-northeast-1.amazoncognito.com/oauth2/token'
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const dataBody = {
      'grant_type': 'authorization_code',
      'client_id': '3nrprle24kbu17llooplvc3ije',
      'code': code,
      'redirect_uri': redirect_uri,
      'client_secret': '10476eta9p78okt6iqiumulqegk8rmbm6475tucdstea143ai85p'
    }
    const formBody = [];
    for (var property in dataBody) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(dataBody[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    const body = formBody.join("&");
    let options = { headers: headers };
    return this.http.post(
      AUTH_TOKEN,
      body,
      options
    ).pipe(
      map((data: any) => {
        // login successful if there's a jwt token in the response
        if (data && data.id_token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.setCredentials(data.id_token);
        }
        return data.id_token;
      })
    );
  }
}
