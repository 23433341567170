/** 発注管理 */
export const BREADCRUMB_ORDER = {
  SELECT_WORK_EMPLOYEE_BUSINESS: '営業担当',
  SELECT_WORK_EMPLOYEE_SALES: '実務担当',
  SELECT_WORK_ITEM: '資材選択',
  SELECT_WORK_DELIVERY_FROM: '差出元情報',
  SELECT_WORK_DELIVERY_TO: '届先情報',
  INPUT_WORK_ORDER: '差出・届先詳細',
  QUANTITY_INPUT: '数量・納期',
};
